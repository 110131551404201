import React, { Component } from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { BookshelfService } from "../Services/Services";
import { Dropdown, } from 'react-bootstrap';
import DOMPurify from 'dompurify'
import { BackButton } from "./BackButton";
import { InternalBackButton } from "./InternalBackButton";
import { LoadingComponent, LoadingComponentWeb } from "./LoadingComponent";
class BookshelfSingleModule extends Component {

    constructor(props) {
        super(props)

        this.state = {
            bs: new BookshelfService(),
            loading: true,
            viewAll_available: false,
            viewAll_unavailable: false,
            viewAll_borrowed: false,
            avalaible_books: [],
            avalaible_books_viewAll: [],
            unavalaible_books: [],
            unavalaible_books_viewAll: [],
            borrowed_books: [],
            borrowed_books_viewAll: [],
            page_items: 14,
            searching: '',
            currentPage: 1,

            selectedBook: '',
            filtered_books: [],
            total_filtered_books: [],
            barcode_modal: false,
            result: '',
            stopStream: false,
            data: '',
            book_scan_info: null,
            cam_loading: false,
            isbn: '',
            info: '',
            categories: [],
            categoryDropdownName: 'Category',
            isSearching: false,
            viewAll_status: '',
            tutorial_pages: [],
            selectedTutorialPageID: 1,
            tutorialPopup: false,
            selectedTutorialPage: 0
        }

        this.goBack = this.goBack.bind(this)
        this.handleViewAll = this.handleViewAll.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.setSelectedBook = this.setSelectedBook.bind(this)
        this.deselectBook = this.deselectBook.bind(this)
        this.openBarCodeModal = this.openBarCodeModal.bind(this)
        this.closeBarCodeModal = this.closeBarCodeModal.bind(this)
        this.setStopStream = this.setStopStream.bind(this)
        this.setData = this.setData.bind(this)
        this.borrowBook = this.borrowBook.bind(this)
        this.returnBook = this.returnBook.bind(this)
        this.retry = this.retry.bind(this)
        this.setCamLoading = this.setCamLoading.bind(this)
        this.setBookInfo = this.setBookInfo.bind(this)

        this.setLoading = this.setLoading.bind(this)
        this.setAvalaibleBooks = this.setAvalaibleBooks.bind(this)
        this.setAvalaibleBooksViewAll = this.setAvalaibleBooksViewAll.bind(this)
        this.setUnavalaibleBooks = this.setUnavalaibleBooks.bind(this)
        this.setUnavalaibleBooksViewAll = this.setUnavalaibleBooksViewAll.bind(this)
        this.setBorrowHistory = this.setBorrowHistory.bind(this)
        this.setBorrowHistoryViewAll = this.setBorrowHistoryViewAll.bind(this)
        this.borrow = this.borrow.bind(this)
        this.search = this.search.bind(this)
        this.resetSearchText = this.resetSearchText.bind(this)
        this.setCategories = this.setCategories.bind(this)
        this.selectCategory = this.selectCategory.bind(this)
        this.setFilteredBooks = this.setFilteredBooks.bind(this)
        this.setHasMore = this.setHasMore.bind(this)
        this.selectTutorialPage = this.selectTutorialPage.bind(this)
        this.notify = this.notify.bind(this)
        this.openTutorialPopup = this.openTutorialPopup.bind(this)
        this.changeTutorialPage = this.changeTutorialPage.bind(this)

    }

    componentDidMount() {
        this.reload()

        if (this.props.bookshelf && this.props.bookshelf.bookshelfs && this.props.bookshelf.bookshelfs.id)
            this.state.bs.getCategories(this.props.bookshelf.bookshelfs.id).then((data) => {
                this.setCategories(data)
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fromDate != this.state.fromDate) {
            this.setState({
                loadingStats: true
            })
            this.getStats('', true, false);
        }
    }

    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }
    async setLoading(val) {
        this.setState({
            loading: val
        })
    }

    reload() {
        this.setLoading(true)
        if (this.props.bookshelf && this.props.bookshelf.bookshelfs && this.props.bookshelf.bookshelfs.id)
            this.state.bs.getBookshelfByID(this.props.bookshelf.bookshelfs.id).then((data) => {

                this.setAvalaibleBooks(data.available_books);
                this.setUnavalaibleBooks(data.unavailable_books);
                this.setBorrowHistory(data.borrow_history);
                this.setHasMore(data);
                this.setLoading(false)
            })
        else this.setLoading(false)
    }

    setAvalaibleBooks(data) {
        this.setState({
            avalaible_books: data
        })
    }

    setAvalaibleBooksViewAll(data) {
        this.setState({
            avalaible_books_viewAll: data
        })
    }

    setUnavalaibleBooks(data) {
        this.setState({
            unavalaible_books: data
        })
    }

    setUnavalaibleBooksViewAll(data) {
        this.setState({
            unavalaible_books_viewAll: data
        })
    }

    setBorrowHistory(data) {
        this.setState({
            borrowed_books: data
        })
    }

    setBorrowHistoryViewAll(data) {
        this.setState({
            borrowed_books_viewAll: data
        })
    }

    setCategories(data) {
        this.setState({
            categories: data
        })
    }

    selectCategory(category) {
        let category_id = ''

        if (category === '') {
            category_id = ''
            this.setState({
                categoryDropdownName: 'Category',
                selectCategoryID: '',
                loading: true
            })
        } else {
            this.setState({
                categoryDropdownName: category.name,
                selectCategoryID: category.id,
                loading: true
            })
            category_id = category.id
        }

        let options = {}
        if (this.state.viewAll_available) {
            options = {
                "categoryID": category_id,
                "search_text": this.state.searching,
                "status": 'available'
            }
        } else if (this.state.viewAll_unavailable) {
            options = {
                "categoryID": category_id,
                "search_text": this.state.searching,
                "status": 'unavailable'
            }
        } else if (this.state.viewAll_borrowed) {
            options = {
                "categoryID": category_id,
                "search_text": this.state.searching,
                "status": 'borrowed'
            }
        }

        this.state.bs.searchV2(options, this.props.bookshelf.bookshelfs.id).then((data) => {
            this.setFilteredBooks(data)
            this.setLoading(false)
        })
    }

    setFilteredBooks(data) {
        if (this.state.viewAll_available) {
            this.setState({
                avalaible_books_viewAll: data,
                avalaible_books: data
            })
        } else if (this.state.viewAll_unavailable) {
            this.setState({
                unavalaible_books_viewAll: data
            })
        } else if (this.state.viewAll_borrowed) {
            this.setState({
                borrowed_books_viewAll: data
            })
        }
    }

    goBack() {
        /* this.props.setPathlistValue(null)
         this.props.select('offices', this.props.selected_office)
         this.props.setHidden(false)*/

        if (this.props.user.modules.my_office)
            this.props.select('home', this.props.user.modules.my_office)
        else {
            setTimeout(() => {
                this.props.select('home', null)
            }, 500);
        }
    }

    handleViewAll(val, books) {
        if (val !== 'back' && window.innerWidth < 1000)
            document.getElementById('sidebar').style.display = 'none'
        if (val === 'back' && window.innerWidth < 1000)
            document.getElementById('sidebar').style.display = 'block'
        switch (val) {
            case this.getTranslationByKey('BS.HOME.AVAILABLE'): {
                if (this.state.isSearching) {
                    this.setLoading(false)
                    this.setAvalaibleBooksViewAll(this.state.avalaible_books)
                    this.setState({
                        viewAll_available: true,
                        viewAll_unavailable: false,
                        viewAll_borrowed: false,
                        viewAll_status: 'available'

                    })
                } else {
                    this.state.bs.getAvailableBooksByID(this.props.bookshelf.bookshelfs.id).then((data) => {
                        this.setAvalaibleBooksViewAll(data)
                        this.setState({
                            viewAll_available: true,
                            viewAll_unavailable: false,
                            viewAll_borrowed: false,
                            viewAll_status: 'available'
                        })
                    })
                }
                break;
            }
            case this.getTranslationByKey('BS.HOME.UNAVAILABLE'): {
                if (this.state.isSearching) {
                    this.setLoading(false)
                    this.setUnavalaibleBooksViewAll(this.state.unavalaible_books)
                    this.setState({
                        viewAll_available: false,
                        viewAll_unavailable: true,
                        viewAll_borrowed: false,
                        viewAll_status: 'unavailable'

                    })
                } else {
                    this.state.bs.getUnavailableBooksByID(this.props.bookshelf.bookshelfs.id).then((data) => {
                        this.setUnavalaibleBooksViewAll(data)
                        this.setState({
                            viewAll_available: false,
                            viewAll_unavailable: true,
                            viewAll_borrowed: false,
                            viewAll_status: 'unavailable'
                        })
                    })
                }
                break;
            }
            case this.getTranslationByKey('BS.HOME.BORROWEDBYME'): {
                if (this.state.isSearching) {
                    this.setLoading(false)
                    this.setBorrowHistoryViewAll(this.state.borrowed_books)
                    this.setState({
                        viewAll_available: false,
                        viewAll_unavailable: false,
                        viewAll_borrowed: true,
                        avalaible_books_viewAll: [],
                        unavalaible_books_viewAll: [],
                        borrowed_books_viewAll: books,
                        viewAll_status: 'borrowed'

                    })
                } else {
                    this.state.bs.getBorrowHistory().then(() => {
                        this.setBorrowHistoryViewAll()
                        this.setState({
                            viewAll_available: false,
                            viewAll_unavailable: false,
                            viewAll_borrowed: true,
                            avalaible_books_viewAll: [],
                            unavalaible_books_viewAll: [],
                            borrowed_books_viewAll: books,
                            viewAll_status: 'borrowed'
                        })
                    })
                }
                break;
            }
            case 'back':
                this.setState({
                    currentPage: 1,
                    viewAll_available: false,
                    viewAll_unavailable: false,
                    viewAll_borrowed: false,
                    avalaible_books_viewAll: [],
                    unavalaible_books_viewAll: [],
                    borrowed_books_viewAll: [],
                    isSearching: false,
                    searching: '',
                    viewAll_status: '',
                    categoryDropdownName: 'Category',
                    selectCategoryID: ''
                })
                this.reload()

                break;
            default: break
        }
    }

    getBookshelfLine(flag, list, listViewAll, title, has_more) {
        return (
            <div className="bookshelf_line_single_module" Style={flag && window.innerWidth < 1000 ? 'height: 450px;' : ''} >
                {flag ?
                    <React.Fragment>
                        <div className="bookshelf_section_title_container flex">
                            <div className="category_margin" Style={"margin-left: auto;"}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-bookshelf">
                                        {this.state.categoryDropdownName}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.selectCategory('')} key={''} >{this.getTranslationByKey("BOOKSHELF.ALLCATEGORIES")}</Dropdown.Item>

                                        {this.state.categories.map((c) =>
                                            <Dropdown.Item onClick={() => this.selectCategory(c)} key={c.id}>{c.name}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    ''
                }

                <div className="bookshelf_section_title_container flex ">
                    <div className="bookshelf_section_title ">{title}</div>
                </div>
                <div className={flag ? "bookshelf_section_books_all_single_module" : "bookshelf_section_books"}>
                    <div className="bookshelf_section_books_container flex" Style={flag ? ' flex-wrap: wrap;  ' : ''}>
                        {flag ?
                            listViewAll.map((book, index) =>
                                <div key={title + "_" + index} className={title === this.getTranslationByKey('BS.HOME.BORROWEDBYME') && !book.borrowed ? "book_cover_img_sigle_module pointer past_book relative" : "book_cover_img_sigle_module pointer relative"} Style={"background-image: url('" + book.image_url + "');"} onClick={() => this.setSelectedBook(book)}></div>
                            )
                            :
                            (list.map((book, index) =>
                                <div key={title + "_" + index} className={title === this.getTranslationByKey('BS.HOME.BORROWEDBYME') && !book.borrowed ? "book_cover_img_sigle_module pointer past_book relative" : "book_cover_img_sigle_module pointer relative "} Style={"background-image: url('" + book.image_url + "');"} onClick={() => this.setSelectedBook(book)}></div>
                            ))}
                        {flag && listViewAll.length == 0 ? <div >{this.getTranslationByKey('BS.HOME.NOBOOKSFOUND')}</div> : (!flag && list.length == 0 ? <div >{this.getTranslationByKey('BS.HOME.NOBOOKSFOUND')}</div> : '')}
                        {(!flag && has_more && list.length > 0) ? <div className="relative">
                            <div key={title + "_-1"} className="book_cover_img_sigle_module book_cover_img_view_all relative pointer" onClick={() => this.handleViewAll(title, list)}></div>
                            <div className="bookshelf_card_view_all_single_module pointer" onClick={() => this.handleViewAll(title, list)}>{this.getTranslationByKey('BS.HOME.VIEWALL')}</div>
                        </div> : ''}
                    </div>
                </div>
                {flag ?
                    <div Style={"width: 100%; place-content: center !important; display: flex;"} >
                    </div>
                    : null}
            </div >
        )
    }

    handleSearch(e) {
        if (e.target.value === '') {
            this.setState({
                searching: e.target.value,
                isSearching: false,
                books: []
            })
            this.reload()
        } else
            this.setState({
                searching: e.target.value,
            })
    }

    resetSearchText() {
        if (this.state.searching !== '') {
            this.setState({
                searching: '',
                isSearching: false,

                books: []
            })
            setTimeout(() => {
                this.search()
            }, 200)
        }
    }

    search() {

        let options = {}

        this.setState({
            isSearching: true,
            loading: true,
        })

        if (this.state.viewAll_available || this.state.viewAll_unavailable || this.state.viewAll_borrowed) {
            options = {
                "categoryID": this.state.selectCategoryID,
                "search_text": this.state.searching,
                "status": this.state.viewAll_status
            }

            this.state.bs.searchV2(options, this.props.bookshelf.bookshelfs.id).then((data) => {
                this.setFilteredBooks(data)
                this.setLoading(false)
            })
        } else {

            options = {
                "categoryID": '',
                "search_text": this.state.searching
            }

            this.state.bs.searchAll(options, this.props.bookshelf.bookshelfs.id,).then((data) => {
                this.setAvalaibleBooks(data.available_books);
                this.setUnavalaibleBooks(data.unavailable_books);
                this.setBorrowHistory(data.borrow_history);
                this.setLoading(false)
            })
        }
    }

    setSelectedBook(book) {
        if (document.getElementById('sidebar'))
            document.getElementById('sidebar').style.display = 'none'
        this.setState({
            selectedBook: book
        })
    }

    deselectBook() {
        if (document.getElementById('sidebar') && window.innerWidth < 1000)
            document.getElementById('sidebar').style.display = 'block'
        this.setState({
            selectedBook: '',
        })
        this.closeBarCodeModal()
    }

    openBarCodeModal() {
        this.setState({
            barcode_modal: true
        })
    }

    closeBarCodeModal() {
        this.setState({
            barcode_modal: false,
            result: null,
            data: null,
            book_scan_info: null,
            stopStream: false
        })
    }

    async setStopStream(val) {
        this.setState({
            stopStream: val
        })
    }

    setBookInfo(data) {
        if (data.title) {
            this.setState({
                book_scan_info: data,
                selectedBook: data,
            })
            this.closeBarCodeModal()
        }
        else {
            this.setState({
                book_scan_info: data,
            })
        }
    }

    setData(data) {
        if (data !== "Not Found" && !this.state.result) {
            this.setCamLoading(true)
            this.setState({
                data: data,
                result: data,
                isbn: data
            })
            setTimeout(() => {

                this.setStopStream(true)
            }, 1000);

            let isbn = {
                isbn: data
            }

            this.state.bs.getInfoByIsbn(this.props.bookshelf.bookshelfs.id, isbn).then((data) => {
                this.setBookInfo(data)
            })
            setTimeout(() => {
                this.setCamLoading(false)
            }, 200);

        } else {
            this.setCamLoading(false)
        }

    }

    retry() {
        this.setCamLoading(true)
        setTimeout(() => {

            this.setState({
                data: null,
                result: null,
                book_scan_info: null,
            })
            this.setStopStream(false)

            this.setCamLoading(false)
        }, 500);

    }

    borrow() {
        this.state.bs.borrow(this.props.bookshelf.bookshelfs.id, this.state.book_scan_info.id).then(() => {
            this.closeBarCodeModal()

        })
    }

    async setCamLoading(val) {
        this.setState({
            cam_loading: val
        })
    }

    borrowBook() {
        this.state.bs.borrow(this.props.bookshelf.bookshelfs.id, this.state.selectedBook.id).then(() => {
            this.closeBarCodeModal()

            this.setState({
                selectedBook: '',
                viewAll_available: false,
                viewAll_unavailable: false,
                viewAll_borrowed: false,
                viewAll_status: '',
                avalaible_books_viewAll: [],
                unavalaible_books_viewAll: [],
                borrowed_books_viewAll: [],
            })
            if (window.innerWidth < 1000)
                document.getElementById('sidebar').style.display = 'block'
            this.reload()
        })
    }

    returnBook() {
        this.state.bs.returnBook(this.state.selectedBook.borrow_id).then(() => {
            this.setState({
                selectedBook: '',
                viewAll_available: false,
                viewAll_unavailable: false,
                viewAll_borrowed: false,
                avalaible_books_viewAll: [],
                unavalaible_books_viewAll: [],
                borrowed_books_viewAll: [],
            })
            if (window.innerWidth < 1000)
                document.getElementById('sidebar').style.display = 'block'
            this.reload()
        })
    }

    getFilteredSearch() {
        /* funzione per la barra di ricerca dei books */
        var searchingList = ''
        if (this.state.viewAll_available) {

            searchingList = this.state.avalaible_books_viewAll.map((book, index) =>
                <React.Fragment>
                    <div key={"searching_" + index} className="book_cover_img_sigle_module pointer" Style={"background-image: url('" + book.image_url + "');"} onClick={() => this.setSelectedBook(book)}></div>
                </React.Fragment>
            )

        } else if (this.state.viewAll_unavailable) {
            searchingList = this.state.unavalaible_books_viewAll.map((book, index) =>
                <React.Fragment>
                    <div key={"searching_" + index} className="book_cover_img_sigle_module pointer" Style={"background-image: url('" + book.image_url + "');"} onClick={() => this.setSelectedBook(book)}></div>
                </React.Fragment>
            )
        } else if (this.state.viewAll_borrowed) {
            searchingList = this.state.borrowed_books_viewAll.map((book, index) =>
                <React.Fragment>
                    <div key={"searching_" + index} className="book_cover_img_sigle_module pointer" Style={"background-image: url('" + book.image_url + "');"} onClick={() => this.setSelectedBook(book)}></div>
                </React.Fragment>
            )
        }

        return searchingList
    }

    setHasMore(data) {
        this.setState({
            has_more_available_books: data.has_more_available_books,
            has_more_borrow_history: data.has_more_borrow_history,
            has_more_unavailable_books: data.has_more_unavailable_books
        })
    }

    selectTutorialPage(val) {
        let page_id = this.state.selectedTutorialPageID
        if (val)
            page_id += 1
        else
            page_id -= 1


        this.setState({
            selectedTutorialPageID: page_id,
            selectedTutorialPage: this.state.tutorial_pages[page_id - 1]
        })
    }

    getDate(string) {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(string).toLocaleDateString([], options);
    }

    notify() {
        this.state.bs.notifyMe(this.props.bookshelf.bookshelfs.id, this.state.selectedBook.id).then(() => {
            let sel = this.state.selectedBook
            if (this.state.selectedBook.is_followed)
                sel.is_followed = false
            else
                sel.is_followed = true
            this.setSelectedBook(sel)
            if (window.innerWidth < 1000)
                document.getElementById('sidebar').style.display = 'block'
            this.reload()
        })
    }
    openTutorialPopup() {
        this.setState({
            tutorialPopup: !this.state.tutorialPopup,
            selectedTutorialPage: 0
        })
    }
    changeTutorialPage(type) {
        if (type === 'prev')
            this.setState({
                selectedTutorialPage: this.state.selectedTutorialPage - 1
            })
        else {
            this.setState({
                selectedTutorialPage: this.state.selectedTutorialPage + 1
            })
        }
    }
    render() {
        let customModalStyles = {

            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                maxWidth: '600px',
                width: 'calc(100% - 64px)',
                height: '85vh',
                marginRight: '-50%',
                padding: '15px',
                transform: 'translate(-50%, -50%)',
                'z-index': 99,
                transition: "opacity 20ms ease-in -out",
                "background-color": "#f0efef"
            },

        };
        /* funzione per la barra di ricerca dei books */
        var searchingList = ''
        if (this.state.filtered_books && this.state.isSearching) {

            searchingList = this.state.filtered_books.map((book, index) =>
                <React.Fragment>
                    <div key={"searching_" + index} className="book_cover_img_sigle_module pointer" Style={"background-image: url('" + book.image_url + "');"} onClick={() => this.setSelectedBook(book)}></div>
                </React.Fragment>
            )

        }
        let stream = this.state.stopStream
        return (
            this.state.loading ?
                <LoadingComponentWeb />
                :
                <React.Fragment>

                    {window.innerWidth > 1000 && (this.state.selectedBook !== '' || this.state.viewAll_status !== '')
                        ?
                        <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={this.state.selectedBook !== '' ? this.deselectBook : () => this.handleViewAll('back')} index={99} /> : ''}

                    {window.innerWidth < 1000 ?
                        <BackButton key='generic_back' backButtonFunction={this.goBack} index={210} />
                        : ''}
                    <div className="flex bookshelf" Style={window.innerWidth > 1000 ? 'position:relative' : ''}>
                        {this.state.selectedBook ?
                            <>
                                {this.state.selectedBook !== '' && window.innerWidth < 1000
                                    ?
                                    <BackButton key='selected_book' backButtonFunction={this.deselectBook} index={210} /> : ''
                                }

                                <div className="single_module_container pm_info_container_bookshelf_sm" Style={window.innerWidth < 1000 ? '' : 'width:' + parseInt(window.innerWidth - 400) + 'px;'}>
                                    <div className="bookshelf_detail_container_sm">
                                        <div className="bookshelf_detail_header">
                                            <div className="bookshelf_detail_header_container_single_module flex" Style="padding: 20px;">
                                                <div className="bookshelf_detail_cover_container_single_module">
                                                    <div className="bookshelf_detail_cover_single_module" Style={"background-image: url('" + this.state.selectedBook.image_url + "');"}></div>
                                                    <div Style={"text-align: center; text-align: -webkit-center;"} >
                                                        {this.state.selectedBook.borrowed ?
                                                            <div className="bookshelf_detail_button_single_module pointer" onClick={() => this.returnBook()}>{this.getTranslationByKey('BS.DETAIL.RETURNBOOK')}</div>
                                                            : this.state.selectedBook.available ?
                                                                <div className="bookshelf_detail_button_single_module pointer" onClick={() => this.borrowBook()}>{this.getTranslationByKey('BS.DETAIL.BORROWBOOK')}</div>

                                                                :
                                                                (!this.state.selectedBook.is_followed
                                                                    ? <div className="bookshelf_detail_button_single_module pointer" onClick={() => this.notify()}>{this.getTranslationByKey('BS.DETAIL.NOTIFY')}</div>
                                                                    :
                                                                    <div className="bookshelf_detail_button_single_module pointer" onClick={() => this.notify()}>{this.getTranslationByKey('BS.DETAIL.STOPNOTIFY')}</div>)
                                                        }

                                                    </div>
                                                </div>
                                                <div className="bookshelf_detail_title_container_single_module">
                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.TITLE.KEY')}</div>
                                                        <div className="bookshelf_detail_subtitle">{this.state.selectedBook.title}</div>
                                                    </div>
                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.AUTHOR.KEY')}</div>
                                                        <div className="bookshelf_detail_subtitle">{this.state.selectedBook.author}</div>
                                                    </div>
                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.STAUS.KEY')}</div>
                                                        <div className="bookshelf_detail_subtitle">{this.state.selectedBook.borrowed ? 'Borrowed' : this.state.selectedBook.available ? 'Available' : 'Unavailable'}</div>
                                                    </div>
                                                    {this.state.selectedBook.first_expected_return_date ?
                                                        <div Style={"margin-bottom: 10px;"}>
                                                            <div className="bookshelf_detail_title">Data di rientro prevista</div>
                                                            <div className="bookshelf_detail_subtitle">{this.getDate(this.state.selectedBook.first_expected_return_date)}</div>
                                                        </div>
                                                        : null}
                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.ISBN.KEY')}</div>
                                                        <div className="bookshelf_detail_subtitle">{this.state.selectedBook.isbn}</div>
                                                    </div>


                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.DESCRIPTION.KEY')}</div>
                                                        <div>{this.state.selectedBook.description}</div>
                                                    </div>
                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.CATEGORY.KEY')}</div>
                                                        <div>{this.state.selectedBook.category.name}</div>
                                                    </div>
                                                    {this.state.selectedBook.borrowed ?
                                                        <div className="flex">
                                                            <div Style={"margin-bottom: 10px;"}>
                                                                <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.RENTALDATE.KEY')}</div>
                                                                <div className="bookshelf_detail_description" Style={"text-transform: capitalize;"}>{this.getDate(this.state.selectedBook.borrowed_from)}</div>
                                                            </div>
                                                            <div className="bookshelf_rental_exp_date">
                                                                <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.RENTALEXPDATE.KEY')}</div>
                                                                <div className="bookshelf_detail_description" Style={"text-transform: capitalize;"}>{this.getDate(this.state.selectedBook.estimated_return_date)}</div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.PROVIDEDBY.KEY')}</div>
                                                        <div>{this.state.selectedBook.provided_by}</div>
                                                    </div>
                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.AVAILABLECOPY.KEY')}</div>
                                                        <div className="bookshelf_detail_description">{this.state.selectedBook.available_copies}</div>
                                                    </div>
                                                    <div Style={"margin-bottom: 10px;"}>
                                                        <div className="bookshelf_detail_title">{this.getTranslationByKey('BS.DETAIL.TOTOALCOPY.KEY')}</div>
                                                        <div className="bookshelf_detail_description">{this.state.selectedBook.total_copies}</div>
                                                    </div>

                                                    <div Style={"margin-top: 20px;"}>
                                                        <a href="mailto:ccms-bit@gucci.com">
                                                            <div Style={"text-align: center; text-align: -webkit-center;    padding-right: 20px;"} dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(this.getTranslationByKey('BS.DETAIL.DONATEBOOK'))
                                                            }}></div>
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div > </>
                            : <>
                                {this.state.viewAll_status !== '' && window.innerWidth < 1000 ?
                                    <BackButton id='view_all' backButtonFunction={() => this.handleViewAll('back')} index={210} />
                                    :
                                    ''}

                                <div className="single_module_container pm_info_container_bookshelf_sm" Style={window.innerWidth < 1000 ? '' : 'width:' + parseInt(window.innerWidth - 300) + 'px;'}>
                                    <div className="bookshelf_line_single_module flex">
                                        <div className="bookshelf_search_sm flex" Style={window.innerWidth < 1000 ? "width: 100%; margin-top: 10px;" : (this.state.viewAll_status !== '' ? 'width: 85%; margin-top: 12px;' : "width: 100%; margin-top: 10px;")}>
                                            <input className="bookshelf_search_input_single_module" id='bookshelf_search_input_single_module' placeholder={this.getTranslationByKey("DESKBOOKING.MAP.SEARCH")} onChange={(e) => this.handleSearch(e)} value={this.state.searching}>
                                            </input>
                                            <div id="search_reset_bookshelf_single_module" onClick={this.resetSearchText}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                            <div Style={"width: 50px;border-radius: 5px;    margin-right: 10px;"}>
                                                <div className="search_button_bookshelf pointer" onClick={() => this.search()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search" viewBox="0 0 16 16">
                                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className='barcode_cont'>
                                                <div id="barcode_search" className="pointer">
                                                    <svg onClick={() => this.openBarCodeModal()} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
                                                        <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                                                    </svg>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className={this.state.viewAll_status ? "" : "bookshelf_container"}>
                                        {this.state.isSearching && this.state.viewAll_status ?

                                            <div className="bookshelf_line_single_module" Style={"max-height: 500px;"}>
                                                <div className="bookshelf_section_title_container flex">
                                                </div>
                                                <div className="bookshelf_section_title_container flex">
                                                    <div className="bookshelf_section_title">{this.getTranslationByKey('BS.HOME.SEARCHRESULTS')}</div>
                                                    <div className="category_margin" Style={"margin-left: auto;"}>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="success" id="dropdown-bookshelf">
                                                                {this.state.categoryDropdownName}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => this.selectCategory('')} key={''}>{this.getTranslationByKey("BOOKSHELF.ALLCATEGORIES")}</Dropdown.Item>
                                                                {this.state.categories.map((c) =>
                                                                    <Dropdown.Item onClick={() => this.selectCategory(c)} key={c.id}>{c.name}</Dropdown.Item>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="bookshelf_section_books_all_single_module">
                                                    {this.getFilteredSearch().length > 0 ?
                                                        this.getFilteredSearch()
                                                        :
                                                        <div>{this.getTranslationByKey('BS.HOME.NOBOOKSFOUND')}</div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <React.Fragment>
                                                {!this.state.viewAll_unavailable && !this.state.viewAll_borrowed ?
                                                    this.getBookshelfLine(this.state.viewAll_available, this.state.avalaible_books, this.state.avalaible_books_viewAll, this.getTranslationByKey('BS.HOME.AVAILABLE'), this.state.has_more_available_books)
                                                    : null}
                                                {!this.state.viewAll_available && !this.state.viewAll_borrowed ?
                                                    this.getBookshelfLine(this.state.viewAll_unavailable, this.state.unavalaible_books, this.state.unavalaible_books_viewAll, this.getTranslationByKey('BS.HOME.UNAVAILABLE'), this.state.has_more_unavailable_books)
                                                    : null}
                                                {!this.state.viewAll_available && !this.state.viewAll_unavailable ?
                                                    this.getBookshelfLine(this.state.viewAll_borrowed, this.state.borrowed_books, this.state.borrowed_books_viewAll, this.getTranslationByKey('BS.HOME.BORROWEDBYME'), this.state.has_more_borrow_history)
                                                    : null}
                                            </React.Fragment>
                                        }
                                    </div>

                                    <Dialog
                                        open={this.state.barcode_modal}
                                        onClose={() => this.closeBarCodeModal()}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        fullWidth
                                        maxWidth="sm"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            <Typography className="gucci-text-bold" variant="h5" >
                                                {this.getTranslationByKey("BS.SCAN.TITLE")}
                                            </Typography>
                                            <div class='close_scan' onClick={this.closeBarCodeModal}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                </svg></div></DialogTitle>
                                        <DialogContent>
                                            <div className={!this.state.result ? "camera_container" : "camera_container_after"}>


                                                {!this.state.cam_loading ?
                                                    <BarcodeScannerComponent
                                                        width={"100%"}
                                                        height={350}
                                                        onUpdate={(err, result) => setTimeout(() => {

                                                            if (result) this.setData(result.text);
                                                            else this.setData("Not Found");
                                                        }, 500)
                                                        }
                                                        stopStream={stream}
                                                    />

                                                    : <LoadingComponent />}

                                            </div>
                                            {this.state.book_scan_info && this.state.book_scan_info !== 'not_found' ?
                                                <React.Fragment>
                                                    <div className="gucci-text-bold borrowing_text">{this.getTranslationByKey('BS.DETAIL.ALERT.TITLE2')}</div>
                                                    <div className="gucci-text borrowing_subtext">{this.state.book_scan_info.title}{this.state.book_scan_info.author}</div>
                                                </React.Fragment>
                                                : (this.state.book_scan_info === 'not_found' ?
                                                    <><div className="gucci-text borrowing_subtext">{this.getTranslationByKey('BS.HOME.NOBOOKSFOUND')}</div> <div className="gucci-text borrowing_subtext">{this.state.isbn}</div> </> : '')}
                                            {this.state.result ?
                                                <React.Fragment>
                                                    <div className="flex" Style={"width: 100%; margin-top: 5%;justify-content: center;"}>
                                                        <div className="bookshelf_button_retry" onClick={this.retry}>{this.getTranslationByKey('BS.SCAN.OTHER')}</div>
                                                        {this.state.book_scan_info === 'not_found' ? '' : <div className="bookshelf_button_retry" onClick={this.borrow} Style={"padding-top:15px"}>{this.getTranslationByKey('UTIL.LABEL.CONFIRM')}</div>}
                                                    </div>
                                                </React.Fragment> : ''}

                                        </DialogContent>

                                    </Dialog>
                                    <Dialog
                                        open={this.state.tutorialPopup}
                                        onAfterOpen={null}
                                        onClose={this.openTutorialPopup}
                                        style={customModalStyles}
                                        className="tutorial_dialog"
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                        fullWidth
                                        maxWidth="sm"
                                    >
                                        <DialogTitle>
                                            <div className="close" >
                                                <svg onClick={() => this.openTutorialPopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                        </DialogTitle>
                                        <DialogContent>
                                            <div className="modal_body pb-25">
                                                <div className="desk_tutorial_container">
                                                    <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                                        {this.state.selectedTutorialPage > 0 ?
                                                            <div>
                                                                <svg onClick={() => this.changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                                </svg>
                                                            </div>
                                                            : <div Style={"width: 30px;"}></div>}
                                                        {this.props.user && this.props.user.modules && this.props.user.modules.bookshelf && this.props.tutorial ? <div className="desk_tutorial_page" Style={"background-image: url('" + this.props.tutorial[this.state.selectedTutorialPage] + "')"}></div> : ''}

                                                    </div>
                                                </div>


                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                </div ></>}
                    </div>
                </React.Fragment>
        )
    }
}
export default BookshelfSingleModule