import { getTranslationByKey } from "../global";

export function CustomMenuButton(props) {
    return (<div className={" menu_row  " + props.class} onClick={() => props.handleClick(props.click_value)}>
        <div className="logo_menu">
            <div className="offices_logo" Style={'background-color:#' + props.color + ';'}>
                <div className={props.logo}></div>
            </div>
        </div>
        <div className="text_menu_container">
            <div className="text_menu desk_menu">{getTranslationByKey(props.title)}</div>
        </div>
    </div>)
}
