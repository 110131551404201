import React, { useEffect } from 'react';
import moment from 'moment'
import 'moment/locale/en-gb';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ReadyToGrowService } from '../Services/Services';
import { UseAlertMessage } from './Alert';
import { LoadingComponentWeb } from './LoadingComponent';
export default function ReadyToGrowSingleModule(props) {

    const [loading, setLoading] = React.useState(true);
    const [view, setView] = React.useState('week');
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [openDetailDialog, setOpenDetailDialog] = React.useState(false);
    const [startSlot, setStartSlot] = React.useState('');
    const [endSlot, setEndSlot] = React.useState('');
    const [slotID, setSlotID] = React.useState('');
    const [optionsToAdd, setOptionToAdd] = React.useState('')
    const [events, setEvents] = React.useState([])
    const [tutorialPopup, setTutorialPopup] = React.useState(false)
    const [selectedTutorialPage, setSelectedTutorialPage] = React.useState(0)

    useEffect(() => {
        getSlots()
    }, [])


    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    const getSlots = () => {
        let rs = new ReadyToGrowService()
        let date = new Date().getFullYear()
        let options = {
            "from_date": date + "-01-01",
            "to_date": date + "-12-31"
        }
        rs.getAdminSlots(options).then((data) => {
            setSlots(data)
        })
    }

    const setSlots = (data) => {
        let events = []
        let startDate, endDate;
        data.map((d) => {
            startDate = new Date(d.date).setHours(d.from.split(":")[0])
            startDate = new Date(startDate).setMinutes(d.from.split(":")[1])
            endDate = new Date(d.date).setHours(d.to.split(":")[0])
            endDate = new Date(endDate).setMinutes(d.to.split(":")[1])
            let startTitleHours = (new Date(startDate).getHours() < 10 ? '0' + new Date(startDate).getHours() : new Date(startDate).getHours())
            let startTitleMinutes = (new Date(startDate).getMinutes() < 10 ? '0' + new Date(startDate).getMinutes() : new Date(startDate).getMinutes())
            let endTitleHours = (new Date(endDate).getHours() < 10 ? '0' + new Date(endDate).getHours() : new Date(endDate).getHours())
            let endTitleMinutes = (new Date(endDate).getMinutes() < 10 ? '0' + new Date(endDate).getMinutes() : new Date(endDate).getMinutes())
            let e = {
                start: new Date(startDate),
                end: new Date(endDate),
                title: d.is_booked && d.booked_by ? startTitleHours + ':' + startTitleMinutes + '-' + endTitleHours + ':' + endTitleMinutes + ' - ' + d.booked_by.fullname : startTitleHours + ':' + startTitleMinutes + '-' + endTitleHours + ':' + endTitleMinutes,

                id: d.id,
                is_booked: d.is_booked
            }
            events.push(e)
        })
        setEvents(events)
        setLoading(false)
    }

    //dont remove e,view
    const nav = (e, view, type) => {
        if (type === 'DATE')
            setView('week')
    }

    const createSlot = () => {
        setLoading(true)
        let rs = new ReadyToGrowService()
        rs.addSlot(optionsToAdd).then(() => {
            setOpenCreateDialog(false)
            getSlots()
        }).catch(() => {
            setOpenCreateDialog(false)
        })
    }

    const openDetailPopup = (e) => {
        setStartSlot(e.start)
        setEndSlot(e.end)
        setSlotID(e.id)
        setOpenDetailDialog(true)
    }

    const selectSlot = (slot) => {
        let diff = new Date(slot.end).getTime() - new Date(slot.start).getTime()
        if (new Date(slot.start).getTime() < new Date().getTime() && diff != 86400000) {
            UseAlertMessage(404, getTranslationByKey("READYTOGROW.ERROR.PAST"))
        }
        else if (diff == 3600000) {
            let start = new Date(slot.start)
            let end = new Date(slot.end)
            setStartSlot(start)
            let start_date = start.getFullYear() + '-' + (parseInt(start.getMonth() + 1) < 9 ? '0' + parseInt(start.getMonth() + 1) : parseInt(start.getMonth() + 1)) + '-' + start.getDate()
            let from = (start.getHours() < 9 ? '0' + start.getHours() : start.getHours()) + ':' + (start.getMinutes() < 9 ? '0' + start.getMinutes() : start.getMinutes()) + ':00'
            let to = (end.getHours() < 9 ? '0' + end.getHours() : end.getHours()) + ':' + (end.getMinutes() < 9 ? '0' + end.getMinutes() : end.getMinutes()) + ':00'

            setEndSlot(new Date(slot.end))
            let options = {
                date: start_date,
                from: from,
                to: to
            }
            setOptionToAdd(options)
            setOpenCreateDialog(true)

        }
        else if (diff != 86400000) {
            UseAlertMessage(404, getTranslationByKey("READYTOGROW.ERROR.DURATION"))
        }
    }

    const removeSlot = () => {
        let rs = new ReadyToGrowService()
        setLoading(true)
        rs.deleteAdminSlot(slotID).then(() => {
            setOpenDetailDialog(false)
            getSlots()
        })
    }

    const localizer = momentLocalizer(moment)

    const openTutorialPopup = () => {
        setTutorialPopup(!tutorialPopup)
    }

    const changeTutorialPage = (type) => {
        if (type === 'prev')
            setSelectedTutorialPage(selectedTutorialPage - 1)
        else {
            setSelectedTutorialPage(selectedTutorialPage + 1)
        }
    }

    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '600px',
            width: 'calc(100% - 64px)',
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },

    };

    const closeTutorialPopup = () => {
        setSelectedTutorialPage(0)
        openTutorialPopup(false)
    }

    return (
        <React.Fragment>


            {loading ?
                <LoadingComponentWeb />
                : <div className='flex'>
                    <div className="single_module_container pm_info_container_bookshelf_sm ready_to_grow_sm_container" >
                        {window.innerWidth > 1000 ?
                            <div Style={'position:relative'} >
                                {props.tutorial && props.tutorial.length > 0 ?
                                    <div className='tutorial_icon_readytogrow_sm' onClick={openTutorialPopup}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                        </svg>
                                    </div> : ''}
                            </div>
                            : ''}
                        <div className="calendar_card" Style={'margin: -20px 20px 0px 20px'}>
                            <Calendar
                                localizer={localizer}
                                eventPropGetter={event => ({
                                    style: {
                                        background: event.is_booked ? "linear-gradient(180deg, #f0ba49 70%, rgb(212 212 212 / 41%) 100%)" : "linear-gradient(180deg, #000000 70%, #8c8c8c 100%)", borderColor: '#000000'
                                    }
                                })}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: '85vh' }}
                                onSelectEvent={(e) => openDetailPopup(e)}
                                onShowMore={() => setView('week')}
                                allDayAccessor={true}
                                view={view}
                                onNavigate={(e, t, v) => nav(e, t, v)}
                                onView={(v) => setView(v)}
                                selectable={true}
                                onSelectSlot={(e) => selectSlot(e)}
                                views={['month', 'week']}
                                min={new Date(2017, 10, 0, 8, 0, 0)}
                                max={new Date(2017, 10, 0, 20, 0, 0)}
                            />
                        </div>
                    </div>
                    {/* <div className='right_block'></div>*/}
                    {/*create slot*/}
                    <Dialog
                        open={openCreateDialog}
                        onClose={() => setOpenCreateDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle id="alert-dialog-title bold" className='bold' >
                            {getTranslationByKey('READYTOGROW.CREATE.SLOT')}
                        </DialogTitle>
                        <DialogContent>
                            <div>

                                {getTranslationByKey('READYTOGROW.CREATE.DATE')}
                                <br />

                                <div className='gucci-text-bold-m'>
                                    {startSlot ? startSlot.getDate() + '-' + (parseInt(startSlot.getMonth() + 1) < 10 ? '0' + parseInt(startSlot.getMonth() + 1) : parseInt(startSlot.getMonth() + 1)) + '-' + startSlot.getFullYear() : ''}
                                </div>

                                {getTranslationByKey('READYTOGROW.CREATE.TIME')}

                                <br />
                                <div className='gucci-text-bold-m'>
                                    {startSlot ? (startSlot.getHours() < 10 ? '0' + startSlot.getHours() : startSlot.getHours()) + ':' + (startSlot.getMinutes() == 0 ? '00' : startSlot.getMinutes()) : ''}
                                    -
                                    {endSlot ? (endSlot.getHours() < 10 ? '0' + endSlot.getHours() : endSlot.getHours()) + ':' + (endSlot.getMinutes() == 0 ? '00' : endSlot.getMinutes()) : ''}
                                </div>

                                <br />
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenCreateDialog(false)} className="modal_button">
                                Cancel
                            </Button>
                            <Button className="red dialog_close_button" autoFocus onClick={() => createSlot()}>
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*view slot / remove slot */}
                    <Dialog
                        open={openDetailDialog}
                        onClose={() => setOpenDetailDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"

                    >
                        <DialogTitle id="alert-dialog-title " className='bold' >
                            {getTranslationByKey('MENU.READYTOGROW.TITLE')}
                        </DialogTitle>

                        <DialogContent>
                            <div>
                                {getTranslationByKey("READYTOGROW.CREATE.DATE")}
                                <br />
                                <div className='bold gucci-text-bold-m'>
                                    {startSlot ? (startSlot.getDate() < 10 ? '0' + startSlot.getDate() : startSlot.getDate()) + '-' + (parseInt(startSlot.getMonth() + 1) < 10 ? '0' + parseInt(startSlot.getMonth() + 1) : parseInt(startSlot.getMonth() + 1)) + '-' + startSlot.getFullYear() : ''}
                                </div>
                                {getTranslationByKey("READYTOGROW.CREATE.TIME")}

                                <br />
                                <div className='gucci-text-bold-m'>
                                    {startSlot ? (startSlot.getHours() < 10 ? '0' + startSlot.getHours() : startSlot.getHours()) + ':' + (startSlot.getMinutes() == 0 ? '00' : startSlot.getMinutes()) : ''}
                                    -
                                    {endSlot ? (endSlot.getHours() < 10 ? '0' + endSlot.getHours() : endSlot.getHours()) + ':' + (endSlot.getMinutes() == 0 ? '00' : endSlot.getMinutes()) : ''}
                                </div>

                                <br />
                                {startSlot && new Date().getTime() > startSlot.getTime() ? '' : <Button onClick={() => removeSlot()} className="remove_modal_button">
                                    {getTranslationByKey("READYTOGROW.REMOVE.SLOT")}
                                </Button>}
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDetailDialog(false)} className="modal_button">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={tutorialPopup}
                        onAfterOpen={null}
                        onClose={closeTutorialPopup}
                        style={customModalStyles}
                        className="tutorial_dialog"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>
                            <div className="close" >
                                <svg onClick={() => closeTutorialPopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div className="modal_body pb-25">
                                <div className="desk_tutorial_container">
                                    <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                        {selectedTutorialPage > 0 ?
                                            <div>
                                                <svg onClick={() => changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                        {props.tutorial ? <div className="desk_tutorial_page" Style={"background-image: url('" + props.tutorial[selectedTutorialPage] + "')"}></div> : ''}
                                        {props.tutorial ? selectedTutorialPage < props.tutorial.length - 1 ?
                                            <div>
                                                <svg onClick={() => changeTutorialPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div> : ''}
                                    </div>
                                </div>


                            </div>
                        </DialogContent>
                    </Dialog>
                </div>}
        </React.Fragment>)

}