import React, { useEffect, useState } from "react";
import { BusinessTravelService, DeskService } from "../Services/Services";
import { Dialog, DialogContent, DialogTitle, } from "@mui/material";
import Agenda from "./Agenda";
import { BackButton } from "./BackButton";
import Communications from "./Communications";
import Markdown from 'markdown-to-jsx'
import EventCountdownMenu from "./EventCoundownMenu";
import { getTranslationByKey } from "../global";
import { LoadingComponent } from "./LoadingComponent";

export function EventPage(props) {

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState('')
    const [tutorialPopup, setTutorialPopup] = useState(false)
    const [selectedTutorialPage, setSelectedTutorialPage] = useState(0)
    const [selectedSubModule, setSelectedSubModule] = useState('event')

    useEffect(() => {

        let ds = new DeskService()
        ds.getPagebyId(props.page_id).then((data) => {
            setIsLoading(false)
            setInfo(data)
        })

        window.history.pushState(null, null, window.location.pathname)
        window.addEventListener('popstate', onBackButtonEvent)

    }, [props.page_id])

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        window.location.reload()
    }

    const openTutorialPopup = () => {
        setTutorialPopup(!tutorialPopup)
        setSelectedTutorialPage(0)
    }

    const changeTutorialPage = (type) => {
        if (type === 'prev')
            setSelectedTutorialPage(selectedTutorialPage - 1)
        else {
            setSelectedTutorialPage(selectedTutorialPage + 1)
        }
    }

    const setInfo = (info) => {
        setData(info)
        setIsLoading(false)
    }

    //download file api
    const getDocument = (url) => {

        setIsLoading(true)
        let bs = new BusinessTravelService()
        bs.getDocument(url, true).then((data) => {
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })

    }

    const back = () => {
        let s = 'event'
        if (data.name)
            s = data.name
        setSelectedSubModule(s)
        props.setPathlistValue(s)
    }

    const select = (val) => {
        setSelectedSubModule(val)
        props.setPathlistValue(val)
    }

    //desktop variables
    let sections = ''
    if (data && data.content)
        sections = data.content.map((c) =>
            <React.Fragment>

                {!c.button_link ?
                    (c.countdown_image ?
                        <div Style={"text-align: -webkit-center; margin-top: 10px;margin-bottom:20px"}>
                            <div className="pointer event_image_container" Style={"background-image:url('" + c.countdown_image.image_url + "');"}>
                                <EventCountdownMenu event={c} />
                            </div>
                        </div> : (c.text ?
                            <div className="office_page_subtitle mb-3">
                                <Markdown>{getTranslationByKey(c.text)}</Markdown>
                            </div> : (c.title ? <div className="office_page_title mt-2 mb-2">
                                {getTranslationByKey(c.title)}
                            </div> : <div className="office_page_subtitle">
                                <img className='w-100' src={c.image} alt="img"></img>
                            </div>)
                        ))
                    :
                    (c.button_link.external_url ? <div Style={"text-align: -webkit-center; margin-top: 10px;margin-bottom:20px"}>
                        <div className="pointer text_sub_menu_container" onClick={() => window.open(c.button_link.external_url, '_blank')}>
                            <div className="text_sub_menu desk_menu">
                                {getTranslationByKey(c.button_link.text)}
                            </div>
                        </div>
                    </div> : (c.button_link.file_url ? <div Style={"text-align: -webkit-center; margin-top:10px;margin-bottom:20px"}>
                        <div className="pointer text_sub_menu_container" onClick={() => getDocument(c.button_link.file_url)}>
                            <div className="text_sub_menu desk_menu">
                                {getTranslationByKey(c.button_link.text)}
                            </div>
                        </div>
                    </div> : (c.button_link.module_info ? <div Style={"text-align: -webkit-center; margin-top:10px;margin-bottom:20px"}>
                        <div className="pointer text_sub_menu_container" onClick={() => select(c.button_link.module_info.name)}>
                            <div className="text_sub_menu desk_menu">
                                {getTranslationByKey(c.button_link.text)}
                            </div>
                        </div>
                    </div> : '')))
                }
            </React.Fragment>)


    let color = 'background: #000;'
    let global_className = 'gh_changemakers_global_container'
    if (props.user && props.user.modules) {

        if (props.pageData && props.pageData.color === 'ed9cad') {
            color = 'background: #000'
            global_className = 'pm_changemakers_global_container'
        } else if (props.pageData && props.pageData.color === '4d3047') {
            color = 'background: #000'
            global_className = 'gh_changemakers_global_container'
        } else {
            color = 'background: #000'
            global_className = 'generic_menu_global_container'
        }
    }
    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '600px',
            width: 'calc(100% - 64px)',
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },

    };

    const goBackButton = () => {
        props.select(3, props.selected_office)
        props.setHidden(false)
    }
    let bb = window.innerWidth > 1000 ? '' : <BackButton backButtonFunction={() => goBackButton()} />
    let eventModule = <div className="flex">
        <React.Fragment>
            <div className={global_className} Style={'padding-bottom:30px'}>
                <div className="generic_menu_global_title_container">
                    <div className="office_page_title">{getTranslationByKey(data.name)}</div>
                </div>
                {sections}
            </div>
        </React.Fragment >
        {/*<div className="right_block"></div>*/}
        <Dialog
            open={tutorialPopup}
            onAfterOpen={null}
            onClose={() => openTutorialPopup(false)}
            style={customModalStyles}
            className="tutorial_dialog"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                <div className="close" >
                    <svg onClick={() => openTutorialPopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
            </DialogTitle>
            <DialogContent>
                {props.tutorial ? <div className="modal_body pb-25">
                    <div className="desk_tutorial_container">
                        <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                            {selectedTutorialPage > 0 ?
                                <div>
                                    <svg onClick={() => changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                </div>
                                : <div Style={"width: 30px;"}></div>}
                            <div className="desk_tutorial_page" Style={"background-image: url('" + props.tutorial[selectedTutorialPage] + "')"}></div>
                            {selectedTutorialPage < props.tutorial.length - 1 ?
                                <div>
                                    <svg onClick={() => changeTutorialPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                                : <div Style={"width: 30px;"}></div>}
                        </div>
                    </div>
                </div>
                    : ''}
            </DialogContent>
        </Dialog>
    </div>
    let choices = <React.Fragment>
        <BackButton backButtonFunction={back} index={210} />
        {selectedSubModule === 'Agenda' ?
            <Agenda
                event={props.user.event}
                eventDetail={props.eventDetail} /> :
            selectedSubModule === 'Communications' ?
                <Communications
                    user={props.user}
                    event={props.user.event.id} /> : ''}
    </React.Fragment>
    return (

        isLoading ?
            <LoadingComponent />
            :
            <React.Fragment>
                {bb}
                {selectedSubModule !== 'Agenda' && selectedSubModule !== 'Communications' ? eventModule : choices}
            </React.Fragment>
    )
}