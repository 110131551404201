import React, { useEffect, useState } from "react"
import { BusinessTravelService, DeskService } from "../Services/Services"
import { getTranslationByKey } from "../global"
import { Dialog, DialogContent, DialogTitle, } from "@mui/material";
import { BackButton } from "./BackButton";
import Markdown from 'markdown-to-jsx'
import EventCountdownMenu from "./EventCoundownMenu";
import Agenda from "./Agenda";
import Communications from "./Communications";
import { PDFDocument } from "./PdfDocument";
import { InternalBackButton } from "./InternalBackButton";
export default function CustomPage(props) {
    const [loading, setLoading] = useState(false)
    const [load, setLoad] = useState(true)
    const [tutorialPopup, setTutorialPopup] = useState(false)
    const [selectedTutorialPage, setSelectedTutorialPage] = useState(true)
    const [info, setInfo] = useState(true)
    const [selectedSubModule, setSelectedSubModule] = useState(null)
    let ds = new DeskService()
    let bs = new BusinessTravelService()

    useEffect(() => {

        //setLoading(true)
        window.history.pushState(null, null, window.location.pathname)
        window.addEventListener('popstate', onBackButtonEvent)
        if (props.page_id) {
            ds.getPagebyId(props.page_id).then((data) => {
                setLoading(false)
                setInfo(data)
                setLoad(false)
            })
        }

        //routing setting
        if (props.routingObj && props.routingObj.path && props.routingObj.path.split('/event/')[1].split('/')[1] && props.routingObj.path.split('/event/')[1].split('/')[1].includes('agenda') && props.isEvent)
            select('Agenda')
        else if (props.routingObj && props.routingObj.path && props.routingObj.path.split('/event/')[1].split('/')[1] && props.routingObj.path.split('/event/')[1].split('/')[1].includes('communications') && props.isEvent)
            select('Communications')
        else
            setSelectedSubModule('event')

    }, [props.page_id])

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        window.location.reload()
    }

    //download file api
    const getDocument = (url) => {
        setLoading(true)
        bs.getDocument(url, true).then(() => {
            setLoading(false)
        })
    }

    const openTutorialPopup = () => {
        setTutorialPopup(!tutorialPopup)
        setSelectedTutorialPage(0)
    }

    const changeTutorialPage = (type) => {
        if (type === 'prev')
            setSelectedTutorialPage(selectedTutorialPage - 1)
        else {
            setSelectedTutorialPage(selectedTutorialPage + 1)
        }
    }

    const select = (val) => {
        if ((val === 'Agenda' || val == 'Communications') && window.innerWidth > 1000) {
            let w = props.webPathlist
            w.push({
                type: val,
                value: { name: val }
            })
            props.setWebPathlist([...w])
            setSelectedSubModule(val)
        }
        else {

            setSelectedSubModule(val)
            props.setPathlistValue(val)
        }
    }
    useEffect(() => {
        if (props.webPathlist.length == 1 && !props.routingObj)
            setSelectedSubModule('event')

    }, [props.webPathlist])



    const loadSubPage = (id) => {
        setLoading(true)
        ds.getPagebyId(id).then((data) => {
            setLoading(false)
            setInfo(data)
            setLoad(false)
        })
    }
    let def_img = <React.Fragment>
        <div className="logo_preview_container">
            <div className="logo_preview"></div>
            {/*<div className="text_preview">{getTranslationByKey('UTILS.CUSTOMPAGE.DEFAULTLABEL')}</div>*/}
        </div>
    </React.Fragment>

    let def_doc = ''
    if (info.default_file_content)
        def_doc = <PDFDocument url={info.default_file_content} />
    //desktop variables
    let content = ''
    if (info.content)
        content = info.content.map((c) =>


            !c.button_link ?
                (c.countdown_image ?
                    <div Style={"text-align: -webkit-center; margin-top: 10px;margin-bottom:20px"}>
                        <div className={window.innerWidth < 1000 ? "pointer event_image_container" : "pointer  event_image_container_web"} Style={"background-image:url('" + (window.innerWidth < 1000 ? c.countdown_image.image_url : c.countdown_image.web_image_url) + "');"}>
                            <EventCountdownMenu event={c} />
                        </div>
                    </div > : (c.text ?
                        <div className="office_page_subtitle mb-3">
                            <Markdown>{getTranslationByKey(c.text)}</Markdown>
                        </div> : (c.title ? <div className="office_page_title mt-2 mb-2">
                            {getTranslationByKey(c.title)}
                        </div> : <div className="office_page_subtitle">
                            <img className='w-100' src={c.image}></img>
                        </div>)
                    ))
                :
                (c.button_link.external_url ? <div Style={"text-align: -webkit-center; margin-top: 10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => window.open(c.button_link.external_url, '_blank')}>
                        <div className="text_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : (c.button_link.file_url ? <div Style={"text-align: -webkit-center; margin-top:10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => getDocument(c.button_link.file_url)}>
                        <div className="text_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : (c.button_link.module_info ? <div Style={"text-align: -webkit-center; margin-top:10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => select(c.button_link.module_info.name)}>
                        <div className="text_sub_menu button_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : (c.button_link.page_id ? <div Style={"text-align: -webkit-center; margin-top:10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => loadSubPage(c.button_link.page_id)}>
                        <div className="text_sub_menu button_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : ''))))
        )

    let default_content = ''
    if (info.default_content)
        default_content = info.default_content.map((c) =>
            !c.button_link ?
                (c.text ?
                    <div className="office_page_subtitle mb-3">
                        {getTranslationByKey(c.text)}
                    </div> : (c.title ? <div className="office_page_title mt-2 mb-2">
                        {getTranslationByKey(c.title)}
                    </div> : <div className="office_page_subtitle">
                        <img className='w-100' src={c.image}></img>
                    </div>)
                )
                :
                (c.button_link.external_url ? <div Style={"text-align: -webkit-center; margin-top: 10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => window.open(c.button_link.external_url, '_blank')}>
                        <div className="text_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : (c.button_link.file_url ? <div Style={"text-align: -webkit-center; margin-top:10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => getDocument(c.button_link.file_url)}>
                        <div className="text_sub_menu button_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : (c.button_link.module_info ? <div Style={"text-align: -webkit-center; margin-top:10px;margin-bottom:20px"}>
                    <div className="pointer text_sub_menu_container" onClick={() => select(c.button_link.module_info.name)}>
                        <div className="text_sub_menu button_sub_menu desk_menu">
                            {getTranslationByKey(c.button_link.text)}
                        </div>
                    </div>
                </div> : '')))
        )


    let color = 'background: #000;'

    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '600px',
            width: 'calc(100% - 64px)',
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },

    };

    //back to event page from agenda or communications
    const back = () => {
        let s = 'event'
        if (info.name)
            s = info.name
        setSelectedSubModule(s)
        props.setPathlistValue(s)
        let t = props.webPathlist
        if (t.length > 0) {
            t.splice(t.length - 1, 1)
            props.setWebPathlist([...t])
        }
    }


    //back home
    const customBack = () => {
        if (info.previous_page_id && props.isEvent)
            loadSubPage(info.previous_page_id)
        else {
            if (props.comingFrom !== 'extra_info_link') {
                props.setIsEvent(false)
                props.setHidden(false)
            }
            if (props.user.modules.my_office)
                props.select('home', props.user.modules.my_office)
            else {
                setTimeout(() => {
                    props.select('home', null)
                }, 500);
            }
        }
    }

    let contentValue = <React.Fragment>
        {props.isEvent && info.previous_page_id ? <BackButton backButtonFunction={customBack} index={210} /> : ''}
        {props.comingFrom === 'extra_info_link' && window.innerWidth > 1000 ? <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={customBack} index={99} /> : ''}
        <div className="flex custom_page_container" >
            <div Style={'padding-bottom:30px; '}>
                {load ? '' : default_content !== '' ? default_content : (content !== '' ? content : (def_doc !== '' && window.innerWidth > 1000 ? def_doc : def_img))}
                <div></div>
            </div>
            <Dialog
                open={tutorialPopup}
                onAfterOpen={null}
                onClose={() => openTutorialPopup(false)}
                style={customModalStyles}
                className="tutorial_dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    <div className="close" >
                        <svg onClick={() => openTutorialPopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {props.tutorial ? <div className="modal_body pb-25">
                        <div className="desk_tutorial_container">
                            <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                {selectedTutorialPage > 0 ?
                                    <div>
                                        <svg onClick={() => changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                    </div>
                                    : <div Style={"width: 30px;"}></div>}
                                <div className="desk_tutorial_page" Style={"background-image: url('" + props.tutorial[selectedTutorialPage] + "')"}></div>
                                {selectedTutorialPage < props.tutorial.length - 1 ?
                                    <div>
                                        <svg onClick={() => changeTutorialPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    : <div Style={"width: 30px;"}></div>}
                            </div>
                        </div>
                    </div>
                        : ''}
                </DialogContent>
            </Dialog>
        </div>
    </React.Fragment>

    let choices = props.user ? <React.Fragment>
        <InternalBackButton name={'back_button_component_dx pointer flex'} key='selected_book' backButtonFunction={back} index={99} />
        {window.innerWidth < 1000 ? <BackButton backButtonFunction={back} index={210} /> : ''}
        {selectedSubModule === 'Agenda' ?
            <Agenda
                select={props.select}
                event={props.info}
                eventDetail={props.eventDetail}
                user={props.user} /> :
            selectedSubModule === 'Communications' ?
                <Communications
                    select={props.select}
                    user={props.user}
                    event={props.info.id} /> : ''}
    </React.Fragment> : ''

    return (
        loading ?
            <div className='loader_ellipsis loader_ellipsis_web'>
                <div class="lds-ellipsis">
                    <div Style={color}></div>
                    <div Style={color}></div>
                    <div Style={color}></div>
                    <div Style={color}></div>
                </div>
            </div>
            :
            selectedSubModule !== 'Agenda' && selectedSubModule !== 'Communications' ? contentValue : choices
    )
}