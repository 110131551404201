import React, { useEffect, useState } from "react"
import { FeedList } from "./FeedList";
import { GroupList } from "./GroupList";
import { FeedService } from "../Services/Services";
import { FeedDetail } from "./FeedDetail";
import { InternalBackButton } from "./InternalBackButton";
import { BackButton } from "./BackButton";
import { UseAlertMessage } from "./Alert";

export function FeedComponent(props) {

    const [showList, setShowList] = useState('feedlist');
    const [feedlist, setFeedList] = useState([])
    const [groupName, setGroupName] = useState(null)
    const [groupImg, setGroupImg] = useState(null)
    const [selectedFeed, setSelectedFeed] = useState([])
    const [loading, setLoading] = useState([])
    const [prevPathlist, setPrevPathlist] = useState(props.pathlist_value)

    let service = new FeedService()

    //feed by group
    const setShowListByGroup = (id_group) => {
        setLoading(true)
        props.setPathlistValue(prevPathlist)
        //get feed list and set feedlist
        service.getFeedListById(id_group).then((data) => {
            setFeedList(data)
            setLoading(false)
        }).catch(() => {
            //timeout to show alert message
            setTimeout(() => {
                if (props.user.modules.my_office)
                    props.select('home', props.user.modules.my_office)
                else {
                    setTimeout(() => {
                        props.select('home', null)
                    }, 500);
                }
            }, 1000);
        }).finally(() => {
            setShowList('feedlistGroup')
        })
    }

    useEffect(() => {

        let type = ''
        let exists = false
        if (props.routingObj && props.routingObj.path) {
            type = props.routingObj.path.split('/feed')[1]
            if (type.split('/')[1] === 'group') {
                service.getGroupInfo(type.split('/')[2]).then((data) => {
                    setGroupImg(data.image_url)
                    setGroupName(data.name)
                    setFeedList(data.resources)
                    if (window.innerWidth > 1000)
                        props.setPathlistValue(prevPathlist)
                    setShowList('feedlistGroup')
                    setLoading(false)
                }).catch((err) => {
                    setLoading(true)
                    setShowList('feedlist')
                    props.select('home', null)
                })
                // setShowListByGroup(type.split('/')[2])
            } else if (type.split('/')[1] === 'resource') {
                getFeedList().then((data) => {
                    data.map((d) => {
                        if (d.id == type.split('/')[2]) {
                            exists = true
                            selectFeed(d)
                        }
                    })
                    if (!exists) {
                        UseAlertMessage(400, 'Resource not available')
                        if (props.user.modules.my_office)
                            props.select('home', props.user.modules.my_office)
                        else {
                            setTimeout(() => {
                                props.select('home', null)
                            }, 500);
                        }
                    }
                })
            } else
                getFeedList().then((data) => {
                    setFeedList(data)
                })
        } else {
            getFeedList().then((data) => {
                setFeedList(data)
            })
        }
    }, [])

    const getFeedList = async () => {
        return new Promise(async (resolve, reject) => {
            if (showList === 'feeddetailgroup') {

                setShowList('feedlistGroup')
                props.setPathlistValue(groupName)
            }
            else {
                setLoading(true)
                setShowList('feedlist')
                service.getFeedList().then((data) => {
                    setFeedList(data)
                    setLoading(false)
                    resolve(data)
                })
            }
        })
    }

    const selectFeed = (f) => {
        if (showList === 'feedlist')
            setShowList('feeddetail')
        else
            setShowList('feeddetailgroup')
        setSelectedFeed(f)

    }

    useEffect(() => {
        window.scrollTo(0, 0, 'smooth')
        //navbar add group name

        if (showList !== 'feedlistGroup' && showList !== 'feeddetailgroup' && window.innerWidth < 1000) {
            setGroupName('')
            setGroupImg(null)
            props.setPathlistValue(prevPathlist)
        }
    }, [showList])

    useEffect(() => {
        if (groupName)
            props.setPathlistValue(groupName)
    }, [groupName])

    const selectMenu = (value) => {
        setShowList(value)
        if (value === 'feedlist')
            service.getFeedList().then((data) => {
                setFeedList(data)
            })
    }

    const setSelectedGroup = (g) => {
        setGroupName(g.name)
        setGroupImg(g.image_url)
    }

    return (
        <div className="feed_component">
            <div className="feed_component_container">
                {showList != 'feedlistGroup' && showList != 'feeddetail' && showList != 'feeddetailgroup' ?
                    <div className="feed_buttons_container">
                        <div className={showList === 'feedlist' ? "feed_button_selected bold" : "feed_button pointer"} onClick={() => selectMenu('feedlist')}>Feed</div>
                        <div className={showList === 'grouplist' ? "feed_button_selected bold" : "feed_button pointer"} onClick={() => selectMenu('grouplist')}>Group</div>
                    </div>
                    : ''}
            </div>

            {showList === 'feedlistGroup' ?
                (window.innerWidth > 1000 ?
                    <InternalBackButton name={'back_button_component_dx_feed_grouplist pointer flex mt-3'} key='feed_list_group' backButtonFunction={() => setShowList('grouplist')} index={210} />
                    :
                    <BackButton key='selected_book' backButtonFunction={() => setShowList('grouplist')} index={210} />) : ''}

            {showList === 'feedlist' || showList === 'feedlistGroup' ?
                <React.Fragment>
                    {showList === 'feedlistGroup' && window.innerWidth > 1000 && groupImg && groupName ?
                        <div className="feed_list_container">
                            <div Style={"background-image: url('" + groupImg + "');min-height:110px"} className="group_card_image">
                                <div className="group_card_name bold">{groupName}</div>
                            </div>
                        </div>
                        : ''}
                    <FeedList feedlist={feedlist} selectFeed={selectFeed} loading={loading} />
                </React.Fragment>
                :
                (showList === 'grouplist' ?
                    <GroupList getFeedById={setShowListByGroup} setSelectedGroup={setSelectedGroup} /> :
                    showList === 'feeddetail' || showList === 'feeddetailgroup' ?
                        <FeedDetail back={getFeedList} feed={selectedFeed} />
                        :
                        '')
            }
        </div>
    )
}
