function getTraslationByKey(key) {
    //let translation = ''
    //
    //if (sessionStorage.getItem('translation')) {
    //    translation = JSON.parse(sessionStorage.getItem('translation'))
    //    return translation[key].v
    //} else {
    return ''
    //}
}
export function getTranslationByKey(key) {
    let translation = ''
    let translation_en = ''
    if (sessionStorage.getItem('translation')) {
        translation = JSON.parse(sessionStorage.getItem('translation'))
        translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
        return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
    } else {
        return key
    }
}

export const home_title = "Wellness Lab";
export const my_bookings = getTraslationByKey('my_bookings');
export const add_button = getTraslationByKey('add');

