import { getTranslationByKey } from "../global";
import { DocButton, GoToButton, ProgressButton } from "./Buttons";
import { WellnessService } from "../Services/Services";

export default function WellnessLabNotActive(props) {
    let ws = new WellnessService()
    const download = (value) => {
        ws.downloadPdf(value)
    }

    return (<div className="wellness_lab_not_active_container">
        <div className="wellness_central_logo_container">
            <div className="wellness_central_logo"></div>

            <div className="home_text_container_left">
                <div className="home_text_welcome">{getTranslationByKey('MENU.HOMEVIEW.WELCOME')}</div>
                <div className="home_title_welcome">{getTranslationByKey('MENU.WELLNESSLAB.TITLE')}</div>
                <div className="subtitle_home mt-3"> {getTranslationByKey('WELLNESS.TUTORIAL.PAGE1.INFO')} </div>
                <div className="doc_button mobile_go_next mt-2" >{getTranslationByKey('BUTTON.TITLE.NEXT')}</div>
            </div>
        </div>


        <div className="home_text_container_right mt-2">
            <div> {getTranslationByKey('WELLNESS.TUTORIAL.PAGE2.DOCUMENTATION')} </div>
        </div>

        <div className="subtitle_home_well"> {getTranslationByKey('WELLNESS.TUTORIAL.PAGE2.LEARNMORE')} </div>
        <div className="doc_buttons_container">
            <DocButton onClick={() => download('AttendancesManagementProcedure')} text={getTranslationByKey('WELLNESS.TUTORIAL.PAGE2.ATTENDANCEPROCEDURE')} />
            <DocButton onClick={() => download('RegulationOfUse')} text={getTranslationByKey('WELLNESS.TUTORIAL.PAGE2.REGULATIONS')} />
            <DocButton onClick={() => download('ActivityTimetable')} text={getTranslationByKey('WELLNESS.TUTORIAL.PAGE2.ACTIVITYTIMETABLE')} />
        </div>

        <div className="join_text">{getTranslationByKey('WELLNESS.TUTORIAL.PAGE2.JOIN')} </div>
        <div className="info_container  ">
            <div className='info'>
                <ProgressButton text='1' />
                <div className="subtitle_home_well">{getTranslationByKey('WELLNESS.TUTORIAL.PAGE3.CREATEACCOUNT')}</div>
                <GoToButton link='https://app.wellnessincloud.it/gucciwellnesslab/' text={getTranslationByKey('WELLNESS.TUTORIAL.PAGE3.GOTO')} />
                <div className="subtitle_home_well">{getTranslationByKey('WELLNESS.TUTORIAL.PAGE3.CONFIRMATIONMAIL')}</div>
                <ProgressButton text='2' />
                <div className="subtitle_home_well">{getTranslationByKey('WELLNESS.TUTORIAL.PAGE3.EMAILVERIFIED')}</div>
                <ProgressButton text='3' />
                <div className="subtitle_home_well">{getTranslationByKey('WELLNESS.TUTORIAL.PAGE4.APPROVALCERT')}<br />{getTranslationByKey('WELLNESS.TUTORIAL.CERTIFICATEAPPROVAL')}</div>
            </div>
        </div>
    </div>)
}