import { useEffect, useState } from "react";
import ScrollContainer from 'react-indiana-drag-scroll'

export default function WellnessScrollCalendar(props) {
    const [selectedId, setSelectedId] = useState(0)
    const [days, setDays] = useState([])

    useEffect(() => {
        let differenceDays = 0
        let dayahead = 16
        let days = []

        //from event>>agenda
        if (props.startDate && props.endDate) {
            differenceDays = datediff(new Date(props.startDate).setHours(0, 0, 0, 0), new Date(props.endDate).setHours(0, 0, 0, 0));
            dayahead = differenceDays + 1
        }

        //create days array
        for (let i = 0; i < dayahead; i++) {
            let day = props.startDate && props.endDate ? new Date(props.startDate) : new Date();
            day.setDate(day.getDate() + i);
            days.push({
                id: i,
                day: day.getDate(),
                date: day
            })
        }
        setDays(days)
    }, [])

    const select = (val) => {
        setSelectedId(val.id)
        if (props.startDate && props.endDate)
            props.setSelectedDate(val.date)
        else {
            props.loading(true)
            props.setSelectedDate(val.date)
        }
    }

    const datediff = (first, second) => {
        return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
    }

    /* */
    const checkDate = (date) => {
        let d1 = new Date(date)
        let year = props.selectedDate.split('-')[0]
        let month = props.selectedDate.split('-')[1]
        let day = props.selectedDate.split('-')[2]
        let newDate = new Date()
        newDate.setFullYear(year)
        newDate.setMonth(month - 1)
        newDate.setDate(day)
        newDate.setHours(0, 0, 0, 0)
        if (d1.getFullYear() == year && d1.getMonth() == (month - 1) && d1.getDate() == day) return true
        return false
    }
    /* */

    /* questa funzione non seleziona il giorno su safari * /
    const checkDate = (date) => {
        let d1 = new Date(date)
        let d2 = new Date(props.selectedDate)
        if (d1.getFullYear() == d2.getFullYear() && d1.getMonth() == d2.getMonth() && d1.getDate() == d2.getDate) return true
        return false
    }
    /* */

    let daysList = days.map((d) =>
        <div className={props.selectedDate ? (!checkDate(d.date) ? 'calendar_date' : 'calendar_date_selected') : (selectedId == d.id ? 'calendar_date_selected' : "calendar_date")} onClick={() => select(d)}>
            <div className='uppercase-xs'>{d.date.toLocaleDateString(navigator.language.split('-')[0], { month: 'short' })}</div>
            <div className="calendar_day">{d.day}</div>
            <div className='uppercase-s'>{d.date.toLocaleDateString(navigator.language.split('-')[0], { weekday: 'short' })}</div>
        </div>)

    return (
        <ScrollContainer className={props.startDate && props.endDate ? "agenda_mycalendar_container flex" : "calendar_container flex"}  >
            {daysList}
        </ScrollContainer>)
}