import { useState } from 'react';
import { Notifications } from './Notifications';

export function Navbar(props) {

    const [notificationBar, setNotificationBar] = useState(false)

    const myFunction = (val) => {
        if (val)
            props.notificationBarStatus(val)
        else
            props.closeNotificationBar()
    }

    const handleProfile = () => {
        if (props.profileShow)
            props.closeProfile()
        else
            props.openProfile()
    }

    return (
        <div className="navbar_container mb-10" Style={window.innerWidth > 1000 ? "z-index:300;background-color:#" + props.user.navbar_color + "; " : "background-color:#" + props.user.navbar_color + "; "}>
            <div Style={"max-width: 1366px; width: 1366px;"}>
                <div Style={"width: 100%; place-content: center;"} className='flex'>
                    <div className="burger_menu"></div>
                    <div className="navbar_gucci_logo" ></div>
                    <div className='flex icon_navbar_container'>

                        <div className="pointer topNotificationIcon" Style={"margin-right: 10px;"}>
                            <svg className="dropbtn" onClick={() => myFunction(!props.notificationBar)} id='notificationIcon' xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" class="bi bi-bell-fill" viewBox="0 0 16 16">
                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                            </svg>
                            <div className="notification_num">{props.unreadNotifications > 0 ? props.unreadNotifications : ''}</div>
                            <div id="myDropdown" class="dropdown-content-header">
                                {props.notificationBar ?
                                    <Notifications notificationBar={notificationBar} openedOne={props.openedOne} getNotificationsNumber={props.getNotificationsNumber} notifications={props.notifications} closeNotificationBar={props.notificationBarStatus} />
                                    : ''}
                            </div>
                        </div>
                        <div className="pointer">
                            <svg onClick={() => handleProfile()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" class="bi bi-person-circle" viewBox="0 0 16 16">
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}