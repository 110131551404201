import React from "react";
import { getTranslationByKey } from "../global";
import BookButton, { BookButtonDisabled, FollowButton, RemoveBooking, RemoveWaitingBooking, WaitButton } from "./Buttons";

export default function WellnessLabCourseCard(props) {


    //format date
    const getDate = (string) => {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(string).toLocaleDateString([], options);
    }

    //format hour
    const getHour = (string) => {
        return string.slice(0, 5)
    }

    //check function
    const isCoursePassed = () => {
        let now = new Date()
        let courseDate = new Date(props.course.date)
        let ora_inizio = props.course.time.from.split(':')[0]
        //same date
        if (now.getDate() == courseDate.getDate() && now.getMonth() == courseDate.getMonth() && now.getFullYear() == courseDate.getFullYear())
            if (now.getHours() >= parseInt(ora_inizio)) {
                return true
            }
        return false
    }

    const isToday = () => {
        let now = new Date()
        let courseDate = new Date(props.course.date)
        //same date
        if (now.getDate() == courseDate.getDate() && now.getMonth() == courseDate.getMonth() && now.getFullYear() == courseDate.getFullYear())
            if (props.course.booked)
                return true
        return false
    }

    let imageLinkCourse = ''
    if (props.course !== null) {
        imageLinkCourse = "background-image:url('" + props.course.course.image_url + "')"
    }

    let key_available = 'WELLNESS.AVAILABLEPLACES'
    let string_available = getTranslationByKey(key_available)

    return (
        <div className="card_container" >

            <div className="flex " onClick={() => props.goToDetailView(props.course)}>
                <div className="course_image" Style={imageLinkCourse}>
                </div>
                <div className="course_name">
                    {getTranslationByKey(props.course.course.name)}
                    <br />
                    {getDate(props.course.date)} | {getHour(props.course.time.from)} - {getHour(props.course.time.to)}
                    <div className="course_description">
                        {props.course.trainer.first_name} {props.course.trainer.last_name}
                    </div>
                    {props.course.is_bookable ?
                        (!isCoursePassed() ?
                            <div className="course_seats">
                                {props.course.available_seats == 1 ? props.course.available_seats + ' ' + string_available : props.course.available_seats + ' ' + string_available}
                            </div> : '')
                        : ''}
                </div>
                <div className="book_button_container">
                    {props.course.is_bookable ?
                        (isCoursePassed() ?
                            (isToday() ?
                                <div className="gucci-text-bold-s" >
                                    {getTranslationByKey('WELLNESS.UTILS.BOOKED')}
                                </div>
                                : <BookButtonDisabled className="book_button_disabled" name={getTranslationByKey('WELLNESS.UTILS.BOOK')} />
                            )
                            :
                            <React.Fragment>
                                {props.course.booked ? '' :
                                    (props.course.available_seats == 0 ?

                                        props.course.is_waiting ?
                                            <RemoveWaitingBooking setLoading={props.setLoading} className="remove_waiting mt-1" course={props.course} user={props.user} reload={props.reload} message={getTranslationByKey('WELLNESS.UTILS.REMOVEDWAITING')} name={getTranslationByKey('WELLNESS.UTILS.EXITWAITINGLIST')} />
                                            :
                                            <WaitButton setLoading={props.setLoading} className="book_button" course={props.course} user={props.user} reload={props.reload} check_course={props.course} checkIfModal={() => props.openModal(props.course)} message={getTranslationByKey('WELLNESS.UTILS.INSERTEDWAITING')} name={getTranslationByKey('WELLNESS.UTILS.WAITING')} />
                                        :
                                        <BookButton setLoading={props.setLoading} className="book_button" course={props.course} user={props.user} reload={props.reload} setBooked={props.setBooked} checkIfModal={() => props.openModal(props.course)} check_course={props.course} message={getTranslationByKey('WELLNESS.UTILS.INSERTEDBOOKING')} name={getTranslationByKey('WELLNESS.UTILS.BOOK')} />
                                    )}
                                {props.course.booked ?
                                    (props.course.deletable ?
                                        <RemoveBooking setLoading={props.setLoading} className="remove_booking mt-1" course={props.course} user={props.user} reload={props.reload} name={getTranslationByKey('WELLNESS.UTILS.DELETE')} />
                                        : <div className="gucci-text-bold-s" >
                                            {getTranslationByKey('WELLNESS.UTILS.BOOKED')}
                                        </div>) :
                                    ''}
                            </React.Fragment>
                        )
                        :
                        <FollowButton setLoading={props.setLoading} course={props.course} user={props.user} reload={props.reload} is_followed={props.course.is_followed} />
                    }
                </div>
            </div>
        </div >
    )
}