import { getTranslationByKey } from "../global";

export function IconMenuButton(props) {
    return (
        <div className={" menu_row  " + props.class} onClick={props.external_url ? () => window.open(props.external_url, "_blank") : () => props.handleClick(props.moduleName, props.module, props.index, props.from)}>
            <div className="logo_menu">
                <div className="offices_logo" Style={'background-color:#' + props.color + ';'}>
                    <div className={props.logo} Style={props.logo_url ? 'background-image: url("' + props.logo_url + '")' : ''}></div>
                </div>
            </div>

            <div className="text_menu_container">
                <div className={props.selectedKey === props.module.name && window.innerWidth > 1000 ? "text_menu desk_menu menu_selected" : "text_menu desk_menu"} >{getTranslationByKey(props.module.name)}</div>
            </div>
        </div>
    )
}