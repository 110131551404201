import { MultiSelect } from "react-multi-select-component";
import React, { useEffect } from 'react';

export default function MultiSelectComponent(props) {

    const [myOption, setMyOption] = React.useState([]);

    useEffect(() => {
        let tmp = []
        props.selectedGuest.map((o) => {
            tmp.push(o)
        })
        let exists = false
        props.options.map((o) => {
            exists = false
            props.selectedGuest.map((val) => {
                if (val.value === o.value)
                    exists = true
            })
            if (!exists)
                tmp.push(o)
        })
        setMyOption(tmp)

    }, [props.options, props.selectedGuest])

    return (

        <MultiSelect
            id={props.id}
            key={''}
            className={props.id}
            options={myOption.length > 0 ? myOption : props.options}
            value={props.selectedGuest}
            onChange={(e, value) => props.setSelectedGuest(e, value)}
            labelledBy={props.label}
            placeholder={props.label}
            valueRenderer={() => {
                return props.selectedGuest.length > 0
                    ? props.selectedGuest.length + ' Selected'
                    : (props.selectedGuest.length > 0 ? props.selectedGuest.map(({ label }, index) => index < props.selectedGuest.length && index != 0 ? ',' + label : label) : props.label);
            }}
            isCreatable={false}
            hasSelectAll={true}
        />

    )

}