import React, { useEffect } from "react"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import PanoramaFishEye from '@mui/icons-material/PanoramaFishEye';
import Markdown from 'markdown-to-jsx'
export function Notifications(props) {

    const [notifications, setNotifications] = React.useState([])
    const [seeMore, setSeeMore] = React.useState(false)
    const [heightBasedOnSeeMore, setHeightBasedOnSeeMore] = React.useState('40vh')

    useEffect(() => {
        setNotifications(props.notifications)
    }, [props.notifications])

    useEffect(() => {
        if (seeMore)
            setHeightBasedOnSeeMore('80vh')
        else
            setHeightBasedOnSeeMore('40vh')
    }, [seeMore])

    const read = (e, n) => {
        e.stopPropagation()
        if (!n.read) {
            let not = notifications
            not.map((notification) => {
                if (notification.id == n.id)
                    notification.read = true
            })
            setNotifications(not)
        }
    }

    //translate function
    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    let notificationsObj = ''
    if (notifications.length > 0)
        notificationsObj = notifications.map((n) =>
            <React.Fragment>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon fontSize="small" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={n.read ? "panel1a-header1" : "panel1a-header"}
                        onClick={(e) => read(e, n)}
                    >
                        {n.read ? <PanoramaFishEye fontSize="small" style={{ color: '#4b000a', marginRight: 20 }} /> : <FiberManualRecord fontSize="small" style={{ color: '#4b000a', marginRight: 20 }} />}
                        <Typography>
                            <div className="notificationText">{getTranslationByKey(n.title)}</div>

                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography ><div className="notificationValue">
                            <Markdown>{n.details}</Markdown>
                        </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        )


    return (
        <React.Fragment>
            <div Style={"width: 300px; max-width: 300px;"}>
                <div Style={"margin-left: 270px;margin-top: 8px;"}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F0EFEF" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                    </svg>
                </div>
            </div>
            <div id="notificationBar" className="notificationBar" Style={seeMore ? "height: " + heightBasedOnSeeMore + ";" : ''}>
                <div id="notificationContent">
                    <div Style={notifications && notifications.length > 4 ? "overflow: auto; height: calc(" + heightBasedOnSeeMore + " - 40px);" : "overflow: auto; height: " + heightBasedOnSeeMore + ";"}>
                        {notifications && notifications.length > 0 ? notificationsObj : <div Style={"background-color: #f0efef; padding: 25px 20px;"}>{getTranslationByKey("NO.LIST.NODATA")}</div>}
                    </div>
                    {notifications && notifications.length > 4 ?
                        <div className="notification" onClick={() => setSeeMore(!seeMore)}>
                            {seeMore ?
                                <div className="notificationTitle" Style={'text-align:center;'}>{getTranslationByKey("NOTIFICATIONS.SEELESS")}</div>
                                :
                                <div className="notificationTitle" Style={'text-align:center;'}>{getTranslationByKey("NOTIFICATIONS.SEEMORE")}</div>
                            }
                        </div> : ''}
                </div>
            </div>
        </React.Fragment>
    )
}