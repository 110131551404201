import React, { useEffect, useState } from "react";
import { WellnessService } from "../Services/Services";
import { BackButton } from "./BackButton";
import { LoadingComponentWeb } from "./LoadingComponent";

export default function WellnessLabGymInfo(props) {
    const [loading, setLoading] = useState(true)
    const [gym, setGym] = useState(null)
    let ws = new WellnessService()

    useEffect(() => {
        ws.getGymById(1).then((data) => {
            setGym(data)
            setLoading(false)
        })
    }, [])


    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    const goBack = () => {
        props.select('offices', props.office)
        props.setHidden(false)
    }

    return (
        <React.Fragment>
            {window.innerWidth > 1000 ? '' : <BackButton backButtonFunction={() => goBack()} />}
            {loading ?
                <LoadingComponentWeb />
                :
                <div Style={"position: relative;  overflow: auto; height: 100vh;padding-bottom:100px"}>

                    <div className="gym_info">
                        <div>
                            <div className="gym_info_image_container" Style={"background-image:url('" + gym.image_url + "');"}></div>
                            <div className="gym_info_description">
                                <div>{getTranslationByKey(gym.description)}</div>
                            </div>
                            <div className="bold">{getTranslationByKey('WELLNESS.INFO.OPENINGTIME')}</div>

                            <div className="gym_info_hours_container">
                                <div className="flex gym_info_row">
                                    <div className="bold gym_info_day">{getTranslationByKey('DAY.MONDAY')}</div>
                                    <div> {gym.openingHours.Monday}</div>
                                </div>
                                <div className="flex gym_info_row">
                                    <div className="bold gym_info_day">{getTranslationByKey('DAY.TUESDAY')}</div>
                                    <div> {gym.openingHours.Tuesday}</div>
                                </div>
                                <div className="flex gym_info_row">
                                    <div className="bold gym_info_day">{getTranslationByKey('DAY.WEDNESDAY')}</div>
                                    <div> {gym.openingHours.Wednesday}</div>
                                </div>
                                <div className="flex gym_info_row">
                                    <div className="bold gym_info_day">{getTranslationByKey('DAY.THURSDAY')}</div>
                                    <div> {gym.openingHours.Thursday}</div>
                                </div>
                                <div className="flex gym_info_row">
                                    <div className="bold gym_info_day">{getTranslationByKey('DAY.FRIDAY')}</div>
                                    <div> {gym.openingHours.Friday}</div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            }
        </React.Fragment>
    )
}