
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

const containerStyle = {
    width: "88%",
    height: "130px",
    margin: "auto",
    position: "relative",
    overflow: "hidden",
    "border-radius": "20px",
};

export function MapGoogle(props) {

    const center = {
        lat: parseFloat(props.location.latitude),
        lng: parseFloat(props.location.longitude)

    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCfVWPE1iQD0v5fUsm-XzTckCJtNSD9b4U"
    })

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            id='google-map-script'
            zoom={18}
            onClick={() => window.open('https://maps.google.com/?q=' + props.location.latitude + ',' + props.location.longitude)}
        >
            <Marker
                position={center}
            />
            { /* Child components, such as markers, info windows, etc. */}
        </GoogleMap>
    ) : <></>
}
