import React, { useEffect } from "react";
import { DeskService } from "../Services/Services";
import { DeskBookingModule } from "./DeskBookingModule";
import BookshelfSingleModule from "./BookshelfSingleModule";
import ReadyToGrowSingleModule from "./ReadyToGrowSingleModule";
import InfoUtili from "./InfoUtili";
import { Canteen } from "./Canteen";
import { EventPage } from "./EventPage";
import { VRLearning } from "./VRLearning";
import { WellnessLabSingleModule } from "./WellnessLabSingleModule";
import WellnessLabCourses from "./WellnessLabCourses";
import WellnessLabReservations from "./WellnessLabReservation";
import WellnessLabGymInfo from "./WellnessLabGymInfo";
import WellnessLabNotActive from "./WellnessLabNotActive";
import MapComponent from "./MapComponent";
import CustomPage from "./CustomPage";
import { MeetingRooms } from "./MeetingRooms";
import { ReadyToGrow } from "./ReadyToGrow";
import Bookshelf from "./Bookshelf";
import WellnessDocumentPreview from "./WellnessDocumentPreview";
import { PDFDocument } from "./PdfDocument";
import { FeedComponent } from "./FeedComponent";

export function CentralComponent(props) {
    const [page_info, setPageInfo] = React.useState('')
    const [file_url, setFileUrl] = React.useState(false)
    const [openFilterMeetingRoomsMobile, setOpenFilterMeetingRoomsMobile] = React.useState(false)
    const ds = new DeskService()

    useEffect(() => {
        setFileUrl(false)
        if (props.custom_office && props.custom_office.file_url) {
            setFileUrl(true)
        }
    }, [props.selected, props.custom_office])

    useEffect(() => {
        if (props.selected === 'custom_pages' && props.custom_office.page_id) {
            ds.getInfoLocationv4(props.custom_office.page_id).then((data) => {
                this.setPageInfo(data)
            })
        }
    }, [])

    const handleOpenFilterMobile = () => {
        if (window.innerWidth < 1000)
            setOpenFilterMeetingRoomsMobile(!openFilterMeetingRoomsMobile)
    }

    return (
        <div className={props.selected === 'wellness' ? "central_component_width central_component_width_mobile central_component_wellness_admin" : "central_component_width central_component_width_mobile"}>
            <div Style="max-width: 1066px; width: 100%;">
                {props.selected === 'file_url' ? <PDFDocument url={props.custom_office} /> :
                    props.selected === 'custom' ? <CustomPage routingObj={props.routingObj} comingFrom={props.comingFrom} webPathlist={props.webPathlist} setWebPathlist={props.setWebPathlist} isEvent={props.isEvent} user={props.user} setPathlistValue={props.setPathlistValue} file_url={file_url} tutorial={props.custom_office && props.custom_office.tutorial ? props.custom_office.tutorial : ''} page_id={props.custom_office.id} selected_office={props.office} select={props.select} setHidden={props.setHidden} /> :
                        props.selected === 'bookshelf' ? <Bookshelf user={props.user} bookshelf={props.user.modules.bookshelf} info={props.custom_office} tutorial={props.user.modules.bookshelf && props.user.modules.bookshelf.tutorial ? props.user.modules.bookshelf.tutorial : ''} selected_office={props.office} select={props.select} setHidden={props.setHidden} /> :
                            props.selected === 'bookshelf_singlem' ? <BookshelfSingleModule setPathlistValue={props.setPathlistValue} user={props.user} bookshelf={props.user.modules.bookshelf} tutorial={props.user.modules.bookshelf && props.user.modules.bookshelf.tutorial ? props.user.modules.bookshelf.tutorial : ''} selected_office={props.office} select={props.select} setHidden={props.setHidden} /> :
                                props.selected === 'ready_to_grow_singlem' ? <ReadyToGrowSingleModule setPathlistValue={props.setPathlistValue} user={props.user} /> :
                                    props.selected === 'ready_to_grow' ? <ReadyToGrow user={props.user} selected_office={props.office} select={props.select} setHidden={props.setHidden} /> :
                                        props.selected === 'wellness' ? <WellnessLabSingleModule user={props.user} selected_office={props.office} select={props.select} setHidden={props.setHidden} /> :
                                            props.selected === 'custom_pages' || props.selected === 'final_page' || props.selected === 'file_url' || file_url ? <CustomPage routingObj={props.routingObj} comingFrom={props.comingFrom} webPathlist={props.webPathlist} setWebPathlist={props.setWebPathlist} isEvent={props.isEvent} user={props.user} setPathlistValue={props.setPathlistValue} file_url={file_url} info={props.custom_office} tutorial={props.custom_office && props.custom_office.tutorial ? props.custom_office.tutorial : ''} page_id={props.custom_office.page_id} selected_office={props.office} select={props.select} setHidden={props.setHidden} />
                                                : props.selected === 'desk_booking' ? <DeskBookingModule selected_office={props.office} select={props.select} setHidden={props.setHidden} /> :
                                                    props.selected === 'useful_info' || props.selected === 'home' ? <InfoUtili backToOffice={props.backToOffice} tutorial={null} key={props.custom_office && props.custom_office.id ? props.custom_office.id : ''} id={props.custom_office && props.custom_office.id ? props.custom_office.id : null} user={props.user} office={props.custom_office} selected_office={props.office} select={props.select} setHidden={props.setHidden} />
                                                        : props.selected === 'canteen' ? <Canteen user={props.user} office={props.office} select={props.select} setHidden={props.setHidden} /> :
                                                            props.selected === 'event' ? <EventPage pathlist_value={props.pathlist_value} setPathlistValue={props.setPathlistValue} user={props.user} tutorial={props.custom_office && props.custom_office.tutorial ? props.custom_office.tutorial : ''} page_id={props.custom_office.page_id} selected_office={props.office} select={props.select} setHidden={props.setHidden} /> :
                                                                props.selected === 'vr_learning' ? <VRLearning user={props.user} office={props.office} select={props.select} setHidden={props.setHidden} />
                                                                    : props.selected === 'wellness_orario_corsi' ? <WellnessLabCourses user={props.user} selected_wellness={props.custom_office.default_gym ? props.custom_office.default_gym : null} office={props.office} select={props.select} setHidden={props.setHidden} />
                                                                        : props.selected === 'wellness_my_reservations' ? <WellnessLabReservations office={props.office} expiring_certificate={props.custom_office.expiring_certificate} user={props.user} selected_wellness={props.custom_office.default_gym ? props.custom_office.default_gym : null} select={props.select} setHidden={props.setHidden} />
                                                                            : props.selected === 'wellness_info' ? <WellnessLabGymInfo info={props.custom_office} office={props.office} select={props.select} setHidden={props.setHidden} />
                                                                                : props.selected === 'wellness_lab_not_active' ? <WellnessLabNotActive info={props.custom_office} />
                                                                                    : props.selected === 'interactive_map' ? <MapComponent user={props.user} info={props.custom_office} office={props.custom_office} name={page_info.name} select={props.select} setHidden={props.setHidden} />
                                                                                        : props.selected === 'meeting_room' ? <MeetingRooms user={props.user} office={props.office} name={page_info.name} setOpenFilterMobile={handleOpenFilterMobile} selected_office={props.office} select={props.select} setHidden={props.setHidden} />
                                                                                            : props.selected === 'wellness_file_url' ? <WellnessDocumentPreview url={props.custom_office} />
                                                                                                : props.selected === 'feed' ? <FeedComponent pathlist_value={props.pathlist_value} setPathlistValue={props.setPathlistValue} select={props.select} user={props.user} routingObj={props.routingObj} module={props.custom_office} /> : ''}
            </div>
        </div>)
}
