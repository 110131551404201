import React, { Component } from "react";
import BookButton, { RemoveBooking, RemoveWaitingBooking, WaitButton, ManageButton, FollowButton, BookButtonDisabled } from "./Buttons";

//Component Card for course description
class CourseCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            course_name: props.course.course.name,
            description: props.course.course.description,
            available_seats: props.course.available_seats,
            from: props.course.time.from,
            to: props.course.time.to,
            course_id: props.course.course.id,
            user: props.user
        }

        this.getHour = this.getHour.bind(this)
        this.getDate = this.getDate.bind(this)
        this.goToHandler = this.goToHandler.bind(this)
    }

    //applanga translation function
    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    goToCoursePage() {
        this.props.changeRightMenuStatus(true);
        this.props.goToCoursePage(this.props.course, this.props.user)
    }

    goToManagePage() {
        this.props.changeRightMenuStatus(true);
        this.props.goToManagePage(this.props.course, this.props.user)
    }

    goToHandler() {
        if (this.state.user.modules.wellness_lab && this.state.user.modules.wellness_lab.is_trainer) {
            this.props.changeRightMenuStatus(true);
            this.props.goToManagePage(this.props.course, this.props.user)
        } else {
            this.props.changeRightMenuStatus(true);
            this.props.goToCoursePage(this.props.course, this.props.user)
        }
    }
    //format date
    getDate(string) {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(string).toLocaleDateString([], options);
    }

    //format hour
    getHour(string) {
        return string.slice(0, 5)
    }

    //check function
    isCoursePassed() {
        let now = new Date()
        let courseDate = new Date(this.props.course.date)
        let ora_inizio = this.state.from.split(':')[0]
        //same date
        if (now.getDate() == courseDate.getDate() && now.getMonth() == courseDate.getMonth() && now.getFullYear() == courseDate.getFullYear())
            if (now.getHours() >= parseInt(ora_inizio)) {
                return true
            }
        return false
    }

    isToday() {
        let now = new Date()
        let courseDate = new Date(this.props.course.date)
        //same date
        if (now.getDate() == courseDate.getDate() && now.getMonth() == courseDate.getMonth() && now.getFullYear() == courseDate.getFullYear())
            if (this.props.course.booked)
                return true
        return false
    }

    render() {

        let imageLinkCourse = ''
        if (this.props.course !== null) {
            imageLinkCourse = "background-image:url('" + this.props.course.course.image_url + "')"
        }

        let key_available = 'WELLNESS.AVAILABLEPLACES'
        let string_available = this.getTranslationByKey(key_available)

        return (
            <div className="card_container" onClick={() => this.goToHandler()}>

                <div className="flex ">
                    <div className="course_image" Style={imageLinkCourse}>
                    </div>
                    <div className="course_name">
                        {this.getTranslationByKey(this.state.course_name)}
                        <br />
                        {this.getDate(this.props.course.date)} | {this.getHour(this.state.from)} - {this.getHour(this.state.to)}
                        <div className="course_description">
                            {this.props.course.trainer.first_name} {this.props.course.trainer.last_name}
                        </div>
                        {this.props.course.is_bookable ?
                            (!this.isCoursePassed() ?
                                <div className="course_seats">
                                    {this.state.available_seats == 1 ? this.state.available_seats + ' ' + string_available : this.state.available_seats + ' ' + string_available}
                                </div> : '')
                            : ''}
                    </div>
                    <div className="book_button_container">

                        {/* default user */}
                        {(this.props.user) && (this.props.user.modules.wellness_lab && (this.props.user.modules.wellness_lab.is_admin || this.props.user.modules.wellness_lab.is_trainer)) ?
                            /* trainer or admin */
                            <React.Fragment>
                                <ManageButton goToManagePage={() => this.goToManagePage()} className="book_button" course={this.props.course} user={this.props.user} showManagePage={(val) => this.props.showManagePage(val)} reload={() => this.props.reload()} />
                            </React.Fragment>
                            :
                            (this.props.course.is_bookable ?
                                (this.isCoursePassed() ?
                                    (this.isToday() ?
                                        <div className="gucci-text-bold-s" >
                                            {this.getTranslationByKey('WELLNESS.UTILS.BOOKED')}
                                        </div>
                                        : <BookButtonDisabled className="book_button_disabled" name={this.getTranslationByKey('WELLNESS.UTILS.BOOK')} />
                                    )
                                    :
                                    <React.Fragment>
                                        {this.props.course.booked ? '' :
                                            (this.props.course.available_seats == 0 ?

                                                this.props.course.is_waiting ?
                                                    <RemoveWaitingBooking className="remove_waiting mt-1" course={this.props.course} user={this.props.user} reload={() => this.props.reload()} message={this.getTranslationByKey('WELLNESS.UTILS.REMOVEDWAITING')} name={this.getTranslationByKey('WELLNESS.UTILS.EXITWAITINGLIST')} />
                                                    :
                                                    <WaitButton className="book_button" course={this.props.course} user={this.props.user} reload={() => this.props.reload()} check_course={this.props.course} checkIfModal={(course) => this.props.openModal(course)} message={this.getTranslationByKey('WELLNESS.UTILS.INSERTEDWAITING')} name={this.getTranslationByKey('WELLNESS.UTILS.WAITING')} />
                                                :
                                                <BookButton className="book_button" course={this.props.course} user={this.props.user} reload={() => this.props.reload()} setBooked={(val) => this.props.setBooked(val)} checkIfModal={(course) => this.props.openModal(course)} check_course={this.props.course} message={this.getTranslationByKey('WELLNESS.UTILS.INSERTEDBOOKING')} name={this.getTranslationByKey('WELLNESS.UTILS.BOOK')} />
                                            )}
                                        {this.props.course.booked ?
                                            (this.props.course.deletable ?
                                                <RemoveBooking className="remove_booking mt-1" course={this.props.course} user={this.props.user} reload={() => this.props.reload()} name={this.getTranslationByKey('WELLNESS.UTILS.DELETE')} />
                                                : <div className="gucci-text-bold-s" >
                                                    {this.getTranslationByKey('WELLNESS.UTILS.BOOKED')}
                                                </div>) :
                                            ''}
                                    </React.Fragment>
                                )
                                :
                                <FollowButton course={this.props.course} user={this.props.user} reload={() => this.props.reload()} is_followed={this.props.course.is_followed} />
                            )
                        }
                    </div>
                </div>
            </div >
        )
    }
}
export default CourseCard;