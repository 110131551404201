import { useIsAuthenticated } from "@azure/msal-react";
import "./App.css";
import "./BootstapOfficeDetail.css";
import Cookies from "universal-cookie";
import Login from "./View/Login";
import NewHomepage from "./View/NewHomepage";
import 'bootstrap/dist/css/bootstrap.css';
import { initializeApp } from "firebase/app";
import { useEffect } from "react";

const AppContent = (props) => {
  let app = ''

  useEffect(() => {
    if (window.location.hostname !== 'gucciforyou.gucci.cn') {
      var my_script = document.createElement('script');
      var my_script2 = document.createElement('script');

      my_script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?libraries=geometry&key=AIzaSyCfVWPE1iQD0v5fUsm-XzTckCJtNSD9b4U');
      my_script2.setAttribute('src', 'https://unpkg.com/@mapsindoors/components@8.2.0/dist/mi-components/mi-components.js');

      document.head.appendChild(my_script);
      document.head.appendChild(my_script2);
    }
    var meta = document.createElement('meta');
    meta.setAttribute('property', 'og:image');
    meta.setAttribute('content', 'https://' + window.location.hostname + '/guccilogopreview.png');
    document.head.appendChild(meta);

  }, [])

  if (window.location.hostname === 'gucciforyou.gucci.com') {
    const firebaseConfig = {
      apiKey: "AIzaSyAYCpPpDGqJyfgQNyNjYKdgkAd-zzfzI3g",
      authDomain: "smartoffice-998e9.firebaseapp.com",
      projectId: "smartoffice-998e9",
      storageBucket: "smartoffice-998e9.appspot.com",
      messagingSenderId: "555848803828",
      appId: "1:555848803828:web:615dab0f54af71473005a1",
      measurementId: "G-Y5T4FCHNH1"
    };

    // Initialize Firebase
    app = initializeApp(firebaseConfig);

  }
  const isSsoAuthenticated = useIsAuthenticated();
  let isAuthenticated = false;


  const cookies = new Cookies();

  if (cookies.get('user_token') === undefined) {
    if (isSsoAuthenticated) {
      cookies.set('user_token', 'temp', { path: '/', maxAge: 31536000 });
      cookies.set('user_token_type', 'sso', { path: '/', maxAge: 31536000 });
      isAuthenticated = true;
    }
  } else {
    isAuthenticated = true;
  }

  return (
    <>
      {isAuthenticated ? <NewHomepage sel={props.sel} app={window.location.hostname === 'gucciforyou.gucci.com' ? app : ''} /> : <Login />}
    </>
  );
};

export default function App() {

  return (
    <AppContent />
  );
}
