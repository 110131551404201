import React, { useEffect, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import ReactPlayer from 'react-player'

export function FeedVideoComponent(props) {


    return (
        <div>
            <div className='feed_video_title' Style="margin: 20px 0;">
                <div className="bold">{props.feed.title}</div>
                <div>{props.getUploadedAtData()}</div>
            </div>
            <div className='feed_video_container' >
                <ReactPlayer
                    url={props.resource.url}
                    controls={true}
                    playing={false}
                    width="100%"
                    height="auto"
                />
                <div className='feed_video_title_mobile' Style="margin: 20px 0;">
                    <div className="bold">{props.feed.title}</div>
                </div>

            </div>
        </div>
    )
}
