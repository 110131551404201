import { getTranslationByKey } from "../global";

export function GroupCard(props) {

    let currentdate = new Date(props.group.last_update_date);
    let datetime = currentdate.toLocaleDateString([], { weekday: 'short' }) + " " + ("0" + currentdate.getDate()).slice(-2)

    const handleSelectGroup = (id, g) => {
        props.setSelectedGroup(g)
        props.getFeedById(id)
    }

    return (
        <div className="group_card pointer" onClick={() => handleSelectGroup(props.group.id, props.group)}>
            <div Style={"background-image: url('" + props.group.image_url + "');"} className="group_card_image">
                <div className="group_card_name bold">{props.group.name}</div>
            </div>
            <div className="group_card_icon_container flex">
                <div>
                    <div className="flex" Style="align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                        </svg>
                        <div className="group_viewed_font">
                            {props.group.viewed_resource_count} / {props.group.resource_count}
                        </div>
                    </div>
                </div>
                <div className="last_update_string">
                    {getTranslationByKey('CANTEEN.LASTUPDATE %@').replace('%@', datetime)}
                </div>
            </div>
        </div>
    )
}
