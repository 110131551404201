import { useEffect, useState } from 'react';
import { FeedService } from '../Services/Services';
import { GroupCard } from './GroupCard';
import { LoadingComponentWeb } from './LoadingComponent';

export function GroupList(props) {

    const fS = new FeedService();
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        fS.getGroups().then((data) => {
            setGroups(data)
            setLoading(false)
        });
    }, [])

    return (
        <div>
            {loading ?
                <LoadingComponentWeb />
                :
                <div className='feed_grouplist_container'>
                    <div>
                        {groups.map((group) =>
                            <GroupCard group={group} getFeedById={props.getFeedById} setSelectedGroup={props.setSelectedGroup} />
                        )}
                    </div>
                </div>}
        </div>
    )
}
