import React, { useEffect, useState } from "react";
import { getTranslationByKey } from "../global";
import { BookServiceButton, RemoveBooking, RemoveWaitingBooking } from "./Buttons";
import { WellnessService } from "../Services/Services";
import WellnessLabCoursePage from "./WellnessLabCoursePage";
import Modal from 'react-modal';
import Select from "react-select";
import { BackButton } from "./BackButton";
import { LoadingComponent } from "./LoadingComponent";

export default function WellnessLabReservations(props) {
    const [loading, setLoading] = useState(true)
    const [courses, setCourses] = useState([])
    const [selected, setSelected] = useState('confirmed')
    const [coursePageView, setCoursePageView] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [modal_status, setModalStatus] = useState(false)
    const [selectedService, setSelectedService] = useState(null)
    const [services, setServices] = useState([])
    const [loadingModal, setLoadingModal] = useState(false)
    const [bookedNum, setBookedNum] = useState('')
    const [waitingNum, setWaitingNum] = useState('')
    let ws = new WellnessService()

    useEffect(() => {
        let countB = 0
        let countW = 0
        ws.getAllMyBookingsV2().then((data) => {
            data.map((c) => {
                if (c.schedule.is_waiting)
                    countW += 1
                else
                    countB += 1
            })
            setBookedNum(countB)
            setWaitingNum(countW)
            setCourses(data)
            ws.getGymById(props.office.id).then((data2) => {
                setLoading(false)
            })
        })
    }, [])

    const getDate = (string) => {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(string).toLocaleDateString([], options);
    }

    const reload = () => {
        setLoading(true)
        ws.getAllMyBookingsV2().then((data) => {
            setCourses(data)
            setLoading(false)
        })
    }

    const getHour = (string) => {
        return string.slice(0, 5)
    }

    const getLink = (link) => {
        return "background-image:url('" + link + "')"
    }

    const dateCompare = (course) => {
        let from = course.time.from
        let date = course.date.split('T')
        let new_date = date[0] + 'T' + from

        let today = new Date()
        let dateToCompare = new Date(new_date)
        return dateToCompare < today

    }

    const goToCoursePage = (c) => {
        setSelectedCourse(c)
        setCoursePageView(true)
    }
    const goBack = () => {
        setCoursePageView(false)
        setSelectedCourse(null)
    }
    const openModal = (course) => {
        if (modal_status) {
            setModalStatus(false)
            setSelectedService(getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") ? getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") : 'Select a Service')
        } else {
            setLoadingModal(true)
            ws.getServices(course.id).then((data) => {
                let list = []
                data.map((s) => {
                    list.push({ 'value': s.id, 'label': s.name })
                })
                setServices(list)
            }).finally(() => {
                setLoadingModal(false)
            })

            setModalStatus(true)
            setLoadingModal(false)
            setSelectedService(getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") ? getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") : 'Select a Service')
        }
    }

    let coursesList = ''
    coursesList =
        courses.map((c) => (!c.schedule.is_waiting && selected === 'confirmed') || (c.schedule.is_waiting && selected === 'waiting') ?
            <div className="reservation_line flex mt-2" onClick={() => goToCoursePage(c)}>
                <div className="reservation_course_sx flex">
                    <div className="reservation_course_image" Style={getLink(c.schedule.course.image_url)}></div>
                    <div className="reservation_course_name">
                        {c.schedule.course.type.id == 2 ?
                            (c.schedule.course.service_type && c.schedule.course.service_type.name ? getTranslationByKey(c.schedule.course.service_type.name) : '')
                            :
                            getTranslationByKey(c.schedule.course.name)
                        }
                        <div className="reservation_course_description" Style={"margin-bottom: 18px"}>
                        </div>
                        <div className="reservation_course_description flex">
                            <div Style={"font-family: GucciSans-Bold; margin-right: 5px"}>{getTranslationByKey('WELLNESS.COURSES.FILTER.DATE')}</div> {getDate(c.schedule.date)}
                        </div>
                        <div className="reservation_course_description flex">
                            <div Style={"font-family: GucciSans-Bold; margin-right: 5px"}>{getTranslationByKey('WELLNESS.TIME')}: </div> {getHour(c.schedule.time.from)} - {getHour(c.schedule.time.to)}
                        </div>
                    </div>
                </div>
                <div className="reservation_course_dx flex">
                    <div className="reservation_course_name">
                        <div className="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" class="bi bi-person-fill" viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                            <div className="reservation_course_description" Style={"margin-left: 20px"}>
                                <div className="flex">
                                    <div Style={"font-family: GucciSans-Bold; margin-right: 5px"}>{getTranslationByKey('WELLNESS.TRAINER')} </div>
                                    {c.schedule.trainer.first_name} {c.schedule.trainer.last_name}
                                </div>
                                <div Style={"font-family: GucciSans-Bold"}>
                                    {c.schedule.course.type.id == 2 ?
                                        "Corso a Servizio"
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" class="bi bi-building" className="reservation_icon" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                            </svg>
                            <div className="reservation_course_description margin_course_location" Style={"margin-left: 20px"}>
                                <div Style={"font-family: GucciSans-Bold;"}>{c.schedule.gym.location.name} </div>
                                {c.schedule.gym.location.street_address}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reservation_course_button">
                    {dateCompare(c.schedule) ?
                        null
                        :
                        <div className="mt-4">
                            {c.deletable ?
                                !c.schedule.is_waiting && selected === 'confirmed' ?
                                    <RemoveBooking setLoading={setLoading} className="remove_booking mt-1" course={c.schedule} user={props.user} reload={reload} name={getTranslationByKey('WELLNESS.UTILS.DELETE')} />
                                    :
                                    <RemoveWaitingBooking setLoading={setLoading} className="remove_booking mt-1" course={c.schedule} user={props.user} reload={reload} message={getTranslationByKey('WELLNESS.UTILS.REMOVEDWAITING')} name={getTranslationByKey('WELLNESS.UTILS.EXITWAITINGLIST')} />
                                : ''}
                        </div>

                    }
                </div>
            </div>
            : '')

    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            height: '400px',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },
    };

    const customStylesServices = {
        control: (base, state) => ({
            ...base,
            background: "#f0efef",
            border: 0,
            /* borderColor: isSelected ? null : null,
             boxShadow: isFocused ? null : null,*/

        }),
        menuList: base => ({
            ...base,
            background: "#f0efef",
            padding: 0,
            height: "270px",
            "::-webkit-scrollbar-thumb": {
                background: "#000000 !important"
            },
        })
    };

    const goBackButton = () => {
        props.select('offices', props.office)
        props.setHidden(false)
    }

    return (<React.Fragment>
        {window.innerWidth > 1000 ? '' : <BackButton backButtonFunction={() => goBackButton()} />}
        {!coursePageView ?
            <div className='reserv_menu'>
                <div className="reservation_header"></div>
                {props.expiring_certificate ? <div className="reservation_search flex">
                    <div className="reservation_course_certificate flex">{getTranslationByKey('WELLNESS.EXPIRINGCERTIFICATE')}</div>
                </div> : ''}
                {window.innerWidth > 1000 ?
                    <div className="reservation_class_web">
                        <div className={selected === "confirmed" ? "reservation_button_selected_web bold" : "reservation_button_web pointer"} onClick={() => setSelected("confirmed")}>{getTranslationByKey('WELLNESS.RESERVATIONS.CONFIRMED')}</div>
                        <div className={selected === "waiting" ? "reservation_button_selected_web bold" : "reservation_button_web pointer"} onClick={() => setSelected("waiting")} >{getTranslationByKey('WELLNESS.RESERVATIONS.WAITINGLIST')}</div>
                    </div>
                    :
                    <div className="reservation_class">
                        <div className={selected === "confirmed" ? "reservation_class_confirmed_selected" : "reservation_class_confirmed"} onClick={() => setSelected("confirmed")}>{getTranslationByKey('WELLNESS.RESERVATIONS.CONFIRMED')}</div>
                        <div className={selected === "waiting" ? "reservation_class_waiting_selected" : "reservation_class_waiting"} onClick={() => setSelected("waiting")} >{getTranslationByKey('WELLNESS.RESERVATIONS.WAITINGLIST')}</div>
                    </div>}

                {!loading ?
                    <React.Fragment>
                        <div className="reservations">
                            <div className="reservations_container">
                                {coursesList}
                                {bookedNum == 0 && selected === 'confirmed' ? <div className="no_reservations">{getTranslationByKey('WELLNESS.RESERVATIONS.NORESERVATIONS')}</div> : ''}
                                {waitingNum == 0 && selected === 'waiting' ? <div className="no_reservations">{getTranslationByKey('WELLNESS.RESERVATIONS.NORESERVATIONS')}</div> : ''}
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <LoadingComponent />
                }
            </div> :
            <WellnessLabCoursePage loading={loading} setLoading={setLoading} key={selectedCourse.schedule.id} course={selectedCourse.schedule} user={props.user} reload={reload} openModal={openModal} goBack={goBack} />}
        <Modal
            isOpen={modal_status}
            onAfterOpen={null}
            onRequestClose={() => openModal(selectedCourse)}
            style={customModalStyles}
            contentLabel="Example Modal"
            appElement={document.getElementById('root') || undefined}
        >
            <div className="close" >
                <svg onClick={() => openModal(selectedCourse)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>


            <div className="modal_body">
                <div className="modal_select">
                    <Select
                        value={selectedService ? selectedService.value : null}
                        onChange={setSelectedService}
                        options={services}
                        className="filter_input_services pt-1"
                        placeholder={selectedService ? selectedService.label : ''}
                        styles={customStylesServices}
                    />
                </div>

                <div className="modal_button">
                    <BookServiceButton className="book_button" course={selectedCourse && selectedCourse.schedule ? selectedCourse.schedule : ''} user={props.user} reload={reload} selectedServiceType={selectedService && selectedService.value ? selectedService.value : ''} openModal={() => openModal(selectedCourse.schedule)} name={getTranslationByKey('WELLNESS.UTILS.BOOK')} message={getTranslationByKey('WELLNESS.UTILS.BOOKING.SUCCESS')} />
                </div>
            </div>

        </Modal>

    </React.Fragment>
    )
}