import { FeedCard } from "./FeedCard";
import { LoadingComponentWeb } from "./LoadingComponent";

export function FeedList(props) {

    let feedcards = ''
    if (props.feedlist)
        feedcards = props.feedlist.map((f) => <FeedCard feed={f} selectFeed={props.selectFeed} />)

    return (
        props.loading ?
            <LoadingComponentWeb />
            :
            <div className="feed_list_container">
                {feedcards}
            </div >
    )
}
