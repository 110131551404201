import React, { useEffect } from "react"
import { VrService } from "../Services/Services"
import { HeadsetDetail } from './HeadsetDetail.js'
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { BackButton } from "./BackButton";
import { LoadingComponentWeb } from "./LoadingComponent";

export function VRLearning(props) {

    const [loading, setLoading] = React.useState(false)
    const [all_headsets, setAllHeadsets] = React.useState([])
    const [headsetDetail, setHeadsetDetail] = React.useState(false)
    const [selectedHeadset, setSelectedHeadset] = React.useState(false)
    const [viewAll, setViewAll] = React.useState(false)
    const [tutorialPopup, setTutorialPopup] = React.useState(false)
    const [selectedTutorialPage, setSelectedTutorialPage] = React.useState(0)
    const vs = new VrService()

    useEffect(() => {
        if (props.office.id)
            vs.getAllHeadsets(props.office.id).then((data) => {
                setAllHeadsets(data)
            })
    }, [])

    //translation applanga function
    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        }
    }

    //back from detail
    const back = () => {
        setLoading(true)

        vs.getAllHeadsets(props.office.id).then((data) => {
            setAllHeadsets(data)
        })
        let a = document.getElementsByClassName('home_menu')
        if (a && a[0])
            a[0].scrollTo(0, 0, 'smooth')

        setHeadsetDetail(false)
        setSelectedHeadset(false)
        setLoading(false)
    }

    //it changes tutorial page
    const changeTutorialPage = (type) => {
        if (type === 'prev')
            setSelectedTutorialPage(selectedTutorialPage - 1)
        else {
            setSelectedTutorialPage(selectedTutorialPage + 1)
        }
    }

    //it opens the tutorial popup
    const openTutorialPopup = (val) => {
        setTutorialPopup(val)
    }

    //open detail page and scroll to top
    const handleSelectDetail = (headset) => {
        setHeadsetDetail(true)
        setSelectedHeadset(headset)
        let a = document.getElementsByClassName("home_menu")
        if (a && a[0])
            a[0].scrollTo(0, 0, 'smooth')
    }

    //modal style
    let customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },
    };

    const goBack = () => {
        props.select('offices', props.office)
        props.setHidden(false)
    }

    return (
        <div>
            {loading ?
                <LoadingComponentWeb />
                :
                <React.Fragment>
                    {window.innerWidth > 1000 ? (headsetDetail ? <BackButton backButtonFunction={() => back()} index={203} /> : '') : <BackButton backButtonFunction={() => goBack()} />}
                    <div className="flex">
                        <div className="vr_global_container">
                            {headsetDetail ?
                                <HeadsetDetail back={() => back()} headset={selectedHeadset} />
                                :
                                <React.Fragment>
                                    <div>
                                        <div Style={"font-family: 'GucciSans-Bold'; margin: 15px 0;"}>{getTranslationByKey('VR.HOME.INSTRUCTIONS.TITLE.KEY')}</div>
                                        <div>
                                            {getTranslationByKey('VR.HOME.INSTRUCTIONS.TEXT')}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="vr_headsets_container">
                                            <div className="flex">
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('VR.TITLE')}</div>

                                            </div>
                                            <div Style={"margin: 10px 0;"}>
                                                <div className="flex_wrap">
                                                    {all_headsets.map((h) =>
                                                        <div className={h.borrowed ? "headset_card_borrowed" : "headset_card"} Style={"background-image:url(" + (h.image_url ? h.image_url : '') + ");"} onClick={() => handleSelectDetail(h)}></div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>}
                        </div>
                    </div>

                    <Dialog
                        open={tutorialPopup}
                        onAfterOpen={null}
                        onClose={() => openTutorialPopup(false)}
                        style={customModalStyles}
                        className="tutorial_dialog"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>
                            <div className="close" >
                                <svg onClick={() => openTutorialPopup(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            {props.tutorial ? <div className="modal_body pb-25">
                                <div className="desk_tutorial_container">
                                    <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                        {selectedTutorialPage > 0 ?
                                            <div>
                                                <svg onClick={() => changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                        <div className="desk_tutorial_page" Style={"background-image: url('" + props.tutorial[selectedTutorialPage] + "')"}></div>
                                        {selectedTutorialPage < props.tutorial.length - 1 ?
                                            <div>
                                                <svg onClick={() => changeTutorialPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                    </div>
                                </div>
                            </div>
                                : ''}
                        </DialogContent>
                    </Dialog>
                </React.Fragment >}
        </div >
    )


}