import { useState } from "react";
import Calendar from "../components/Calendar";
import { BackButton } from "./BackButton";

export function DeskBookingModule(props) {
    const [loading3, setLoading3] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dateString, setDateString] = useState("TODAY")
    const [calendarDate, setCalendarDate] = useState(new Date().toISOString().split('T')[0])
    const [differenceString, setDifferenceString] = useState('')
    const [monthBookings, setMonthBookings] = useState([])
    const [selectedBookings, setSelectedBookings] = useState([])
    const [officeDetailShow, setOfficeDetailShow] = useState(false)


    const updateCalendar = () => {
        this.setLoading3(true)
        setTimeout(() => {
            this.setLoading3(false)
        }, 500);
    }

    const setCalendarDateFunc = (val, dateStr, newDifferenceString) => {
        let date = new Date()
        date.setFullYear(val.split('-')[0])
        date.setMonth(parseInt(val.split('-')[1], '') - 1)
        date.setDate(val.split('-')[2])

        let newDate = date.toISOString().split('T')[0]
        let finalDate = newDate.split('-')[0] + '-' + newDate.split('-')[1] + '-' + (newDate.split('-')[2])

        setCalendarDate(finalDate)
        setDateString(dateStr)
        setDifferenceString(newDifferenceString)

    }

    const resetCalendarDate = () => {
        setCalendarDate(new Date().toISOString().split('T')[0])
        setDateString("TODAY")
        setDifferenceString('')
    }

    const updateMonthBooking = (val) => {
        setLoading(true)
        setMonthBookings(val)
    }

    const updateSelectedBookings = (val) => {
        setLoading(true)
        setSelectedBookings(val)
    }
    const setOfficeDetail = (val) => {
        setLoading(true)
        setOfficeDetailShow(val)
        setLoading(false)
    }

    const goBack = () => {
        props.select('offices', props.selected_office)
        props.setHidden(false)
    }

    return (
        <div className="flex deskbooking_container">
            <BackButton backButtonFunction={() => goBack()} index={99} />
            <div className="home_menu">
                <Calendar
                    user={props.user}
                    updateCalendar={updateCalendar}
                    setLoading2={setLoading2}
                    loading2={loading2}
                    reload={props.refresh2}
                    setLoading={setLoading}
                    select={(val) => this.select(val)}
                    loading={loading}
                    monthBookings={monthBookings}
                    updateMonthBooking={updateMonthBooking}
                    officeDetailShow={officeDetailShow}
                    setOfficeDetail={setOfficeDetail}
                    bookDetail={false}
                    date={calendarDate}
                    dateString={dateString}
                    differenceString={differenceString}
                    resetCalendarDate={resetCalendarDate}
                    setCalendarDate={setCalendarDateFunc}
                    selectedBookings={selectedBookings}
                    updateSelectedBookings={updateSelectedBookings} />

            </div>
            <div className="right_menu">

                <div className={officeDetailShow ? "book_detail_container detail_open" : "book_detail_container"}>
                    <Calendar
                        right={true}
                        user={props.user}
                        setLoading2={setLoading2}
                        loading2={loading2}
                        reload={props.refresh2}
                        setLoading={setLoading}
                        select={(val) => this.select(val)}
                        loading={loading}
                        monthBookings={monthBookings}
                        updateMonthBooking={updateMonthBooking}
                        officeDetailShow={officeDetailShow}
                        bookDetail={true}
                        date={calendarDate}
                        dateString={dateString}
                        updateCalendar={updateCalendar}
                        differenceString={differenceString}
                        setOfficeDetail={setOfficeDetail}
                        resetCalendarDate={resetCalendarDate}
                        selectedBookings={selectedBookings}
                        updateSelectedBookings={updateSelectedBookings} /></div>
            </div>



        </div>)
}