import React, { useEffect, useRef, useState } from "react"
import { WellnessService } from "../Services/Services"
import { getTranslationByKey } from "../global"
import { ApplyFilters, ResetFilters } from "./Buttons"
import Select from "react-select";
import CourseCard from "./CourseCard"
import ScrollCalendar from "./ScrollCalendar"
import WellnessLabCourseCard from "./WellnessLabCourseCard";
import CoursePage from "./CoursePage";
import Modal from 'react-modal';
import { BookServiceButton } from "./Buttons";
import WellnessLabCoursePage from "./WellnessLabCoursePage";
import { BackButton } from "./BackButton";
export default function WellnessLabComponent(props) {

    const [trainers, setTrainers] = useState([])
    const [locations, setLocations] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [loading, setLoading] = useState(true)
    const [course, setCourse] = useState(null)
    const [filters, setFilters] = useState(null)
    const [text, setText] = useState('')
    const [selectedLocation, setSelectedLocation] = useState({ label: getTranslationByKey("WELLNESS.COURSES.FILTER.CHOOSELAB") ? getTranslationByKey("WELLNESS.COURSES.FILTER.CHOOSELAB") : 'Choose Lab', value: null })
    const [selectedTrainer, setSelectedTrainer] = useState({ label: getTranslationByKey("WELLNESS.COURSES.FILTER.CHOOSETRAINER") ? getTranslationByKey("WELLNESS.COURSES.FILTER.CHOOSETRAINER") : 'Choose trainer', value: null })
    const [freeSeats, setFreeSeats] = useState(null)
    const [courses, setCourses] = useState(null)
    const [modal_status, setModalStatus] = useState(false)
    const [selectedService, setSelectedService] = useState({ label: getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") ? getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") : 'Select a Service', value: null })
    const [services, setServices] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [loadingModal, setLoadingModal] = useState(null)
    const [myBookingValue, setMyBookingValue] = useState(null)
    const [courseDetailView, setCourseDetailView] = useState(false)
    const myRef = useRef(null);
    let ws = new WellnessService()

    useEffect(() => {
        //get filter options
        ws.getTrainers().then((data) => {
            let list = []
            data.map((t) => {
                list.push({ value: t.id, label: t.first_name + ' ' + t.last_name })
            })
            setTrainers(list)
        }).catch((err) => {
            // setLoading(false)
        })

        ws.getGyms().then((data) => {
            let list = []
            data.map((l) => {
                list.push({ value: l.id, label: l.name })
            })
            setLocations(list)
        })

        let sd = new Date()
        let s = sd.getFullYear() + '-' + ('0' + (sd.getMonth() + 1)).slice(-2) + '-' + sd.getDate()
        setSelectedDate(s)
        getCoursesbyDate(s);
    }, [])

    useEffect(() => {
        if (!modal_status) {
            //setLoading(false)
        }
    }, [modal_status])


    const getCoursesbyDate = async (s) => {
        let options = {
            "name": text,
            "from_date": s,
            "to_date": s,
            "gym_id": selectedLocation.value,
            "trainer_id": selectedTrainer.value,
            "only_available": freeSeats
        }
        ws.getFilteredSchedule(options).then((data) => {
            setCoursesData(data)
        }).finally(() => {
            setTimeout(() => {

                setLoading(false)
            }, 300);
        })
    }

    const setCoursesData = (courses) => {
        setCourses(courses)
        //update current course selected
        if (course) {
            courses.map((c) => {
                if (c.id == course.id) {
                    setCourse(c)
                }
            })
        }
    }
    const handleClick = (e) => {
        if (document.getElementsByClassName('filters_container') && document.getElementsByClassName('filters_container')[0])
            if (!document.getElementsByClassName('filters_container')[0].contains(e.target) && !document.getElementsByClassName('bi-filter')[0].contains(e.target)) {
                showFilters()
            }
    }
    useEffect(() => {
        document.addEventListener('click', handleClick, false)

        return () => {
            document.removeEventListener('click', handleClick, false)
        }
    }, [])

    const openModal = (course) => {
        if (modal_status) {
            setModalStatus(false)
            setSelectedService(getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") ? getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") : 'Select a Service')
        } else {
            setLoading(false)
            setLoadingModal(true)
            ws.getServices(course.id).then((data) => {
                let list = []
                data.map((s) => {
                    list.push({ 'value': s.id, 'label': s.name })
                })
                setServices(list)
            }).finally(() => {
                setLoadingModal(false)
            })

            setModalStatus(true)
            setSelectedCourse(course)
            setLoadingModal(false)
            setSelectedService(getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") ? getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") : 'Select a Service')
        }
    }

    const setSelectedDateString = (sd) => {
        let s = sd.getFullYear() + '-' + (1 + sd.getMonth()) + '-' + sd.getDate()
        setSelectedDate(s)
        setCourse(null)
        setFilters(false)
        getCoursesbyDate(s);
    }

    const reload = () => {
        setLoading(true)
        setTimeout(() => {
            getCoursesbyDate(selectedDate)
        }, 1500)
    }
    const showFilters = () => {
        setFilters(!filters)

    }
    const applyFilters = () => {
        let options = {
            "name": text,
            "from_date": selectedDate,
            "to_date": selectedDate,
            "gym_id": selectedLocation.value,
            "trainer_id": selectedTrainer.value,
            "only_available": freeSeats
        }

        ws.getFilteredSchedule(options).then((data) => {
            setCourses(data)
            setLoading(false)
            showFilters()
        })
    }
    const resetFilters = () => {
        setSelectedLocation({
            label: getTranslationByKey("WELLNESS.COURSES.FILTER.CHOOSELAB") ? getTranslationByKey("WELLNESS.COURSES.FILTER.CHOOSELAB") : 'Choose Lab',
            value: null
        })
        setSelectedTrainer({
            label: getTranslationByKey("WELLNESS.COURSES.FILTER.CHOOSETRAINER") ? getTranslationByKey("WELLNESS.COURSES.FILTER.CHOOSETRAINER") : 'Choose trainer',
            value: null,
        })
        setText('')
        setFreeSeats(false)

        let options = {
            "name": '',
            "from_date": selectedDate,
            "to_date": selectedDate,
            "gym_id": null,
            "trainer_id": null,
            "only_available": ''
        }

        ws.getFilteredSchedule(options).then((data) => {
            setCourses(data)
        })
    }
    const goToDetailView = (c) => {
        setCourseDetailView(true)
        setSelectedCourse(c)
    }
    const goBack = (c) => {
        setCourseDetailView(false)
        setSelectedCourse(null)
        reload()
    }
    const customStyles = {
        control: (base, state) => ({
            ...base,
            cursor: 'pointer',
            color: window.innerWidth > 1000 ? "#9a9a9a !important" : '#000',
            background: window.innerWidth > 1000 ? "#fff" : "#fbfbfb",
            border: 0,
            borderColor: state.isSelected ? null : null,
            boxShadow: state.isFocused ? null : null,
            "text-align": 'left'
        }),
        singleValue: provided => ({
            ...provided,
            color: window.innerWidth > 1000 ? "#9a9a9a" : '#000'
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...base,
                cursor: 'pointer',
                background: window.innerWidth > 1000 ? "#fff" : "#fbfbfb",
                color: '#000',
                "font-size": '14px',
                padding: '5px 0',
                '&:hover': {
                    background: "#d6d6d6",
                }
            };
        }
    };
    let coursesList = null;
    if (!loading) {
        if (props.user && courses && courses.length > 0)
            coursesList = courses.map((c) => <WellnessLabCourseCard key={c.id} course={c} user={props.user} reload={reload} openModal={openModal} goToDetailView={goToDetailView} setLoading={setLoading} />)
    }
    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: window.innerWidth > 1000 ? '600px' : '100%',
            height: '400px',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": window.innerWidth > 1000 ? '#fff' : "#f0efef",
            "border-radius": window.innerWidth > 1000 ? '20px' : 0
        },
    };

    const customStylesServices = {
        control: (base, state) => ({
            ...base,
            background: window.innerWidth > 1000 ? '#fff' : "#f0efef",
            border: 0, border: 0,
            borderColor: state.isSelected ? null : null,
            boxShadow: state.isFocused ? null : null,

        }),
        menuList: base => ({
            ...base,
            background: window.innerWidth > 1000 ? '#fff' : "#f0efef",
            padding: 0,
            height: "270px",
            "::-webkit-scrollbar-thumb": {
                background: "#000000 !important"
            },
        })
    };

    const goBackButton = () => {
        props.select('offices', props.office)
        props.setHidden(false)
    }
    useEffect(() => {

    }, [])

    return (
        <div className="wellnesslab_courses_container">
            {window.innerWidth > 1000 ? '' : <BackButton backButtonFunction={() => goBackButton()} />}
            {!courseDetailView ?
                <div className="wellnesslab_courses">
                    <div className="flex">
                        <div class="calendar">
                            <ScrollCalendar loading={setLoading} setSelectedDate={setSelectedDateString} showFilters={showFilters} selectedDate={selectedDate} />
                        </div>
                        <div className="title_container">
                            <div className="filters flex">
                                <svg onClick={showFilters} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class=" pointer ml-3 bi bi-filter" viewBox="0 0 16 16">
                                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                                </svg>
                            </div>
                            <div className={filters ? "filters_container" : "hidden"} >
                                <div className="close" >
                                    <svg onClick={showFilters} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                                <div className="filters_list">
                                    <div className="filters_body">
                                        <div className="filter_title">
                                            {getTranslationByKey('WELLNESS.COURSES.FILTER.NAME')}</div>
                                        <div className="filters_card">
                                            <input className="filter_input_name" placeholder={getTranslationByKey('WELLNESS.COURSES.FILTER.COURSENAME')} type='text' value={text} onChange={(e) => setText(e.target.value)} />
                                            <hr className="filter_separator" />
                                            <div className="filter_text_fixed flex" type='text' >
                                                {getTranslationByKey('WELLNESS.COURSES.FILTER.FREESEATS')}
                                                <input checked={freeSeats} type="checkbox" className="checkbox" id="chk" onChange={() => setFreeSeats(!freeSeats)} />
                                                <label className="filter_label" htmlFor="chk">
                                                    <div className="filter_ball"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="filter_title mt-3">
                                            {getTranslationByKey('WELLNESS.COURSES.FILTER.TRAINER')}</div>
                                        <div className="filters_card_s">
                                            <Select
                                                value={selectedTrainer}
                                                onChange={setSelectedTrainer}
                                                options={trainers}
                                                className="filter_input pt-1"
                                                placeholder={selectedTrainer.value}
                                                styles={customStyles}
                                                isSearchable={false}
                                            />
                                        </div>
                                        <div className="filter_title mt-3">
                                            {getTranslationByKey('WELLNESS.COURSES.FILTER.LOCATION')}</div>
                                        <div className="filters_card_s">
                                            <Select
                                                value={selectedLocation}
                                                onChange={setSelectedLocation}
                                                options={locations}
                                                className="filter_input pt-1"
                                                placeholder={selectedLocation.value}
                                                styles={customStyles}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="filters_buttons_container">
                                        <ApplyFilters onClick={applyFilters} />
                                        <ResetFilters onClick={resetFilters} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="separator" />
                    {loading ?
                        <div className='loader_ellipsis' Style={"margin-top: 200px !important;"}>
                            <div class="lds-ellipsis">
                                <div Style={"background: #000"}></div>
                                <div Style={"background: #000"}></div>
                                <div Style={"background: #000"}></div>
                                <div Style={"background: #000"}></div>
                            </div>
                        </div>
                        :
                        <div className="courses_container">
                            {coursesList ? coursesList :
                                <div className="no_available">
                                    <div className='no_courses'>{getTranslationByKey('WELLNESS.COURSES.NOCLASSES')}</div>
                                </div>}
                        </div>
                    }
                </div>
                :
                <WellnessLabCoursePage loading={loading} setLoading={setLoading} key={selectedCourse.id} course={selectedCourse} courses={courses} user={props.user} reload={reload} openModal={openModal} goBack={goBack} />}


            <Modal
                isOpen={modal_status}
                onAfterOpen={null}
                onRequestClose={() => openModal(selectedCourse)}
                style={customModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
            >
                <div className="close" >
                    <svg onClick={() => openModal(selectedCourse)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>


                <div className="modal_body" ref={myRef}>
                    <div className="modal_select">
                        <Select
                            value={selectedService ? selectedService.value : null}
                            onChange={setSelectedService}
                            options={services}
                            className="filter_input_services pt-1"
                            placeholder={selectedService ? selectedService.label : ''}
                            styles={customStylesServices}
                        />
                    </div>

                    <div className="modal_button">
                        <BookServiceButton className="book_button" course={selectedCourse} user={props.user} reload={reload} selectedServiceType={selectedService.value} openModal={() => openModal(selectedCourse)} name={getTranslationByKey('WELLNESS.UTILS.BOOK')} message={getTranslationByKey('WELLNESS.UTILS.BOOKING.SUCCESS')} />
                    </div>
                </div>

            </Modal>
        </div>)
}