import React, { useEffect } from "react";
import { getTranslationByKey } from "../global";

export function WebPathlistSingleModule(props) {

    useEffect(() => { ; }, [props.selected])

    return (
        <div className="web_pathlist">
            <div Style="max-width: 1345px;">
                <div className=" web_pathlist_text">
                    <div className="flex bold" Style="align-content: center;">

                        {props.selected ?
                            <React.Fragment>
                                <div className="bold pointer bg-white" onClick={() => props.handleSelect(-1)}>
                                    {getTranslationByKey('Homepage')}
                                </div>
                                <div Style="margin: 0 5px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg>
                                </div>
                                <div className="bold pointer bg-white">
                                    {getTranslationByKey(props.selected && props.selected === 'wellness' ? 'Wellness Lab' : (props.selected === 'bookshelf_singlem' ? 'Bookshelf' : (props.selected === 'ready_to_grow_singlem' ? 'Ready to grow' : (props.selected === 'feed' ? 'Feed' : ''))))}
                                </div>

                            </React.Fragment> : ''}

                        {props.tutorial && props.tutorial.length > 0 ?
                            <div Style="margin-left: auto;" onClick={() => props.openTutorialPopup()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                            </div> : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}
