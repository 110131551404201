import { FeedService } from "../Services/Services";
import React, { useEffect, useState } from 'react';
import { getTranslationByKey } from "../global";
import { FeedAudioComponent } from "./FeedAudioComponent";
import { FeedVideoComponent } from "./FeedVideoComponent";
import { InternalBackButton } from "./InternalBackButton";
import { BackButton } from "./BackButton";

export function FeedDetail(props) {

    const fS = new FeedService();
    const [loading, setLoading] = useState(true);
    const [resource, setResource] = useState(true);

    useEffect(() => {
        fS.getResourceById(props.feed.id).then((data) => {
            setResource(data)
            setLoading(false)
            fS.putViewResource(props.feed.id)
        });
    }, [props.feed])

    const getUploadedAtData = () => {
        var currentdate = new Date(props.feed.uploaded_at);
        var datetime = ("0" + currentdate.getDate()).slice(-2) + " "
            + currentdate.toLocaleDateString([], { month: 'short' }) + " "
            + currentdate.getFullYear()
        return datetime
    }

    return (
        <div className="feed_mobile_overflow">
            {window.innerWidth > 1000 ?
                <InternalBackButton name={'back_button_component_dx_feed_detail pointer flex'} key='feed_detail' backButtonFunction={props.back} index={203} />
                : <BackButton key='selected_book' backButtonFunction={props.back} index={210} />}

            {loading ?
                ''
                :
                <React.Fragment>
                    <div className="multimedial_content">
                        {props.feed.type && props.feed.type === 'video' ?
                            <FeedVideoComponent feed={props.feed} getUploadedAtData={getUploadedAtData} resource={resource} /> :
                            <FeedAudioComponent feed={props.feed} resource={resource} />}
                    </div>
                    <div className="feed_description_content">
                        <div>
                            <div className="flex" Style="margin-bottom: 20px;">
                                <div className="bold">{getTranslationByKey('BS.DETAIL.DESCRIPTION.KEY')}</div>
                                <div className="audio_date">{getUploadedAtData()}</div>
                            </div>
                            <div className="feed_description_detail">{props.feed.description}</div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}