import moment from "moment";
import React, { Component } from "react";
import Modal from 'react-modal';
import { WellnessService } from "../Services/Services";
import BookButton, { RemoveBooking, WaitButton, RemoveWaitingBooking, BookedButton } from "./Buttons";
import { BackButton } from "./BackButton";
import { LoadingComponent } from "./LoadingComponent";

//Page with all course description
class CoursePage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            course: this.props.course,
            gym_info: false,
            gym: null,
            ws: new WellnessService()
        }

        this.setCourse = this.setCourse.bind(this)
        this.setGym = this.setGym.bind(this)
    }

    componentDidMount() {
        if (this.props.course.gym.id)
            this.state.ws.getGymById(this.state.course.gym.id).then((data) => {
                this.setGym(data)
            })
    }

    componentDidUpdate(props) {
        if (props.course.id != this.props.course.id) {
            this.state.ws.getGymById(this.props.course.gym.id,).then((data) => {
                this.setGym(data)
                this.setState({
                    course: this.props.course
                })
            })
            return true
        }
        if (props.course.name != this.props.course.name) {

            this.state.ws.getGymById(this.props.course.gym.id).then((data) => {
                this.setGym(data)
                this.setState({
                    course: this.props.course
                })
            })
            return true
        }
    }

    //translation applanga function
    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    setGym(g) {
        this.setState({
            gym: g
        })
    }

    setModalStatus(bool) {
        this.setState({
            modal_status: bool
        })
    }

    async setLoading(val) {
        this.setState({
            loading: val
        })
    }

    setCourse(c) {
        this.setState({
            course: c
        })
        if (this.state.course.gym)
            this.state.ws.getGymById(this.state.course.gym.id).then((data) => {
                this.setGym(data)
            })
    }

    reload() {
        this.state.ws.getCourseById(this.state.course.id).then((data) => {
            this.setCourse(data)
        })
    }

    compareDate(course) {

        let from = course.time.from
        let date = course.date.split('T')
        let new_date = date[0] + 'T' + from

        let today = new Date()
        let dateToCompare = new Date(new_date)

        let ret = false
        if (dateToCompare < today)
            ret = true

        return ret
    }

    render() {

        let customModalStyles = {

            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '600px',
                height: '400px',
                marginRight: '-50%',
                padding: '15px',
                transform: 'translate(-50%, -50%)',
                'z-index': 99,
                transition: "opacity 20ms ease-in -out",
                "background-color": "#f0efef"
            },

        };

        let imageLinkTrainer = ''
        let imageLinkCourse = ''
        if (this.props.course !== null) {
            imageLinkTrainer = "background-image:url('" + this.props.course.trainer.image_url + "')"
            imageLinkCourse = "background-image:url('" + this.props.course.course.image_url + "')"
        }

        let address = ""
        if (this.state.gym)
            address = "https://www.google.it/maps/place/" + this.state.gym.location.street_address


        let string_available = this.getTranslationByKey('WELLNESS.AVAILABLEPLACES')
        return (
            <React.Fragment>
                {window.innerWidth > 1000 ? <BackButton backButtonFunction={this.props.goBack} index={99} /> : ''}
                <div className="reserv_menu">
                    <div className="course_container">
                        {!this.state.loading ?
                            <div className="course">
                                <div className="top_course">

                                    <div className="flex header_container">
                                        <div className="course_header" Style={imageLinkCourse}>
                                        </div>
                                        <div className="info_header">
                                            <div className="info">
                                                <div className="row_info_title flex">
                                                    <div className="course_info">
                                                        <div className="course_page_info_title flex" >
                                                            <div Style={"font-family: 'GucciSans-Bold'; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;"}>{this.getTranslationByKey(this.state.course.course.name)}</div>
                                                            {this.props.course.booked ? <div className="flag_booked">{this.getTranslationByKey('WELLNESS.UTILS.BOOKED')}</div> : ''}
                                                        </div>
                                                        <div className="course_page_info_subtitle flex">
                                                            <a target="_blank" rel="noreferrer" href={address}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" class="bi bi-geo-alt-fill location_icon" viewBox="0 0 16 16">
                                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                                </svg>
                                                            </a>
                                                            <div className="mt-1" Style={"overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;"} >{this.state.course.gym ? this.state.course.gym.name : "No gym defined"}</div>

                                                        </div>
                                                        {this.compareDate(this.props.course) ?
                                                            ''
                                                            :
                                                            <div className="course_page_info_subtitle" Style={"font-family: 'GucciSans-Book'"}>
                                                                {this.state.course.available_seats + " " + string_available}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="course_img_container">
                                                        <div className="course_img" Style={imageLinkTrainer}></div>
                                                    </div>
                                                </div>

                                                <div className="course_page_info_title">
                                                    {moment(this.state.course.date + '.000Z').utc().format('ddd').toUpperCase() + moment(this.state.course.date + '.000Z').utc().format(', D MMMM YYYY')}
                                                </div>
                                                <div className="course_page_info_subtitle">
                                                    {(this.state.course.time.from).slice(0, 5)} - {(this.state.course.time.to).slice(0, 5)}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="course_description_container">
                                        <div className="course_description_row flex">
                                            <div className="course_description_title">{this.getTranslationByKey('WELLNESS.COURSES.DESCRIPTION')}</div>
                                            <div className="container_list_button flex">
                                                {this.compareDate(this.props.course) || !this.props.course.is_bookable ?
                                                    (this.props.course.deletable ?
                                                        <RemoveBooking className="remove_booking mt-1" course={this.props.course} user={this.props.user} reload={() => this.props.reload()} name={this.getTranslationByKey('WELLNESS.UTILS.DELETE')} />
                                                        : '')
                                                    :
                                                    this.props.course.booked ?
                                                        (this.props.course.deletable ?
                                                            <RemoveBooking className="remove_booking mt-1" course={this.props.course} user={this.props.user} reload={() => this.props.reload()} name={this.getTranslationByKey('WELLNESS.UTILS.DELETE')} />
                                                            :
                                                            <BookedButton className="remove_booking cursor_auto" name={this.getTranslationByKey('WELLNESS.UTILS.BOOKED')} />)
                                                        :
                                                        (this.props.course.available_seats == 0 ?
                                                            this.props.course.is_waiting ?
                                                                <RemoveWaitingBooking className="remove_booking mt-1" course={this.props.course} user={this.props.user} reload={() => this.props.reload()} message={this.getTranslationByKey('WELLNESS.UTILS.REMOVEDWAITING')} name={this.getTranslationByKey('WELLNESS.UTILS.EXITWAITINGLIST')} />
                                                                :
                                                                <WaitButton className="book_button" course={this.props.course} user={this.props.user} reload={(val) => this.props.reload(val)} check_course={this.props.course} checkIfModal={(course) => this.props.openModal(course)} message={this.getTranslationByKey('WELLNESS.UTILS.INSERTEDWAITING')} name={this.getTranslationByKey('WELLNESS.UTILS.WAITING')} />
                                                            :
                                                            <BookButton className="book_button" course={this.props.course} user={this.props.user} reload={() => this.props.reload()} setBooked={(val) => this.props.setBooked(val)} checkIfModal={(course) => this.props.openModal(course)} check_course={this.props.course} message={this.getTranslationByKey('WELLNESS.UTILS.INSERTEDBOOKING')} name={this.getTranslationByKey('WELLNESS.UTILS.BOOK')} />)}
                                            </div>
                                        </div>
                                        <div className="course_description_content" >{this.getTranslationByKey(this.state.course.course.description)}</div>
                                    </div>

                                </div>
                            </div> :
                            <LoadingComponent />
                        }

                        <Modal
                            isOpen={this.state.modal_status}
                            onAfterOpen={null}
                            onRequestClose={() => this.setModalStatus(false)}
                            style={customModalStyles}
                            contentLabel="Example Modal"
                            appElement={document.getElementById('root') || undefined}
                        >
                            <div className="close" >
                                <svg onClick={() => this.setModalStatus(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                            <div className="modal_title">
                                {this.getTranslationByKey('WELLNESS.COURSES.PARTECIPANT')}
                            </div>

                            <div className="modal_body pb-25">
                                <div className="participants_container">
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div >
            </React.Fragment>

        )
    }
}
export default CoursePage;
