import Countdown from 'react-countdown';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
export default function EventCountdownLeftbar(props) {

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        // Render a countdown
        return (
            <div className="flex">
                <div className="countdown_columns">
                    <div className="countdown_counter_text">{zeroPad(days, 2)} </div>
                    <div className="countdown_text" >days</div>
                </div>

                <div className="countdown_columns">
                    <div className="countdown_counter_text" > {zeroPad(hours, 2)}</div>
                    <div className="countdown_text" >hours</div>
                </div>

                <div className="countdown_columns">
                    <div className="countdown_counter_text" > {zeroPad(minutes, 2)}</div>
                    <div className="countdown_text" >minutes</div>
                </div>

                <div className="countdown_columns">
                    <div className="countdown_counter_text" > {zeroPad(seconds, 2)} </div>
                    <div className="countdown_text" >seconds</div>
                </div>

            </div>
        )
    };

    const zeroPad = (num, numZeros) => {
        var n = Math.abs(num);
        var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
        var zeroString = Math.pow(10, zeros).toString().substr(1);
        if (num < 0) {
            zeroString = '-' + zeroString;
        }
        return zeroString + n;
    }

    let imgs = props.events.map((e) => <>
        {e.has_countdown ?
            <div className={"countdown_container_string_leftbar"} Style={window.innerWidth > 1000 ? 'cursor: pointer;  top: ' + e.configuration.desktop_countdown_padding_top + 'px;' : 'cursor: pointer; top: ' + e.configuration.desktop_countdown_padding_top + "%;"} >
                <Countdown
                    date={new Date(e.to_date.split('T')[0])}
                    renderer={renderer}
                />
            </div>
            :
            ''
        }
        <img className="countdown_container_leftbar pointer" src={e.image_url} alt="img"></img>
    </>)

    return (
        props.events && props.events.length > 0 ?
            <div Style={"text-align: -webkit-center; margin-bottom: 10px;"} className='carousel_cont'>
                <div Style={'position:relative '} className='carousel_contain'>
                    {/* <img className="countdown_container_leftbar pointer" src={props.event.image_url} onClick={() => props.goToPage()}></img>*/}
                    <Carousel
                        interval={4000}
                        infiniteLoop={true}
                        transitionTime={1000}
                        showThumbs={false}
                        showStatus={false}
                        onClickItem={(e) => props.user.events[e].page_id ? props.select('custom_pages_event', props.user.events[e], 'custom_pages_event', 'menu') : window.open(props.user.events[e].redirect_url)}
                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            const selected = { marginLeft: -10, color: "#535353", cursor: "pointer" };
                            const notSelected = { marginLeft: -10, color: "#c2c2c2", cursor: "pointer" };
                            const style = isSelected
                                ? { ...selected, color: "#535353" }
                                : { ...notSelected };
                            return (
                                <span
                                    style={style}
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                    tabIndex={0}
                                    aria-label={`${label} ${index + 1}`}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-dot pointer" viewBox="0 0 16 16">
                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                    </svg>

                                </span>
                            );
                        }}
                    >
                        {imgs}

                    </Carousel>

                </div>
            </div > : ''
    )
}