import React, { Component } from "react";

//Footer Component for couse page
class CoursePageFooter extends Component {

    constructor(props) {
        super(props)
    }

    //translation applanga function
    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    render() {
        let address = "";
        if (this.props.gym && this.props.gym.location)
            address = "https://www.google.it/maps/place/" + this.props.gym.location.street_address
        return (
            <div className={this.props.showMap ? "course_page_footer" : "course_page_footer_slim"}>

                {this.props.gym && this.props.gym.location && this.props.gym.openingHours ?
                    <div className="course_page_footer_container flex">
                        <div className="gym_info_container_hours">
                            <div className="gym_info_hours">
                                <div className="text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class=" mb-1 bi bi-clock" viewBox="0 0 16 16">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                    </svg> {this.getTranslationByKey("WELLNESS.INFO.OPENINGTIME")}
                                </div>
                                <br />
                                <div className="text-center">
                                    <div className='hours_container flex'>
                                        <div className="day">   {this.getTranslationByKey("DAY.MONDAY")}:  <div className="grid hours">
                                            {this.props.gym.openingHours.Monday.map((m) => <p> {m} </p>)} </div></div>
                                        <div className="day">   {this.getTranslationByKey("DAY.TUESDAY")}:  <div className="grid hours">
                                            {this.props.gym.openingHours.Tuesday.map((m) => <p> {m} </p>)} </div></div>
                                        <div className="day">  {this.getTranslationByKey("DAY.WEDNESDAY")}:  <div className="grid hours">
                                            {this.props.gym.openingHours.Wednesday.map((m) => <p> {m} </p>)} </div></div>
                                        <div className="day">  {this.getTranslationByKey("DAY.THURSDAY")}:  <div className="grid hours">
                                            {this.props.gym.openingHours.Thursday.map((m) => <p> {m} </p>)} </div></div>
                                        <div className="last_day">   {this.getTranslationByKey("DAY.FRIDAY")}:  <div className="grid hours">
                                            {this.props.gym.openingHours.Friday.map((m) => <p> {m} </p>)} </div></div>
                                    </div>
                                </div>
                                {this.props.showMap ?
                                    <div className="gym_location_container flex">

                                        <a target="_blank" rel="noreferrer" href={address}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                        </svg></a>
                                        <div Style={"margin-top: 2px;"}>
                                            {this.props.gym.location.name} {this.props.gym.location.street_address} {this.props.gym.location.country}
                                        </div>
                                    </div> : null}
                            </div>
                            {/* mobile */}
                            <div className="gym_info_hours_mobile">

                                <div className="text-center" Style={"margin-top: 5px"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class=" mb-1 bi bi-clock" viewBox="0 0 16 16">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                    </svg> {this.getTranslationByKey("WELLNESS.INFO.OPENINGTIME")}    </div>
                                <div className='mt-2'>
                                    <div className="day_footer flex">
                                        <div className="day_name">
                                            {this.getTranslationByKey("DAY.MONDAY")}:
                                        </div>
                                        {this.props.gym.openingHours.Monday.map((m, index) =>
                                            <React.Fragment>
                                                <div>
                                                    {m}
                                                </div>
                                                {this.props.gym.openingHours.Monday.length - 1 > index ?
                                                    <div className="day_hour">
                                                        |
                                                    </div>
                                                    : ''}
                                            </React.Fragment>
                                        )}
                                    </div>

                                    <div className="day_footer flex">
                                        <div className="day_name">
                                            {this.getTranslationByKey("DAY.TUESDAY")}:
                                        </div>
                                        {this.props.gym.openingHours.Tuesday.map((m, index) =>
                                            <React.Fragment>
                                                <div className="day_hour">
                                                    {m}
                                                </div>
                                                {this.props.gym.openingHours.Tuesday.length - 1 > index ?
                                                    <div className="day_hour">
                                                        |
                                                    </div>
                                                    : ''}
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="day_footer flex">
                                        <div className="day_name">
                                            {this.getTranslationByKey("DAY.WEDNESDAY")}:
                                        </div>
                                        {this.props.gym.openingHours.Wednesday.map((m, index) =>
                                            <React.Fragment>
                                                <div>
                                                    {m}
                                                </div>
                                                {this.props.gym.openingHours.Wednesday.length - 1 > index ?
                                                    <div className="day_hour">
                                                        |
                                                    </div>
                                                    : ''}
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="day_footer flex">
                                        <div className="day_name">
                                            {this.getTranslationByKey("DAY.THURSDAY")}:
                                        </div>
                                        {this.props.gym.openingHours.Thursday.map((m, index) =>
                                            <React.Fragment>
                                                <div>
                                                    {m}
                                                </div>
                                                {this.props.gym.openingHours.Thursday.length - 1 > index ?
                                                    <div className="day_hour">
                                                        |
                                                    </div>
                                                    : ''}
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="day_footer flex">
                                        <div className="day_name">  {this.getTranslationByKey("DAY.FRIDAY")}:</div>
                                        {this.props.gym.openingHours.Friday.map((m, index) =>
                                            <React.Fragment>
                                                <div>
                                                    {m}
                                                </div>
                                                {this.props.gym.openingHours.Friday.length - 1 > index ?
                                                    <div className="day_hour">
                                                        |
                                                    </div>
                                                    : ''}

                                            </React.Fragment>)} </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }
            </div >
        )
    }
}
export default CoursePageFooter;