import React, { useEffect, useState } from 'react';
import { IoIosCheckmark } from "react-icons/io";
import { DeskService, WellnessService } from '../Services/Services';
import { LoadingComponentWeb } from './LoadingComponent';

export function Profile(props) {

    const [ds] = React.useState(new DeskService())
    const [ws] = React.useState(new WellnessService())
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState([])
    const [defaultFloor, setDefaultFloor] = useState([])
    const [openChooseFloor, setOpenChooseFloor] = useState(false)
    const [isEmailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false)
    const [isPushNotificationsEnabled, setPushNotificationsEnabled] = useState(false)
    const [openLanguageMenu, setOpenLanguageMenu] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : navigator.language.split('-')[0])
    const [isWellnessCalendaEnabled, setWellnessCalendaEnabled] = useState(user.enabled_to_wellness)

    useEffect(() => {
        ds.getUserProfile().then((data) => {
            setUser(data)
            setPushNotificationsEnabled(data.is_push_notifications_enabled)
            setEmailNotificationsEnabled(data.is_mail_notifications_enabled)
            setWellnessCalendaEnabled(data.wellness_calendar_active)
            if (data.modules && data.modules.desk_booking) {
                setDefaultFloor(data.modules.desk_booking.floor)
                //ds.getLocation(data.modules.desk_booking.location.id)
                setLoading(false)
            } else
                setLoading(false)
        })
    }, [])

    let languages = JSON.parse(sessionStorage.getItem('translations'))
    let list_langs = { "en": "English", "it": "Italiano" }
    let selected_lang = 'Default'
    Object.keys(languages).map(key => {
        if (key == selectedLanguage) {
            selected_lang = list_langs[key]
        }
    })

    const saveNewDefaults = () => {
        setLoading(true)

        let body = {
            "is_push_notifications_enabled": isPushNotificationsEnabled,
            "is_mail_notifications_enabled": isEmailNotificationsEnabled
        }

        localStorage.setItem('selectedLanguage', selectedLanguage)

        let el = {
            wellness_calendar_active: isWellnessCalendaEnabled
        }
        ws.updateWellnessCalendarSetting(el, false)
        if (user.modules.desk_booking) {
            ds.updateUser(user.id, defaultFloor.id).then(() => {
                ds.setNotificationType({
                    "is_push_notifications_enabled": isPushNotificationsEnabled,
                    "is_mail_notifications_enabled": isEmailNotificationsEnabled
                }).then(() => {
                    ds.getTranslationByLanguage(selectedLanguage).then(() => {
                        setTimeout(() => {
                            setLoading(false)
                        }, 1000);
                    })
                })
            })
        } else {
            ds.setNotificationType(body).then(() =>
                ds.getTranslationByLanguage(selectedLanguage).then(() => {
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000);
                }
                )
            )
        }
    }

    const downloadVideo = () => {
        ds.download('tutorial')
    }

    let webProfile = <div>
        <div className="ContentProfileName">{props.getTranslationByKey("DESKBOOKING.PROFILE.TITLE")}</div>
        <div className="ProfileDetailsWeb">
            <img className="imageProfileWeb" />
            <div className="flex" Style="align-items: center; width: 100%;">
                <div className='infoProfileContainerWeb'>
                    <div className='bold'>{Object.keys(user).length != 0 ? user.lastname : null} {Object.keys(user).length != 0 ? user.firstname : null}</div>
                    <div>{Object.keys(user).length != 0 ? user.job_title : null}</div>
                    <div>{Object.keys(user).length != 0 ? user.location_address : null}</div>
                </div>
                <div Style="margin-left: auto;">
                    <div className='flex' onClick={props.logout}>
                        <div Style="margin-right: 5px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                                <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                            </svg>
                        </div>
                        <div className="bold pointer" Style="padding-top: 2px;">{props.getTranslationByKey("DESKBOOKING.PROFILE.LOGOUT")}</div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
        <div className='profileSettingsContainer'>
            <div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3q0-.405-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708M3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z" />
                    </svg>
                </div>
                <div className='bold'>{props.getTranslationByKey("DESKBOOKING.PROFILE.SETTINGS")}</div>
            </div>
            <div className="DefaultDetailsWeb">

                <div className="profileDetalsline" >
                    <div>
                        <div className="textinone">
                            {props.getTranslationByKey("DESKBOOKING.PROFILE.PUSHNOTIFICATIONS")}
                        </div>
                    </div>
                    <div className="textPositionRight"><div className="textinone2">
                        <input checked={isPushNotificationsEnabled} type="checkbox" className="checkbox" id="chkPush" onChange={() => setPushNotificationsEnabled(!isPushNotificationsEnabled)} />
                        <label className="profileLabel" htmlFor="chkPush">
                            <div className="profileBall"></div>
                        </label>
                    </div>
                    </div>
                </div>
                <hr className="line_separator" Style="width: 100%"></hr>
                <div className="profileDetalsline" >
                    <div>
                        <div className="textinone">
                            {props.getTranslationByKey("DESKBOOKING.PROFILE.MAIL")}
                        </div>
                    </div>
                    <div className="textPositionRight">
                        <div className="textinone2">
                            <input checked={isEmailNotificationsEnabled} type="checkbox" className="checkbox" id="chkEmail" onChange={() => setEmailNotificationsEnabled(!isEmailNotificationsEnabled)} />
                            <label className="profileLabel" htmlFor="chkEmail">
                                <div className="profileBall"></div>
                            </label>
                        </div>
                    </div>
                </div>
                <hr className="line_separator" Style="width: 100%"></hr>
                <div className="profileDetalsline" onClick={() => setOpenLanguageMenu(!openLanguageMenu)} style={{ 'cursor': 'pointer' }}>
                    <div>
                        <div className="textinone">{props.getTranslationByKey("DESKBOOKING.PROFILE.CHANGELANG")}</div>
                    </div>
                    <div className="textPositionRight">
                        <div className="textinone2 ">
                            <div className='flex' Style={"align-items: center;"}>
                                <div Style={"margin-right: 10px;"}>{selected_lang}</div>
                                {openLanguageMenu ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                    </svg>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {openLanguageMenu ?
                    <React.Fragment>
                        {Object.keys(languages).map(key =>
                            <div style={{ 'display': 'flex', 'justify-content': 'space-between', lineHeight: '2em', cursor: 'pointer' }} onClick={() => setSelectedLanguage(key)}>
                                <div className="textPositionLeft" key={key}>
                                    {list_langs[key]}
                                </div>
                                {selectedLanguage == key ? <div style={{ 'text-align': 'right', 'color': '#000000', 'margin-right': '5%', 'font-size': '1.5em' }}><IoIosCheckmark /></div> : null}
                            </div>)}
                    </React.Fragment> : ''}
            </div>
        </div>
        <div className='profileSettingsContainer'>
            <div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                    </svg>
                </div>
                <div className='bold'>{props.getTranslationByKey("DESKBOOKING.PROFILE.INFO")}</div>
            </div>
            <div className="DefaultDetailsWeb">
                <div>
                    <div className="profileDetalsline" >
                        <div>
                            <div onClick={() => downloadVideo()} download='tutorial' className="pointer" style={{ textDecoration: 'none' }}>{props.getTranslationByKey("DESKBOOKING.PROFILE.VIDEOTUTORIAL")}</div>
                        </div>
                    </div>
                    <hr className="line_separator" Style="width: 100%"></hr>
                    <div className="profileDetalsline">
                        <div>
                            <a href="https://keringconnect.service-now.com/sp?id=sc_cat_item&sys_id=76f46c82db58f380c917ef3c0b9619c8" target="_blank" style={{ textDecoration: 'none', color: '#000' }}>{props.getTranslationByKey("DESKBOOKING.PROFILE.HELP")}</a>
                        </div>
                    </div>
                    <hr className="line_separator" Style="width: 100%"></hr>
                    <div className="profileDetalsline" style={{ marginBottom: '10px' }}>
                        <div>
                            <a href="mailto:gucciforyou-smb@gucci.com" style={{ textDecoration: 'none', color: '#000' }}>{props.getTranslationByKey("DESKBOOKING.PROFILE.SENDFEEDBACK")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className='flex' Style="justify-content: center; margin-top: 20px;">
                <div className='profile_button pointer' Style="background-color: #000; color: #fff;" onClick={() => saveNewDefaults()}>{props.getTranslationByKey("DESKBOOKING.PROFILE.SAVE")}</div>
                <div className='profile_button pointer' Style="margin-left: 20px;" onClick={props.closeProfile}>{props.getTranslationByKey("UTIL.ALERT.CLOSE")}</div>
            </div>
        </div>
    </div>


    let mobileProfile = <div Style={"max-width: 1066px;"}>
        <div>
            <div className="ContentProfileName">{props.getTranslationByKey("DESKBOOKING.PROFILE.TITLE")}</div>
            <div className="ProfileDetails">
                <img className="imageProfile" />
                <div className="UserInformation">
                    <p Style={"margin-bottom: 7px; font-family: GucciSans-Bold"}>{Object.keys(user).length != 0 ? user.lastname : null} {Object.keys(user).length != 0 ? user.firstname : null}</p>
                    <p Style={"margin-bottom: 7px"}>{Object.keys(user).length != 0 ? user.job_title : null}</p>
                    <p Style={"margin-bottom: 7px"}>{Object.keys(user).length != 0 ? user.location_address : null}</p>
                </div>
            </div>
        </div>
        {user && user.modules && user.modules.desk_booking ?
            <div className="ContentProfileSubName">
                {props.getTranslationByKey("DESKBOOKING.PROFILE.DEFAULTSETTINGS")}
            </div>
            : ''}

        {user && user.modules && user.modules.desk_booking && user.modules.desk_booking.location ?
            <div className="DefaultDetails">
                <div className="profileDetalsline">
                    <div className="textPositionLeft">
                        <div className="textinone">{props.getTranslationByKey("DESKBOOKING.PROFILE.SEDE")}</div>
                    </div>
                    <div className="textPositionRight">
                        <div className="textinone2">{Object.keys(user).length != 0 && user.modules.desk_booking ? user.modules.desk_booking.location.name : null}</div>
                    </div>
                </div>
                <hr className="line_separator"></hr>
                <div className="profileDetalsline" onClick={() => setOpenChooseFloor(!openChooseFloor)} style={{ 'cursor': 'pointer' }}>
                    <div className="textPositionLeft">
                        <div className="textinone">{props.getTranslationByKey("DESKBOOKING.PROFILE.PIANO")}</div>
                    </div>
                    <div className="textPositionRight">
                        <div className="textinone2">{defaultFloor !== null ? defaultFloor.name : null}
                            {openChooseFloor ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                </svg>
                            }

                        </div>
                    </div>
                </div>
            </div>

            : ''}

        <div className="ContentProfileSubName" Style={'text-transform:uppercase; padding-top:23px'}>
            {props.getTranslationByKey("DESKBOOKING.PROFILE.NOTIFICATION")}
        </div>
        <div className="DefaultDetails">

            <div className="profileDetalsline" >
                <div className="textPositionLeft">
                    <div className="textinone ">
                        {props.getTranslationByKey("DESKBOOKING.PROFILE.PUSHNOTIFICATIONS")}
                    </div>
                </div>
                <div className="textPositionRight"><div className="textinone2">
                    <input checked={isPushNotificationsEnabled} type="checkbox" className="checkbox" id="chkPush" onChange={() => setPushNotificationsEnabled(!isPushNotificationsEnabled)} />
                    <label className="profileLabel" htmlFor="chkPush">
                        <div className="profileBall"></div>
                    </label>
                </div>
                </div>
            </div>
            <hr className="line_separator"></hr>
            <div className="profileDetalsline" >
                <div className="textPositionLeft">
                    <div className="textinone">
                        {props.getTranslationByKey("DESKBOOKING.PROFILE.MAIL")}
                    </div>
                </div>
                <div className="textPositionRight">
                    <div className="textinone2">
                        <input checked={isEmailNotificationsEnabled} type="checkbox" className="checkbox" id="chkEmail" onChange={() => setEmailNotificationsEnabled(!isEmailNotificationsEnabled)} />
                        <label className="profileLabel" htmlFor="chkEmail">
                            <div className="profileBall"></div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className="DefaultDetails">
            <div className="profileDetalsline" onClick={() => setOpenLanguageMenu(!openLanguageMenu)} style={{ 'cursor': 'pointer' }}>
                <div className="textPositionLeft">
                    <div className="textinone">{props.getTranslationByKey("DESKBOOKING.PROFILE.CHANGELANG")}</div>
                </div>
                <div className="textPositionRight">
                    <div className="textinone2 ">
                        <div className='flex' Style={"align-items: center;"}>
                            <div Style={"margin-right: 10px;"}>{selected_lang}</div>
                            {openLanguageMenu ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                </svg>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {openLanguageMenu ?
                <React.Fragment>
                    {Object.keys(languages).map(key =>
                        <div style={{ 'display': 'flex', 'justify-content': 'space-between', lineHeight: '2em', cursor: 'pointer' }} onClick={() => setSelectedLanguage(key)}>
                            <div className="textPositionLeft" key={key}>
                                {list_langs[key]}
                            </div>
                            {selectedLanguage == key ? <div style={{ 'text-align': 'right', 'color': '#000000', 'margin-right': '5%', 'font-size': '1.5em' }}><IoIosCheckmark /></div> : null}
                        </div>)}
                </React.Fragment> : ''}
        </div>

        {user && user.enabled_to_wellness ?
            <div className="DefaultDetails">

                <div className="profileDetalsline">
                    <div className="textPositionLeft">
                        <div className="textinone"> {props.getTranslationByKey('WELLNESS.CALENDAR')}</div>
                    </div>
                    <div className="textPositionRight">
                        <div className="textinone2">
                            <input checked={isWellnessCalendaEnabled} type="checkbox" className="checkbox" id="chkCalendar" onChange={() => this.handleWellnessCalendar()} />
                            <label className="profileLabel" htmlFor="chkCalendar">
                                <div className="profileBall"></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div> : ''}
        <div className="moreInfo">
            <div className="profileDetalsline" >
                <div className="textPositionLeft">
                    <div onClick={() => downloadVideo()} download='tutorial' className="textinone pointer bold" style={{ textDecoration: 'none', color: '#000000' }}>{props.getTranslationByKey("DESKBOOKING.PROFILE.VIDEOTUTORIAL")}</div>
                </div>
            </div>
            <hr className="line_separator"></hr>
            <div className="profileDetalsline">
                <div className="textPositionLeft">
                    <a href="https://keringconnect.service-now.com/sp?id=sc_cat_item&sys_id=76f46c82db58f380c917ef3c0b9619c8" target="_blank" className="textinone bold" style={{ textDecoration: 'none', color: '#000000' }}>{props.getTranslationByKey("DESKBOOKING.PROFILE.HELP")}</a>
                </div>
            </div>
            <hr className="line_separator"></hr>
            <div className="profileDetalsline" style={{ marginBottom: '10px' }}>
                <div className="textPositionLeft">
                    <a className='bold' href="mailto:gucciforyou-smb@gucci.com" style={{ textDecoration: 'none', color: '#000000' }}>{props.getTranslationByKey("DESKBOOKING.PROFILE.SENDFEEDBACK")}</a>
                </div>
            </div>
        </div>

        <div className="moreInfo">
            <div className="profileDetalsline" style={{ marginBottom: '20px' }}>
                <div className="textPositionLeft"><div onClick={props.logout} className="textinone pointer bold" style={{ color: '#000000' }}>{props.getTranslationByKey("DESKBOOKING.PROFILE.LOGOUT")}</div></div> <div className="textPositionRight"><h3 className="textinone2"></h3></div>
            </div>
        </div>
        <div className='flex' Style="margin-top: 20px; justify-content: center;">
            <div class="OpenOfficeDetailBox mt-2" onClick={() => saveNewDefaults()}>{props.getTranslationByKey("DESKBOOKING.PROFILE.SAVE")}</div>
            <div class="CloseOfficeDetailBox_button mt-2" onClick={props.closeProfile}>{props.getTranslationByKey("UTIL.ALERT.CLOSE")}</div>
        </div>

    </div>

    let profileComponent = <div className='profile_container'>
        {window.innerWidth > 1000 ? webProfile : mobileProfile}
    </div >

    return (
        loading ?
            <LoadingComponentWeb />
            :
            profileComponent
    )
}
