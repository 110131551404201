import React, { useEffect, useState } from "react";
import { ReadyToGrowService } from "../Services/Services";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { Dialog, DialogContent, DialogTitle, } from "@mui/material";
import DatePicker from "react-datepicker";
import { BackButton } from "./BackButton";
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import { LoadingComponentWeb } from "./LoadingComponent";

export function ReadyToGrow(props) {

    const rtgS = new ReadyToGrowService()
    const [isLoading, setLoading] = useState(true)
    const [loadingSlots, setLoadingSlots] = useState(false)
    const [date, setDate] = useState(new Date().toISOString().split('T')[0])
    const [calendarDate, setCalendarDate] = useState(new Date().toISOString().split('T')[0])
    const [tutorialPopup, setTutorialPopup] = useState(false)
    const [selectedTutorialPage, setSelectedTutorialPage] = useState(0)
    const [tutorial, setTutorial] = useState(null)
    const [slotByDate, setSlotsByDate] = useState([])
    const [slots, setSlots] = useState([])
    const [dateString, setDateString] = useState('')
    const [differenceString, setDifferenceString] = useState('')

    let calendarInstance = React.createRef();

    useEffect(() => {
        var d = new Date(date);
        var firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
        var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        let fday = firstDay.getFullYear().toString() + "-" + (firstDay.getMonth() + 1).toString() + "-" + firstDay.getDate().toString()
        let lday = lastDay.getFullYear().toString() + "-" + (lastDay.getMonth() + 1).toString() + "-" + lastDay.getDate().toString()
        let options = {
            "date": date
        }
        let options2 = {
            "from_date": fday,
            "to_date": lday
        }
        rtgS.getSlotsByDate(options).then((data) => {
            setSlotsByDateFunction(data).then(() => {
                rtgS.getSlots(options2).then((data) => {
                    setSlotsFunction(data).then(() => {
                        setInitialStrings()
                        setLoading(false)
                    })
                })
            })
        }).catch((err) => {
            setLoading(false)
        })
    }, [])

    const setSlotsByDateFunction = async (data) => {
        setSlotsByDate(data)
    }

    const setSlotsFunction = async (data) => {
        setSlots(data)
    }

    const setInitialStrings = () => {
        setDateString("Today")
        setDifferenceString("Today")
    }

    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    const reload = () => {
        setLoadingSlots(true)
        let d = new Date(calendarDate)
        var firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
        var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        let fday = firstDay.getFullYear().toString() + "-" + (firstDay.getMonth() + 1).toString() + "-" + firstDay.getDate().toString()
        let lday = lastDay.getFullYear().toString() + "-" + (lastDay.getMonth() + 1).toString() + "-" + lastDay.getDate().toString()
        let options = {
            "date": calendarDate
        }
        let options2 = {
            "from_date": fday,
            "to_date": lday
        }
        rtgS.getSlotsByDate(options).then((data) => {
            setSlotsByDateFunction(data).then(() => {
                rtgS.getSlots(options2).then((data) => {
                    setSlotsFunction(data).then(() => {
                        setInitialStrings()
                        setLoadingSlots(false)
                    })
                })
            })
        })
    }

    const getDaySlots = (date) => {
        setLoadingSlots(true)
        let options = {
            "date": date
        }
        rtgS.getSlotsByDate(options).then((data) => {
            setSlotsByDate(data)
        })

    }

    const datediff = (first, second) => {
        return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
    }

    const dateChange = (selectedDate) => {
        setLoading(true)
        let newDate = selectedDate.value.getFullYear().toString() + "-" + (selectedDate.value.getMonth() + 1).toString() + "-" + selectedDate.value.getDate().toString();
        setDate(new Date(selectedDate.value).toISOString().split('T')[0])
        getDaySlots(newDate)

        let today = new Date();

        let differenceDays = datediff(today, selectedDate.value);
        let newDateString = "";
        let newDifferenceString = "";
        let lang = navigator.language
        if (localStorage.getItem("selectedLanguage"))
            lang = localStorage.getItem("selectedLanguage")

        if (differenceDays == null) {
            newDateString = selectedDate.value.toLocaleDateString(lang.split('-')[0], { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
        } else if (differenceDays === 0) {
            newDateString = "TODAY";
            newDifferenceString = "TODAY";
        } else if (differenceDays === 1) {
            newDateString = "TOMORROW";
            newDifferenceString = "TOMORROW";
        } else if (differenceDays === -1) {
            newDateString = "YESTERDAY";
            newDifferenceString = "YESTERDAY";
        } else if (differenceDays > 0) {
            newDateString = selectedDate.value.toLocaleDateString(lang.split('-')[0], { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = differenceDays + " DAYS FROM TODAY";
        } else {
            newDateString = selectedDate.value.toLocaleDateString(lang.split('-')[0], { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = Math.abs(differenceDays) + " DAYS AGO";
        }

        setCalendarDateFunction(newDate, newDateString, newDifferenceString)

        setDateString(newDateString)
        setDifferenceString(newDifferenceString)
        setDate(selectedDate.value)
        //setOnSwipe(1)
        setLoading(false)
        setLoadingSlots(false)


        //setTimeout(() => {
        //  setLoading(false)
        //}, 1000)

    };

    const setCalendarDateFunction = (val, dateStr, newDifferenceString) => {
        let date = new Date()
        date.setFullYear(val.split('-')[0])
        date.setMonth(parseInt(val.split('-')[1], '') - 1)
        date.setDate(val.split('-')[2])

        let newDate = date.toISOString().split('T')[0]
        let finalDate = newDate.split('-')[0] + '-' + newDate.split('-')[1] + '-' + (newDate.split('-')[2])

        setCalendarDate(finalDate)
        setDateString(dateStr)
        setDifferenceString(newDifferenceString)
    }

    const customDates = (cell) => {
        let month = parseInt(cell.date.getMonth() + 1)
        let day = cell.date.getDate();
        if (month < 10) {
            month = '0' + month
        }
        if (day < 10) {
            day = '0' + day
        }
        let newDate = cell.date.getFullYear() + '-' + month + '-' + day
        let t = new Date(date)
        let today = new Date()
        let selectedDate = t.getFullYear().toString() + "-"
            + ((t.getMonth() + 1).toString().length == 1 ? '0' + (t.getMonth() + 1).toString() : (t.getMonth() + 1).toString()) + "-"
            + ((t.getDate()).toString().length == 1 ? '0' + (t.getDate()).toString() : (t.getDate()).toString())
        let todayDate = today.getFullYear().toString() + "-"
            + ((today.getMonth() + 1).toString().length == 1 ? '0' + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString()) + "-"
            + ((today.getDate()).toString().length == 1 ? '0' + (today.getDate()).toString() : (today.getDate()).toString())

        if (newDate === selectedDate) {
            cell.element.children[0].classList.add("e-selected")
        }

        if (newDate === todayDate) {
            cell.element.children[0].classList.add("e-today-date")
        }

        cell.element.children[0].classList.add("e-ready-to-grow")

        let slotDate = ''
        let is_bookable = false
        let is_booked_by_me = false
        let is_passed = false
        let is_full = false
        slots.map((slot) => {
            slotDate = getData(slot.date)
            if (slotDate === newDate) {
                is_full = true
                if (!slot.is_deletable) {
                    if (slot.booked_by_me)
                        is_booked_by_me = true
                    else
                        is_passed = true
                } else if (slot.booked_by_me) {
                    is_booked_by_me = true
                } else if (!slot.is_booked) {
                    is_bookable = true
                    is_full = false
                }
            }
        })
        if (is_booked_by_me) {
            cell.element.children[0].classList.add("a-booked-by-me")
        } else if (is_bookable) {
            cell.element.children[0].classList.add("a-bookable")
        } else if (is_passed || is_full) {
            cell.element.children[0].classList.add("a-all-booked")
        }
    }

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        return toStringDate
    }

    const formatData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return toStringDate
    }

    const onNavigate = (args) => {
        setLoading(true)
        let d = new Date(args.date)
        var firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
        var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        let fday = firstDay.getFullYear().toString() + "-" + (firstDay.getMonth() + 1).toString() + "-" + firstDay.getDate().toString()
        let lday = lastDay.getFullYear().toString() + "-" + (lastDay.getMonth() + 1).toString() + "-" + lastDay.getDate().toString()

        let options2 = {
            "from_date": fday,
            "to_date": lday
        }

        rtgS.getSlots(options2).then((data) => {
            let tmp = { value: firstDay }
            setSlotsFunction(data).then(() => {
                dateChange(tmp)
            })
        })
    }

    const bookSlot = (slot) => {
        setLoadingSlots(true)
        rtgS.bookSlot(slot.id).then(() => {
            reload()
        })
    }

    const deleteSlot = (slot) => {
        setLoadingSlots(true)
        rtgS.deleteSlot(slot.id).then(() => {
            reload()
        })
    }

    const isPassed = (slot) => {
        let now = new Date()
        let slotDate = new Date(slot.date)

        if (now.getTime() > slotDate.getTime())
            return true
        return false
    }

    const openTutorialPopup = () => {
        setTutorialPopup(!tutorialPopup)
        setSelectedTutorialPage(0)
    }

    const changeTutorialPage = (type) => {
        if (type === 'prev')
            setSelectedTutorialPage(selectedTutorialPage - 1)
        else {
            setSelectedTutorialPage(selectedTutorialPage + 1)
        }
    }

    let lang = navigator.language
    if (localStorage.getItem("selectedLanguage"))
        lang = localStorage.getItem("selectedLanguage")

    let date_title = new Date(date)
    let day = date_title.toLocaleDateString(lang.split('-')[0], { weekday: 'long' })
    let dtitle = day + ', ' + ("0" + date_title.getDate()).slice(-2).toString() + ' ' + date_title.toLocaleDateString(lang, { month: 'long' }).replace(',', '') //+ ' ' + date_title.getFullYear().toString()
    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            /*
            'max-height': '800px',
            'min-height': '200px',
            */
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },

    };

    const goBack = () => {
        props.select('offices', props.selected_office)
        props.setHidden(false)
    }

    return (

        <div className="canteen_global_container">
            {isLoading ?
                <LoadingComponentWeb />
                :
                <React.Fragment>
                    {window.innerWidth > 1000 ? '' : <BackButton backButtonFunction={() => goBack()} />}
                    <div Style={'position:relative'} >  {tutorial &&
                        tutorial.length > 0 && window.innerWidth > 1000 ?
                        <div className='tutorial_icon_readytogrow' onClick={openTutorialPopup}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </div> : ''}</div>
                    <div className="ready_to_grow_container">
                        <div Style={"text-align: -webkit-center;"}>
                            <div className="Calendar ready-to-grow-calendar">
                                <CalendarComponent value={date} navigated={onNavigate} change={dateChange} renderDayCell={customDates} firstDayOfWeek={1} />

                            </div>
                        </div>
                        <div className="slot_global_container">
                            {loadingSlots ?
                                <div className='loader_ellipsis_slots'>
                                    <div class="lds-ellipsis">
                                        <div Style={'background-color: #000;'}></div>
                                        <div Style={'background-color: #000;'}></div>
                                        <div Style={'background-color: #000;'}></div>
                                        <div Style={'background-color: #000;'}></div>
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div Style={"display: flex;justify-content: center;"}>
                                        <div id="title-ready-to-grow">
                                            <div className="bold" Style={"text-transform: uppercase;"}>{dtitle}</div>
                                            <div id="subtitle-ready-to-grow">{differenceString}</div>
                                        </div>
                                    </div>
                                    <div className="slot_list_global_container">
                                        <div className="slot_list_container">
                                            {slotByDate.map((slot) =>
                                                <div className="slot_card">
                                                    <div className="flex item-center">
                                                        <div className="slot_icon" Style={"background-image: url('" + slot.hr.image_url + "')"}>
                                                            {!slot.hr.image_url ? <div className="slot_hr_name">{slot.hr.firstname[0]}{slot.hr.lastname[0]}</div> : ''}
                                                        </div>
                                                        <div Style={"margin-left: 3%; text-align: -webkit-left;"}>
                                                            <div className="slot_title">Ready to grow</div>
                                                            <div className="slot_subtitle">{slot.hr.fullname}</div>
                                                            <div className="slot_subtitle">
                                                                <div>{formatData(slot.date)},</div>
                                                                <div className="slot_hours">{(slot.from).slice(0, 5)} - {(slot.to).slice(0, 5)}</div></div>
                                                        </div>
                                                        <div className="slot_button_container">
                                                            {slot.is_booked ?
                                                                <React.Fragment>
                                                                    <div className="slot_button_align" >
                                                                        <div className={slot.booked_by_me ? "booked_byme_slot_button" : "booked_slot_button"}>{getTranslationByKey('WELLNESS.UTILS.BOOKED')}</div>
                                                                        {slot.is_deletable && slot.booked_by_me ?
                                                                            <div className="delete_slot_button" onClick={() => deleteSlot(slot)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-trash3 pointer" viewBox="0 0 16 16">
                                                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                                                </svg>
                                                                            </div>
                                                                            :
                                                                            <div className="delete_slot_button"></div>
                                                                        }
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                isPassed(slot) ?
                                                                    ''
                                                                    :
                                                                    <div className="slot_button_align" >
                                                                        <div className="book_slot_button pointer" onClick={() => bookSlot(slot)}>{getTranslationByKey('WELLNESS.UTILS.BOOK')}</div>
                                                                        <div className="delete_slot_button"></div>
                                                                    </div>

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <Dialog
                        open={tutorialPopup}
                        onAfterOpen={null}
                        onClose={() => openTutorialPopup(false)}
                        style={customModalStyles}
                        className="tutorial_dialog"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>
                            <div className="close" >
                                <svg onClick={() => openTutorialPopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            {tutorial ? <div className="modal_body pb-25">
                                <div className="desk_tutorial_container">
                                    <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                        {selectedTutorialPage > 0 ?
                                            <div>
                                                <svg onClick={() => changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                        <div className="desk_tutorial_page" Style={"background-image: url('" + tutorial[selectedTutorialPage] + "')"}></div>
                                        {selectedTutorialPage < tutorial.length - 1 ?
                                            <div>
                                                <svg onClick={() => changeTutorialPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                            : <div Style={"width: 30px;"}></div>}
                                    </div>
                                </div>


                            </div>
                                : ''}
                        </DialogContent>
                    </Dialog>
                </React.Fragment>
            }

        </div>
    )
}