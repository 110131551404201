import React, { useEffect, useState } from "react"
import { getTranslationByKey } from "../global"
import { WellnessService } from "../Services/Services"
import { BackButton } from "./BackButton"
import moment from "moment";
import BookButton, { BookedButton, FollowButton, RemoveBooking, RemoveWaitingBooking, WaitButton } from "./Buttons";
import { Pathlist } from "./Pathlist";
import { InternalBackButton } from "./InternalBackButton";
import { LoadingComponentWeb } from "./LoadingComponent";
export default function WellnessLabCoursePage(props) {
    const [course, setCourse] = useState({})
    const [gym, setGym] = useState(null)
    let ws = new WellnessService()

    useEffect(() => {
        if (props.course) {
            setCourse({ ...props.course })
            if (props.course.gym)
                setGym(props.course.gym)
        }
    }, [props.course])

    useEffect(() => {
        if (props.courses)
            props.courses.map((c) => {
                if (c.id == props.course.id)
                    setCourse({ ...c })
            })
    }, [props.courses])

    const reload = () => {
        ws.getCourseById(course.id).then((data) => {
            setCourse({ ...data })
            props.setLoading(false)
        })
    }
    const dateCompare = (course) => {

        let from = course.time.from
        let date = course.date.split('T')
        let new_date = date[0] + 'T' + from

        let today = new Date()
        let dateToCompare = new Date(new_date)

        return dateToCompare < today
    }

    //check function
    const isCoursePassed = () => {
        let ret = false
        let now = new Date()
        let courseDate = new Date(course.date)
        let ora_inizio = course.time.from.split(':')[0]
        //same date
        if (now.getDate() == courseDate.getDate() && now.getMonth() == courseDate.getMonth() && now.getFullYear() == courseDate.getFullYear()) {
            if (now.getHours() >= parseInt(ora_inizio))
                ret = true
        } else if (now.setHours(0, 0, 0, 0) > courseDate.setHours(0, 0, 0, 0))
            ret = true
        return ret
    }

    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            height: '400px',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },

    };
    let imageLinkTrainer = ''
    let imageLinkCourse = ''
    if (!Object.is(course, null) && course.trainer && course.course) {
        imageLinkTrainer = "background-image:url('" + course.trainer.image_url + "')"
        imageLinkCourse = "background-image:url('" + course.course.image_url + "')"
    }

    let address = ""
    if (gym)
        address = "https://www.google.it/maps/place/" + gym.location.street_address


    let string_available = getTranslationByKey('WELLNESS.AVAILABLEPLACES')


    return (<React.Fragment>
        <div className="reserv_menu_container">
            {props.loading ?
                <LoadingComponentWeb />
                :
                <React.Fragment>
                    {window.innerWidth > 1000 ?
                        <InternalBackButton name={'back_button_component_dx_wellness pointer flex'} key='selected_book' backButtonFunction={props.goBack} index={99} /> : ''}
                    {!Object.is(course, null) && course.course ?
                        <React.Fragment>
                            {window.innerWidth < 1000 ? <BackButton backButtonFunction={props.goBack} index={300} /> : ''}
                            {window.innerWidth > 1000 ? '' : <Pathlist name={getTranslationByKey(course.course.name)} />}
                            <div className="reserv_menu">
                                <div className="course_container">
                                    <div className="course">
                                        <div className="top_course">
                                            <div className="flex header_container">
                                                <div className="course_header" Style={imageLinkCourse}>
                                                </div>
                                                <div className="info_header">
                                                    <div className="info">
                                                        <div className="row_info_title flex">
                                                            <div className="course_info">
                                                                <div className="course_page_info_title flex" >
                                                                    <div Style={"font-family: 'GucciSans-Bold'; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;"}>{getTranslationByKey(course.course.name)}</div>
                                                                    {course.booked ? <div className="flag_booked">{getTranslationByKey('WELLNESS.UTILS.BOOKED')}</div> : ''}
                                                                </div>
                                                                <div className="course_page_info_subtitle">
                                                                    {course.trainer.first_name} {course.trainer.last_name}
                                                                </div>
                                                                {dateCompare(course) ?
                                                                    ''
                                                                    :
                                                                    <div className="course_page_info_subtitle" Style={"font-family: 'GucciSans-Book'"}>
                                                                        {course.available_seats + " " + string_available}
                                                                    </div>
                                                                }
                                                            </div>

                                                        </div>

                                                        <div className="course_page_info_title">
                                                            {moment(course.date + '.000Z').utc().format('ddd').toUpperCase() + moment(course.date + '.000Z').utc().format(', D MMMM YYYY')}
                                                        </div>
                                                        <div className="course_page_info_subtitle">
                                                            {(course.time.from).slice(0, 5)} - {(course.time.to).slice(0, 5)}
                                                        </div>
                                                        <div className="course_page_info_subtitle flex" Style={"margin-top: 20px;"}>
                                                            <a target="_blank" rel="noreferrer" href={address}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" class="bi bi-geo-alt-fill location_icon" viewBox="0 0 16 16">
                                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                                </svg>
                                                            </a>
                                                            <div className="mt-1" Style={"overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;"} >{course.gym ? course.gym.name : "No gym defined"}</div>

                                                        </div>
                                                    </div>

                                                    <div className="course_img_container">
                                                        {course.trainer.image_url ? <div className="course_img" Style={imageLinkTrainer}></div> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course_description_container">
                                                <div className="course_description_row flex">
                                                    <div className="course_description_title">{getTranslationByKey('WELLNESS.COURSES.DESCRIPTION')}</div>
                                                    <div className="container_list_button flex">
                                                        {dateCompare(course) || !course.is_bookable ?
                                                            (isCoursePassed() ? ''
                                                                :
                                                                <FollowButton setLoading={props.setLoading} course={course} user={props.user} reload={reload} is_followed={course.is_followed} />) :
                                                            course.booked ?
                                                                (course.deletable ?
                                                                    <RemoveBooking setLoading={props.setLoading} className="remove_booking mt-1" course={course} user={props.user} reload={reload} name={getTranslationByKey('WELLNESS.UTILS.DELETE')} />
                                                                    :
                                                                    <BookedButton setLoading={props.setLoading} className="remove_booking cursor_auto" name={getTranslationByKey('WELLNESS.UTILS.BOOKED')} />)
                                                                :
                                                                (course.available_seats == 0 ?
                                                                    course.is_waiting ?
                                                                        <RemoveWaitingBooking setLoading={props.setLoading} className="remove_booking mt-1" course={course} user={props.user} reload={reload} message={getTranslationByKey('WELLNESS.UTILS.REMOVEDWAITING')} name={getTranslationByKey('WELLNESS.UTILS.EXITWAITINGLIST')} />
                                                                        :
                                                                        <WaitButton setLoading={props.setLoading} className="book_button" course={course} user={props.user} reload={reload} check_course={course} checkIfModal={() => props.openModal(course)} message={getTranslationByKey('WELLNESS.UTILS.INSERTEDWAITING')} name={getTranslationByKey('WELLNESS.UTILS.WAITING')} />
                                                                    :
                                                                    <BookButton setLoading={props.setLoading} className="book_button" course={course} user={props.user} reload={reload} checkIfModal={() => props.openModal(course)} check_course={course} message={getTranslationByKey('WELLNESS.UTILS.INSERTEDBOOKING')} name={getTranslationByKey('WELLNESS.UTILS.BOOK')} />)}
                                                    </div>
                                                </div>
                                                <div className="course_description_content" >{getTranslationByKey(course.course.description)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </React.Fragment>
                        : ''}
                </React.Fragment>
            }
        </div>
    </React.Fragment>

    )
}