import React, { useEffect, useState } from "react";
import { MeetingRoomsService } from "../Services/Services";
import MeetingRoomsDetail from "./MeetingRoomsDetail";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { Dialog, DialogContent, DialogTitle, } from "@mui/material";
import DatePicker from "react-datepicker";
import { BackButton } from "./BackButton";
import { LoadingComponentWeb } from "./LoadingComponent";

export function MeetingRooms(props) {

    const [loading, setLoading] = useState(true)
    const [meeting_rooms_list, setMeetingRooms] = useState([])
    const [selectedMeetingRoom, setSelectedMeetingRoom] = useState([])
    const [filters, setFilters] = useState([])
    const [detail, setDetail] = useState(false)
    const [tutorialPopup, setTutorialPopup] = useState(false)
    const [timeFrom, setTimeFrom] = useState('')
    const [timeTo, setTimeTo] = useState('')
    const [searching, setSearching] = useState('')
    const [allDay, setAllDay] = useState(false)
    const [filter_fav, setFilterFav] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [openMobileFilter, setOpenMobileFilter] = useState(false)
    const [selectedFilterBuilding, setSelectedFilterBuilding] = useState([])
    const [selectedFilterLevel, setSelectedFilterLevel] = useState([])
    const [selectedFilterCapacity, setSelectedFilterCapacity] = useState([])
    const [selectedFilterEquipment, setSelectedFilterEquipment] = useState('')
    const [has_monitor, setHasMonitor] = useState(false)
    const [has_projector, setHasProjector] = useState(false)
    const [has_camera, setHasCamera] = useState(false)
    const [has_board, setHasBoard] = useState(false)
    const [has_phone, setHasPhone] = useState(false)
    const [date, setDate] = useState('')
    const [selectedTutorialPage, setSelectedTutorialPage] = useState('')
    const [openFilterMobile, setOpenFilterMobile] = useState(false)
    let mrs = new MeetingRoomsService()

    useEffect(() => {
        mrs.getByLocationID(props.office.id).then((data) => {
            setMeetingRooms(data)
            mrs.getFilter(props.office.id).then((data) => {
                setFilters(data)
                setLoading(false)
            })
        })
    }, [])

    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    const openTutorialPopup = () => {
        setTutorialPopup(!tutorialPopup)
        setSelectedTutorialPage(0)
    }

    const changeTutorialPage = (type) => {
        if (type === 'prev')
            setSelectedTutorialPage(selectedTutorialPage - 1)
        else {
            setSelectedTutorialPage(selectedTutorialPage + 1)
        }
    }

    const openDetail = (event, meeting_room) => {
        event.preventDefault()
        setDetail(true)
        setSelectedMeetingRoom(meeting_room)
    }

    const setDetailFunction = (val) => {
        if (val) {
            setDetail(val)
        } else {
            setLoading(true)
            mrs.getByLocationID(props.office.id).then((data) => {
                setMeetingRooms(data)
                setDetail(val)
                setLoading(false)
            })
        }
    }

    const onChangeTimeFrom = (event) => {
        setTimeFrom(event)
    }

    const onChangeTimeTo = (event) => {
        setTimeTo(event)
    }

    const handleSearch = (e) => {
        setSearching(e.target.value)
    }

    const handleCheckbox = () => {
        setAllDay(!allDay)
    }

    const handleCheckboxMobileFav = () => {
        setFilterFav(!filter_fav)
    }

    const handleCheckboxFavFilter = () => {
        setFilterFav(!filter_fav)
    }

    const setFav = (event, meeting_room) => {
        setLoading(true)
        event.stopPropagation()
        mrs.setFav(meeting_room.id).then(() => {

            let options = {
                "text": searching,
                "date": getData(date),
                "all_day": allDay,
                "from_timestamp": timeFrom,
                "to_timestamp": timeTo,
                "building": selectedFilterBuilding,
                "level": selectedFilterLevel,
                "capacity": selectedFilterCapacity,
                "has_monitor": has_monitor,
                "has_projector": has_projector,
                "has_camera": has_camera,
                "has_board": has_board,
                "has_phone": has_phone,
                "favourites": filter_fav
            }
            mrs.applyFilter(props.info.id, options).then((data) => {
                setMeetingRooms(data)
                setOpenFilterMobile(!openFilterMobile)
                setLoading(false)
            })
        })
    }

    const selectFilterBuildings = (building) => {
        let buildingsList = selectedFilterBuilding
        if (!selectedFilterBuilding.includes(building))
            buildingsList.push(building)
        else
            buildingsList.splice(buildingsList.indexOf(building), 1);
        setSelectedFilterBuilding([...buildingsList])
    }

    const selectFilterLevel = (level) => {
        let levelList = selectedFilterLevel
        if (!selectedFilterLevel.includes(level))
            levelList.push(level)
        else
            levelList.splice(levelList.indexOf(level), 1);
        setSelectedFilterLevel([...levelList])
    }

    const selectFilterCapacity = (capacity) => {
        let capacityList = selectedFilterCapacity
        if (!selectedFilterCapacity.includes(capacity))
            capacityList.push(capacity)
        else
            capacityList.splice(capacityList.indexOf(capacity), 1);
        setSelectedFilterCapacity([...capacityList])
    }

    const setSelectedEquipment = (equipment) => {
        switch (equipment) {
            case 'has_monitor': {
                setHasMonitor(!has_monitor)
                break;
            }
            case 'has_projector': {
                setHasProjector(!has_projector)
                break;
            }
            case 'has_camera': {
                setHasCamera(!has_camera)
                break;
            }
            case 'has_board': {
                setHasBoard(!has_board)
                break;
            }
            case 'has_phone': {
                setHasPhone(!has_phone)
                break;
            }
            default:
                break;
        }
    }

    const resetFilter = () => {

        setLoading(false)
        setSearching('')
        setDate('')
        setTimeFrom('')
        setTimeTo('')
        setAllDay(false)
        setSelectedFilterBuilding([])
        setSelectedFilterLevel([])
        setSelectedFilterCapacity([])
        setSelectedFilterEquipment('')
        setHasMonitor(false)
        setHasProjector(false)
        setHasCamera(false)
        setHasBoard(false)
        setHasPhone(false)
        setFilterFav(false)

        let options = {
            "text": '',
            "date": '',
            "all_day": false,
            "from_timestamp": null,
            "to_timestamp": null,
            "building": [],
            "level": [],
            "capacity": [],
            "has_monitor": false,
            "has_projector": false,
            "has_camera": false,
            "has_board": false,
            "has_phone": false,
            "favourites": false
        }

        mrs.applyFilter(props.office.id, options).then((data) => {
            setMeetingRooms(data)
            setLoading(false)
        })
    }

    const applyFilter = (type) => {
        setLoading(true)

        let options = {}
        if (type === 'search')
            options = {
                "text": searching,
                "date": getData(date),
                "all_day": allDay,
                "from_timestamp": timeFrom,
                "to_timestamp": timeTo,
                "building": [],
                "level": [],
                "capacity": [],
                "has_monitor": false,
                "has_projector": false,
                "has_camera": false,
                "has_board": false,
                "has_phone": false,
                "favourites": false
            }
        else
            options = {
                "text": searching,
                "date": getData(date),
                "all_day": allDay,
                "from_timestamp": timeFrom,
                "to_timestamp": timeTo,
                "building": selectedFilterBuilding,
                "level": selectedFilterLevel,
                "capacity": selectedFilterCapacity,
                "has_monitor": has_monitor,
                "has_projector": has_projector,
                "has_camera": has_camera,
                "has_board": has_board,
                "has_phone": has_phone,
                "favourites": filter_fav
            }
        mrs.applyFilter(props.office.id, options).then((data) => {
            setMeetingRooms(data)
            setOpenFilterMobile(!openFilterMobile)
            setLoading(false)
        })
    }

    const checkInList = (type, string) => {
        let ret = false
        switch (type) {
            case 'building': {
                if (selectedFilterBuilding.includes(string))
                    ret = true
                break;
            }
            case 'capacity': {
                if (selectedFilterCapacity.includes(string))
                    ret = true
                break;
            }
            case 'level': {
                if (selectedFilterLevel.includes(string))
                    ret = true
                break;
            }
            default:
                break;
        }
        return ret
    }

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        return toStringDate
    }

    let customModalStyles = {

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            height: '85vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef"
        },

    };

    const goBack = () => {
        props.select('offices', props.selected_office)
        props.setHidden(false)
    }

    return (
        <div>
            {loading ?
                <LoadingComponentWeb />
                :
                detail ?
                    <React.Fragment>
                        <BackButton backButtonFunction={setDetail} index={210} />
                        <MeetingRoomsDetail meeting_room_data={selectedMeetingRoom} setDetail={setDetail} selectedDate={date} />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {window.innerWidth > 1000 ? '' : <BackButton backButtonFunction={() => goBack()} />}
                        {window.innerWidth < 1000 ?
                            <div Style="position: relative; top: 100px; right: 10px; text-align: -webkit-right;">
                                <div onClick={() => setOpenFilterMobile(!openFilterMobile)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="black" class="bi bi-filter" viewBox="0 0 16 16">
                                        <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </div>
                            </div> : ''}
                        <div className="meeting_room_container meeting_room_container_web">
                            {window.innerWidth > 1000 ?
                                <div className="mr_filter_header_container item-center">
                                    <div className="flex" id="mr_filter_header_left">
                                        <div>
                                            <div Style={"height: 24px;"}></div>
                                            <input className="meeting_rooms_input" id='meeting_rooms_input' placeholder="Search" onChange={(e) => handleSearch(e)} value={searching}>
                                            </input>
                                        </div>
                                        <div className="mr_datepicker_container">
                                            <div className="filter_name">{getTranslationByKey('MR.FILTER.DATE')}</div>
                                            <DatePicker
                                                showIcon
                                                className="meeting_rooms_datepicker"
                                                selected={date}
                                                minDate={new Date()}
                                                onChange={(date) => { setDate(date) }}
                                                placeholderText="gg/mm/yyyy"
                                                popperPlacement="bottom-center"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex" id="mr_filter_header_right">
                                        <div className="mr_timepicker meeting_room_switch" type='text' >
                                            <div className="filter_name">{getTranslationByKey('GENERIC.TOGGLE.ALLDAY')}</div>
                                            <div Style={"padding: 6px;margin-right: 5px;"}>
                                                <input checked={allDay} type="checkbox" className="checkbox" id="chk" onChange={() => handleCheckbox()} />
                                                <label className="meeting_room_switch_label" htmlFor="chk">
                                                    <div className="meeting_room_ball"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mr_timepicker">
                                            <div className="filter_name">{getTranslationByKey('MR.FILTER.PROPERTIES.FROM')}</div>
                                            <div>
                                                <TimePicker onChange={(e) => onChangeTimeFrom(e)} value={timeFrom} disableClock={true} />
                                            </div>
                                        </div>
                                        <div className="mr_timepicker">
                                            <div className="filter_name">{getTranslationByKey('MR.FILTER.PROPERTIES.TO')}</div>
                                            <div>
                                                <TimePicker onChange={(e) => onChangeTimeTo(e)} value={timeTo} disableClock={true} />
                                            </div>
                                        </div>
                                        <div className="flex" Style={"margin-left: auto;"}>
                                            {openFilter ? '' :
                                                <div className="mr_header_icon pointer" onClick={() => applyFilter('search')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-search" viewBox="0 0 16 16">
                                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                                    </svg>
                                                </div>
                                            }
                                            <div className="mr_header_icon pointer" onClick={() => setOpenFilter(!openFilter)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            {openFilter ?
                                <div className="filter_board_container">
                                    <div className="filter_board_options_container">
                                        <div className="filter_board_column" Style={"border-left: 0px;"}>
                                            <div className="flex">
                                                <div Style={"margin-right: 10px;"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-buildings-fill" viewBox="0 0 16 16">
                                                        <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z" />
                                                    </svg>
                                                </div>
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.BUILDING')}</div>
                                            </div>
                                            <div className="mr_filter_items_container">
                                                {filters.building.map((b) =>
                                                    <div className={checkInList("building", b) ? "mr_filter_item_selected pointer" : "mr_filter_item pointer"} onClick={() => selectFilterBuildings(b)}>{b}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="filter_board_column">
                                            <div className="flex">
                                                <div Style={"margin-right: 10px;"}>
                                                    <div className="level_icon"></div>
                                                </div>
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.LEVEL')}</div>
                                            </div>
                                            <div className="mr_filter_items_container">
                                                {filters.level.map((l) =>
                                                    <div className={checkInList("level", l) ? "mr_filter_item_selected pointer" : "mr_filter_item pointer"} onClick={() => selectFilterLevel(l)}>{l}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="filter_board_column">
                                            <div className="flex">
                                                <div Style={"margin-right: 10px;"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill ml-2 capacity_icon" viewBox="0 0 16 16">
                                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                    </svg>
                                                </div>
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.CAPACITY')}</div>
                                            </div>
                                            <div className="mr_filter_items_container">
                                                {filters.capacity.map((c) =>
                                                    <div className={checkInList("capacity", c) ? "mr_filter_item_selected pointer" : "mr_filter_item pointer"} onClick={() => selectFilterCapacity(c)}>{c}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="filter_board_column" >
                                            <div className="flex">
                                                <div Style={"margin-right: 10px;"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                                                        <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                                                    </svg>
                                                </div>
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.EQUIPMENT')}</div>
                                            </div>
                                            <div className="mr_filter_items_container">
                                                <div className={has_monitor ? "mr_filter_selected_icon pointer monitor_icon" : "mr_filter_icon pointer monitor_icon"} onClick={() => setSelectedEquipment("has_monitor")}></div>
                                                <div className={has_camera ? "mr_filter_selected_icon pointer cam_icon" : "mr_filter_icon pointer cam_icon"} onClick={() => setSelectedEquipment("has_camera")}></div>
                                                <div className={has_projector ? "mr_filter_selected_icon pointer projector_icon" : "mr_filter_icon pointer projector_icon"} onClick={() => setSelectedEquipment("has_projector")}></div>
                                                <div className={has_board ? "mr_filter_selected_icon pointer board_icon" : "mr_filter_icon pointer board_icon"} onClick={() => setSelectedEquipment("has_board")}></div>
                                                <div className={has_phone ? "mr_filter_selected_icon pointer telephone_icon" : "mr_filter_icon pointer telephone_icon"} onClick={() => setSelectedEquipment("has_phone")}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mr_filter_bottom">
                                        <div Style="width: 60%;">
                                            <div className="flex">
                                                <div Style={"font-family: 'GucciSans-Bold'; margin-top: 12px; margin-right: 10px;"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.FAVOURITES')}</div>
                                                <div Style={"padding: 6px;"}>
                                                    <input checked={filter_fav} type="checkbox" className="checkbox" id="chk_2" onChange={() => handleCheckboxFavFilter()} />
                                                    <label className="meeting_room_switch_label" htmlFor="chk_2" Style={"margin-top: 11px;"}>
                                                        <div className="meeting_room_ball"></div>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="mr_filter_buttons_container flex" Style={""}>
                                            <div id="reset_filter_button" className="pointer" onClick={() => resetFilter()}>{getTranslationByKey("MR.FILTER.ROOMDETAIL.RESET")}</div>
                                            <div id="apply_filter_button" className="pointer" onClick={() => applyFilter('applyFilter')}>{getTranslationByKey('WELLNESS.COURSES.FILTER.APPLY')}</div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            <div>
                                {meeting_rooms_list && meeting_rooms_list.length > 0 ? meeting_rooms_list.map((meeting_room) =>
                                (window.innerWidth > 1300 ?
                                    <div className="meeting_room_line meeting_room_line_web pointer flex vertical_center" onClick={(event) => openDetail(event, meeting_room)}>
                                        <div Style="width: 48%;">
                                            <div className="vertical_center flex">
                                                <div className="meeting_rooms_icon" Style={"background-image:url('" + meeting_room.image_url + "')"}></div>
                                                <div className="meeting_rooms_vertical_separator"></div>
                                                <div>
                                                    <div className="meeting_room_name" Style={"font-size: 16px;"}>{meeting_room.name}</div>
                                                    <div Style={"font-size: 14px;"}>{meeting_room.building} - {meeting_room.level}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div Style="width: 52%;">
                                            <div className="flex vertical_center" Style='justify-content: right; margin-right: 5%;'>
                                                <div className="flex vertical_center meeting_room_capacity_container">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-fill ml-2 capacity_icon" viewBox="0 0 16 16">
                                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                    </svg>
                                                    <div className="meeting_room_capacity">{meeting_room.max_guests}</div>
                                                </div>
                                                <div className="meeting_rooms_vertical_separator" Style="margin-left: 10px;"></div>
                                                <div className="flex">
                                                    <div className={meeting_room.properties.has_monitor ? 'room_icon_active monitor_icon' : 'room_icon monitor_icon'}></div>
                                                    <div className={meeting_room.properties.has_camera ? 'room_icon_active cam_icon' : 'room_icon cam_icon'}></div>
                                                    <div className={meeting_room.properties.has_projector ? 'room_icon_active projector_icon' : 'room_icon projector_icon'}></div>
                                                    <div className={meeting_room.properties.has_board ? 'room_icon_active board_icon' : 'room_icon board_icon'}></div>
                                                    <div className={meeting_room.properties.has_phone ? 'room_icon_active telephone_icon' : 'room_icon telephone_icon'}></div>
                                                </div>

                                                <div className="meeting_rooms_vertical_separator" Style="margin-left: 10px;"></div>
                                                <div onClick={(event) => setFav(event, meeting_room)}>
                                                    {meeting_room.starred ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                                                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                                        </svg>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className="meeting_room_line pointer flex vertical_center" onClick={(event) => openDetail(event, meeting_room)}>
                                            <div Style={"min-width: 15%;"}>
                                                <div className="vertical_center flex">
                                                    <div className="meeting_rooms_icon" Style={"background-image:url('" + meeting_room.image_url + "')"}></div>
                                                    <div className="meeting_rooms_vertical_separator"></div>
                                                </div>
                                            </div>
                                            <div className="meeting_room_name_container vertical_center flex">
                                                <div Style={"width: 90%;"}>
                                                    <div >
                                                        <div className="meeting_room_name" Style={"font-size: 16px;"}>{meeting_room.name}</div>
                                                        <div Style={"font-size: 14px;"}>{meeting_room.building}</div>
                                                        <div Style={"font-size: 14px;"}>{meeting_room.level}</div>
                                                    </div>
                                                    <div>
                                                        <div className="mr_icon_line_container flex" >
                                                            <div className={meeting_room.properties.has_monitor ? 'room_icon_active monitor_icon' : 'room_icon monitor_icon'}></div>
                                                            <div className={meeting_room.properties.has_camera ? 'room_icon_active cam_icon' : 'room_icon cam_icon'}></div>
                                                            <div className={meeting_room.properties.has_projector ? 'room_icon_active projector_icon' : 'room_icon projector_icon'}></div>
                                                            <div className={meeting_room.properties.has_board ? 'room_icon_active board_icon' : 'room_icon board_icon'}></div>
                                                            <div className={meeting_room.properties.has_phone ? 'room_icon_active telephone_icon' : 'room_icon telephone_icon'}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div Style={"width: 10%;"}>
                                                    <div className="flex vertical_center meeting_room_capacity_container">
                                                        <div className="meeting_room_capacity">{meeting_room.max_guests}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="meeting_rooms_vertical_separator" Style="margin-left: 10px;"></div>
                                            <div Style={" width: 15%; text-align: center;"} onClick={(event) => setFav(event, meeting_room)}>
                                                {meeting_room.starred ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                    </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                                                        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                                    </svg>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>)
                                ) :
                                    <div>{getTranslationByKey('MR.FILTER.ROOMDETAIL.NOROOM')}</div>}
                            </div>
                            {openFilterMobile ?
                                <div className="mr_mobile_filter_container">
                                    <div className="flex pointer">
                                        <div onClick={() => setOpenFilterMobile(!openFilterMobile)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle-fill pointer" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                            </svg>
                                        </div>
                                        <div Style={"margin-left: auto; font-family: 'GucciSans-Bold';"}>{getTranslationByKey('GENERIC.LIST.FILTER')}</div>
                                        <div Style={"margin-left: auto;"} onClick={() => applyFilter('applyFilter')}>
                                            <div Style={"font-family: 'GucciSans-Bold';"} className="pointer">
                                                {getTranslationByKey('MR.FILTER.SEARCH')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mr_mobile_filter_body">
                                        <div>
                                            <div>
                                                <div Style={"height: 10px;"}></div>
                                                <input className="meeting_rooms_input" id='meeting_rooms_input' placeholder="Search" onChange={(e) => handleSearch(e)} value={searching}>
                                                </input>
                                            </div>
                                            <div Style={"margin-left: auto; margin-top: 5px;"}>
                                                <div className="filter_name">{getTranslationByKey('MR.FILTER.DATE')}</div>
                                                <DatePicker
                                                    showIcon
                                                    className="meeting_rooms_datepicker"
                                                    selected={date}
                                                    minDate={new Date()}
                                                    onChange={(date) => { setDate(date) }}
                                                    placeholderText="gg/mm/yyyy"
                                                    popperPlacement="bottom-center"
                                                />
                                            </div>
                                            <div className="mr_timepicker meeting_room_switch" type='text' Style={"padding: 5px 0;"}>
                                                <div className="filter_name">{getTranslationByKey('GENERIC.TOGGLE.ALLDAY')}</div>
                                                <div Style={"padding: 6px; margin-right: 5px;"}>
                                                    <input checked={allDay} type="checkbox" className="checkbox" id="chk" onChange={() => handleCheckbox()} />
                                                    <label className="meeting_room_switch_label" htmlFor="chk">
                                                        <div className="meeting_room_ball"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="mr_timepicker">
                                                    <div className="filter_name">{getTranslationByKey('MR.FILTER.PROPERTIES.FROM')}</div>
                                                    <div>
                                                        <TimePicker onChange={(e) => onChangeTimeFrom(e)} value={timeFrom} disableClock={true} />
                                                    </div>
                                                </div>
                                                <div className="mr_timepicker">
                                                    <div className="filter_name">{getTranslationByKey('MR.FILTER.PROPERTIES.TO')}</div>
                                                    <div>
                                                        <TimePicker onChange={(e) => onChangeTimeTo(e)} value={timeTo} disableClock={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div Style={"font-family: 'GucciSans-Bold'; text-align: -webkit-center; padding: 10px 0;"}>{getTranslationByKey('MR.FILTER.ROOMDETAILTITLE')}</div>
                                        <div>
                                            <div>
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.BUILDING')}</div>
                                            </div>
                                            <div className="mr_filter_items_container">
                                                {filters.building.map((b) =>
                                                    <div className={checkInList("building", b) ? "mr_filter_item_selected pointer" : "mr_filter_item pointer"} onClick={() => selectFilterBuildings(b)}>{b}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex">
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.LEVEL')}</div>
                                            </div>
                                            <div className="mr_filter_items_container">
                                                {filters.level.map((l) =>
                                                    <div className={checkInList("level", l) ? "mr_filter_item_selected pointer" : "mr_filter_item pointer"} onClick={() => selectFilterLevel(l)}>{l}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex">
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.CAPACITY')}</div>
                                            </div>
                                            <div className="mr_filter_items_container">
                                                {filters.capacity.map((c) =>
                                                    <div className={checkInList("capacity", c) ? "mr_filter_item_selected pointer" : "mr_filter_item pointer"} onClick={() => selectFilterCapacity(c)}>{c}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mr_timepicker meeting_room_switch" type='text' Style={"padding: 5px 0;"}>
                                            <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.FAVOURITES')}</div>
                                            <div Style={"padding: 6px;margin-right: 5px;"}>
                                                <input checked={filter_fav} type="checkbox" className="checkbox" id="chk_fav" onChange={() => handleCheckboxMobileFav()} />
                                                <label className="meeting_room_switch_label" htmlFor="chk_fav">
                                                    <div className="meeting_room_ball"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex">
                                                <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.EQUIPMENT')}</div>
                                            </div>
                                            <div className="mr_filter_items_container">
                                                <div className={has_monitor ? "mr_filter_selected_icon pointer monitor_icon" : "mr_filter_icon pointer monitor_icon"} onClick={() => setSelectedEquipment("has_monitor")}></div>
                                                <div className={has_camera ? "mr_filter_selected_icon pointer cam_icon" : "mr_filter_icon pointer cam_icon"} onClick={() => setSelectedEquipment("has_camera")}></div>
                                                <div className={has_projector ? "mr_filter_selected_icon pointer projector_icon" : "mr_filter_icon pointer projector_icon"} onClick={() => setSelectedEquipment("has_projector")}></div>
                                                <div className={has_board ? "mr_filter_selected_icon pointer board_icon" : "mr_filter_icon pointer board_icon"} onClick={() => setSelectedEquipment("has_board")}></div>
                                                <div className={has_phone ? "mr_filter_selected_icon pointer telephone_icon" : "mr_filter_icon pointer telephone_icon"} onClick={() => setSelectedEquipment("has_phone")}></div>
                                            </div>
                                        </div>
                                        <div Style={"text-align: -webkit-center; padding: 5px 0;"} >
                                            <div className="reset_filter_button_mobile pointer" onClick={() => resetFilter()}>{getTranslationByKey('MR.FILTER.ROOMDETAIL.RESET')}</div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                        </div>
                    </React.Fragment >
            }
            <Dialog
                open={tutorialPopup}
                onAfterOpen={null}
                onClose={() => openTutorialPopup(false)}
                style={customModalStyles}
                className="tutorial_dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    <div className="close" >
                        <svg onClick={() => openTutorialPopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {props.tutorial ? <div className="modal_body pb-25">
                        <div className="desk_tutorial_container">
                            <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                {selectedTutorialPage > 0 ?
                                    <div>
                                        <svg onClick={() => changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                    </div>
                                    : <div Style={"width: 30px;"}></div>}
                                <div className="desk_tutorial_page" Style={"background-image: url('" + props.tutorial[selectedTutorialPage] + "')"}></div>
                                {selectedTutorialPage < props.tutorial.length - 1 ?
                                    <div>
                                        <svg onClick={() => changeTutorialPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                    : <div Style={"width: 30px;"}></div>}
                            </div>
                        </div>
                    </div> : ''}
                </DialogContent>
            </Dialog>
        </div>

    )

}