/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import BookingDetailMonth from './BookingDetailsMonth';
import OfficeDetail from './OfficeDetail';
import Profile from './Profile';
import closeButton from '../content/images/black_close.png';
import '../App.css';
import '../global.js';
import { Swipe } from "react-swipe-component";
import { Row, Col, Container } from 'react-bootstrap';
import "izitoast-react/dist/iziToast.css";
import { DeskService } from '../Services/Services';
import { Dialog, DialogContent, DialogTitle, } from "@mui/material";
const config = {
    delta: 10,                             // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false,   // preventDefault on touchmove, *See Details*
    trackTouch: true,                      // track touch input
    trackMouse: false,                     // track mouse input
    rotationAngle: 0,                      // set a rotation angle
}
//DeskBooking Calendar
class Calendar extends React.Component {
    constructor(props) {
        super(props);
        let officeDetail = false;
        if (props.officeDetail !== null || props.officeDetail !== undefined) {
            officeDetail = props.officeDetailShow
        }
        let mb = []
        let sb = []
        if (props.monthBookings && props.monthBookings !== undefined) {
            mb = props.monthBookings
        }
        if (props.selectedBookings && props.selectedBookings !== undefined) {
            sb = props.selectedBookings
        }
        this.calendarInstance = React.createRef();
        this.state = {
            user: {},
            date: props.date,
            dateString: props.dateString,
            differenceString: props.differenceString,
            selectedBookings: sb,
            bookings: [],
            monthBookings: mb,
            qrCodeShow: false,
            officeDetailShow: officeDetail,
            profileShow: false,
            navigationDate: new Date(),
            navigationDateStatus: "Month",
            infoBoxText: "Please, try again later",
            bookDetail: props.bookDetail,
            loading: false,
            swiped: false,
            onswipe: 0,
            tutorialPopup: false,
            selectedTutorialPage: 0
        }

        this.setLoading = this.setLoading.bind(this)
        this.setLoading2 = this.setLoading2.bind(this)
        this.setUser = this.setUser.bind(this)
        this.setBookings = this.setBookings.bind(this)
        this.setInitialStrings = this.setInitialStrings.bind(this)
        this.getBookings = this.getBookings.bind(this)
        this.closeOfficeDetail = this.closeOfficeDetail.bind(this)
        this.closeTemporaryOfficeDetail = this.closeTemporaryOfficeDetail.bind(this)
        this.update = this.update.bind(this)
        this.openTutorialPopup = this.openTutorialPopup.bind(this)
        this.changeTutorialPage = this.changeTutorialPage.bind(this)
    };


    setUser(data) {
        this.setState({ user: data })
    }

    getUserDesk() { }

    setInitialStrings() {
        this.setState({
            dateString: "Today",
            differenceString: "",
        });

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.monthBookings.length !== this.props.monthBookings)
            return true
        if (nextState.monthBookings.length !== this.state.monthBookings)
            return true
    }
    //to test
    componentDidUpdate(props, state) {
        return true
    }

    componentDidMount() {
        this.setLoading(true)
        let ds = new DeskService();
        ds.getUserBookingsV3().then((data) => {
            this.setBookings(data)
            this.setInitialStrings()
            this.selectedDateBookings(new Date());
            this.selectedMonthBookings(new Date())
            this.setLoading(false)
        })
    }

    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        }
    }

    getBookings() {
        this.setLoading(true).then(() => {
            let ds = new DeskService();
            ds.getUserBookingsV3().then((data) => {
                this.setBookings(data)
                this.setInitialStrings()
                this.selectedDateBookings(new Date());
                this.selectedMonthBookings(new Date())
                this.setLoading(false)
            })
        })
        this.dateChange(new Date())
    }

    async setLoading(val) {
        this.setState({
            loading: val
        })
        this.props.setLoading(val)
    }

    setLoading2(val) {

        this.setState({
            loading2: val
        })

    }


    datediff = (first, second) => {
        return Math.ceil((second - first) / (1000 * 60 * 60 * 24));
    }

    dateChange = (selectedDate) => {
        this.props.setLoading2(true)

        // this.props.reload()
        this.closeTemporaryOfficeDetail();
        //let newDate = selectedDate.value.toISOString().split('T')[0];
        let newDate = selectedDate.value.getFullYear().toString() + "-" + (selectedDate.value.getMonth() + 1).toString() + "-" + selectedDate.value.getDate().toString();

        let compareDate = selectedDate.value.getFullYear().toString() + "-"
            + ((selectedDate.value.getMonth() + 1).toString().length == 1 ? '0' + (selectedDate.value.getMonth() + 1).toString() : (selectedDate.value.getMonth() + 1).toString()) + "-"
            + ((selectedDate.value.getDate()).toString().length == 1 ? '0' + (selectedDate.value.getDate()).toString() : (selectedDate.value.getDate()).toString())


        this.setState({
            date: newDate
        });
        let today = new Date();

        let differenceDays = this.datediff(today, selectedDate.value);
        let newDateString = "";
        let newDifferenceString = "";

        if (differenceDays == null) {
            newDateString = selectedDate.value.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
        } else if (differenceDays === 0) {
            newDateString = "TODAY";
        } else if (differenceDays === 1) {
            newDateString = "TOMORROW";
        } else if (differenceDays === -1) {
            newDateString = "YESTERDAY";
        } else if (differenceDays > 0) {
            newDateString = selectedDate.value.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = differenceDays + " DAYS FROM TODAY";
        } else {
            newDateString = selectedDate.value.toLocaleDateString("en-US", { weekday: 'long', month: 'long', day: 'numeric' }).replace(',', '');
            newDifferenceString = Math.abs(differenceDays) + " DAYS AGO";
        }
        this.props.setCalendarDate(newDate, newDateString, newDifferenceString)

        this.setState({
            dateString: newDateString,
            differenceString: newDifferenceString, onswipe: 1
        });

        let exist = false
        this.state.bookings.map((booking) => {
            if (booking.date === compareDate) {
                exist = true
            }
        })
        this.selectedDateBookings(selectedDate.value);

        setTimeout(() => {
            if (exist) {
                this.openOfficeDetail()

            } else {
                this.props.setOfficeDetail(false)

            }
            this.props.setLoading2(false)
        }, 100)

        setTimeout(() => {

            this.props.setBookDetails(true)
        }, 100);

    };

    selectedDateBookings = (date) => {


        let val = this.state.onswipe
        if (this.state.swiped)
            val = 0

        let sb = []
        if (this.state.bookings !== undefined) {
            sb = this.state.bookings.filter(function (item) {
                return Date.parse(item.date) === Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), (date.getUTCDate() + val));
            })
        }
        this.setState({
            selectedBookings: sb
        });
        this.props.updateSelectedBookings(sb)

    }

    selectedMonthBookings = (date) => {
        let year = new Date(date).getFullYear()
        let month = new Date(date).getMonth()
        let monthB = this.state.bookings.filter(booking => {
            return (new Date(booking.date).getMonth() === month) && (new Date(booking.date).getFullYear() === year);
        })
        this.setState({
            monthBookings: monthB,
            loading: false
        });
        this.props.updateMonthBooking(monthB)

    }
    update() {
        this.props.updateMonthBooking(this.state.monthBookings)
        this.props.updateCalendar()
    }


    openQRCodeReader = () => {
        this.setState({
            qrCodeShow: true,
        });
    }

    closeQRCodeReader = () => {
        this.setState({
            qrCodeShow: false,
        });
    }

    openOfficeDetail = () => {
        this.setState({
            officeDetailShow: true,
        });
        this.props.setOfficeDetail(true)
    }

    setBookings(b) {
        this.setState({
            //bookings: b,
            bookings: b.desk_bookings,
            meeting_rooms_bookings: b.meeting_room_bookings
        })
    }
    closeOfficeDetail = () => {
        this.setLoading(true)
        //this.props.select(3)

        let ds = new DeskService();
        ds.getUserBookingsV3().then((data) => {
            this.setBookings(data)
            this.setInitialStrings()
            this.selectedDateBookings(new Date(this.props.date));
            this.selectedMonthBookings(new Date(new Date(this.props.date)))
            this.setLoading(false)
        })


        this.setState({
            officeDetailShow: false,
            loading: false
        });

        this.props.setOfficeDetail(false)

    }
    closeTemporaryOfficeDetail = () => {


        this.setState({
            officeDetailShow: false,

        });

        this.props.setOfficeDetail(false)
    }

    openProfile = () => {
        this.setState({
            profileShow: true,
        });
    }

    closeProfile = () => {
        this.setState({
            profileShow: false,
        });
    }

    onSwipeLeftListener = () => {
        let dateStatus = this.calendarInstance.current.headerElement.classList[1]

        let date = this.state.navigationDate
        if (dateStatus == "e-month") {
            date = new Date(date.setMonth(date.getMonth() - 1))
            this.calendarInstance.current.navigateTo("Month", date)
            this.setState({ navigationDateStatus: "Month" })
        }
        if (dateStatus == "e-year") {
            date = new Date(date.setFullYear(date.getFullYear() - 1))
            this.calendarInstance.current.navigateTo("Year", date)
            this.setState({ navigationDateStatus: "Year" })
        }
        if (dateStatus == "e-decade") {
            date = new Date(date.setFullYear(date.getFullYear() - 10))
            this.calendarInstance.current.navigateTo("Decade", date)
            this.setState({ navigationDateStatus: "Decade" })
        }

        this.setState({
            navigationDate: date,
            swiped: true,
            onswipe: -1
        })

        this.swipeNavigate(date)

    }

    onSwipeRightListener = () => {
        let dateStatus = this.calendarInstance.current.headerElement.classList[1]
        let date = this.state.navigationDate
        if (dateStatus == "e-month") {
            date = new Date(date.setMonth(date.getMonth() + 1))
            this.calendarInstance.current.navigateTo("Month", date)
        }
        if (dateStatus == "e-year") {
            date = new Date(date.setFullYear(date.getFullYear() + 1))
            this.calendarInstance.current.navigateTo("Year", date)
        }
        if (dateStatus == "e-decade") {
            date = new Date(date.setFullYear(date.getFullYear() + 10))
            this.calendarInstance.current.navigateTo("Decade", date)
        }

        this.setState({
            navigationDate: date, swiped: true,
            onswipe: -1,
            swiped: true
        })
        this.swipeNavigate(date)
    }


    customDates = (cell) => {
        let year = cell.date.getFullYear();
        let month = parseInt(cell.date.getMonth() + 1)
        let day = cell.date.getDate();
        if (month < 10) {
            month = '0' + month
        }
        if (day < 10) {
            day = '0' + day
        }
        let newDate = cell.date.getFullYear() + '-' + month + '-' + day

        for (let i = 0; i < this.state.bookings.length; i++) {
            if (this.state.bookings[i].date === newDate) {
                if (this.state.bookings[i].holiday) {
                    cell.element.children[0].classList.add("c-holiday");
                }
                else if (this.state.bookings[i].away) {
                    cell.element.children[0].classList.add("b-away");
                }
                else {
                    cell.element.children[0].classList.add("a-present");
                    cell.element.children[0].addEventListener("click", this.openOfficeDetail);
                }

            }
        }
    }

    refreshBookingList = (id) => {
        this.setLoading(true)
        let bookingList = this.state.bookings
        let selectedDate = this.props.date
        bookingList = bookingList.filter(booking => {
            return booking.id != id;
        })
        let monthBookings = this.props.monthBookings.filter(booking => {
            return booking.id != id;
        })//da fare
        this.setState({ bookings: bookingList })
        this.setState({ monthBookings: monthBookings })
        this.setState({
            selectedBookings: bookingList.filter(function (item) {
                return Date.parse(item.date) === selectedDate;
            })
        });
        this.props.updateMonthBooking(this.state.monthBookings)
        this.setLoading(false)
        this.componentDidMount()
        //this.calendarInstance.current.navigateTo("Month", this.props.date)
    }

    onNavigate = (args) => {

        let month = args.date.getMonth()
        let year = args.date.getFullYear()
        let monthBookings = this.state.bookings.filter(booking => {
            return (new Date(booking.date).getMonth() === month) && (new Date(booking.date).getFullYear() === year);
        })
        this.setState({ monthBookings: monthBookings })
        this.props.updateMonthBooking(monthBookings)
    }

    swipeNavigate = (navigateDate) => {
        let year = new Date(navigateDate).getFullYear()
        let month = new Date(navigateDate).getMonth()
        let monthBookings = this.state.bookings.filter(booking => {
            return (new Date(booking.date).getMonth() === month) && (new Date(booking.date).getFullYear() === year);
        })
        this.setState({ monthBookings: monthBookings })
        this.props.updateMonthBooking(monthBookings)
    }

    selectDate(date) {
        this.setState({
            date: date
        })
    }
    openTutorialPopup() {
        this.setState({
            tutorialPopup: !this.state.tutorialPopup,
            selectedTutorialPage: 0
        })
    }
    changeTutorialPage(type) {
        if (type === 'prev')
            this.setState({
                selectedTutorialPage: this.state.selectedTutorialPage - 1
            })
        else {
            this.setState({
                selectedTutorialPage: this.state.selectedTutorialPage + 1
            })
        }
    }
    render() {
        let customModalStyles = {

            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '600px',
                /*
                'max-height': '800px',
                'min-height': '200px',
                */
                height: '85vh',
                marginRight: '-50%',
                padding: '15px',
                transform: 'translate(-50%, -50%)',
                'z-index': 99,
                transition: "opacity 20ms ease-in -out",
                "background-color": "#f0efef"
            },

        };

        let color = 'background: #000000;'
        if (!this.state.loading) {
            if (!this.props.bookDetail || this.props.bookDetail === null) {
                return (
                    <div>


                        <div className="Navbar container-nav">
                            <div className="AppName"><h4 className='my_booking'>{this.getTranslationByKey("WELLNESS.DASHBOARD.RESERVATIONS")}</h4></div>
                        </div>

                        <Container className="noPadding container">
                            <Row className="noMargin w-100" >
                                <Col xs="12" className="noPadding">
                                    <Swipe nodeName="div"
                                        className="CalendarContainer"
                                        detectTouch={true}
                                        onSwipedDown={this.onSwipeLeftListener}
                                        onSwipedUp={this.onSwipeRightListener}
                                    >
                                        <div className="Calendar" >
                                            <CalendarComponent navigated={this.onNavigate} change={this.dateChange} renderDayCell={this.customDates} ref={this.calendarInstance} />

                                        </div>
                                        <div className="OpenOfficeDetailBox" onClick={this.openOfficeDetail}>
                                            {this.getTranslationByKey("WELLNESS.ADD")}
                                        </div>
                                    </Swipe></Col>

                                <Col xs="12" md="4" className="noPadding">

                                </Col>
                            </Row>

                        </Container>

                        {this.state.profileShow ?
                            <div className='profile'>
                                <div className="ProfileBox" id="ProfileBox">
                                    <Profile />
                                </div>
                            </div>
                            : null
                        }
                    </div>
                );
            } else {
                return (<div>
                    {this.props.loading2 ? <div className='loader_ellipsis'>
                        <div class="lds-ellipsis">
                            <div Style={"background: #000"}></div>
                            <div Style={"background: #000"}></div>
                            <div Style={"background: #000"}></div>
                            <div Style={"background: #000"}></div>
                        </div>
                    </div> : (this.props.officeDetailShow ?
                        <div style={{ height: 'fit-content', 'max-height': '89vh' }}>
                            <img src={closeButton} className="CloseOfficeDetailBox" onClick={() =>
                                this.closeOfficeDetail()} />

                            <div className="OfficeDetailBox">
                                {this.props.user && this.props.user.modules ? <OfficeDetail getBookings={this.getBookings} date={this.props.date} userInfo={this.props.user} loading={this.setLoading} close={this.closeOfficeDetail} selectedDateBookings={(date) => this.selectDate(date)} select={(now) => this.props.select(now)} update={this.update} reload={this.props.reload} />
                                    : ''}       </div>
                        </div>
                        :


                        <div Style={'position:relative'} >
                            {this.props.user && this.props.user.modules && this.props.user.modules.desk_booking && this.props.user.modules.desk_booking.tutorial &&
                                this.props.user.modules.desk_booking.tutorial.length > 0 && window.innerWidth > 1000 ?
                                <div className='tutorial_icon' onClick={this.openTutorialPopup}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-info-circle pointer" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                </div> : ''}
                            <Container className="noPadding container CalendarFooterComplete">
                                <Row>
                                    <Col xs="12" className="noPadding selected_date_container">
                                        <div className="CalendarSideBar">

                                            <h5 className="DateString">{this.props.dateString}</h5>
                                            <p className={this.props.differenceString ? "DifferenceDateString" : "NullDifferenceDateString"}>{this.props.differenceString}</p>
                                            <div id="BookingList" onClick={this.openOfficeDetail}></div>

                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="CalendarFooter CalendarFooterComplete">
                                <BookingDetailMonth update={this.update} reload={this.props.reload} refresh={(bookid) => this.refreshBookingList(bookid)} className="BookingDetail" key={this.props.date} value={this.props.monthBookings} loading={(val) => this.setLoading(val)} getBookings={this.getBookings} />

                            </div>
                            <div className='noPadding container container'>
                                <div className="CalendarFooter CalendarFooterThin">
                                    <h5 className="DateString">{this.props.dateString}</h5>
                                    <p className={this.props.differenceString ? "DifferenceDateString" : "NullDifferenceDateString"}>{this.props.differenceString}</p>
                                    <div id="BookingList" onClick={this.openOfficeDetail}></div>

                                    <BookingDetailMonth update={this.update} reload={this.props.reload} refresh={(bookid) => this.refreshBookingList(bookid)} className="BookingDetail" key={this.props.date} value={this.props.selectedBookings} loading={(val) => this.setLoading(val)} getBookings={this.getBookings} />
                                </div>
                            </div>

                            <Dialog
                                open={this.state.tutorialPopup}
                                onAfterOpen={null}
                                onClose={this.openTutorialPopup}
                                style={customModalStyles}
                                className="tutorial_dialog"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth
                                maxWidth="sm"
                            >
                                <DialogTitle>
                                    <div className="close" >
                                        <svg onClick={() => this.openTutorialPopup()} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    {this.props.user && this.props.user.modules && this.props.user.modules.desk_booking && this.props.user.modules.desk_booking.tutorial ?
                                        <div className="modal_body pb-25">
                                            <div className="desk_tutorial_container">
                                                <div className=" item-center flex" Style={"justify-content: center;    display: flex;    align-items: center;"}>
                                                    {this.state.selectedTutorialPage > 0 ?
                                                        <div>
                                                            <svg onClick={() => this.changeTutorialPage('prev')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-left pointer" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                            </svg>
                                                        </div>
                                                        : <div Style={"width: 30px;"}></div>}
                                                    <div className="desk_tutorial_page" Style={"background-image: url('" + this.props.user.modules.desk_booking.tutorial[this.state.selectedTutorialPage] + "')"}></div>
                                                    {this.state.selectedTutorialPage < this.props.user.modules.desk_booking.tutorial.length - 1 ?
                                                        <div>
                                                            <svg onClick={() => this.changeTutorialPage('next')} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                            </svg>
                                                        </div>
                                                        : <div Style={"width: 30px;"}></div>}
                                                </div>
                                            </div>


                                        </div> : ''}
                                </DialogContent>
                            </Dialog>
                        </div>
                    )}
                </div>)
            }

            /* ogni div corrisponde ad un pallino del loading, non rimuoverle */
        } else {

            return (
                <div className='loader_ellipsis'>
                    <div class="lds-ellipsis">
                        <div Style={color}></div>
                        <div Style={color}></div>
                        <div Style={color}></div>
                        <div Style={color}></div>
                    </div>
                </div>)
        }
    }
}

export default Calendar;
