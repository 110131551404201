import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import { WellnessService } from "../Services/Services";
import CoursePageFooter from "./CoursePageFooter";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Modal from 'react-modal';
import Select from "react-select";
import { BookUser, MoveToWaitingList, MoveToBookingList, RemoveUserBooking, RemoveUserWaiting, SetPresentButton } from "./Buttons";
import { getTranslationByKey } from "../global";

//Page with all course description
export function ManagePage(props) {
    const [gym, setGym] = useState(null)
    const [trainers, setTrainers] = useState([])
    const [services, setServices] = useState([])
    const [courses, setCourses] = useState([])
    const [users, setUsers] = useState([])
    const [loadingModal, setLoadingModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [course, setCourse] = useState(props.course)
    const [modal_status, setModalStatusValue] = useState(null)
    const [confirm_modal_status, setConfirmModalStatusValue] = useState(false)
    const [confirm_modal_text, setConfirmModalTextValue] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [selectedTrainer, setSelectedTrainer] = useState(null)
    const [selectedUser, setSelectedUser] = useState(getTranslationByKey("WELLNESS.COURSES.FILTER.USER") ? getTranslationByKey("WELLNESS.COURSES.FILTER.USER") : 'Select User')
    const [selectedService, setSelectedService] = useState(getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") ? getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") : 'Select a Service')
    const [selectedParticipant, setSelectedParticipant] = useState(null)
    const [updateTrainer, setUpdateTrainer] = useState(false)
    const [updateCourse, setUpdateCourse] = useState(false)
    const [modal_type, setModalType] = useState(0)
    const [isSelected, setIsSelected] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    let ws = new WellnessService()

    useEffect(() => {
        ws.getGymById(props.course.gym.id).then((data) => {
            setGym(data)
        })
        ws.getTrainers().then((data) => {
            let list = []
            data.map((t) => {
                list.push({ 'value': t.id, 'label': t.first_name + ' ' + t.last_name })
            })
            setTrainers(list)
        }).catch((err) => {
            setLoading(false)
        })

        ws.getCourseList().then((data) => {
            let list = []
            data.map((c) => {
                list.push({ 'value': c.id, 'label': getTranslationByKey(c.name) })
            })
            setCourses(list)
        })
        setLoadingModal(true)
        ws.getUsersbyScheduleId(props.course.id).then((data) => {
            let list = []
            data.map((s) => {
                list.push({ 'value': s.id, 'label': s.lastname + ' ' + s.firstname })
            })
            setUsers(list)
            setLoading(false)
        })
        if (props.course.course.type.id == 2) {
            ws.getServices(props.course.id).then((data) => {
                let list = []
                data.map((s) => {
                    list.push({ 'value': s.id, 'label': s.name })
                })
                setServices(list)
            }).finally(() => {
                setLoadingModal(false)
            })
        } else {
            setLoadingModal(false)
        }
    }, [])

    const manageReload = () => {
        setLoading(true)
        setModalStatus(false)
        setConfirmModalStatusValue(false)

        ws.getCoursebyScheduleId(props.course.id).then((data) => {
            setCourse(data)
            ws.getGymById(props.course.gym.id).then((data) => {
                setGym(data)
            })
        })
        ws.getUsersbyScheduleId(props.course.id).then((data) => {
            setUsers(data)
            setLoading(false)
        })
    }

    const setModalStatus = (bool) => {
        if (!bool) {
            setSelectedService(getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") ? getTranslationByKey("WELLNESS.COURSES.FILTER.SERVICE") : 'Select a Service')
            setSelectedUser(getTranslationByKey("WELLNESS.COURSES.FILTER.USER") ? getTranslationByKey("WELLNESS.COURSES.FILTER.USER") : 'Select User')
            window.scroll({ top: -1, left: 0, behavior: "smooth" });
        }
        setModalStatusValue(bool)
    }

    const manageAction = (participant, action) => {
        let tmpString = ''

        setConfirmModalStatusValue(true)
        setSelectedParticipant(participant)
        switch (action) {
            case 'moveToWaitingList':
                tmpString = getTranslationByKey('WELLNESS.MOVETOWAITINGLIST')
                setModalType(1)
                break;
            case 'moveToBookingList':
                setModalType(2)
                tmpString = getTranslationByKey('WELLNESS.MOVETOSIGNED')
                break;
            case 'deleteBooking':
                setModalType(3)
                tmpString = getTranslationByKey('WELLNESS.REMOVEFROMSIGNED')
                break;
            case 'deleteFromWaitingList':
                setModalType(4)
                tmpString = getTranslationByKey('WELLNESS.REMOVEFROMWAITINGLIST')
                break;
            default:
                break;
        }
        let tmp = tmpString.replace('[FIRSTNAME]', participant.firstname)
        let res = tmp.replace('[LASTNAME]', participant.lastname)
        setConfirmModalTextValue(res)
    }

    const formatDate = (string) => {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Date(parseInt(string) * 1000).toLocaleDateString([], options);
    }

    const isCourseNow = () => {
        let now = new Date()
        let courseDate = new Date(props.course.date)
        let ret = false
        //same date
        if (now.getDate() == courseDate.getDate() && now.getMonth() == courseDate.getMonth() && now.getFullYear() == courseDate.getFullYear())
            ret = true
        return ret
    }

    const isPassed = () => {
        let now = new Date()
        let courseDate = new Date(props.course.date)
        let ret = false
        now.setHours(0, 0, 0, 0);
        courseDate.setHours(0, 0, 0, 0);
        if (courseDate < now) {
            ret = true
        }
        return ret
    }

    const getLink = (link) => {
        return "background-image:url('" + link + "')"
    }

    const handleUpdate = () => {
        setLoading(true)

        let options = {
            "course_id": selectedCourse && selectedCourse.value ? selectedCourse.value : course.course.id,
            "trainer_id": selectedTrainer && selectedTrainer.value ? selectedTrainer.value : course.trainer.id
        }

        ws.updateCourse(course.id, options).then(() => {
            manageReload()
            props.reload()
            setUpdateTrainer(false)
            setUpdateCourse(false)
        })
    }

    let percentage = 0
    if (!loading && course) {
        percentage = course.course.max_participants - course.available_seats;
    }

    let modalUserButton = ''
    if (modal_type == 1) {
        modalUserButton = <MoveToWaitingList className="book_button" course={props.course} user={props.user} reload={props.reload} setConfirmModalStatus={setConfirmModalStatusValue} manageReload={manageReload} selectedParticipant={selectedParticipant} />
    } else if (modal_type == 2) {
        modalUserButton = <MoveToBookingList className="book_button" service_id={selectedService} course={props.course} user={props.user} reload={props.reload} setConfirmModalStatus={setConfirmModalStatusValue} manageReload={manageReload} selectedParticipant={selectedParticipant} message={getTranslationByKey('WELLNESS.UTIL.BOOKING.REMOVE')} />
    } else if (modal_type == 3) {
        modalUserButton = <RemoveUserBooking className="book_button" course={props.course} user={props.user} reload={props.reload} setConfirmModalStatus={setConfirmModalStatusValue} manageReload={manageReload} selectedParticipant={selectedParticipant} />
    } else {
        modalUserButton = <RemoveUserWaiting className="book_button" course={props.course} user={props.user} reload={props.reload} setConfirmModalStatus={setConfirmModalStatusValue} manageReload={manageReload} selectedParticipant={selectedParticipant} message={getTranslationByKey('WELLNESS.UTILS.USER.REMOVEFROMWAITINGLIST')} />
    }

    let customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: window.innerWidth < 1000 ? '90%' : '600px',
            height: '400px',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": window.innerWidth < 1000 ? "#f0efef" : "#fff",
            "border-radius": window.innerWidth < 1000 ? "" : "20px !important; "
        }
    };

    let customConfirmModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '600px',
            height: modal_type == 2 ? '330px' : '300px',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": window.innerWidth > 1000 ? "#fff" : "#f0efef"
        }
    };

    const customStylesServices = {
        control: (base, state) => ({
            ...base,
            background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
            border: 0,
            // Overwrittes the different states of border
            borderColor: isSelected ? null : null,
            // Removes weird border around container
            boxShadow: isFocused ? null : null,
        }),
        menuList: base => ({
            ...base,
            background: window.innerWidth > 1000 ? "#fff" : "#f0efef",
            // kill the white space on first and last option
            padding: 0,
            "max-height": "220px",
            "::-webkit-scrollbar-thumb": {
                background: "#000 !important"
            },
        })
    };

    let address = ""
    if (gym) {
        address = "https://www.google.it/maps/place/" + gym.location.street_address
    }

    return (
        !loading ?
            <React.Fragment>
                <div className="reserv_menu_manage">
                    <div className="manage_page_title_container flex" onClick={() => props.showManagePage(false)}>

                    </div>
                    {props.course !== null ?
                        <div className="course">
                            <div>
                                <div className="flex header_container">
                                    <div className="course_header_container">
                                        <div className="course_header_manage" Style={getLink(course.course.image_url)}>
                                        </div>
                                    </div>
                                    <div className="info_manage_container flex">
                                        <div className="info_manage">
                                            <div className="flex">
                                                <div className="course_info_manage">
                                                    <div className="course_page_info_title_manage flex">
                                                        {updateCourse ?
                                                            <Select
                                                                value={selectedCourse}
                                                                onChange={(e) => setSelectedCourse(e)}
                                                                options={courses}
                                                                className="filter_input_update pt-1"
                                                                placeholder={selectedCourse}
                                                                styles={customStylesServices}
                                                            />
                                                            :
                                                            <React.Fragment>{getTranslationByKey(course.course.name)}</React.Fragment>
                                                        }

                                                        {props.user.modules.wellness_lab.is_admin && !isPassed() ?
                                                            (updateCourse ?
                                                                <div className="flex">
                                                                    <div className="pointer" Style={"margin: 5px 7px;"} onClick={handleUpdate}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="pointer" Style={"margin: 5px 0;"} onClick={() => setUpdateCourse(!updateCourse)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="update_coure_icon pointer" onClick={() => setUpdateCourse(!updateCourse)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                    </svg>
                                                                </div>)
                                                            : null}
                                                        {course.available_seats == 0 ? <div className="flag_complete">{getTranslationByKey('UTIL.LABEL.FULL')}</div> : null}

                                                    </div>
                                                    <div className="course_page_info_subtitle_manage flex">
                                                        {updateTrainer ?
                                                            <Select
                                                                value={selectedTrainer}
                                                                onChange={(e) => setSelectedTrainer(e)}
                                                                options={trainers}
                                                                className="filter_input_update pt-1"
                                                                placeholder={selectedTrainer}
                                                                styles={customStylesServices}
                                                            />
                                                            :
                                                            <React.Fragment>{course.trainer.first_name} {course.trainer.last_name}</React.Fragment>
                                                        }

                                                        {props.user.modules.wellness_lab.is_admin && !isPassed() ?
                                                            (updateTrainer ?
                                                                <div className="flex">
                                                                    <div className="pointer" Style={"margin: 5px 7px;"} onClick={handleUpdate}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="black" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="pointer" Style={"margin: 5px 0;"} onClick={() => setUpdateTrainer(!updateTrainer)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="black" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="update_coure_icon pointer" onClick={() => setUpdateTrainer(!updateTrainer)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                    </svg>
                                                                </div>
                                                            ) : ''}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="flex">
                                                <div>
                                                    <div className="course_page_info_title_manage">
                                                        {moment(course.date + '.000Z').utc().format('D MMMM YYYY')}
                                                    </div>
                                                    <div className="course_page_info_subtitle_manage">
                                                        {(course.time.from).slice(0, 5)} - {(course.time.to).slice(0, 5)}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="manage_location flex">
                                                <a target="_blank" href={address}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#5a5956" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                    </svg>
                                                </a>
                                                {gym && gym.location && gym.location.name ? gym.location.name : ''}
                                            </div>

                                        </div>
                                        <div></div>
                                        <div className="statistic_container">
                                            {props.user.modules.wellness_lab.is_admin && !isPassed() ?
                                                <div className="book_button_add_user add_booking" onClick={() => setModalStatus(true)}>{getTranslationByKey('WELLNESS.ADD')}</div>
                                                : null}
                                            <div className="progressbar_container flex">
                                                <div>
                                                    <div className="progressbar margin_participants">
                                                        <CircularProgressbar
                                                            value={percentage}
                                                            text={course.participants.length + "/" + course.course.max_participants}
                                                            //text={partecipanti.length + "/" + course.course.max_participants}
                                                            maxValue={course.course.max_participants}
                                                            styles={buildStyles({
                                                                // Rotation of path and trail, in number of turns (0-1)
                                                                rotation: 0,

                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                strokeLinecap: 'butt',
                                                                textSize: '22px',
                                                                pathTransitionDuration: 0.5,

                                                                // Colors
                                                                pathColor: `rgba(0, 0, 0, ${percentage})`,
                                                                textColor: 'black',
                                                                trailColor: '#d6d6d6',
                                                                backgroundColor: '#5a5956',
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="legend_progressbar">{getTranslationByKey('WELLNESS.COURSES.PARTECIPANT')}</div>
                                                </div>
                                                <div>
                                                    <div className="progressbar">
                                                        <CircularProgressbar
                                                            className="progressbar_waiting"
                                                            value={percentage}
                                                            text={course.waiting_list.length == 0 ? '0' : course.waiting_list.length}
                                                            maxValue={course.course.max_participants}
                                                            styles={buildStyles({
                                                                rotation: 0,
                                                                strokeLinecap: 'butt',
                                                                textSize: '22px',
                                                                pathTransitionDuration: 0.5,
                                                                pathColor: `rgba(183, 183, 183, ${course.course.max_participants})`,
                                                                textColor: 'black',
                                                                trailColor: 'rgba(183, 183, 183)'
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="legend_progressbar">{getTranslationByKey('WELLNESS.RESERVATIONS.WAITINGLIST')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list_container">
                                {!props.user.modules.wellness_lab.is_admin ?
                                    <div id="list_container_trainer">
                                        <div className="booked_title_container flex">
                                            <div className="booked_title" Style={"width: 70% !important;"}>{getTranslationByKey('WELLNESS.SIGNED')}</div>
                                            <div className="booked_title" Style={"width: 15% !important;"}>Presenze</div>
                                        </div>
                                        <div className="booked_container">
                                            {course.participants.map((p) =>
                                                <div className="flex">
                                                    <div className="booked_manage_card_container flex" Style={"width: 70% !important;"}>
                                                        <div className="booked_manage_card flex">
                                                            <div className="circle_manage_card">{p.firstname[0]}{p.lastname[0]}</div>
                                                            <div Style=" overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
                                                                <div className="name_manage_card"> {p.firstname} {p.lastname}</div>
                                                                {course.course.type.id == 2 ? <div Style={"margin-left: 10px; font-size: 12px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;"}>{getTranslationByKey(course.course.description)}</div> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div Style={"width: 10% !important; margin-top: 10px; padding-left: 3%"}>

                                                        {isCourseNow() ?
                                                            <SetPresentButton is_present={p.is_present} course={props.course} user={props.user} reload={() => props.reload()} setConfirmModalStatus={setConfirmModalStatusValue} manageReload={() => manageReload()} selectedParticipant={p} />
                                                            :
                                                            (p.is_present ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                                                                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                                                                    <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-app " viewBox="0 0 16 16">
                                                                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z" />
                                                                </svg>
                                                            )}

                                                    </div>
                                                    <div className="actions_card_container flex">
                                                        <div className="actions_card">
                                                            {props.user.modules.wellness_lab.is_admin && !isPassed() ?
                                                                <React.Fragment>
                                                                    {course.available_seats == 0 ?
                                                                        <svg onClick={() => manageAction(p, 'moveToWaitingList')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-clock" className="action_icon" viewBox="0 0 16 16">
                                                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                                        </svg>
                                                                        : null}

                                                                    {!isPassed() ?
                                                                        <svg onClick={() => manageAction(p, 'deleteBooking')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-circle" className="action_icon" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                        </svg>
                                                                        : null}
                                                                </React.Fragment>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="waiting_title_container flex">
                                            <div className="booked_title" Style={"width: 70% !important;"}>{getTranslationByKey('WELLNESS.WAITING')}</div>
                                            <div className="booked_title" Style={"width: 30% !important;"}></div>
                                        </div>
                                        <div className="waiting_container">
                                            {course.waiting_list.map((p) =>
                                                <div className="flex">
                                                    <div className="booked_manage_card_container flex" Style={"width: 70% !important;"}>
                                                        <div className="booked_manage_card flex">
                                                            <div className="circle_manage_card">{p.firstname[0]}{p.lastname[0]}</div>
                                                            <div className="name_manage_card"> {p.firstname} {p.lastname}</div>
                                                        </div>
                                                    </div>
                                                    <div className="booked_manage_card_container flex" Style={"width: 30% !important;"}>
                                                        <div className="booked_manage_card">
                                                            <div className="timestamp_manage_card"></div>
                                                        </div>
                                                    </div>
                                                    <div className="actions_card_container flex">
                                                        <div className="actions_card">
                                                            {props.user.modules.wellness_lab.is_admin && !isPassed() ?
                                                                <React.Fragment>
                                                                    {course.available_seats != 0 ?
                                                                        <svg onClick={() => manageAction(p, 'moveToBookingList')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" class="bi bi-arrow-up-circle" className="action_icon" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                                        </svg>
                                                                        : null}
                                                                    <svg onClick={() => manageAction(p, 'deleteFromWaitingList')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-circle" className="action_icon" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg>
                                                                </React.Fragment>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                    :
                                    <div id="list_container">
                                        <div className="booked_title_container flex">
                                            <div className="booked_title">{getTranslationByKey('WELLNESS.SIGNED')}</div>
                                            <div className="booked_title" Style={"width: 35% !important;"}>{getTranslationByKey('WELLNESS.REGISTRATIONDATE')}</div>
                                            <div className="booked_title" Style={"width: 15% !important;"}>{getTranslationByKey('WELLNESS.ATTENDANCES')}</div>
                                        </div>
                                        <div className="booked_container">
                                            {course.participants.map((p) =>
                                                <div className="flex">
                                                    <div className="booked_manage_card_container flex">
                                                        <div className="booked_manage_card flex">
                                                            <div className="circle_manage_card">{p.firstname[0]}{p.lastname[0]}</div>
                                                            <div Style=" overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
                                                                <div className="name_manage_card"> {p.firstname} {p.lastname}</div>
                                                                {course.course.type.id == 2 ? <div Style={"margin-left: 10px; font-size: 12px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;"}>{getTranslationByKey(course.course.description)}</div> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="booked_manage_card_container flex" Style={"width: 35% !important;"}>
                                                        <div className="booked_manage_card">
                                                            <div className="timestamp_manage_card">{getTranslationByKey("WELLNESS.SIGNEDFROM")} {formatDate(p.booking_created_at)}</div>
                                                        </div>
                                                    </div>
                                                    <div Style={"width: 10% !important; margin-top: 10px; padding-left: 3%"}>

                                                        {isCourseNow() ?
                                                            <SetPresentButton is_present={p.is_present} course={props.course} user={props.user} reload={() => props.reload()} setConfirmModalStatus={setConfirmModalStatusValue} manageReload={() => manageReload()} selectedParticipant={p} />
                                                            :
                                                            (p.is_present ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                                                                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                                                                    <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-app " viewBox="0 0 16 16">
                                                                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z" />
                                                                </svg>
                                                            )}

                                                    </div>
                                                    <div className="actions_card_container flex">
                                                        <div className="actions_card">
                                                            {props.user.modules.wellness_lab.is_admin && !isPassed() ?
                                                                <React.Fragment>
                                                                    {course.available_seats == 0 ?
                                                                        <svg onClick={() => manageAction(p, 'moveToWaitingList')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-clock" className="action_icon" viewBox="0 0 16 16">
                                                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                                        </svg>
                                                                        : null}

                                                                    {!isPassed() ?
                                                                        <svg onClick={() => manageAction(p, 'deleteBooking')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-circle" className="action_icon" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                        </svg>
                                                                        : null}
                                                                </React.Fragment>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="waiting_title_container flex">
                                            <div className="booked_title">{getTranslationByKey('WELLNESS.WAITING')}</div>
                                            <div className="booked_title" Style={"width: 35% !important;"}>{getTranslationByKey('WELLNESS.REGISTRATIONDATE')}</div>
                                        </div>
                                        <div className="waiting_container">
                                            {course.waiting_list.map((p) =>
                                                <div className="flex">
                                                    <div className="booked_manage_card_container flex">
                                                        <div className="booked_manage_card flex">
                                                            <div className="circle_manage_card">{p.firstname[0]}{p.lastname[0]}</div>
                                                            <div className="name_manage_card"> {p.firstname} {p.lastname}</div>
                                                        </div>
                                                    </div>
                                                    <div className="booked_manage_card_container flex" Style={"width: 50% !important;"}>
                                                        <div className="booked_manage_card">
                                                            <div className="timestamp_manage_card">{getTranslationByKey("WELLNESS.SIGNEDFROM")} {formatDate(p.booking_created_at)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="actions_card_container flex">
                                                        <div className="actions_card">
                                                            {props.user.modules.wellness_lab.is_admin && !isPassed() ?
                                                                <React.Fragment>
                                                                    {course.available_seats != 0 ?
                                                                        <svg onClick={() => manageAction(p, 'moveToBookingList')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" class="bi bi-arrow-up-circle" className="action_icon" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                                                        </svg>
                                                                        : null}
                                                                    <svg onClick={() => manageAction(p, 'deleteFromWaitingList')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-circle" className="action_icon" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg>
                                                                </React.Fragment>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>

                            <Modal
                                isOpen={modal_status}
                                onAfterOpen={null}
                                onRequestClose={() => setModalStatus(false)}
                                style={customModalStyles}
                                contentClassName="ciao"
                                appElement={document.getElementById('root') || undefined}
                            >
                                <div className="close" >
                                    <svg onClick={() => setModalStatus(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>

                                {loadingModal ?
                                    <div className="loader_modal_managew_mobile_container">
                                        <div className='loader_ellipsis loader_modal_managew_mobile'>
                                            <div class="lds-ellipsis">
                                                <div Style={"background: #000"}></div>
                                                <div Style={"background: #000"}></div>
                                                <div Style={"background: #000"}></div>
                                                <div Style={"background: #000"}></div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="modal_body">
                                        <div className="modal_select">
                                            <Select
                                                value={selectedUser}
                                                onChange={setSelectedUser}
                                                options={users}
                                                className="filter_input_services pt-1"
                                                placeholder={selectedUser}
                                                styles={customStylesServices}
                                                onclo
                                            />

                                            {course.course.type.id == 2 ?
                                                <Select
                                                    value={selectedService}
                                                    onChange={setSelectedService}
                                                    options={services}
                                                    className="filter_input_services pt-1"
                                                    placeholder={selectedService}
                                                    styles={customStylesServices}
                                                /> : null}

                                        </div>
                                        <div className="modal_button">
                                            <BookUser className="book_button" selectedUser={selectedUser} selectedService={selectedService} course={props.course} user={props.user} reload={() => props.reload()} manageReload={() => manageReload()} message={getTranslationByKey('WELLNESS.UTILS.BOOKING.SUCCESS')} />
                                        </div>
                                    </div>
                                }
                            </Modal>
                            <Modal
                                isOpen={confirm_modal_status}
                                onAfterOpen={null}
                                onRequestClose={() => setConfirmModalStatusValue(false)}
                                style={customConfirmModalStyles}
                                contentLabel="Example Modal"
                                appElement={document.getElementById('root') || undefined}
                            >
                                <div className="close" >
                                    <svg onClick={() => setConfirmModalStatusValue(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </div>
                                <div className="modal_body">
                                    {confirm_modal_text}

                                    {modal_type == 2 && course.course.type.id == 2 ?
                                        <Select
                                            value={selectedService}
                                            onChange={setSelectedService}
                                            options={services}
                                            className="filter_input_services pt-1"
                                            placeholder={selectedService}
                                            styles={customStylesServices}
                                        />
                                        : null}
                                    <div className="modal_button">
                                        {modalUserButton}
                                    </div>
                                </div>
                            </Modal>
                            {/*
                            <div className="footer_course">
                                <CoursePageFooter gym={gym} course={props.course} user={props.user} reload={() => props.reload()} showMap={false} />
                            </div>
                            */}
                        </div > : ''
                    }
                </div>
            </React.Fragment>
            : null
    )

}