import { useToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import { WellnessService } from "../Services/Services";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

//wellness lab buttons
export function getTranslationByKey(key) {
    let translation = ''
    let translation_en = ''
    if (sessionStorage.getItem('translation')) {
        translation = JSON.parse(sessionStorage.getItem('translation'))
        translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
        return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
    } else {
        return key
    }
}
export function BookButton(props) {

    const message = useToast({
        message: props.message,
        position: "bottomRight",
        class: "btn btn-outline-success",
        timeout: 3000,
        progressBar: true,
        icon: 'success',
        color: '#00c64a73'
    });

    var count = 0;

    function handleClick(e) {
        e.stopPropagation()
        props.setLoading(true)
        if (props.check_course.course.type.id == 2) {
            props.checkIfModal(props.check_course)
        } else {
            count++;
            if (count == 1) {
                let ws = new WellnessService()
                let booking = {
                    user_id: props.user.id,
                    schedule_id: props.course.id
                }
                ws.insertBooking(booking).then(() => {
                    message()
                    props.reload()
                })
            }
        }
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)} >{props.name}</div>
    )
}
export function BookButtonDisabled(props) {
    return (
        <div className={props.className}>{props.name}</div>
    )
}

export function BookUser(props) {

    const message = useToast({
        message: props.message,
        position: "bottomRight",
        class: "btn btn-outline-success",
        timeout: 3000,
        progressBar: true,
        icon: 'success',
        color: '#00c64a73'
    });

    function handleClick(e) {
        let service_id = null
        if (props.course.course.type.id == 2)
            service_id = props.selectedService.value

        let ws = new WellnessService();
        let booking = {
            user_id: props.selectedUser.value,
            schedule_id: props.course.id,
            service_id: service_id
        }
        ws.addUserToCourse(booking).then(() => {
            message()
            props.manageReload()
        }).finally(() => {
            props.reload()
        })
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)} >{getTranslationByKey('BUTTON.INSERT')}</div>
    )
}

export function ManageButton(props) {

    function handleClick(e) {
        e.stopPropagation()
        props.goToManagePage()
        props.showManagePage(true, props.course)
    }

    return (
        <div className={props.className} onClick={(e) => handleClick(e)} >{getTranslationByKey('BUTTON.MANAGE')}</div>
    )
}


export function BookServiceButton(props) {

    const message = useToast({
        message: props.message,
        position: "bottomRight",
        class: "btn btn-outline-success",
        timeout: 3000,
        progressBar: true,
        icon: 'success',
        color: '#00c64a73'
    });

    function handleClick(e) {

        let ws = new WellnessService()
        let booking = {
            user_id: props.user.id,
            schedule_id: props.course.id,
            service_id: props.selectedServiceType
        }
        props.openModal()
        ws.insertBooking(booking).then(() => {
            message()
            props.reload()
        })
        e.stopPropagation()
    }

    return (
        <div className={props.className} onClick={(e) => handleClick(e)} >{props.name}</div>
    )
}


export function WaitButton(props) {

    const message = useToast({
        message: props.message,
        position: "bottomRight",
        class: "btn btn-outline-warning",
        timeout: 3000,
        progressBar: true,
        icon: 'warning',
        color: '#e1ab09'
    });

    var count = 0;

    function handleClick(e) {
        e.stopPropagation()
        props.setLoading(true)
        count++
        if (count == 1) {
            let ws = new WellnessService()
            let booking = {
                user_id: props.user.id,
                schedule_id: props.course.id
            }
            ws.insertBooking(booking).then(() => {
                message()
                props.reload()
            })
        }
        e.stopPropagation()
    }

    return (
        <div className={props.className} onClick={(e) => handleClick(e)} >{props.name}</div>
    )
}

export function BookedButton(props) {

    function handleClick(e) {
        e.stopPropagation()
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)}>{props.name}</div>
    )
}

export function RemoveBooking(props) {

    var count = 0;

    function handleClick(e) {
        e.stopPropagation()
        props.setLoading(true)
        count++;
        if (count == 1) {
            let ws = new WellnessService()
            let booking = {
                user_id: props.user.id,
                schedule_id: props.course.course.id
            }
            ws.removeBooking(props.course.booking_id, booking).then(() => {
                props.reload()
            }).finally(() => {
                props.reload()
            })
        }
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)}  >{props.name}</div>
    )
}

export function RemoveWaitingBooking(props) {

    const message = useToast({
        message: props.message,
        position: "bottomRight",
        class: "btn btn-outline-success",
        timeout: 3000,
        progressBar: true,
        icon: 'success',
        color: '#00c64a73'
    });

    var count = 0;
    function handleClick(e) {
        e.stopPropagation()
        props.setLoading(true)
        count++;
        if (count == 1) {
            let ws = new WellnessService()
            let booking = {
                user_id: props.user.id,
                schedule_id: props.course.booking_id
            }
            ws.removeWaitingBooking(props.course.booking_id, booking).then(() => {
                message()
            }).finally(() => {
                props.reload()
            })
        }
        e.stopPropagation()
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)}>{props.name}</div>
    )
}

export function FollowButton(props) {

    var count = 0;

    function handleClick(e) {
        count++;
        if (count == 1) {
            let ws = new WellnessService()
            ws.followCourse(props.course.id, props.is_followed).then(() => {
                props.reload()
            })
        }
        e.stopPropagation()
    }
    return (
        <div className="follow_button" onClick={(e) => handleClick(e)}>
            {props.is_followed ?
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-bell-fill" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-bell" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                </svg>
            }

        </div>
    )
}

/* manage buttons */

export function MoveToWaitingList(props) {

    var count = 0;

    function handleClick(e) {
        count++;
        if (count == 1) {


            let service_id = null
            if (props.course.course.id == 3)
                service_id = props.course.course.type.id

            let ws = new WellnessService()
            let booking = {
                user_id: props.selectedParticipant.id,
                schedule_id: props.course.id,
                service_id: service_id
            }
            ws.insertUserInWaitingList(booking).then(() => {
                props.reload()
                props.manageReload()
            })
            props.setConfirmModalStatus(false)
        }
        e.stopPropagation()
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)}>Move</div>
    )
}

export function MoveToBookingList(props) {

    const message = useToast({
        message: props.message,
        position: "bottomRight",
        class: "btn btn-outline-success",
        timeout: 3000,
        progressBar: true,
        icon: 'success',
        color: '#00c64a73'
    });

    var count = 0;

    function handleClick(e) {
        count++;
        if (count == 1) {
            let service_id = null
            if (props.course.course.type.id == 2)
                service_id = props.service_id


            let ws = new WellnessService();
            let booking = {
                user_id: props.selectedParticipant.id,
                schedule_id: props.course.id,
                service_id: service_id
            }
            ws.addUserToCourse(booking).then(() => {
                message()
                props.manageReload()
            }).finally(() => {
                props.reload()
            })
            props.setConfirmModalStatus(false)
        }
        e.stopPropagation()
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)}>Move</div>
    )
}

export function RemoveUserBooking(props) {

    var count = 0;

    function handleClick(e) {
        count++;
        if (count == 1) {
            let ws = new WellnessService()
            let booking = {
                user_id: props.selectedParticipant.id,
                schedule_id: props.course.course.id
            }
            ws.removeBooking(props.selectedParticipant.booking_id, booking).then(() => {
                props.manageReload()
            }).finally(() => {
                props.reload()
            })
            props.setConfirmModalStatus(false)
        }
        e.stopPropagation()
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)}>{getTranslationByKey('WELLNESS.UTILS.REMOVE')}</div>
    )
}

export function RemoveUserWaiting(props) {

    const message = useToast({
        message: props.message,
        position: "bottomRight",
        class: "btn btn-outline-success",
        timeout: 3000,
        progressBar: true,
        icon: 'success',
        color: '#00c64a73'
    });

    var count = 0;

    function handleClick(e) {
        count++;
        if (count == 1) {
            let ws = new WellnessService()
            let booking = {
                user_id: props.selectedParticipant.id,
                schedule_id: props.course.id
            }
            ws.removeWaitingBooking(props.selectedParticipant.booking_id, booking).then(() => {
                message()
                props.manageReload()
            }).finally(() => {
                props.reload()
            })
            props.setConfirmModalStatus(false)
        }
        e.stopPropagation()
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)}>Remove</div>
    )
}

export function SetPresentButton(props) {

    var count = 0;

    function handleClick(e) {
        count++;
        if (count == 1) {
            let ws = new WellnessService()
            let booking = {
                booking_id: props.selectedParticipant.booking_id,
                is_present: !props.is_present
            }
            ws.setPresent(booking, props.is_present).then(() => {
                props.manageReload()
            }).finally(() => {
                props.reload()
            })
        }
        e.stopPropagation()
    }
    return (
        <div className={props.className} onClick={(e) => handleClick(e)}>
            {props.is_present ?
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check2-square pointer" viewBox="0 0 16 16">
                    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                    <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-app pointer" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z" />
                </svg>
            }

        </div>
    )
}

export function ApplyFilters(props) {

    return (
        <div className="apply_filters_button" onClick={props.onClick} >{getTranslationByKey('WELLNESS.COURSES.FILTER.APPLY')}</div>
    )
}

export function ResetFilters(props) {
    return (
        <div className="reset_filters_button" onClick={props.onClick} >{getTranslationByKey('WELLNESS.COURSES.FILTER.RESET')}</div>
    )
}

export function DocButton(props) {
    return (
        <a download={props.text + '.pdf'} className="link_button" >   <div className="doc_button" onClick={props.onClick} >{props.text}</div> </a>
    )
}

export function GoToButton(props) {
    const [openPopup, setOpenPopup] = React.useState(false)
    const goto = () => {
        setOpenPopup(false)
        window.open(props.link, "_blank")
    }
    return (
        <React.Fragment>

            <a className="link_button" target="_blank" onClick={() => setOpenPopup(true)}> <div className="doc_button" > {props.text}  </div></a>
            <Dialog
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"

            >
                <DialogTitle id="alert-dialog-title" Style="font-family: 'GucciSans-Bold' !important;">
                    {props.text}
                </DialogTitle>

                <DialogContent>
                    <div className="subtitle_home mt-3"> {getTranslationByKey('WELLNESS.TUTORIAL.CERTIFICATEAPPROVAL')} </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPopup(false)} className="wellness_goto_button_close">
                        {getTranslationByKey('UTIL.ALERT.CLOSE')}
                    </Button>

                    <Button onClick={() => goto()} className="wellness_goto_button_confirm">
                        {getTranslationByKey('UTIL.LABEL.CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export function ProgressButton(props) {
    return (
        <div className="progress_button" >{props.text}</div>
    )
}

export default BookButton;