import React from 'react';
import $ from "jquery";
import { IoIosArrowBack, IoIosArrowForward, IoIosCheckmark } from "react-icons/io";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../App.css';
import { DeskService, WellnessService } from '../Services/Services';
import Cookies from 'universal-cookie';
import { msalInstance } from '../authConfig';
import reactDom from 'react-dom';
import App from '../App';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            locations: [],
            floors: [],
            defaultFloor: {},
            showChooseFloor: false,
            showTutorial: false,
            showLanguageMenu: false,
            showWellnessCalendarMenu: false,
            selectedLanguage: localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : navigator.language.split('-')[0],
            selectedWellnessCalendarSetting: null,
            enableNotification: false,
            isPushNotificationsEnabled: false,
            isEmailNotificationsEnabled: false,
            isWellnessCalendaEnabled: false,
            selectedWellnessCalendarSettingValue: null,
            slideImages: [
                'https://smartoffice.globalbit.gucci/pwa/tutorial/1.png',
                'https://smartoffice.globalbit.gucci/pwa/tutorial/2.png',
                'https://smartoffice.globalbit.gucci/pwa/tutorial/3.png',
                'https://smartoffice.globalbit.gucci/pwa/tutorial/4.png'
            ],
            infoBoxText: "Please, try again later",
            loading: false
        }
        this.setUser = this.setUser.bind(this)
        this.setDefaultFloor = this.setDefaultFloor.bind(this)
        this.setFloors = this.setFloors.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.reload = this.reload.bind(this)
        this.handleCheckboxPush = this.handleCheckboxPush.bind(this)
        this.handleCheckboxEmail = this.handleCheckboxEmail.bind(this)
        this.handleWellnessCalendar = this.handleWellnessCalendar.bind(this)
        this.selectWellnessCalendarSetting = this.selectWellnessCalendarSetting.bind(this)
    };

    componentDidMount() {
        this.setState({ loading: true })

        let ds = new DeskService();
        ds.getUserProfile().then((data) => {
            this.setUser(data)
            if (data.modules && data.modules.desk_booking) {
                this.setDefaultFloor(data.modules.desk_booking.floor)
                ds.getLocation(data.modules.desk_booking.location.id)
                this.setState({ loading: false })
            } else
                this.setState({ loading: false })
        })
    }

    reload() {
        this.setState({ loading: true })
        let ds = new DeskService();
        ds.getUserProfile().then((data) => {
            this.setUser(data)
            if (data.modules && data.modules.desk_booking) {
                this.setDefaultFloor(data.modules.desk_booking.floor)
                ds.getLocation(data.modules.desk_booking.location.id)
                this.setState({ loading: false })
            }
            else
                this.setState({ loading: false })
        })
    }

    setDefaultFloor(f) {
        this.setState({
            defaultFloor: f
        })
    }
    setFloors(f) {
        this.setState({
            floors: f
        })
    }
    setLoading(val) {
        this.setState({
            loading: val
        })
    }
    setUser(user) {
        this.setState({
            user: user,
            isEmailNotificationsEnabled: user.is_mail_notifications_enabled,
            isPushNotificationsEnabled: user.is_push_notifications_enabled,
            isWellnessCalendaEnabled: user.wellness_calendar_active
        })
    }

    setNotificationType() {

        $('#CloseProfileBox').show();
    }

    openChooseFloor = () => {
        this.setState({
            showChooseFloor: true,
        });

        $('#CloseProfileBox').hide();
    }

    closeChooseFloor = () => {
        this.setState({
            showChooseFloor: false,
        });

        $('#CloseProfileBox').show();
    }

    openTutorial = () => {
        this.setState({
            showTutorial: true,
        });

        $('#CloseProfileBox').hide();
    }

    closeTutorial = () => {
        this.setState({
            showTutorial: false,
        });

        $('#CloseProfileBox').show();
    }

    openLanguageMenu = () => {
        this.setState({
            showLanguageMenu: true,
        });

        $('#CloseProfileBox').hide();
    }
    openWellnessCalendarMenu = () => {
        this.setState({
            showWellnessCalendarMenu: true,
        });

        $('#CloseProfileBox').hide();
    }

    closeLanguageMenu = () => {
        this.setState({
            showLanguageMenu: false,
        });

        this.reload()
        $('#CloseProfileBox').show();
    }
    closeWellnessCalendarMenu = () => {
        this.setState({
            showWellnessCalendarMenu: false,
        });


        $('#CloseProfileBox').show();
    }

    selectNotificationType = (type) => {
        if (type === 'push') {
            this.setState({
                isPushNotificationsEnabled: !this.state.isPushNotificationsEnabled
            })
        }
        else {
            this.setState({
                isEmailNotificationsEnabled: !this.state.isEmailNotificationsEnabled
            })
        }
    }

    selectLanguageType = (type) => {

        this.setState({
            selectedLanguage: type
        })

    }
    selectWellnessCalendarSetting = (type, value) => {

        this.setState({
            selectedWellnessCalendarSetting: type,
            selectedWellnessCalendarSettingValue: value
        })

    }

    writeFloors = () => {

        let a = document.getElementById('ProfileBox');
        if (a)
            a.scrollTo({ left: 0, top: 0, behavior: 'smooth' })

        var floors = this.state.floors;

        var wrottenFloors = [];

        for (let i = 0; i < floors.length; i++) {

            if (i != 0) {
                wrottenFloors.push(<hr className="line_separator"></hr>);
            }
            wrottenFloors.push(<div style={{ 'display': 'flex', 'justify-content': 'space-between', lineHeight: '2em', cursor: 'pointer' }} onClick={() => this.selectNewDefaultFloor(floors[i])}>
                <div className="textPositionLeft" key={floors[i].id}>
                    {floors[i].name}
                </div>
                {this.state.defaultFloor && (floors[i].id == this.state.defaultFloor.id) ? <div style={{ 'text-align': 'right', 'color': '#000000', 'margin-right': '5%', 'font-size': '1.5em' }}><IoIosCheckmark /></div> : null}
            </div>);
        }

        return wrottenFloors;
    }


    writeNotificationMenu = () => {
        if (this.state.loading)
            return (<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>)
        else
            return (
                <React.Fragment>
                    <div style={{ 'display': 'flex', 'justify-content': 'space-between', lineHeight: '2em', cursor: 'pointer' }} onClick={() => this.selectNotificationType('email')}>
                        <div className="textPositionLeft" key={'email'}>
                            Email notification
                        </div>
                        {this.state.isEmailNotificationsEnabled ? <div style={{ 'text-align': 'right', 'color': '#07524b', 'margin-right': '5%', 'font-size': '1.5em' }}><IoIosCheckmark /></div> : null}
                    </div>
                    <div style={{ 'display': 'flex', 'justify-content': 'space-between', lineHeight: '2em', cursor: 'pointer' }} onClick={() => this.selectNotificationType('push')}>
                        <div className="textPositionLeft" key={'push'}>
                            Push notification
                        </div>
                        {this.state.isPushNotificationsEnabled ? <div style={{ 'text-align': 'right', 'color': '#07524b', 'margin-right': '5%', 'font-size': '1.5em' }}><IoIosCheckmark /></div> : null}
                    </div>
                </React.Fragment>
            );
    }

    writeLanguageMenu = () => {

        let a = document.getElementById('ProfileBox');
        if (a)
            a.scrollTo({ left: 0, top: 0, behavior: 'smooth' })

        let languages = JSON.parse(sessionStorage.getItem('translations'))
        let list_langs = { "en": "English", "it": "Italiano" }

        /**/
        let langs = Object.keys(languages).map(key =>
            <div style={{ 'display': 'flex', 'justify-content': 'space-between', lineHeight: '2em', cursor: 'pointer' }} onClick={() => this.selectLanguageType(key)}>
                <div className="textPositionLeft" key={key}>
                    {list_langs[key]}
                </div>
                {this.state.selectedLanguage == key ? <div style={{ 'text-align': 'right', 'color': '#000000', 'margin-right': '5%', 'font-size': '1.5em' }}><IoIosCheckmark /></div> : null}
            </div>
        )

        if (this.state.loading)
            return (<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>)
        else
            return (
                <React.Fragment>
                    {langs}
                </React.Fragment>
            );
    }
    writeWellnessCalendarMenu = () => {
        let a = document.getElementById('ProfileBox');
        if (a)
            a.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        /**/
        let value =
            <>
                <div style={{ 'display': 'flex', 'justify-content': 'space-between', lineHeight: '2em', cursor: 'pointer' }} onClick={() => this.selectWellnessCalendarSetting(this.getTranslationByKey('UTIL.LABEL.YES'), true)}>
                    <div className="textPositionLeft" key={this.getTranslationByKey('UTIL.LABEL.YES')}>
                        {this.getTranslationByKey('UTIL.LABEL.YES')}
                    </div>
                    {this.state.selectedWellnessCalendarSetting === this.getTranslationByKey('UTIL.LABEL.YES') ? <div style={{ 'text-align': 'right', 'color': '#000000', 'margin-right': '5%', 'font-size': '1.5em' }}><IoIosCheckmark /></div> : null}
                </div>
                <div style={{ 'display': 'flex', 'justify-content': 'space-between', lineHeight: '2em', cursor: 'pointer' }} onClick={() => this.selectWellnessCalendarSetting(this.getTranslationByKey('UTIL.LABEL.NO'), false)}>
                    <div className="textPositionLeft" key={this.getTranslationByKey('UTIL.LABEL.NO')}>
                        {this.getTranslationByKey('UTIL.LABEL.NO')}
                    </div>
                    {this.state.selectedWellnessCalendarSetting === this.getTranslationByKey('UTIL.LABEL.NO') ? <div style={{ 'text-align': 'right', 'color': '#000000', 'margin-right': '5%', 'font-size': '1.5em' }}><IoIosCheckmark /></div> : null}
                </div>
            </>


        if (this.state.loading)
            return (<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>)
        else
            return (
                <React.Fragment>
                    {value}
                </React.Fragment>
            );
    }

    selectNewDefaultFloor = (floor) => {
        this.setState({
            defaultFloor: floor
        });
    }

    saveNewDefaults = () => {

        this.setState({ loading: true })

        let body = {
            "is_push_notifications_enabled": this.state.isPushNotificationsEnabled,
            "is_mail_notifications_enabled": this.state.isEmailNotificationsEnabled
        }

        let ds = new DeskService()
        let ws = new WellnessService()

        localStorage.setItem('selectedLanguage', this.state.selectedLanguage)

        let el = {
            wellness_calendar_active: this.state.isWellnessCalendaEnabled
        }
        ws.updateWellnessCalendarSetting(el, false)
        if (this.state.user.modules.desk_booking) {
            ds.updateUser(this.state.user.id, this.state.defaultFloor.id).then(() =>
                ds.setNotificationType(body).then(() =>
                    ds.getTranslationByLanguage(this.state.selectedLanguage).then(() => {
                        setTimeout(() => {
                            this.setLoading(false)
                        }, 1000);
                    }
                    )
                )
            )
        } else {
            ds.setNotificationType(body).then(() =>
                ds.getTranslationByLanguage(this.state.selectedLanguage).then(() => {
                    setTimeout(() => {
                        this.setLoading(false)
                    }, 1000);
                }
                )
            )
        }


    }

    handleCheckboxEmail() {
        this.setState({ isEmailNotificationsEnabled: !this.state.isEmailNotificationsEnabled })
    }

    handleWellnessCalendar() {
        this.setState({ isWellnessCalendaEnabled: !this.state.isWellnessCalendaEnabled })
    }

    handleCheckboxPush() {
        this.setState({ isPushNotificationsEnabled: !this.state.isPushNotificationsEnabled })
    }

    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        }
    }

    logout = () => {
        let cookies = new Cookies();
        cookies.remove("user_token_type", { path: '/' })
        cookies.remove("user_token", { path: '/' })
        localStorage.clear()
        sessionStorage.clear()

        msalInstance.logoutRedirect().catch(e => {
        });

        reactDom.render(<App />, document.getElementById('root'));

    }
    downloadVideo = () => {
        let ds = new DeskService()
        ds.download('tutorial')
    }

    render() {


        let languages = JSON.parse(sessionStorage.getItem('translations'))
        let list_langs = { "en": "English", "it": "Italiano" }
        let selected_lang = 'Default'
        Object.keys(languages).map(key => {
            if (key == this.state.selectedLanguage) {
                selected_lang = list_langs[key]
            }
        }
        )

        if (this.state.loading)
            return (<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>)
        else
            return (
                <div className="container">
                    <div>
                        <div className="ContentProfile">
                            <div className='flex'>
                                <div className="pointer flex" >
                                    <svg onClick={this.props.closeProfile} xmlns="http://www.w3.org/2000/svg" width="15" height="25" fill="black" class="bi bi-chevron-left " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                </div>
                                <div onClick={this.props.closeProfile} Style={"margin-top: 0px;"} className="pointer">{this.getTranslationByKey('UTIL.LABEL.BACK')}</div>
                            </div>
                            <div className="ContentProfileName">{this.getTranslationByKey("DESKBOOKING.PROFILE.TITLE")}</div>
                            <div className="ProfileDetails">
                                <img className="imageProfile" />
                                <div className="UserInformation">
                                    <p Style={"margin-bottom: 7px; font-family: GucciSans-Bold"}>{Object.keys(this.state.user).length != 0 ? this.state.user.lastname : null} {Object.keys(this.state.user).length != 0 ? this.state.user.firstname : null}</p>
                                    <p Style={"margin-bottom: 7px"}>{Object.keys(this.state.user).length != 0 ? this.state.user.job_title : null}</p>
                                    <p Style={"margin-bottom: 7px"}>{Object.keys(this.state.user).length != 0 ? this.state.user.location_address : null}</p>
                                </div>
                            </div>

                            {this.state.user && this.state.user.modules && this.state.user.modules.desk_booking ?

                                <div className="ContentProfileSubName">
                                    {this.getTranslationByKey("DESKBOOKING.PROFILE.DEFAULTSETTINGS")}
                                </div>
                                : ''}

                            {this.state.user && this.state.user.modules && this.state.user.modules.desk_booking && this.state.user.modules.desk_booking.location ?
                                <div className="DefaultDetails">
                                    <div className="profileDetalsline">
                                        <div className="textPositionLeft"><div className="textinone">{this.getTranslationByKey("DESKBOOKING.PROFILE.SEDE")}</div></div> <div className="textPositionRight"><div className="textinone2">{Object.keys(this.state.user).length != 0 ? this.state.user.modules.desk_booking.location.name : null}</div></div>
                                    </div>
                                    <hr className="line_separator"></hr>

                                    <div className="profileDetalsline" onClick={this.openChooseFloor} style={{ 'cursor': 'pointer' }}>
                                        <div className="textPositionLeft"><div className="textinone">{this.getTranslationByKey("DESKBOOKING.PROFILE.PIANO")}</div></div> <div className="textPositionRight"><div className="textinone2">{this.state.defaultFloor !== null ? this.state.defaultFloor.name : null} <IoIosArrowForward /></div></div>
                                    </div>
                                </div>

                                : ''}

                            <div className="ContentProfileSubName" Style={'text-transform:uppercase; padding-top:23px'}>
                                {this.getTranslationByKey("DESKBOOKING.PROFILE.NOTIFICATION")}
                            </div>
                            <div className="DefaultDetails">

                                <div className="profileDetalsline" >
                                    <div className="textPositionLeft">
                                        <div className="textinone ">
                                            {this.getTranslationByKey("DESKBOOKING.PROFILE.PUSHNOTIFICATIONS")}
                                        </div>
                                    </div>
                                    <div className="textPositionRight"><div className="textinone2">
                                        <input checked={this.state.isPushNotificationsEnabled} type="checkbox" className="checkbox" id="chkPush" onChange={() => this.handleCheckboxPush()} />
                                        <label className="profileLabel" htmlFor="chkPush">
                                            <div className="profileBall"></div>
                                        </label></div></div>
                                </div>
                                <hr className="line_separator"></hr>
                                <div className="profileDetalsline" >
                                    <div className="textPositionLeft">
                                        <div className="textinone">
                                            {this.getTranslationByKey("DESKBOOKING.PROFILE.MAIL")}
                                        </div>
                                    </div>
                                    <div className="textPositionRight">
                                        <div className="textinone2">
                                            <input checked={this.state.isEmailNotificationsEnabled} type="checkbox" className="checkbox" id="chkEmail" onChange={() => this.handleCheckboxEmail()} />
                                            <label className="profileLabel" htmlFor="chkEmail">
                                                <div className="profileBall"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="DefaultDetails">

                                <div className="profileDetalsline" onClick={this.openLanguageMenu} style={{ 'cursor': 'pointer' }}>
                                    <div className="textPositionLeft"><div className="textinone">{this.getTranslationByKey("DESKBOOKING.PROFILE.CHANGELANG")}</div></div> <div className="textPositionRight"><div className="textinone2">{selected_lang} <IoIosArrowForward /></div></div>
                                </div>
                            </div>
                            {this.state.user && this.state.user.enabled_to_wellness ?
                                <div className="DefaultDetails">

                                    <div className="profileDetalsline">
                                        <div className="textPositionLeft">
                                            <div className="textinone"> {this.getTranslationByKey('WELLNESS.CALENDAR')}</div>
                                        </div>
                                        <div className="textPositionRight">
                                            <div className="textinone2">
                                                <input checked={this.state.isWellnessCalendaEnabled} type="checkbox" className="checkbox" id="chkCalendar" onChange={() => this.handleWellnessCalendar()} />
                                                <label className="profileLabel" htmlFor="chkCalendar">
                                                    <div className="profileBall"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}


                            <div className="moreInfo">
                                <div className="profileDetalsline" >
                                    <div className="textPositionLeft"><div onClick={() => this.downloadVideo()} download='tutorial' className="textinone pointer" style={{ textDecoration: 'none', color: '#000000', 'font-weight': '700' }}>{this.getTranslationByKey("DESKBOOKING.PROFILE.VIDEOTUTORIAL")}</div></div>
                                </div>
                                <hr className="line_separator"></hr>
                                <div className="profileDetalsline">
                                    <div className="textPositionLeft"><a href="https://keringconnect.service-now.com/sp?id=sc_cat_item&sys_id=76f46c82db58f380c917ef3c0b9619c8" target="_blank" className="textinone" style={{ textDecoration: 'none', color: '#000000', 'font-weight': '700' }}>{this.getTranslationByKey("DESKBOOKING.PROFILE.HELP")}</a></div>
                                </div>
                                <hr className="line_separator"></hr>
                                <div className="profileDetalsline" style={{ marginBottom: '10px' }}>
                                    <div className="textPositionLeft"><a href="mailto:gucciforyou-smb@gucci.com" style={{ textDecoration: 'none', color: '#000000', 'font-weight': '700' }}>{this.getTranslationByKey("DESKBOOKING.PROFILE.SENDFEEDBACK")}</a></div>
                                </div>
                            </div>

                            <div className="moreInfo">
                                <div className="profileDetalsline" style={{ marginBottom: '20px' }}>
                                    <div className="textPositionLeft"><div onClick={this.logout} className="textinone pointer" style={{ color: '#000000', 'font-weight': '700' }}>{this.getTranslationByKey("DESKBOOKING.PROFILE.LOGOUT")}</div></div> <div className="textPositionRight"><h3 className="textinone2"></h3></div>
                                </div>
                            </div>
                        </div>

                        <div class="OpenOfficeDetailBox mt-2" onClick={() => this.saveNewDefaults()}>{this.getTranslationByKey("DESKBOOKING.PROFILE.SAVE")}</div>

                    </div>

                    {this.state.showChooseFloor ? <div className="ChooseDefaultFloor" id="ChooseDefaultFloor">
                        <div className="CloseChooseDefaultFloor" onClick={this.closeChooseFloor}> <IoIosArrowBack /> {this.getTranslationByKey("DESKBOOKING.PROFILE.TITLE")} </div>
                        <div className="DefaultDetails">{this.writeFloors()}</div>
                    </div> : null}

                    {this.state.showLanguageMenu ? <div className="ChooseDefaultFloor" id="ChooseDefaultFloor">
                        <div className="CloseChooseDefaultFloor" onClick={this.closeLanguageMenu}> <IoIosArrowBack /> {this.getTranslationByKey("DESKBOOKING.PROFILE.TITLE")} </div>
                        <div className="DefaultDetails">{this.writeLanguageMenu()}</div>
                    </div> : null}

                    {this.state.showWellnessCalendarMenu ? <div className="ChooseDefaultFloor" id="ChooseDefaultFloor">
                        <div className="CloseChooseDefaultFloor" onClick={this.closeWellnessCalendarMenu}> <IoIosArrowBack /> {this.getTranslationByKey("DESKBOOKING.PROFILE.TITLE")} </div>
                        <div className="DefaultDetails">{this.writeWellnessCalendarMenu()}</div>
                    </div> : null}

                    {this.state.showTutorial ?
                        <div className="ChooseDefaultFloor" style={{ background: '#fff' }}>
                            <div className="CloseChooseDefaultFloor" onClick={this.closeTutorial}> <IoIosArrowBack /> {this.getTranslationByKey("DESKBOOKING.PROFILE.TITLE")} </div>
                            <Slide>
                                {this.state.slideImages.map((slideImage, index) => (
                                    <div className="each-slide" key={index} style={{ marginTop: '10vh' }}>
                                        <img src={slideImage} style={{ height: '70vh' }} />
                                    </div>
                                ))}
                            </Slide>
                        </div>
                        : null}
                </div >
            );
    }
}

export default Profile;
