import { Component } from "react";
import reactDom from "react-dom"
import Cookies from "universal-cookie";

class MapComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            display: 'none',
            z_index: 0,
            display_from: 'none',
            display_to: 'none',
            display_from_mobile: 'none',
            display_to_mobile: 'none',
            miDirectionsServiceInstance: '',
            miDirectionsRendererInstance: '',
            travelMode: 'WALKING',
            lastLocation: '',
            lat: 41.8976314,
            long: 12.481946,
            text: '',
            text_from: '',
            text_to: '',
            istruzioni: [],
            visibility: [],
            showSteps: false,
            originLocationCoordinate: '',
            destinationCoordinate: '',
            selectedCoordinates: '',
            step_container_selected: 0,
            iter: true,
            menu_1: true,
            menu_2: false,
            menu_sel: false,
            tmp_text: '',
            directions: false,
            fullscreen: false,
            hidescreen: false,
            cookies: new Cookies(),
            activeFloor: '0',
            showSelected: false,
            loading: true
        }

        this.showResult = this.showResult.bind(this)
        this.showResultFrom = this.showResultFrom.bind(this)
        this.showResultFromMobile = this.showResultFromMobile.bind(this)
        this.showResultTo = this.showResultTo.bind(this)
        this.showResultToMobile = this.showResultToMobile.bind(this)
        this.hideResult = this.hideResult.bind(this)
        this.hideResultFrom = this.hideResultFrom.bind(this)
        this.hideResultFromMobile = this.hideResultFromMobile.bind(this)
        this.hideResultTo = this.hideResultTo.bind(this)
        this.hideResultToMobile = this.hideResultToMobile.bind(this)
        this.getRoute = this.getRoute.bind(this)
        this.getFloorRoute = this.getFloorRoute.bind(this)
        this.changeStepVisibility = this.changeStepVisibility.bind(this)
        this.selectStepContainer = this.selectStepContainer.bind(this)
        this.goTo = this.goTo.bind(this)
        this.goToInit = this.goToInit.bind(this)
        this.goBackMenu = this.goBackMenu.bind(this)
        this.goNextMenu = this.goNextMenu.bind(this)
        this.fullScreen = this.fullScreen.bind(this)
        this.hideScreen = this.hideScreen.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.reset = this.reset.bind(this)
        this.goToSearchMobile = this.goToSearchMobile.bind(this)
        this.goToSelect = this.goToSelect.bind(this)
        this.go = this.go.bind(this)
    }

    componentDidUpdate(nextProps, nextState) {

        if (nextState.travelMode != this.state.travelMode)
            return true
        if (nextState.miDirectionsRendererInstance !== this.state.miDirectionsRendererInstance)
            return true
        if (nextState.istruzioni !== this.state.istruzioni)
            return true
        if (nextState.visibility !== this.state.visibility)
            return true
        if (nextState.menu_1 !== this.state.menu_1)
            return true
        if (nextState.menu_2 !== this.state.menu_2)
            return true
    }


    componentDidMount() {
        window.history.pushState(null, null, window.location.pathname)
        window.addEventListener('popstate', this.onBackButtonEvent)
        const miSearchElement = document.getElementById('search_component')
        const miSearchFrom = document.getElementById('search_from')
        const miSearchFromMobile = document.getElementById('search_from_mobile')
        const miSearchTo = document.getElementById('search_to')
        const miSearchToMobile = document.getElementById('search_to_mobile')
        const miListElement = document.getElementById('search_list');
        const miListFrom = document.getElementById('list_from');
        const miListFromMobile = document.getElementById('list_from_mobile');
        const miListTo = document.getElementById('list_to');
        const miListToMobile = document.getElementById('list_to_mobile');
        const miMapElement = document.querySelector('mi-map-googlemaps');

        if (miMapElement) {
            miMapElement.addEventListener('mapsIndoorsReady', () => {

                miMapElement.getMapInstance().then((mapInstance) => {
                    mapInstance.setCenter({
                        lat: parseFloat(this.props.info.coordinates.latitude), lng: parseFloat(this.props.info.coordinates.longitude)
                    });
                    mapInstance.setZoom(20)
                    mapInstance.setOptions({
                        polylineOptions: {
                            strokeColor: 'red'
                        }
                    });
                });

                miMapElement.getMapsIndoorsInstance().then(mapsIndoorsInstance => {
                    mapsIndoorsInstance.setLabelOptions({
                        style: {
                            color: 'black',
                            fontFamily: 'GucciSans-Book',

                            fontSize: '12',
                            strokeStyle: 'white',
                            shadowColor: 'white'
                        }
                    })
                    if (mapsIndoorsInstance)
                        this.setLoading(false)
                    mapsIndoorsInstance.addListener('click', (location) => {
                        let info = ''

                        if (this.state.cookies.get("user_token_type") === 'sso') {
                            info = {
                                description: location.properties.description,
                                room_id: location.properties.roomId,
                                floor_name: location.properties.floorName,
                                building: location.properties.building,
                                venue: location.properties.venue,
                            }
                        }
                        else {
                            info = {
                                room_id: location.properties.roomId,
                                floor_name: location.properties.floorName,
                                building: location.properties.building,
                                venue: location.properties.venue
                            }
                        }
                        this.setState({
                            selectedCoordinates: {
                                lat: location.properties.anchor.coordinates[1],
                                lng: location.properties.anchor.coordinates[0],
                                floor: location.properties.floor,
                                info: info,
                                name: location.properties.name,
                            },
                            menu_sel: true,
                            display_from_mobile: 'none',
                            display_from: 'none',
                            display_to_mobile: 'none',
                            display_to: 'none',
                            showSelected: true,
                            directions: true,
                            showSteps: false,
                            menu_1: false,
                            menu_2: false
                        })


                        //recenter map when user selects a location
                        miMapElement.getMapInstance().then((mapInstance) => {
                            mapInstance.setCenter({
                                lat: location.properties.anchor.coordinates[1], lng: location.properties.anchor.coordinates[0]
                            });
                        });
                    });

                })

                //LIVE DATA ( not used! for desk now )
                miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
                    // eslint-disable-next-line
                    const liveDataManagerInstance = new mapsindoors.LiveDataManager(mapsIndoorsInstance);
                    // eslint-disable-next-line
                    liveDataManagerInstance.enableLiveData(mapsindoors.LiveDataManager.LiveDataDomainTypes.POSITION)
                });


                //Direction service and render
                miMapElement.getDirectionsServiceInstance()
                    .then((directionsServiceInstance) => this.setState({ miDirectionsServiceInstance: directionsServiceInstance }));

                miMapElement.getDirectionsRendererInstance()
                    .then((directionsRendererInstance) => {
                        this.setState({
                            miDirectionsRendererInstance: directionsRendererInstance
                        })
                    });
            })

            //MI SEARCH ( on map)

            miSearchElement.addEventListener('click', (event) => {
                /*  if (document.getElementById('box_description'))
                      document.getElementById('box_description').setAttribute('style', 'display:none!important')*/
                if (!event.target.value || event.target.value === "")
                    this.hideResult()
                else {
                    this.showResult()
                }
            })

            miSearchElement.addEventListener('cleared', (event) => {
                this.hideResult()
            })

            miSearchElement.addEventListener('focusout', (event) => {
                setTimeout(function () { //Start the timer
                    this.hideResult()
                }.bind(this), 250)
            })

            //MI SEARCH FROM ( desktop)

            miSearchFrom.addEventListener('click', (event) => {
                if (!event.target.value || event.target.value === "")
                    this.hideResultFrom()
                else {
                    this.showResultFrom()
                }
            })

            miSearchFrom.addEventListener('focusout', (event) => {
                setTimeout(function () { //Start the timer
                    this.hideResultFrom()
                }.bind(this), 250)
            })

            miSearchFrom.addEventListener('componentRendered', (event) => {
                if (event.target.value && event.target.value.length > 2)
                    this.showResultFrom()

            })

            miSearchFrom.addEventListener('cleared', (event) => {
                this.hideResultFrom()
                this.setState({
                    originLocationCoordinate: ''
                })

            })

            //MI SEARCH TO ( desktop)

            miSearchTo.addEventListener('click', (event) => {

                if (!event.target.value || event.target.value === "")
                    this.hideResultTo()
                else {
                    this.showResultTo()
                }
            })

            miSearchTo.addEventListener('focusout', (event) => {
                setTimeout(function () { //Start the timer
                    this.hideResultTo()
                }.bind(this), 250)
            })

            miSearchTo.addEventListener('componentRendered', (event) => {
                if (event.target.value && event.target.value.length > 2)
                    this.showResultTo()
            })

            miSearchTo.addEventListener('cleared', (event) => {
                this.hideResultTo()
                this.setState({
                    destinationCoordinate: ''
                })

            })

            //MI SEARCH FROM (mobile)
            miSearchFromMobile.addEventListener('click', (event) => {
                if (!event.target.value || event.target.value === "")
                    this.hideResultFromMobile()
                else {
                    this.showResultFromMobile()
                }
            })

            miSearchFromMobile.addEventListener('focusout', (event) => {
                setTimeout(function () { //Start the timer
                    this.hideResultFromMobile()
                }.bind(this), 250)
            })

            miSearchFromMobile.addEventListener('cleared', (event) => {
                this.hideResultFromMobile()
                this.setState({
                    originLocationCoordinate: ''
                })

            })

            //MI SEARCH TO (mobile)

            miSearchToMobile.addEventListener('click', (event) => {
                if (!event.target.value || event.target.value === "")
                    this.hideResultToMobile()
                else {
                    this.showResultToMobile()
                }
            })
            miSearchToMobile.addEventListener('focusout', (event) => {
                setTimeout(function () { //Start the timer
                    this.hideResultToMobile()
                }.bind(this), 250)
            })

            miSearchToMobile.addEventListener('componentRendered', (event) => {
                if (event.target.value && event.target.value.length > 2)
                    this.showResultToMobile()

            })

            miSearchToMobile.addEventListener('cleared', (event) => {
                this.hideResultToMobile()
                this.setState({
                    destinationCoordinate: ''
                })
            })

            //MI RESULTS LISTENER

            miSearchElement.addEventListener('results', (event) => {
                window.scrollTo(0, 0);
                window.scroll({ top: -1, left: 0, behavior: "smooth" });
                // Reset search results list
                miListElement.innerHTML = null;
                // Append new search results

                if (event.detail.length > 0) {
                    this.showResult()
                } else {
                    this.hideResult()
                }
                event.detail.forEach(location => {
                    const miListItemElement = document.createElement('mi-list-item-location');
                    //   miListItemElement.style = "font-family:'GucciSans-Bold'"
                    //  location.properties.imageURL = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/' + this.getLocationIcon(location.properties.type) + '.png'
                    location.properties.venue = ''
                    location.properties.building = ''
                    miListItemElement.style =
                        'padding-top:6px;padding-bottom:6px;'

                    miListItemElement.location = location;
                    miListItemElement.addEventListener("click", (e) => {

                        // eslint-disable-next-line
                        mapsindoors.services.LocationsService.getLocations({ q: location.properties.name, includeOutsidePOI: true })
                            .then((res) => {
                                res.map((val) => {

                                    if (val.properties.name === location.properties.name && location.properties.anchor.coordinates[0] == val.properties.anchor.coordinates[0] &&
                                        val.properties.anchor.coordinates[1] == location.properties.anchor.coordinates[1]) {

                                        miMapElement.getMapInstance().then((mapInstance) => {
                                            this.selectLocation(val)
                                            mapInstance.setCenter({
                                                lat: val.properties.anchor.coordinates[1], lng: val.properties.anchor.coordinates[0]
                                            });
                                            let info = ''

                                            if (this.state.cookies.get("user_token_type") === 'sso') {
                                                info = {
                                                    description: val.properties.description,
                                                    room_id: val.properties.roomId,
                                                    floor_name: val.properties.floorName,
                                                    building: val.properties.building,
                                                    venue: val.properties.venue,
                                                }
                                            }
                                            else {
                                                info = {
                                                    room_id: val.properties.roomId,
                                                    floor_name: val.properties.floorName,
                                                    building: val.properties.building,
                                                    venue: val.properties.venue
                                                }
                                            }
                                            this.setState({
                                                selectedCoordinates: {
                                                    lat: val.properties.anchor.coordinates[1],
                                                    lng: val.properties.anchor.coordinates[0],
                                                    floor: val.properties.floor,
                                                    info: info,
                                                    name: val.properties.name,
                                                }
                                            })
                                            setTimeout(() => {
                                                this.goTo()
                                            }, 1000);
                                            mapInstance.setZoom(25)
                                            this.hideResult()
                                        })
                                    }
                                })
                            })

                    });
                    miListElement.appendChild(miListItemElement);

                });
                if (event.detail.length === 0) {
                    const miListEmpty = document.createElement('div');
                    miListEmpty.innerHTML = "Nessun risultato disponibile"
                    miListElement.appendChild(miListEmpty);
                }
                //filter locations on maps
                miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
                    // Filter map to only display search results
                    mapsIndoorsInstance.filter(event.detail.map(location => location.id), false);
                });
            });

            //MI SEARCH FROM result listener (desktop)

            miSearchFrom.addEventListener('results', (event) => {
                // Reset search results list
                miListFrom.innerHTML = null;
                // Append new search results

                if (this.state.originLocationCoordinate === '') {
                    this.showResultFrom()
                } else {
                    this.hideResultFrom()
                }
                event.detail.forEach(location => {
                    const miListItemElementFrom = document.createElement('mi-list-item-location');
                    //  location.properties.imageURL = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/' + this.getLocationIcon(location.properties.type) + '.png'
                    location.properties.venue = ''
                    location.properties.building = ''
                    miListItemElementFrom.key = location.properties.roomId
                    miListItemElementFrom.style =
                        'padding-top:6px;padding-bottom:6px;'

                    miListItemElementFrom.location = location;
                    let obj = {
                        lat: location.properties.anchor.coordinates[1],
                        lng: location.properties.anchor.coordinates[0],
                        floor: location.properties.floor
                    }

                    miListItemElementFrom.addEventListener("click", () => {
                        this.setState({
                            text_from: location.properties.name
                        })
                        this.setState({
                            originLocationCoordinate: obj
                        })
                        if (this.state.destinationCoordinate === '') {
                            this.hideResultFrom()
                        }
                        else
                            this.getRoute(this.state.destinationCoordinate)
                    })
                    miListFrom.appendChild(miListItemElementFrom);
                });

            });

            //MI SEARCH FROM result listener (mobile)

            miSearchFromMobile.addEventListener('results', (event) => {

                // Reset search results list
                miListFromMobile.innerHTML = null;
                // Append new search results

                if (this.state.originLocationCoordinate === '' && event.detail.length > 0) {
                    this.showResultFromMobile()
                } else {
                    this.hideResultFromMobile()
                }
                event.detail.forEach(location => {
                    const miListItemElementFromMobile = document.createElement('mi-list-item-location');
                    // location.properties.imageURL = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/' + this.getLocationIcon(location.properties.type) + '.png'
                    location.properties.venue = ''
                    location.properties.building = ''
                    miListItemElementFromMobile.style =
                        'padding-top:6px;padding-bottom:6px;'

                    miListItemElementFromMobile.location = location;
                    let obj = {
                        lat: location.properties.anchor.coordinates[1],
                        lng: location.properties.anchor.coordinates[0],
                        floor: location.properties.floor
                    }
                    miListItemElementFromMobile.addEventListener("click", () => {
                        this.setState({
                            text_from: location.properties.name
                        })
                        this.setState({
                            originLocationCoordinate: obj
                        })
                        if (this.state.destinationCoordinate === '')
                            this.hideResultFromMobile()
                        else
                            this.getRoute(this.state.destinationCoordinate)
                    })
                    miListFromMobile.appendChild(miListItemElementFromMobile);
                });
                if (event.detail.length === 0) {
                    const miListEmpty = document.createElement('div');
                    miListEmpty.innerHTML = "Nessun risultato disponibile"
                    miListFromMobile.appendChild(miListEmpty);
                }
            });

            //MI SEARCH TO result listener (desktop)

            miSearchTo.addEventListener('results', (event) => {
                // Reset search results list
                miListTo.innerHTML = null;
                // Append new search results

                if (this.state.destinationCoordinate === '') {
                    this.showResultTo()
                } else {
                    this.hideResultTo()
                }
                event.detail.forEach(location => {
                    const miListItemElementTo = document.createElement('mi-list-item-location');
                    //location.properties.imageURL = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/' + this.getLocationIcon(location.properties.type) + '.png'
                    location.properties.venue = ''
                    location.properties.building = ''
                    miListItemElementTo.key = location.properties.roomId
                    miListItemElementTo.style =
                        'padding-top:6px;padding-bottom:6px;'

                    miListItemElementTo.location = location;
                    let obj = {
                        lat: location.properties.anchor.coordinates[1],
                        lng: location.properties.anchor.coordinates[0],
                        floor: location.properties.floor
                    }


                    miListItemElementTo.addEventListener("click", () => {
                        let info = ''
                        if (this.state.cookies.get("user_token_type") === 'sso') {
                            info = {
                                description: location.properties.description,
                                room_id: location.properties.roomId,
                                floor_name: location.properties.floorName,
                                building: location.properties.building,
                                venue: location.properties.venue,
                            }
                        }
                        else {
                            info = {
                                room_id: location.properties.roomId,
                                floor_name: location.properties.floorName,
                                building: location.properties.building,
                                venue: location.properties.venue
                            }
                        }
                        this.setState({
                            selectedCoordinates: {
                                lat: location.properties.anchor.coordinates[1],
                                lng: location.properties.anchor.coordinates[0],
                                floor: location.properties.floor,
                                info: info,
                                name: location.properties.name
                            },
                            showSelected: true
                        })
                        this.setState({
                            text_to: location.properties.name,
                            showSelected: true
                        })
                        this.setState({
                            destinationCoordinate: obj
                        })
                        if (this.state.originLocationCoordinate === '')
                            this.showResultTo()
                        else {
                            this.hideResultTo()
                            this.getRoute(obj)
                        }
                    })
                    miListTo.appendChild(miListItemElementTo);
                });

            });

            //MI SEARCH TO result listener (mobile)

            miSearchToMobile.addEventListener('results', (event) => {
                // Reset search results list
                miListToMobile.innerHTML = null;
                // Append new search results

                if (this.state.destinationCoordinate === '') {
                    this.showResultToMobile()
                } else {
                    this.hideResultToMobile()
                }
                event.detail.forEach(location => {
                    const miListItemElementToMobile = document.createElement('mi-list-item-location');
                    //    location.properties.imageURL = 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/' + this.getLocationIcon(location.properties.type) + '.png'
                    location.properties.venue = ''
                    location.properties.building = ''

                    miListItemElementToMobile.style =
                        'padding-top:6px;padding-bottom:6px;'

                    miListItemElementToMobile.location = location;
                    let obj = {
                        lat: location.properties.anchor.coordinates[1],
                        lng: location.properties.anchor.coordinates[0],
                        floor: location.properties.floor
                    }
                    miListItemElementToMobile.addEventListener("click", () => {
                        this.setState({
                            text_to: location.properties.name
                        })
                        this.setState({
                            destinationCoordinate: obj
                        })
                        if (this.state.originLocationCoordinate === '')
                            this.showResultToMobile()
                        else {
                            this.hideResultToMobile()
                            this.getRoute(obj)
                        }
                    })
                    miListToMobile.appendChild(miListItemElementToMobile);
                });
                if (event.detail.length === 0) {
                    const miListEmpty = document.createElement('div');
                    miListEmpty.innerHTML = "Nessun risultato disponibile"
                    miListToMobile.appendChild(miListEmpty);
                }
            })
        }
    }

    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    onBackButtonEvent = (e) => {
        e.preventDefault();
        window.location.reload()
    }

    async setLoading(value) {
        this.setState({
            loading: value
        })
    }

    selectLocation(val) {

        const originLocationCoordinate = { lat: val.properties.anchor.coordinates[1], lng: val.properties.anchor.coordinates[0], floor: val.properties.floor };
        const destinationCoordinate = { lat: val.properties.anchor.coordinates[1], lng: val.properties.anchor.coordinates[0], floor: val.properties.floor };
        // Route parameters
        const routeParameters = {
            origin: originLocationCoordinate,
            destination: destinationCoordinate,
            travelMode: this.state.travelMode
        };

        // Get route from directions service
        this.state.miDirectionsServiceInstance.getRoute(routeParameters).then((directionsResult) => {
            // Use directions render to display route
            let routes = this.state.miDirectionsRendererInstance

            routes.setRoute(directionsResult);
            this.setState({
                miDirectionsRendererInstance: routes
            })
        })
        this.setState({
            destinationCoordinate: {
                lat: val.properties.anchor.coordinates[1],
                lng: val.properties.anchor.coordinates[0],
                floor: val.properties.floor
            }
        })
    }

    getFloorCode(val) {
        switch (val) {
            case '0':
                return 0;
            case '1M':
                return 5
            case '1':
                return 10
            case '2M':
                return 15
            case '2':
                return 20
            case '3M':
                return 25
            case '3':
                return 30
            case '3M':
                return 35
            case '4':
                return 40
            default:
                return 50
        }
    }

    getFloorRoute(val) {
        let stepToDraw = this.state.istruzioni[val];
        const originLocationCoordinate = { lat: stepToDraw.start_location.lat, lng: stepToDraw.start_location.lng, floor: this.getFloorCode(stepToDraw.end_location.floor_name) };
        const destinationCoordinate = { lat: stepToDraw.end_location.lat, lng: stepToDraw.end_location.lng, floor: this.getFloorCode(stepToDraw.end_location.floor_name) };
        // Route parameters
        const routeParameters = {
            origin: originLocationCoordinate,
            destination: destinationCoordinate,
            travelMode: this.state.travelMode
        };

        // Get route from directions service
        this.state.miDirectionsServiceInstance.getRoute(routeParameters).then((directionsResult) => {
            // Use directions render to display route
            let routes = this.state.miDirectionsRendererInstance
            routes.setRoute(directionsResult);

            this.setState({
                miDirectionsRendererInstance: routes
            })
        })
        const miMapElement = document.querySelector('mi-map-googlemaps');
    }

    getRoute(location) {
        this.setLoading(true).then(() => {
            setTimeout(function () {
                this.setState({
                    lastLocation: location,
                    showSteps: true,
                    showSelected: false
                })
                this.setLoading(false)
            }.bind(this), 1500)
            const originLocationCoordinate = { lat: this.state.originLocationCoordinate.lat, lng: this.state.originLocationCoordinate.lng, floor: this.state.originLocationCoordinate.floor };
            const destinationCoordinate = { lat: location.lat, lng: location.lng, floor: location.floor };

            // Route parameters
            const routeParameters = {
                origin: originLocationCoordinate,
                destination: destinationCoordinate,
                travelMode: this.state.travelMode
            };
            this.setState({
                percorsi: [],
                activeFloor: this.state.originLocationCoordinate.floor
            })

            // Get route from directions service
            this.state.miDirectionsServiceInstance.getRoute(routeParameters).then((directionsResult) => {
                window.scrollTo(0, 0);
                window.scroll({ top: -1, left: 0, behavior: "smooth" });
                // Use directions render to display route
                if (directionsResult === null) {
                    this.setState({
                        iter: false,
                        menu_2: true,
                        menu_1: false
                    })
                }
                else {
                    this.setState({
                        iter: true
                    })
                    let istruzioni = []
                    let visibility = []

                    directionsResult.legs.map((r, index) => {
                        istruzioni.push(r)
                        visibility.push(false)
                    })
                    this.setState({
                        istruzioni: istruzioni,
                        visibility: visibility
                    })

                    let routes = this.state.miDirectionsRendererInstance
                    routes.setRoute(directionsResult);
                    this.setState({
                        miDirectionsRendererInstance: routes,
                        menu_2: true,
                        menu_1: false
                    })
                }
            });
            this.hideResult()
        })
    }

    getLocationIcon(val) {
        switch (val) {
            case 'Stairs':
                return 'building-icons/stairs';
            case 'Bar-Canteen':
                return 'building-icons/bar';
            case 'Reception':
                return 'building-icons/reception';
            case 'Elevator':
                return 'building-icons/elevator';
            case 'IT':
                return 'building-icons/charging-station';
            case 'Meetingroom':
                return 'universities/meeting-room';
            case 'Library':
                return 'universities/library';
            case 'Kitchen':
                return 'building-icons/kitchen';
            case 'MRTW':
                return 'shopping/hair-dresser';
            case 'WRTW':
                return 'shopping/hair-dresser';
            case 'Celebrities':
                return 'shopping/hair-dresser';
            case 'Salone':
                return 'shopping/hair-dresser';
            case 'Restroom':
                return 'building-icons/unisex-toilet';
            default:
                return 'building-icons/room-unknown'
        }
    }

    showResult() {
        this.setState({
            display: 'block',
            z_index: 2
        })
    }

    showResultFrom() {
        this.setState({
            display_from: 'block'
        })
    }

    showResultFromMobile() {
        this.setState({
            display_from_mobile: 'block'
        })
    }

    showResultTo() {
        this.setState({
            display_to: 'block'
        })
    }

    showResultToMobile() {
        this.setState({
            display_to_mobile: 'block',
        })
    }

    hideResult() {
        this.setState({
            display: 'none',
            z_index: 0
        })
        const miMapElement = document.querySelector('mi-map-googlemaps');
        miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
            // Filter map to only display search results
            mapsIndoorsInstance.filter(null);
        });
    }

    hideResultFrom() {
        this.setState({
            display_from: 'none'
        })
        const miMapElement = document.querySelector('mi-map-googlemaps');
        miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
            // Filter map to only display search results
            mapsIndoorsInstance.filter(null);
        });
    }

    hideResultFromMobile() {
        this.setState({
            display_from_mobile: 'none'
        })
        const miMapElement = document.querySelector('mi-map-googlemaps');
        miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
            // Filter map to only display search results
            mapsIndoorsInstance.filter(null);
        });
    }

    hideResultTo() {
        this.setState({
            display_to: 'none'
        })
        const miMapElement = document.querySelector('mi-map-googlemaps');
        miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
            // Filter map to only display search results
            mapsIndoorsInstance.filter(null);
        });
    }

    hideResultToMobile() {
        this.setState({
            display_to_mobile: 'none'
        })
        const miMapElement = document.querySelector('mi-map-googlemaps');
        miMapElement.getMapsIndoorsInstance().then((mapsIndoorsInstance) => {
            // Filter map to only display search results
            mapsIndoorsInstance.filter(null);
        });
    }

    changeStepVisibility(e, index) {
        e.stopPropagation()

        let vis = []
        vis = this.state.visibility
        vis[index] = !this.state.visibility[index]

        this.setState({
            visibility: vis
        })

    }

    translate(text) {
        switch (text) {
            case 'straight':
                return 'Continua Dritto'
            case "turn-left":
                return 'Gira a Sinistra e continua'
            case "uturn-left":
                return 'Gira a Sinistra e continua'
            case "turn-slight-left":
                return 'Gira leggermente a sinistra'
            case "turn-slight-right":
                return 'Gira leggermente a destra'
            case "turn-right":
                return 'Gira a Destra e continua'
            default:
                return "Altro"
        }
    }

    getIcon(text) {

        switch (text) {
            case 'straight':
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#c4000cab" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                </svg>
            case "turn-left":
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#c4000cab" class="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                </svg>
            case "uturn-left":
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#c4000cab" class="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                </svg>
            case "turn-slight-right":
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#c4000cab" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg>
            case "turn-slight-left":
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#c4000cab" class="bi bi-chevron-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                </svg>
            case "turn-right":
                return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#c4000cab" class="bi bi-arrow-90deg-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z" />
                </svg>
            default:
                return "Altro"
        }
    }

    getSteps(index) {
        return this.state.istruzioni[index].steps[0].steps.map((s) =>
            <div className="flex step_row" >
                <div className="step_icon">
                    {this.getIcon(s.maneuver)}
                </div>
                <div className="step_details">
                    <div className="step_text">
                        {this.translate(s.maneuver)}
                    </div>
                    <div className="step_distance">
                        {s.distance.text}
                    </div>
                </div>
            </div>
        )
    }

    getStepsMobile(index) {
        return this.state.istruzioni[index].steps[0].steps.map((s) =>
            <div className="flex step_row_mobile" >
                <div className="step_icon">
                    {this.getIcon(s.maneuver)}
                </div>
                <div className="step_details">
                    <div className="step_text">
                        {this.translate(s.maneuver)}
                    </div>
                    <div className="step_distance">
                        {s.distance.text}
                    </div>
                </div>
            </div>
        )
    }

    fullScreen() {
        this.setState({
            fullscreen: !this.state.fullscreen,
            hidescreen: false
        })
    }

    hideScreen() {
        this.setState({
            hidescreen: !this.state.hidescreen,
            fullScreen: false
        })
    }

    goBack() {
        /*  setTimeout(function () { //Start the timer
              reactDom.render(<Homepage sel={'palazzo_mancini'} from={'map'} office={this.props.office} user={this.props.user} />, document.getElementById('root'))
          }.bind(this), 550)*/
        this.props.select('offices', this.props.office)
        this.props.setHidden(false)
    }

    goBackMenu() {

        this.setState({
            menu_1: true,
            menu_2: false
        })
    }

    goNextMenu() {
        this.setState({
            menu_2: true,
            menu_1: false
        })
    }

    selectStepContainer(val) {
        this.setLoading(true).then(() => {
            this.getFloorRoute(val)
            setTimeout(function () {
                this.setState({
                    step_container_selected: val
                })
                this.setLoading(false)
            }.bind(this), 1000)
        })
    }

    goTo() {
        if (this.state.selectedCoordinates !== '') {
            this.setState({
                text_to: this.state.selectedCoordinates.name,
                text: '',
                destinationCoordinate: this.state.selectedCoordinates,
                display_to_mobile: 'none',
                directions: true,
                menu_sel: false,
                menu_1: true
            })
            if (this.state.originLocationCoordinate !== '')
                this.getRoute(this.state.selectedCoordinates)
        }
        else {
            this.setState({
                text_to: '',
                display_to_mobile: 'none',
                text: '',
                directions: true,
                menu_sel: false,
                menu_1: true
            })
        }
    }

    goToInit() {
        this.setState({
            directions: false,
            text_to: '',
            text: ''
        })
    }

    reset() {
        const miMapElement = document.querySelector('mi-map-googlemaps');
        const miSearchFromInput = document.querySelector('#search_from> input[type=search]')
        miSearchFromInput.value = ''
        const miSearchToInput = document.querySelector('#search_to> input[type=search]')
        miSearchToInput.value = ''
        this.setState({
            directions: false,
            text_from: '',
            text_to: '',
            text: '',
            showSelected: false,
            showSteps: false,
            originLocationCoordinate: '',
            destinationCoordinate: '',
            selectedCoordinates: '',
            istruzioni: [],
            visibility: [],
            activeFloor: "0"

        })

        miMapElement.getMapInstance().then((mapInstance) => {
            mapInstance.setCenter({
                lat: 41.8976038, lng: 12.4820609
            });
            mapInstance.setZoom(20)


        });
        let routes = this.state.miDirectionsRendererInstance

        routes.setRoute(null);

        miMapElement.getMapsIndoorsInstance().then(mapsIndoorsInstance => {
            mapsIndoorsInstance.setFloor(0)
        })
    }

    resetMobile() {
        const miMapElement = document.querySelector('mi-map-googlemaps');
        const miSearchFromMobileInput = document.querySelector('#search_from_mobile> input[type=search]')
        miSearchFromMobileInput.value = ''
        const miSearchToMobileInput = document.querySelector('#search_to_mobile> input[type=search]')
        miSearchToMobileInput.value = ''
        this.setState({
            directions: false,
            text_from: '',
            text_to: '',
            text: '',
            menu_2: false,
            menu_1: true,
            iter: false,
            showSelected: false,
            showSteps: false,
            originLocationCoordinate: '',
            destinationCoordinate: '',
            selectedCoordinates: '',
            istruzioni: [],
            visibility: [],
            activeFloor: "0"
        })

        miMapElement.getMapInstance().then((mapInstance) => {
            mapInstance.setCenter({
                lat: 41.8976314, lng: 12.481946
            });
            mapInstance.setZoom(20)


        });
        let routes = this.state.miDirectionsRendererInstance

        routes.setRoute(null);

        miMapElement.getMapsIndoorsInstance().then(mapsIndoorsInstance => {
            mapsIndoorsInstance.setFloor(0)
        })
    }

    goToSearchMobile() {
        const miMapElement = document.querySelector('mi-map-googlemaps');
        const miSearchFromMobileInput = document.querySelector('#search_from_mobile> input[type=search]')
        miSearchFromMobileInput.value = ''
        const miSearchToMobileInput = document.querySelector('#search_to_mobile> input[type=search]')
        miSearchToMobileInput.value = ''
        this.setState({
            menu_sel: false,
            menu_1: true,
            menu_2: false,
            directions: true,
            text_from: '',
            text_to: '',
            text: '',
            showSteps: false,
            istruzioni: [],
            visibility: [],
            originLocationCoordinate: '',
            destinationCoordinate: ''
        })

        /*  miMapElement.getMapInstance().then((mapInstance) => {
              mapInstance.setCenter({
                  lat: 41.8976038, lng: 12.4820609
              });
              mapInstance.setZoom(20)
          });*/
        let routes = this.state.miDirectionsRendererInstance

        routes.setRoute(null);

        miMapElement.getMapsIndoorsInstance().then(mapsIndoorsInstance => {
            mapsIndoorsInstance.setFloor(0)
        })
    }

    goToSelect() {
        if (this.state.selectedCoordinates === '') {
            this.setState({
                menu_sel: false,
                menu_1: false,
                menu_2: false,
                directions: false
            })
        } else {
            this.setState({
                menu_sel: true,
                menu_1: false,
                menu_2: false,
                directions: true
            })
        }
    }

    go() {
        if (this.state.istruzioni.length > 0) {
            this.setState({
                menu_sel: false,
                menu_1: false,
                menu_2: true,
                showSteps: true,
                showSelected: false,
                directions: true
            })
        }

    }

    render() {
        /* bottom: 200px;*/

        let miListStyle = "width:84%; background-color: #ffffff;  overflow-y: hidden; font-family: 'GucciSans-Book';  margin-left: 0; padding-left: 10px; margin-top: 11px; "
        let miListStyleFrom = "width:100%; background-color: #ffffff;  overflow-y: hidden; font-family: 'GucciSans-Book';   max-height: 50vh;  overflow-y: auto; position:relative; margin-top: -55px;display: " + this.state.display_from
        let miListStyleTo = "width:100%; background-color: #ffffff;  overflow-y: hidden; font-family: 'GucciSans-Book'; max-height: 50vh;  overflow-y: auto;  position:relative; margin-top: 11px;display: " + this.state.display_to
        let miListStyleFromMobile = "width: 100%;background-color: #ffffff;font-family: 'GucciSans-Book'; overflow-y: auto;  position:absolute;bottom:200px;     max-height: 165px;  height: -moz-fit-content;left: 0;border: 1px solid #b9b9b9; border-top-left-radius: 10px;  border-top-right-radius: 10px;display: " + this.state.display_from_mobile
        let miListStyleToMobile = "width: 100%;background-color: #ffffff;font-family: 'GucciSans-Book'; overflow-y: auto; position:absolute;   bottom: 200px;   max-height: 165px;left:0;    height:  -moz-fit-content ; border: 1px solid #b9b9b9; border - top - left - radius: 10px; border - top - right - radius: 10px; display: " + this.state.display_to_mobile

        let resultStyle = "display: " + this.state.display + '; z-index: ' + this.state.z_index
        let steps = 'no'
        let steps_mobile = 'no'

        steps = this.state.istruzioni.map((i, index) =>
            <div className={this.state.step_container_selected == index ? "step_container_selected" : "step_container"} onClick={() => this.selectStepContainer(index)}>

                <div className="step_title">    {index == 0 ? this.state.text_from : (index === this.state.istruzioni.length - 1 ? this.state.text_to : (i.steps[0].highway === 'elevator' ? "Prendi l'ascensore fino al piano" + i.steps[0].end_location.floor_name : 'Cammina'))}</div>
                {this.state.visibility[index]}

                <div className="flex w-100 ">
                    <img className="walk" src='https://icon-library.com/images/walk-icon/walk-icon-22.jpg' />
                    <div className="step_summary" id={'step' + index}>
                        <div>Camminare</div>
                        <div>{i.duration.text} {i.distance.text}  </div>
                    </div>

                    <div className="expand_icon">
                        {this.state.visibility[index] == true ?
                            <svg onClick={(e) => this.changeStepVisibility(e, index)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-down pointer" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            </svg>
                            : <svg onClick={(e) => this.changeStepVisibility(e, index)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-chevron-up pointer" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                            </svg>}
                    </div>
                </div>
                {this.state.visibility[index] ? <div id={'container_step' + index}>
                    {this.getSteps(index)}
                </div> : ''}
            </div>)

        steps_mobile = this.state.istruzioni.map((i, index) =>
            <div className={this.state.step_container_selected == index ? "step_container_selected_mobile" : "step_container_mobile"} onClick={() => this.selectStepContainer(index)}>

                <div className="step_title">    {index == 0 ? this.state.text_from : (index === this.state.istruzioni.length - 1 ? this.state.text_to : (i.steps[0].highway === 'elevator' ? "Prendi l'ascensore fino al piano" + i.steps[0].end_location.floor_name : 'Cammina'))}</div>
                {this.state.visibility[index]}
                <div className="flex w-80 ">
                    <img className="walk ml-2" src='https://icon-library.com/images/walk-icon/walk-icon-22.jpg' />
                    <div className="step_summary_mobile" id={'step' + index}>
                        <div>Camminare</div>
                        <div>{i.duration.text} {i.distance.text}  </div>
                    </div>

                    <div className="expand_icon_mobile">
                        {this.state.visibility[index] == true ?
                            <svg onClick={(e) => this.changeStepVisibility(e, index)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" class="bi bi-chevron-down pointer" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                            </svg>
                            : <svg onClick={(e) => this.changeStepVisibility(e, index)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="black" class="bi bi-chevron-up pointer" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                            </svg>}
                    </div>
                </div>
                {this.state.visibility[index] ? <div className="cs" id={'container_step' + index}>
                    {this.getStepsMobile(index)}
                </div> : ''}
            </div>)

        let duration = 0
        let distance = 0
        this.state.istruzioni.map((i) => duration += i.duration.value)
        this.state.istruzioni.map((i) => distance += i.distance.value)
        parseInt(duration)

        return (
            <div className="map_container ">
                <div className="step_bar">
                    <div className="directions_container">
                        <div className="direction_header">
                            <svg onClick={this.state.showSteps ? () => this.reset() : () => this.goBack()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left mt-2 pointer" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            <div className="gucci_text" Style={'font-size:19px'}>{this.props.name}</div>
                        </div>
                        <div className="directions_search">
                            <div className="content">
                                <mi-search
                                    Style="    width: 94%;
                                    font-family: 'GucciSans-Book';
                                    height: 46px;
                                    margin-bottom: 9px;"
                                    mapsindoors="true"
                                    placeholder={this.getTranslationByKey("MAPSINDOOR.MAP.FROM")}
                                    id="search_from"
                                    value={this.state.text_from}>
                                </mi-search>

                                <mi-search
                                    Style="    width: 94%;
                                    font-family: 'GucciSans-Book';
                                    height: 46px;
                                    margin-bottom: 9px;"
                                    mapsindoors="true"
                                    placeholder={this.getTranslationByKey("MAPSINDOOR.MAP.TO")}
                                    id="search_to"
                                    value={this.state.text_to}>
                                </mi-search>

                                <mi-list
                                    Style={miListStyleFrom}
                                    scroll-buttons-enabled="false"
                                    id="list_from"
                                    scroll-length="200">
                                </mi-list>

                                <mi-list
                                    Style={miListStyleTo}
                                    scroll-buttons-enabled="false"
                                    scroll-length="200"
                                    id="list_to">
                                </mi-list>
                            </div>
                        </div>

                    </div>
                    <div className="direction_selected">

                        {this.state.showSelected ?
                            <div className="map_info_container">

                                <div className="map_info_description">
                                    <div className="map_info_name flex">
                                        {this.state.istruzioni.length > 0 ? <svg onClick={() => this.go()} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                        </svg> : ''}
                                        <div> {this.state.selectedCoordinates.name}</div>
                                    </div>
                                </div>
                                <div className="map_info_description">

                                    <div className="map_info_icon"> i</div>
                                    {this.state.selectedCoordinates.info.room_id}</div>

                                {this.state.selectedCoordinates.info.description ? <div className="map_info_description mt-4 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="#8d98aa" class="bi bi-file-earmark-text-fill map_description_icon mt-1" viewBox="0 0 16 16">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z" />
                                    </svg>
                                    {this.state.selectedCoordinates.info.description}
                                </div>
                                    : ''}

                                <div className="map_info_description ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#8d98aa" class="bi bi-building map_building_icon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                                        <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                                    </svg>
                                    <div className="map_building_info">
                                        {this.getTranslationByKey("MR.FILTER.ROOMDETAIL.LEVEL")} {this.state.selectedCoordinates.info.floor_name}
                                        <div>{this.state.selectedCoordinates.info.building}</div>
                                        <div>{this.state.selectedCoordinates.info.venue}</div>
                                    </div>
                                </div>
                                <div className="go_to_container">
                                    <div className="go_to_button" onClick={() => this.goTo()}>
                                        <div className="go_to_button_icon"></div>
                                        {this.getTranslationByKey("MAPSINDOOR.MAP.DIRECTIONS")}
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                    {this.state.loading ? <div className="loading"> <span class="loader"></span> </div> : (

                        this.state.showSteps ?
                            (this.state.iter ?
                                <div className="step_containers">
                                    <div className="steps_duration">
                                        {parseInt(duration / 60) > 1 ? parseInt(duration / 60) + 'mins a destinazione (' + distance + ' m)' : parseInt(duration / 60) + 'min a destinazione (' + distance + ' m)'}
                                    </div>
                                    {steps}
                                    <div className="change_buttons">
                                        {this.state.step_container_selected === 0 ? <div className="prev_button_disabled"></div> : <div className="prev_button" onClick={() => this.selectStepContainer(this.state.step_container_selected - 1)}> <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                        </svg>{this.getTranslationByKey("MAPSINDOOR.MAP.PREVIOUS")} </div>}
                                        {this.state.step_container_selected === this.state.istruzioni.length - 1 ? <div className="next_button_disabled"></div> : <div className="next_button" onClick={() => this.selectStepContainer(this.state.step_container_selected + 1)}>{this.getTranslationByKey("MAPSINDOOR.MAP.NEXT")} <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                        </svg></div>}
                                    </div>
                                </div>
                                : <div className="steps_duration">{this.getTranslationByKey("MAPSINDOOR.MAP.NOROUTES")}</div>)
                            : '')}
                </div>

                {/*MOBILE*/}

                {this.state.loading ? <div className="loading"> <span class="loader"></span> </div> : ''}
                <div className={this.state.directions ? "step_bar_mobile step_bar_mobile_up" : "step_bar_mobile"}>
                    <div className={this.state.menu_sel ? "directions_container_start " : "directions_container_mobile_disabled"}>
                        <div className="direction_header_mob">
                            <div className="back_mobile_button">
                                {this.state.istruzioni.length > 0 ?
                                    <svg onClick={() => this.go()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left mt-2 pointer" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                    </svg>
                                    : <svg onClick={() => this.goToInit()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left mt-2 pointer" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                    </svg>}
                            </div>

                        </div>
                        <div className="map_info_name_mobile">{this.state.selectedCoordinates.name}</div>
                        {this.state.selectedCoordinates ?
                            <div className="directions_search_mobile">
                                <div className="map_info_container_mobile">
                                    <div className="map_info_container_mob">

                                        <div className="map_info_description  ">
                                            {this.state.selectedCoordinates.info.description ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#8d98aa" class="bi bi-file-earmark-text-fill map_description_icon" viewBox="0 0 16 16">
                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z" />
                                                </svg> : ''}
                                            {this.state.selectedCoordinates.info.description ? this.state.selectedCoordinates.info.description : ''}

                                        </div>

                                        <div className="map_info_description ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#8d98aa" class="bi bi-building map_building_icon" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z" />
                                                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                                            </svg>
                                            <div className="map_building_info">
                                                {this.getTranslationByKey("MR.FILTER.ROOMDETAIL.LEVEL")} {this.state.selectedCoordinates.info.floor_name}, {this.state.selectedCoordinates.info.building}, {this.state.selectedCoordinates.info.venue}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="go_to_container_mobile">
                                    <div className="go_to_button" onClick={() => this.goTo()}>
                                        <div className="go_to_button_icon"></div>
                                        {this.getTranslationByKey("MAPSINDOOR.MAP.DIRECTIONS")}
                                    </div>
                                </div>
                            </div> : ''}
                    </div>

                    <div className={this.state.menu_1 ? "directions_container_start " : "directions_container_disabled"}>
                        <div className="direction_header">
                            <div className="back_mobile_button">
                                <svg onClick={() => this.goToSelect()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left mt-2  pointer" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                            </div>
                        </div>

                        <div className="directions_search_mobile">
                            <div className="content">
                                <mi-search
                                    Style="    width: 70%;
                                    font-family: 'GucciSans-Book';
                                    height: 46px;
                                    margin-bottom: 9px;"
                                    mapsindoors="true"
                                    placeholder={this.getTranslationByKey("MAPSINDOOR.MAP.FROM")}
                                    id="search_from_mobile"
                                    value={this.state.text_from}>
                                </mi-search>

                                <mi-list
                                    Style={miListStyleFromMobile}
                                    scroll-buttons-enabled="false"
                                    id="list_from_mobile"
                                    scroll-length="200">
                                </mi-list>

                                <mi-search
                                    Style="    width: 70%;
                                    font-family: 'GucciSans-Book';
                                    height: 46px;
                                    margin-bottom: 9px;"
                                    mapsindoors="true"
                                    placeholder={this.getTranslationByKey("MAPSINDOOR.MAP.TO")}
                                    id="search_to_mobile"
                                    value={this.state.text_to}>
                                </mi-search>

                                <mi-list
                                    Style={miListStyleToMobile}
                                    scroll-buttons-enabled="false"
                                    scroll-length="200"
                                    id="list_to_mobile">
                                </mi-list>
                            </div>
                        </div>
                    </div>

                    {this.state.menu_2 ?
                        (this.state.iter ? <div className={this.state.fullscreen ? "step_containers_mobile full_screen" :
                            (this.state.hidescreen ? "step_containers_mobile hide_screen" : "step_containers_mobile")}>
                            <div className="directions_container_mobile ">
                                <div className="direction_header_mobile">
                                    <svg onClick={() => this.goToSearchMobile()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left mt-2 " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                    </svg>
                                </div>

                                <div className="steps_duration_mobile">
                                    <div> {parseInt(duration / 60) > 1 ? parseInt(duration / 60) + 'mins a destinazione (' + distance + ' m)' : parseInt(duration / 60) + 'min a destinazione'}</div>
                                    <div> ( {distance}  m)</div>
                                </div>

                                <div className="change_buttons_mobile">
                                    {this.state.step_container_selected === 0 ?
                                        <div className="prev_button_disabled"></div> : <div className="prev_button_mobile" onClick={() => this.selectStepContainer(this.state.step_container_selected - 1)}> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                        </svg><div className="prev_text_mobile">{this.getTranslationByKey("MAPSINDOOR.MAP.PREVIOUS")} </div></div>}
                                    {this.state.step_container_selected === this.state.istruzioni.length - 1 ? <div className="next_button_disabled"></div> : <div className="next_button_mobile" onClick={() => this.selectStepContainer(this.state.step_container_selected + 1)}>
                                        <div className="next_text_mobile">{this.getTranslationByKey("MAPSINDOOR.MAP.NEXT")}</div> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                        </svg></div>}
                                    <div className="screen_settings flex"> <svg onClick={() => this.fullScreen()} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrows-fullscreen  pointer " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                                    </svg>
                                        {!this.state.fullscreen ? (this.state.hidescreen ?
                                            <svg onClick={() => this.hideScreen()} xmlns="http://www.w3.org/2000/svg" width="25" height="28" fill="currentColor" class="bi bi-arrow-bar-up pointer ml-1" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                            : <svg onClick={() => this.hideScreen()} xmlns="http://www.w3.org/2000/svg" width="25" height="28" fill="currentColor" class="bi bi-arrow-bar-down pointer ml-1" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                                            </svg>) : ''}
                                    </div>
                                </div>
                            </div> <div className="steps_mobile">  {steps_mobile}</div>

                        </div> : <div className="step_containers_mobile">
                            <div className="directions_container_start">
                                <div className="direction_header_mobile">
                                    <svg onClick={() => this.goBackMenu()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left mt-2 " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                    </svg>

                                </div> <div className="steps_duration">{this.getTranslationByKey("MAPSINDOOR.MAP.NOROUTES")} </div></div></div>)
                        : ''}
                </div>

                <div className="grid w-100 map_contain">
                    <div id="map" class="map_style"></div>

                    <div className="content">
                        <div>
                            <div className=" map_search map_search_mobile ">
                                {/*<div className="back_button_map" onClick={() => this.goBack()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-left-short pointer" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                </svg>
                                        </div>*/}
                                <mi-search
                                    id="search_component"
                                    Style="width: 87%;
                                font-family: 'GucciSans-Book';
                                justify-self: center;"
                                    mapsindoors="true"
                                    placeholder={this.getTranslationByKey("DESKBOOKING.MAP.SEARCH")}
                                    value={this.state.text}>
                                </mi-search>
                            </div>
                            <div className="map_search_results" Style={resultStyle}>
                                <mi-list
                                    id='search_list'
                                    Style={miListStyle}
                                    scroll-buttons-enabled="false"
                                    scroll-length="200">
                                </mi-list>
                            </div>
                        </div>

                        <mi-map-googlemaps class="map_style"
                            gm-api-key={this.props.info.gKey}
                            mi-api-key={this.props.info.mpKey}
                            floor-selector-control-position="TOP_RIGHT" >
                        </mi-map-googlemaps>
                    </div>

                    {!this.state.directions ? <div className="direction_button" onClick={() => this.goTo()}>
                        <div className="direction_button_text">
                            {this.getTranslationByKey("MAPSINDOOR.MAP.DIRECTIONS")}
                        </div>
                        <div id="directions_img" >
                        </div>
                    </div> : ''}
                </div>
            </div >
        )
    }
}
export default MapComponent