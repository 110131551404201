import React from 'react';
import { TransformWrapper, TransformComponent, } from "react-zoom-pan-pinch";
import { Dropdown, } from 'react-bootstrap';
import $ from "jquery";
import Switch from 'react-ios-switch';
import '../App.css';
import '../BootstapOfficeDetail.css';
import { Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Swipe } from "react-swipe-component";
import { DeskService } from '../Services/Services';

import moment from "moment";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';

class OfficeDetail extends React.Component {
    constructor(props) {
        super(props);
        let now = new Date();

        if (props.date !== null && props.date !== undefined)
            now = props.date
        this.state = {
            userID: props.userInfo.id,
            date: now,
            selectedDate: now,
            selectedLocation: props.userInfo.modules.desk_booking.location ? props.userInfo.modules.desk_booking.location : '',
            selectedFloor: props.userInfo.modules.desk_booking.floor,
            selectedTime: "All Day",
            selectedRoomAvailability: [],
            locations: [],
            Otherlocations: [],
            floors: [],
            OtherFloors: [],
            desks: [],
            desksHTML: [],
            showHours: false,
            reservationShow: false,
            vacationShow: false,
            selectedDeskID: null,
            selectedSituation: "In office",
            showFloors: 1,
            startVacation: new Date(props.date),
            endVacation: new Date(props.date),
            canteen: true,
            away: false,
            holiday: false,
            infoBoxText: "Please, try again later",
            loading: false
        }
        this.setLocations = this.setLocations.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.setFloors = this.setFloors.bind(this)
        this.setSelectedFloor = this.setSelectedFloor.bind(this)
        this.setReservation = this.setReservation.bind(this)
        this.setOtherLocations = this.setOtherLocations.bind(this)
        this.setSelectedLocation = this.setSelectedLocation.bind(this)
        this.setAvailableDeskHTML = this.setAvailableDeskHTML.bind(this)
        this.setRedDeskHTML = this.setRedDeskHTML.bind(this)
        this.setGreyDeskHTML = this.setGreyDeskHTML.bind(this)
        this.setDesks = this.setDesks.bind(this)
        this.setDesksHtml = this.setDesksHtml.bind(this)
        this.setClearFloor = this.setClearFloor.bind(this)
        this.getFloorsFromLocation = this.getFloorsFromLocation.bind(this)
        this.getFirstFloor = this.getFirstFloor.bind(this)
        this.handleCalendarClose = this.handleCalendarClose.bind(this)
        this.getTranslationByKey = this.getTranslationByKey.bind(this)
    };

    componentDidUpdate(nextProps, nextState) {
        if (nextState.desksHTML !== this.state.desksHTML) {
            return true
        }
        if (nextState.desks !== this.state.desks) {
            return true
        }
    }
    getTranslationByKey(key) {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        }
    }
    handleCalendarClose() {
        window.scrollTo(0, 0, 'smooth')
    }
    setDesks(d) {
        let tmp = []
        d.map((des) => {
            tmp.push(des)
        })
        this.setState({
            desks: tmp
        })
    }

    setDesksHtml(d) {
        let tmp = []
        d.map((des) => {
            tmp.push(des)
        })
        this.setState({
            desksHTML: tmp
        })
    }

    setLocations(loc) {
        let tmp = []
        loc.map((l) => {
            tmp.push(l)
        })
        this.setState({
            locations: tmp
        })
    }

    setFloors(f) {
        if (f !== undefined && f[0] !== undefined) {
            let tmp = [];
            this.state.floors.map((fl) => {
                tmp.push(fl)
            })
            f.map((fl) => {

                tmp.push(fl)
            })
            if (this.props.userInfo.modules.desk_booking.location && this.state.selectedLocation.id === this.props.userInfo.modules.desk_booking.location.id) {
                this.setState({
                    floors: tmp
                })
                this.fetchBookings(this.state.selectedDate, this.props.userInfo.modules.desk_booking.floor, this.state.selectedTime)
            } else {
                this.setState({
                    floors: tmp,
                    selectedFloor: f[0]
                })
                this.fetchBookings(this.state.selectedDate, f[0], this.state.selectedTime)
            }
        }
    }

    setClearFloor(f) {
        this.setState({
            floors: []
        })
    }

    setSelectedFloor(f) {
        this.setState({
            selectedFloor: f
        })
    }

    async setLoading(val) {
        this.setState({
            loading: val
        })
    }

    componentDidMount() {
        this.setLoading(true)
        let ds = new DeskService();
        ds.getLocations().then((loc) => {
            var locations = loc
            this.setLocations(locations);

            for (let i = 0; i < locations.length; i++) {
                ds.getLocation(locations[i].id).then((flr) => {
                    if (flr[0]) {
                        this.setFloors(flr);
                    }
                })
            }
            this.fetchBookings(this.state.selectedDate, this.state.selectedFloor, this.state.selectedTime)
            this.setLoading(false)
        })
    }

    formatDate(year, month, day) {
        let m = ''
        switch (month) {
            case 1:
                m = 'Jan'
                break;
            case 2:
                m = 'Feb'
                break;
            case 3:
                m = 'Mar'
                break;
            case 4:
                m = 'Apr'
                break;
            case 5:
                m = 'May'
                break;
            case 6:
                m = 'Jun'
                break;
            case 7:
                m = 'Jul'
                break;
            case 8:
                m = 'Aug'
                break;
            case 9:
                m = 'jan'
                break;
            case 10:
                m = 'jan'
                break;
            case 11:
                m = 'jan'
                break;
            case 12:
                m = 'jan'
                break;
        }

        return m + ' ' + day + ',' + ' ' + year
    }

    writeButtonDates = () => {
        let element = [];
        var date = new Date(this.state.date);
        var yesterday = new Date(date.getTime());
        yesterday.setDate(date.getDate() - 1);
        let twoDaysAgo = new Date(date.getTime());
        twoDaysAgo.setDate(date.getDate() - 2);

        var buttonDateWritten_yesterday = yesterday.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
        let toStringButtonDate_yesterday = yesterday.toISOString().split('T')[0]
        var buttonDateWritten_twoDaysAgo = twoDaysAgo.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
        let toStringButtonDate_twoDaysAgo = twoDaysAgo.toISOString().split('T')[0]

        element.push(<div className="dateButton" id={"select" + toStringButtonDate_twoDaysAgo} key={twoDaysAgo} onClick={() => this.selectBookingDate(toStringButtonDate_twoDaysAgo)}>{buttonDateWritten_twoDaysAgo}</div>);
        element.push(<div className="dateButton" id={"select" + toStringButtonDate_yesterday} key={yesterday} onClick={() => this.selectBookingDate(toStringButtonDate_yesterday)}>{buttonDateWritten_yesterday}</div>);


        for (let i = 0; i < 14; i++) {
            let buttonDate = new Date(this.state.date);
            buttonDate.setDate(buttonDate.getDate() + i);
            var buttonDateWritten = buttonDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
            let toStringButtonDate = buttonDate.toISOString().split('T')[0]
            if (i == 0) {
                element.push(<div className="dateButton selectedDateButton" id={"select" + toStringButtonDate} key={buttonDate} onClick={() => this.selectBookingDate(toStringButtonDate)}>{buttonDateWritten}</div>);
            }
            else {
                element.push(<div className="dateButton" id={"select" + toStringButtonDate} key={buttonDate} onClick={() => this.selectBookingDate(toStringButtonDate)}>{buttonDateWritten}</div>);
            }
        }

        return element;
    }

    writeButtonDates2 = () => {
        let element = [];

        for (let i = 0; i < 14; i++) {
            let buttonDate = new Date(this.state.date);
            buttonDate.setDate(buttonDate.getDate() + i);
            var buttonDateWritten = buttonDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });

            if (i == 0) {
                element.push(<div className="dateButton selectedDateButton" id={"select" + buttonDate.toISOString().split('T')[0]} key={buttonDate} onClick={() => this.selectBookingDate(buttonDate.toISOString().split('T')[0])}>{buttonDateWritten}</div>);
            }
            else {
                element.push(<div className="dateButton" id={"select" + buttonDate.toISOString().split('T')[0]} key={buttonDate} onClick={() => this.selectBookingDate(buttonDate.toISOString().split('T')[0])}>{buttonDateWritten}</div>);
            }
        }

        return element;
    }

    writeButtonDatesInvisible = () => {
        let element = [];

        for (let i = 0; i < 14; i++) {
            let buttonDate = new Date(this.state.date);
            buttonDate.setDate(buttonDate.getDate() + i);
            let toStringButtonDate = buttonDate.getFullYear().toString() + "-" + (buttonDate.getMonth() + 1).toString() + "-" + buttonDate.getDate().toString()
            buttonDate.setDate(buttonDate.getDate() + i);
            var buttonDateWritten = buttonDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });

            if (i == 0) {
                element.push(<div className="dateButton selectedDateButton" id={"select" + toStringButtonDate} key={buttonDate} onClick={() => this.selectBookingDate(toStringButtonDate)}>{buttonDateWritten}</div>);
            }
            else {
                element.push(<div className="dateButton" id={"select" + toStringButtonDate} key={buttonDate} onClick={() => this.selectBookingDate(toStringButtonDate)}>{buttonDateWritten}</div>);
            }
        }

        return element;
    }


    writeButtonDatesRange = () => {

    }

    setAvailableDeskHTML(id, top, left, ratio) {
        var deskStyle = {
            "top": top + "%",
            "left": left + "%",
            "background": "#07524b",
            "color": "white",
            "width": "1.5%",
            "height": 1.5 / ratio + "%",
            "textAlign": "center",
            "verticalAlign": "middle",
        }

        var deskStyle_2 = {
            "top": top + "%",
            "left": left + "%",
            "background": "#07524b",
            "color": "white",
            "width": "1.5%",
            "height": 1.5 / ratio + "%",
            "textAlign": "center",
            "verticalAlign": "middle",
            "cursor": "auto"
        }

        let today = new Date().setHours(0, 0, 0, 0)
        let selectedDate = new Date(this.state.selectedDate).setHours(0, 0, 0, 0)

        if (selectedDate >= today) {
            this.setState({
                desksHTML: this.state.desksHTML.concat(<div className="Desks" key={id} style={deskStyle} onClick={() => this.createReservation(id)}>+</div>)
            })
        } else {
            this.setState({
                desksHTML: this.state.desksHTML.concat(<div className="Desks" key={id} style={deskStyle_2}>+</div>)
            })
        }

    }

    setRedDeskHTML(id, top, left, ratio, start) {
        var deskStyle = {
            "top": top + "%",
            "left": left + "%",
            "background": "#9a1b23",
            "padding": "0.3% 0.2%",
            "width": "1.5%",
            "height": 1.5 / ratio + "%",
            "text-align": "center",
            "vertical-align": "middle",
        }

        this.setState({ desksHTML: this.state.desksHTML.concat(<div className="Desks" key={id} style={deskStyle} onClick={() => this.viewDeskBooker(id)}>{start}</div>) })
    }

    setGreyDeskHTML(id, top, left, ratio) {
        var deskStyle = {
            "top": top + "%",
            "left": left + "%",
            "background": "#adadad",
            "color": "#adadad",
            "width": "1.5%",
            "height": 1.5 / ratio + "%",
            "text-align": "center",
            "vertical-align": "middle",
        }
        this.setState({ meet: this.state.desksHTML.concat(<div className="Desks" key={id} style={deskStyle}>+</div>) })
    }

    fetchBookings = (date, floor, selectedTime) => {
        this.setState({ loading: true })
        this.setState({
            selectedDate: date,
            selectedFloor: floor,
            time: selectedTime
        });


        var previousDateSelected = document.getElementsByClassName("selectedDateButton");

        for (let i = 0; i < previousDateSelected.length; i++) {
            previousDateSelected[0].classList.remove('selectedDateButton');
        }
        if (document.getElementById("select" + date))
            document.getElementById("select" + date).classList.add('selectedDateButton');

        let toSend = JSON.stringify({
            "date": date,
            "floor_id": floor.id,
            "time": selectedTime
        })

        let ds = new DeskService()
        ds.getFloorInfo(toSend).then((data) => {
            this.setDesks(data.desks);
            let tmp = []
            this.setDesksHtml(tmp)

            data.desks.map((dsk) => {

                let top = (dsk.y_pos * 100) / this.state.selectedFloor.height;
                let left = (dsk.x_pos * 100) / this.state.selectedFloor.width;

                let ratio = this.state.selectedFloor.height / this.state.selectedFloor.width

                if ((selectedTime === "All Day" && dsk.morning_available && dsk.afternoon_available) || (selectedTime === "Morning" && dsk.morning_available) || (selectedTime === "Afternoon" && dsk.afternoon_available)) {
                    this.setAvailableDeskHTML(dsk.desk_id, top, left, ratio)

                }
                else if ((selectedTime === "Morning" && !dsk.morning_available) || (selectedTime === "Afternoon" && !dsk.afternoon_available)) {
                    let all_day = 'All day'
                    if (dsk.bookers[0]) {
                        if (selectedTime.toUpperCase() === dsk.bookers[0].time.toUpperCase() || dsk.bookers[0].time.toUpperCase() === all_day.toUpperCase())
                            if (dsk.bookers[0]) {
                                if (selectedTime)
                                    this.setRedDeskHTML(dsk.desk_id, top, left, ratio, dsk.bookers[0].firstname[0] + dsk.bookers[0].lastname[0])
                                else if (dsk.bookers[1] && selectedTime.toUpperCase() === dsk.bookers[1].time.toUpperCase())
                                    this.setRedDeskHTML(dsk.desk_id, top, left, ratio, dsk.bookers[1].firstname[0] + dsk.bookers[1].lastname[0])
                            }
                    }
                }
                else if (selectedTime === "All Day" && (!dsk.morning_available || !dsk.afternoon_available)) {
                    if (dsk.bookers[0])
                        this.setRedDeskHTML(dsk.desk_id, top, left, ratio, dsk.bookers[0].firstname[0] + dsk.bookers[0].lastname[0])

                } else {
                    this.setGreyDeskHTML(dsk.desk_id, top, left, ratio)

                }
            })

        })
        this.setLoading(false)
    }

    selectBookingDate = (date) => {
        this.setState({
            selectedDate: date,
            startVacation: new Date(date),
            endVacation: new Date(date),
            date: date,
            reservationShow: false,
            loading: true
        });

        var previousDateSelected = document.getElementsByClassName("selectedDateButton");

        previousDateSelected[0].classList.value = ' dateButton'
        document.getElementById("select" + date).classList = 'dateButton selectedDateButton';

        this.props.selectedDateBookings(date)
        this.setState({ loading: false })
        this.fetchBookings(date, this.state.selectedFloor, this.state.selectedTime)

    }

    selectLocation = (location) => {
        if (this.state.selectedLocation.id != location.id) {
            this.setState({ loading: true })
            this.setClearFloor()
            let ds = new DeskService()
            ds.getLocation(location.id).then((flr) => {


                if (flr[0]) {
                    this.setFloors(flr);
                }
                this.setState({
                    selectedLocation: location,
                    reservationShow: false
                });

                var status = this.setLocationFloorStatus(location)

                if (this.state.floors.length > 0)
                    //case 1
                    if (status == 1) {
                        /*   var locationFloors = this.state.floors.filter(function (floor) {
                             return floor.location_id == location.id;
                           });
                   */
                        this.setState({
                            selectedFloor: this.state.floors[0],
                            showFloors: 1
                        });

                        this.fetchBookings(this.state.selectedDate, this.state.floors[0], this.state.selectedTime)
                    } else {
                        this.setState({ loading: false })
                    }
                this.setLoading(false)
            })


        }
    }


    setLocationFloorStatus = (location) => {
        //case 1
        if (location.with_desks == true && location.can_book_desks == true) {
            this.setState({
                showFloors: 1
            });
            return 1
        }

        //case 2
        if (location.with_desks == true && location.can_book_desks == false) {
            this.setState({
                showFloors: 2
            });
            return 2
        }
        //case 3
        if (location.with_desks == false && location.can_book_desks == false) {
            this.setState({
                showFloors: 3
            });
        }
        return 3
    }

    selectFloor = (floor) => {
        if (this.state.selectedFloor.id != floor.id) {
            this.setState({ loading: true })

            this.setState({
                selectedFloor: floor
            });

            this.fetchBookings(this.state.selectedDate, floor, this.state.selectedTime)
        }
    }

    selectTime = (time) => {
        if (this.state.selectedTime != time) {
            this.setState({ loading: true })
            /*if there are floors to show */
            this.setState({
                selectedTime: time,
                reservationShow: false
            });
            if (this.state.showFloors == 1) {
                this.fetchBookings(this.state.selectedDate, this.state.selectedFloor, time)
            } else {
                this.setState({ loading: false })
            }

        }
    }

    setOtherLocations(locations) {
        this.setState({ Otherlocations: locations });
    }

    setSelectedLocation(l) {
        this.setState({ selectedLocation: l });
    }

    selectSituation = (situation) => {
        let ds = new DeskService()
        if (this.state.selectedSituation != situation) {
            this.setState({ loading: true })

            this.setState({
                selectedSituation: situation,
                reservationShow: false
            });

            if (situation == "In office") {
                this.setState({
                    canteen: true,
                    away: false,
                    holiday: false
                })


                ds.getLocationv2().then((loc) => {
                    var locations = loc
                    let tmp1 = []
                    tmp1.push(loc)
                    this.setLocations(loc);
                    for (let i = 0; i < locations.length; i++) {
                        this.setLoading(true)
                        ds.getLocation(locations[i].id)
                    }
                    this.setSelectedLocation(locations[0]);
                    this.setLocationFloorStatus(locations[0])
                    let tmp = []
                    this.setClearFloor(tmp)
                    if (this.state.floors[0]) {
                        this.setSelectedFloor(this.state.floors[0])
                        this.fetchBookings(this.state.selectedDate, this.state.floors[0], this.state.selectedTime)
                    }
                    this.setState({ loading: false })
                })
            }
            else {//case vacation
                this.setState({
                    canteen: false,
                    away: false,
                    holiday: true,
                    vacationShow: false,
                    loading: false,
                    selectTime: "All Day"
                })
                this.setState({ showFloors: null })
            }
        }
        this.setState({ loading: false })
    }

    getFirstFloor() {
        return this.state.floors[0]
    }
    requestFloors(locations) {
        let ds = new DeskService()
        for (let i = 0; i < locations.length; i++) {
            ds.getLocation(locations[i].id).then((flr) => {
                if (flr[0]) {
                    this.setFloors(flr);
                }
                this.setLoading(false)
            })
        }
        return this.state.floors
    }

    getFloorsFromLocation(location) {
        var locationFloors = this.state.floors.filter(function (floor) {
            return floor.location_id == location.id;
        });
        return locationFloors
    }

    viewDeskBooker = (deskID) => {
        let bookerName = "";

        for (let i = 0; i < this.state.desks.length; i++) {
            if (this.state.desks[i].desk_id == deskID) {
                if (this.state.desks[i].bookers)
                    if (this.state.desks[i].bookers[0].time.toUpperCase() === this.state.selectedTime.toUpperCase())
                        bookerName = this.state.desks[i].bookers[0].firstname + " " + this.state.desks[i].bookers[0].lastname;
                    else
                        bookerName = this.state.desks[i].bookers[1].firstname + " " + this.state.desks[i].bookers[1].lastname;
            }
        }

        document.getElementById("bookerShow").innerHTML = bookerName;

        $('#bookerShow').fadeIn('slow', function () {
            $(this).delay(5000).fadeOut('slow');
        });
    }

    setReservation(reserv, id) {
        this.setState({
            reservationShow: reserv,
            selectedDeskID: id
        })
    }

    createReservation = (deskID) => {
        this.setReservation(true, deskID)
    }

    confirmReservation = () => {


        let tmp_start_utc = Date.UTC(this.state.startVacation.getFullYear(), this.state.startVacation.getMonth(), this.state.startVacation.getDate());
        let tmp_start_format = new Date(tmp_start_utc).toISOString().split('T')[0]

        let tmp_end_utc = Date.UTC(this.state.endVacation.getFullYear(), this.state.endVacation.getMonth(), this.state.endVacation.getDate());
        let tmp_end_format = new Date(tmp_end_utc).toISOString().split('T')[0]


        let from_date = this.state.selectedSituation != "Day off" ? this.state.selectedDate : tmp_start_format
        let to_date = this.state.selectedSituation != "Day off" ? this.state.selectedDate : tmp_end_format
        let time = this.state.selectedTime
        let desk_id = this.state.selectedDeskID
        let location_id = this.state.selectedSituation != "Day off" ? this.state.selectedLocation.id : null
        let away = this.state.selectedLocation.is_away ? 'true' : 'false'
        let canteen = this.state.canteen ? 'true' : 'false'
        let holiday = this.state.holiday ? 'true' : 'false'

        let toSend = JSON.stringify({
            "user_id": this.state.userID,
            "from_date": from_date,
            "to_date": to_date,
            "time": time,
            "desk_id": desk_id,
            "location_id": location_id,
            "away": away,
            "canteen": canteen,
            "holiday": holiday
        })


        let ds = new DeskService()
        ds.addBooking(toSend).then(() => {

            this.setReservation(false, null)

            this.fetchBookings(this.state.selectedDate, this.state.selectedFloor, this.state.selectedTime)
            this.props.close()
            this.props.update()

        }).catch(() => {
            this.setReservation(false, null)
        }).finally(() => {
            setTimeout(function () { //Start the timer

                this.props.reload()
            }.bind(this), 400)
            this.props.loading()
        })

    }

    switchCanteen = () => {
        this.setState({
            canteen: !this.state.canteen
        })
    }

    onSwipeDownListener = () => {
        var officeDetailComponent = this
        $(".ReservationDetailsfixed").addClass('delete');

        $('.ReservationDetailsfixed').on('animationend', function (e) {
            officeDetailComponent.setState({
                reservationShow: false,
                vacationShow: false
            })

        });

    }

    onCloseDetails() {
        var officeDetailComponent = this
        $(".ReservationDetailsfixed").addClass('delete');

        $('.ReservationDetailsfixed').on('animationend', function (e) {
            officeDetailComponent.setState({ reservationShow: false, vacationShow: false })
        });
    }

    render() {

        const localizer = momentLocalizer(moment)

        let color = 'background: #000000;'

        if (this.state.loading) {

            return (
                <div className='loader_ellipsis'>
                    <div class="lds-ellipsis">
                        <div Style={color}></div>
                        <div Style={color}></div>
                        <div Style={color}></div>
                        <div Style={color}></div>
                    </div>
                </div>)
        }
        else
            return (
                <div style={{ 'padding-bottom': '25px' }}>


                    <div className="SelectedDateBooker">
                        <div className="BookerName" id="bookerShow">BookerName</div>
                    </div>
                    <div className="OfficeDetailInfoContainer">
                        <div className="ListToSelect" id="DateSelect">
                            {this.writeButtonDates()}
                        </div>
                        <div className="ListToSelect" id="LocationSelect">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {this.state.selectedSituation}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.selectSituation("In office")} key="1">In office</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.selectSituation("Day off")} key="3">Day off</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {this.state.selectedSituation != "Day off" ?
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {this.state.selectedLocation.name}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            this.state.locations.map((location) =>
                                                <Dropdown.Item onClick={() => this.selectLocation(location)} key={location.id}>{location.name}</Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                : null}

                            {this.state.showFloors == 1 && this.state.selectedSituation != "Day off" ?
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {this.state.selectedFloor?.name}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            this.state.floors.map((floor) =>
                                                floor.location_id == this.state.selectedLocation.id ?
                                                    <Dropdown.Item onClick={() => this.selectFloor(floor)} key={floor.id}>{floor.name}</Dropdown.Item>
                                                    : null
                                            )
                                        }
                                    </Dropdown.Menu>
                                </Dropdown> :
                                this.state.showFloors == 2 ?
                                    null

                                    :
                                    null}

                            {this.state.selectedSituation != "Day off" ?

                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {this.state.selectedTime}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.selectTime("Morning")} key="1">Morning</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.selectTime("Afternoon")} key="2">Afternoon</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.selectTime("All Day")} key="3">All Day</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                :

                                null
                            }

                        </div>
                    </div>

                    {this.props.userInfo.modules.desk_booking.location && this.state.selectedLocation.is_away ?
                        <div className='flex mess-container'>
                            <div className='required'>*</div >
                            <div className="message"> {this.getTranslationByKey("DESKBOOKING.MAP.TRANSFER.TEXT")}</div>
                        </div>
                        : ''}

                    {this.state.showFloors == 1 ?
                        <div className="OfficeDetailFloorMap">
                            <TransformWrapper>
                                {({ zoomIn, zoomOut, ...rest }) => (
                                    <React.Fragment>
                                        <div class="tools">
                                            <svg onClick={() => zoomIn()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus-circle mb-1 pointer" viewBox="0 0 16 16" >
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                            </svg>
                                            <svg onClick={() => zoomOut()} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-dash-circle pointer" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"></path>
                                            </svg></div>
                                        <TransformComponent>
                                            <div className="FloorBookingDetail">
                                                <img src={this.state.selectedFloor?.image_url} width="100%" className="FloorImage" />
                                                {this.state.desksHTML}
                                            </div>
                                        </TransformComponent>

                                    </React.Fragment>
                                )}
                            </TransformWrapper>
                        </div> : null

                    }

                    {this.state.reservationShow ?
                        <>

                            <Swipe nodeName="div"
                                className="reservationSwipeContainer"
                                detectTouch={true}
                                onSwipedDown={this.onSwipeDownListener}
                            >

                                <div className="ReservationDetailsfixed">


                                    <div className="moreInfoWithMap">

                                        <div className='close_container'><svg onClick={() => this.onCloseDetails()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class=" bi bi-arrow-bar-down" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                                        </svg>
                                        </div>

                                        <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedLocation?.name}|{this.state.selectedFloor?.name}|{this.state.selectedDeskID}</h3></div>

                                        <hr className="reservation_line_separator"></hr>

                                        <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedDate}|{this.state.selectedTime}</h3></div>

                                        <hr className="reservation_line_separator"></hr>

                                        <div className="textPositionLeftReservation">
                                            <h3 className="reservation_textinone">{this.getTranslationByKey("DESKBOOKING.TIME.CANTEEN")} </h3>
                                            <Switch className="Switch "
                                                checked={this.state.canteen}
                                                onChange={() => this.switchCanteen()}
                                                offColor='grey'
                                                onColor='#000000'
                                            />
                                        </div>

                                    </div>

                                    <div className="reservationButton">

                                        <Button variant="secondary" onClick={() => this.confirmReservation()} >{this.getTranslationByKey("DESKBOOKING.PRENOTAZIONE.TITLE")}</Button>
                                    </div>

                                </div>

                                <div className="ReservationDetails">
                                    <div className="moreInfoWithMap">
                                        <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedLocation?.name}|{this.state.selectedFloor?.name}|{this.state.selectedDeskID}</h3></div>
                                        <hr className="reservation_line_separator"></hr>
                                        <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedDate}|{this.state.selectedTime}</h3></div>
                                        <hr className="reservation_line_separator"></hr>
                                        <div className="textPositionLeftReservation">
                                            {this.state.showHours ?
                                                ''
                                                :
                                                <React.Fragment>
                                                    <h3 className="reservation_textinone">{this.getTranslationByKey("DESKBOOKING.TIME.CANTEEN")} </h3>
                                                    <Switch className="Switch"
                                                        checked={this.state.canteen}
                                                        onChange={() => this.switchCanteen()}
                                                        offColor='grey'
                                                        onColor='#000000'
                                                    />
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                    <div className="reservationButton">
                                        <Button variant="secondary" onClick={() => this.confirmReservation()} >{this.getTranslationByKey("DESKBOOKING.PRENOTAZIONE.TITLE")}</Button>
                                    </div>
                                </div>
                            </Swipe>
                        </>
                        : null
                    }
                    {this.state.showFloors == 2 ?
                        <div>
                            <div className="reservationButton">
                                {this.getTranslationByKey("DESKBOOKING.MAP.NOSELECTDESK")}
                            </div>

                            <div className="ReservationDetailsfixed">
                                <div className="moreInfoWithMap">

                                    <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedDate}|{this.state.selectedTime}</h3></div>
                                    <hr className="reservation_line_separator"></hr>
                                    <div className="textPositionLeftReservation">
                                        {this.state.showHours ?
                                            ''
                                            :
                                            <React.Fragment>
                                                <h3 className="reservation_textinone">{this.getTranslationByKey("DESKBOOKING.TIME.CANTEEN")} </h3>
                                                <Switch className="Switch"
                                                    checked={this.state.canteen}
                                                    onChange={() => this.switchCanteen()}
                                                    offColor='grey'
                                                    onColor='#000000'
                                                />
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                                <div className="reservationButton">
                                    <Button variant="secondary" onClick={() => this.confirmReservation()} >{this.getTranslationByKey("DESKBOOKING.PRENOTAZIONE.TITLE")}</Button>
                                </div>
                            </div>
                        </div>

                        : null

                    }

                    {this.state.showFloors == 3 ?
                        <div>
                            <div className="reservationButton">
                                {this.getTranslationByKey("DESKBOOKING.MAP.NOSELECTDESK")}
                            </div>

                            <div className="ReservationDetailsfixed">
                                <div className="moreInfoWithMap">

                                    <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedDate}|{this.state.selectedTime}</h3></div>
                                    <hr className="reservation_line_separator"></hr>
                                    <div className="textPositionLeftReservation">
                                        <h3 className="reservation_textinone">{this.getTranslationByKey("DESKBOOKING.TIME.CANTEEN")} </h3>
                                        <Switch className="Switch"
                                            checked={this.state.canteen}
                                            onChange={() => this.switchCanteen()}
                                            offColor='grey'
                                            onColor='#000000'
                                        />
                                    </div>
                                </div>
                                <div className="reservationButton">
                                    <Button variant="secondary" onClick={() => this.confirmReservation()} >{this.getTranslationByKey("DESKBOOKING.PRENOTAZIONE.TITLE")}</Button>
                                </div>
                            </div>

                            <div className="ReservationDetails">
                                <div className="moreInfoWithMap">

                                    <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedLocation?.name} | {this.state.selectedFloor?.name} | {this.state.selectedDeskID}</h3></div>
                                    <hr className="reservation_line_separator"></hr>
                                    <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedDate} | {this.state.selectedTime}</h3></div>
                                    <hr className="reservation_line_separator"></hr>
                                    <div className="textPositionLeftReservation">
                                        {this.state.showHours ?
                                            ''
                                            :
                                            <React.Fragment>
                                                <h3 className="reservation_textinone">{this.getTranslationByKey("DESKBOOKING.TIME.CANTEEN")} </h3>
                                                <Switch className="Switch"
                                                    checked={this.state.canteen}
                                                    onChange={() => this.switchCanteen()}
                                                    offColor='grey'
                                                    onColor='#000000'
                                                />
                                            </React.Fragment>
                                        }

                                    </div>
                                </div>
                                <div className="reservationButton">
                                    <Button variant="secondary" onClick={() => this.confirmReservation()} >{this.getTranslationByKey("DESKBOOKING.PRENOTAZIONE.TITLE")}</Button>
                                </div>
                            </div>
                        </div>
                        : null

                    }

                    {this.state.selectedSituation == "Day off" ?

                        <div>
                            <div className="pickReservationDates">
                                <div className='datePickerContainer flex'>
                                    <div className="date">
                                        From:
                                        <DatePicker className="dateButton selectedDateButton"
                                            selected={this.state.startVacation}
                                            minDate={new Date()}
                                            onChange={(date) => { this.setState({ startVacation: date }) }}
                                            placeholderText="Start"
                                            popperPlacement="bottom-start"
                                            onCalendarClose={this.handleCalendarClose}
                                        />
                                    </div>
                                    <div className="date">
                                        To:
                                        <DatePicker className="dateButton selectedDateButton"
                                            selected={this.state.endVacation}
                                            minDate={this.state.startVacation}
                                            onChange={(date) => { this.setState({ endVacation: date }) }}
                                            placeholderText="End"
                                            popperPlacement="bottom-start"
                                            onCalendarClose={this.handleCalendarClose}
                                        />
                                    </div>

                                </div>
                                <div className="reservationButton">

                                    <Button variant="secondary" onClick={() => this.confirmReservation()} >{this.getTranslationByKey("DESKBOOKING.PRENOTAZIONE.TITLE")}</Button>
                                </div>

                            </div>

                            <div>
                                {this.state.vacationShow ?
                                    <Swipe nodeName="div"
                                        className="reservationSwipeContainer"
                                        detectTouch={true}
                                        onSwipedDown={this.onSwipeDownListener}
                                        trackColor={{ true: 'red', false: 'yellow' }}
                                    >
                                        <div className="ReservationDetailsfixed">
                                            <div className="moreInfoWithMap">
                                                <div className='close_container'>
                                                    <svg onClick={() => this.onCloseDetails()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class=" bi bi-arrow-bar-down" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                                                    </svg>
                                                </div>
                                                <div className="textPositionLeftReservation"><h3 className="reservation_textinone">From {this.state.startVacation.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })} to {this.state.endVacation.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' })}</h3></div>
                                                <hr className="reservation_line_separator"></hr>
                                                <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedTime}</h3></div>
                                            </div>
                                            <div className="reservationButton">
                                                <Button disabled={this.state.startVacation.getTime() > this.state.endVacation.getTime()} className={this.state.startVacation.getTime() > this.state.endVacation.getTime() ? "disabled-reservation" : ''} variant="secondary" onClick={() => this.confirmReservation()} >{this.getTranslationByKey("DESKBOOKING.PRENOTAZIONE.TITLE")}</Button>
                                            </div>
                                        </div>
                                    </Swipe>
                                    : ''}

                                <div className="ReservationDetails">
                                    <div className="moreInfoWithMap">

                                        <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedLocation?.name}|{this.state.selectedFloor?.name}|{this.state.selectedDeskID}</h3></div>
                                        <hr className="reservation_line_separator"></hr>
                                        <div className="textPositionLeftReservation"><h3 className="reservation_textinone">{this.state.selectedDate}|{this.state.selectedTime}</h3></div>
                                        <hr className="reservation_line_separator"></hr>
                                        <div className="textPositionLeftReservation">
                                            <h3 className="reservation_textinone">{this.getTranslationByKey("DESKBOOKING.TIME.CANTEEN")}  </h3>
                                            <Switch className="Switch"
                                                checked={this.state.canteen}
                                                onChange={() => this.switchCanteen()}
                                                offColor='grey'
                                                onColor='#000000'
                                            />
                                        </div>
                                    </div>
                                    <div className="reservationButton">
                                        <Button variant="secondary" onClick={() => this.confirmReservation()} >{this.getTranslationByKey("DESKBOOKING.PRENOTAZIONE.TITLE")}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null

                    }


                </ div>
            );
    }
}

export default OfficeDetail;
