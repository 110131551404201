import React, { useEffect, useState } from 'react';

import { EventService } from "../Services/Services";
import Markdown from 'markdown-to-jsx'
import { LoadingComponentWeb } from './LoadingComponent';
export default function Communications(props) {

    const [eS] = React.useState(new EventService())
    const [communicationsEvents, setCommunicationsEvents] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (props.event)
            eS.getCommunications(props.event).then((data) => {
                if (data && data.length > 0)
                    setCommunicationsEvents(data)
                else {
                    if (props.user.modules.my_office)
                        props.select('home', props.user.modules.my_office)
                    else {
                        setTimeout(() => {
                            props.select('home', null)
                        }, 500);
                    }
                }
                setLoading(false)
            }).catch(() => {
                if (props.user.modules.my_office)
                    props.select('home', props.user.modules.my_office)
                else {
                    setTimeout(() => {
                        props.select('home', null)
                    }, 500);
                }
            })
    }, [props.event])

    //format date
    const getDate = (timestamp) => {
        let date = new Date(timestamp);
        let formattedDate = date.toLocaleDateString('en-GB', { month: 'short', day: 'numeric' });
        let formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
        return formattedDate + ' ' + formattedTime
    }
    let color = 'background: #000;'

    return (
        <div>
            {loading ?
                <LoadingComponentWeb />
                :
                <div>
                    <div className='communications_events_cards_container'>
                        {communicationsEvents.map((e, index) =>
                            <React.Fragment>
                                {index == 0 ? '' : <hr Style={'margin: 0px 5% 0px 20% !important;'} />}
                                <div className='communications_events_card pointer flex'>
                                    <div className='communication_from' Style={'background-image:url(' + e.from_image_url + ')'}></div>
                                    <div class="communication_block" >
                                        <div className='bold'>
                                            <Markdown>{e.message}</Markdown>
                                        </div>
                                        <div className='bold' Style='margin-top:13px;font-size:15px'>{getDate(e.timestamp)}</div>
                                    </div>
                                </div>
                            </React.Fragment>)}
                    </div>
                </div>
            }
        </div>
    )
}