import React, { useEffect, useState } from 'react';
import { MeetingRoomsService } from "../Services/Services";
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import 'moment/locale/en-gb';
import MultiSelectComponent from './MultiSelectComponent';

import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { UseMessage } from './Alert';
import { TransformWrapper, TransformComponent, } from "react-zoom-pan-pinch";
import { LoadingComponentWeb } from './LoadingComponent';

export default function MeetingRoomsDetail(props) {

    const [rtS] = React.useState(new MeetingRoomsService())
    const [loading, setLoading] = useState(true)
    const [loadingModal, setModalLoading] = useState(false)
    const [allDay, setAllDay] = useState(false)
    const [meetingRoom, setMeetingRoom] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [date, setDate] = useState(props.selectedDate ? props.selectedDate : new Date())
    const [slots, setSlots] = useState([])
    const [timeFrom, setTimeFrom] = useState(null)
    const [timeTo, setTimeTo] = useState(null)
    const [guestList, setGuestList] = useState([])
    const [optionalGuestList, setOptionalGuestList] = useState([])
    const [selectedGuest, setSelectedGuest] = useState([])
    const [selectedOptionalGuest, setSelectedOptionalGuest] = useState([])
    var typingTimer;                //timer identifier
    var doneTypingInterval = 1000;

    //calendar util variable
    const localizer = momentLocalizer(moment)

    useEffect(() => {
        rtS.getByID(props.meeting_room_data.id).then((data) => {
            setMeetingRoom(data)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (openModal)
            addListener()

        return () => {
            removeListener()
        }
    }, [selectedGuest, selectedOptionalGuest, openModal])

    useEffect(() => {
        rtS.getByID(props.meeting_room_data.id).then((data) => {
            setMeetingRoom(data)
            setLoading(false)
        })
    }, [openModal])

    //translate function
    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    const addListener = () => {
        window.addEventListener("keyup", addManual, false);
        window.addEventListener("keydown", clear, false);
    }

    const removeListener = () => {
        window.removeEventListener("keyup", addManual, false);
        window.removeEventListener("keydown", clear, false);
    }

    const addManual = () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
    }

    const clear = () => {
        clearTimeout(typingTimer);
    }

    //user is "finished typing," do something
    const doneTyping = () => {
        let element = document.querySelector(' .select_guest > div > div > div.dropdown-content > div > div > div > input[type=text]')
        let element2 = document.querySelector(' .select_optional_guest > div > div > div.dropdown-content > div > div > div > input[type=text]')
        if (element && element.value) {
            rtS.getGuestList(element.value).then((data) => {
                let tmp = []
                let o = {}
                data.map((d) => {
                    o = {
                        label: d.fullname + ' (' + d.email + ')',
                        value: d.email
                    }
                    tmp.push(o)
                })
                setGuestList(tmp)
            })
        }
        if (element2 && element2.value) {
            rtS.getGuestList(element2.value).then((data) => {
                let tmp = []
                let o = {}
                data.map((d) => {
                    o = {
                        label: d.fullname + '(' + d.email + ')',
                        value: d.email
                    }
                    tmp.push(o)
                })
                setOptionalGuestList(tmp)
            })
        }
    }

    const goBack = () => {
        props.setDetail(false)
    }

    const setFav = () => {
        setLoading(true)
        rtS.setFav(props.meeting_room_data.id).then(() => {
            rtS.getByID(props.meeting_room_data.id).then((data) => {
                setMeetingRoom(data)
                setLoading(false)
            })
        })
    }

    const getAvailability = () => {
        let newDate = ''
        let stringDate = ''
        if (!date) {
            newDate = new Date()
            setDate(newDate)
            stringDate = getData(newDate)
        } else {
            stringDate = getData(date)
        }
        let options = {
            date: stringDate
        }
        rtS.getAvailability(props.meeting_room_data.id, options).then((data) => {
            setAvalaibility(data)
            handleSetOpenModal(true)
        })
    }

    const getData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = new_date.getFullYear().toString() + '-' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '-' + ("0" + new_date.getDate()).slice(-2).toString()
        return toStringDate
    }

    const getEmailData = (date) => {
        let new_date = new Date(date)
        let toStringDate = ""
        toStringDate = ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return toStringDate
    }

    const handleChangeDate = (date) => {
        setModalLoading(true)
        setTimeout(() => {
            setDate(date)
        }, 2000)
        let options = {}
        options = {
            date: getData(date)
        }
        rtS.getAvailability(props.meeting_room_data.id, options).then((data) => {
            setAvalaibility(data)
            setDate(date)
            setModalLoading(false)
        })
    }

    const onChangeTimeFrom = (time) => {
        setTimeFrom(time)
    }

    const onChangeTimeTo = (time) => {
        setTimeTo(time)
    }

    const setAvalaibility = (data) => {
        let slots = []
        let start = moment(date).startOf('day').toDate(); // set to 12:00 am today
        let end = moment(date).endOf('day').toDate(); // set to 23:59 pm today
        data.map((s) => {
            let e = {
                start: s.all_day ? start : new Date(s.from),
                end: s.all_day ? end : new Date(s.to),
                title: s.title
            }
            slots.push(e)
        })
        setSlots(slots)
    }



    const book = () => {
        setModalLoading(true)
        let select_guest = getEmail(selectedGuest)
        let select_optional_guest = getEmail(selectedOptionalGuest)
        let options = {
            "date": getData(date),
            "all_day": allDay,
            "from_timestamp": timeFrom ? timeFrom + ':00' : '',
            "to_timestamp": timeTo ? timeTo + ':00' : '',
            "required_guests": select_guest,
            "optional_guests": select_optional_guest
        }

        if (!allDay) {
            if (!timeFrom) {
                setModalLoading(false)
                UseMessage("inserire l'ora d'inizio")
                return
            } else if (!timeTo) {
                setModalLoading(false)
                UseMessage("inserire l'ora di fine")
                return
            }
        }

        rtS.bookMeetingRoom(props.meeting_room_data.id, options).then(() => {
            handleSetOpenModal(false)
            setModalLoading(false)
        })

    }

    const handleSetOpenModal = (bool) => {
        if (bool)
            setOpenModal(true)
        else {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
            setOpenModal(false)
        }
    }

    const getEmail = (array) => {
        let tmp = []
        array.map((a) => {
            tmp.push(a.value)
        })
        return tmp
    }

    const getString = (key) => {
        let tmpString = getTranslationByKey(key)
        let tmp = tmpString.replace('[NAME]', meetingRoom.name)
        let res = tmp.replace('[DATE]', getEmailData(date))
        return res
    }

    const getCalendarLabelDate = (new_date) => {
        let toStringDate = ""
        let lang = navigator.language
        if (localStorage.getItem("selectedLanguage"))
            lang = localStorage.getItem("selectedLanguage")
        let day = new_date.toLocaleDateString(lang.split('-')[0], { weekday: 'long' })
        toStringDate = day + ', ' + ("0" + new_date.getDate()).slice(-2).toString() + '/' + ("0" + (new_date.getMonth() + 1)).slice(-2).toString() + '/' + new_date.getFullYear().toString()
        return toStringDate
    }

    //get the event style
    const eventPropGetter = () => {
        const style = {
            paddingLeft: "10px",
            color: 'white',
            "font-family": "GucciSans-Book !important"
        };
        return {
            style: style
        };
    }

    const [customModalStyles] = useState({

        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: window.innerWidth > 1000 ? '900px' : '95%',
            height: window.innerWidth > 1000 ? '57vh' : '74vh',
            marginRight: '-50%',
            padding: '15px',
            transform: 'translate(-50%, -50%)',
            'z-index': 99,
            transition: "opacity 20ms ease-in -out",
            "background-color": "#f0efef",
            overflow: "hidden",
        }
    })

    return (loading ?
        <LoadingComponentWeb />
        :
        <React.Fragment>
            <div className='meeting_room_container meeting_room_container_web'>
                <div className="meeting_room_detail_card_name">
                    <div className="meeting_room_detail_card_name_container">
                        <div className='meeting_room_detail_left_container'>
                            <div className="meeting_room_detail_card_img_container">
                                <div className="meeting_room_detail_card_img"></div>
                            </div>
                            <div className="meeting_room_detail_central_header">
                                <div className="meeting_room_detail_name_container">
                                    <div className="flex">
                                        <div>
                                            <div className="meeting_room_detail_name">{meetingRoom.name}</div>
                                            <div className="meeting_room_detail_position">{meetingRoom.level}</div>
                                        </div>
                                        <div className="meeting_room_detail_favourite" onClick={() => setFav(meetingRoom.id)}>
                                            {meetingRoom.starred ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-star-fill pointer" viewBox="0 0 16 16">
                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-star pointer" viewBox="0 0 16 16">
                                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex vertical_center">
                                        <div className="flex vertical_center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-fill ml-2" viewBox="0 0 16 16">
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                            </svg>
                                            <div className="meeting_room_detail_user_count">{meetingRoom.max_guests}</div>
                                        </div>
                                        <div className="meeting_room_vertical_line_detail"></div>
                                        <div className={meetingRoom.properties.has_monitor ? 'room_icon_active monitor_icon' : 'room_icon monitor_icon'}></div>
                                        <div className={meetingRoom.properties.has_camera ? 'room_icon_active cam_icon' : 'room_icon cam_icon'}></div>
                                        <div className={meetingRoom.properties.has_projector ? 'room_icon_active projector_icon' : 'room_icon projector_icon'}></div>
                                        <div className={meetingRoom.properties.has_board ? 'room_icon_active board_icon' : 'room_icon board_icon'}></div>
                                        <div className={meetingRoom.properties.has_phone ? 'room_icon_active telephone_icon' : 'room_icon telephone_icon'}></div>
                                    </div>
                                </div>
                                <div className="meeting_room_manager_container">
                                    <div>{getTranslationByKey('MR.DETAIL.ROOMMANAGER')} <span className="bold">{meetingRoom.account.name}</span></div>
                                    <div>{getTranslationByKey('MR.DETAIL.ROOMACCOUNT')}<span className="bold">{meetingRoom.account.email}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="meeting_room_detail_right_header_container">
                            <div className="meeting_room_detail_right_header">
                                <div className="flex">
                                    <div className="meeting_room_vertical_line_right"></div>
                                    <div className="meeting_room_schedule_container align_center">
                                        {meetingRoom.type === 'outlook' ? <div className="meeting_room_status">{getTranslationByKey('MR.DETAIL.STATUSLABEL')} {meetingRoom.current_status}</div> : ''}
                                        <div className="meeting_room_buttons_container">
                                            {meetingRoom.type === 'outlook' ?
                                                <div className="meeting_room_schedule pointer" onClick={() => getAvailability(true)}>{getTranslationByKey('MR.DETAIL.SCHEDULELABEL')}</div>
                                                :
                                                <a className="meeting_room_schedule mail_to_meeting_room" href={"mailto:" + meetingRoom.account.email + "?subject=" + getString('MR.DETAIL.MAILSUBJECT') + "&body=" + getString('MR.DETAIL.MAILBODY')}  >Mail</a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="how_to_book_container">
                        <div className="bold" Style={"text-transform: uppercase;"}>{getTranslationByKey('MR.DETAIL.BOOK.TITLE')}</div>
                        <div>{getTranslationByKey('MR.DETAIL.BOOK.DESCRIPTION')}</div>
                        <br />
                        <div>{getTranslationByKey('MR.DETAIL.BOOK.DESCRIPTION')}</div>
                    </div>
                </div>
                <div className="meeting_room_map_container">
                    <div id="mr_how_to_reach"> {getTranslationByKey('MR.DETAIL.MAPTITLE')}</div>
                    <div id="meeting_room_map_container">
                        <TransformWrapper>
                            {({ zoomIn, zoomOut, ...rest }) => (
                                <React.Fragment>
                                    <div class="meeting_room_tools">
                                        <svg onClick={() => zoomIn()} id="plus_tool" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle-fill pointer" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>
                                        <svg onClick={() => zoomOut()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-dash-circle-fill pointer" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                                        </svg>
                                    </div>
                                    <TransformComponent>
                                        <div className='meeting_room_map' Style={"background-image: url('" + meetingRoom.map_url + "')"}></div>
                                    </TransformComponent>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={openModal}
                onAfterOpen={null}
                onRequestClose={() => handleSetOpenModal(false)}
                style={customModalStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root') || undefined}
            >
                <div className="pointer close_modal">
                    <svg onClick={() => handleSetOpenModal(false)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>

                {loadingModal || loading ?
                    <div className='mr_loader_ellipsis'>
                        <div class="lds-ellipsis">
                            <div Style={"background: #000000;"}></div>
                            <div Style={"background: #000000;"}></div>
                            <div Style={"background: #000000;"}></div>
                            <div Style={"background: #000000;"}></div>
                        </div>
                    </div>
                    :
                    <div id='mr_modal_container'>
                        <div className='flex'>
                            <div>
                                <DatePicker
                                    showIcon
                                    dateFormat="dd/MM/yyyy"
                                    className="meeting_rooms_datepicker"
                                    selected={date}
                                    minDate={new Date()}
                                    onChange={(date) => { handleChangeDate(date) }}
                                    placeholderText="gg/mm/yyyy"
                                    popperPlacement="bottom-center"
                                    popperClassName="meeting_rooms_details_popper"
                                />
                            </div>
                        </div>
                        <div className='mr_global_container_modal'>
                            <div className='availability_container'>
                                <div Style={"text-align: -webkit-center;"}>
                                    <div className='calendar_card'>
                                        <Calendar
                                            components={{ toolbar: props => (<div id="mr_label_calendar">{getCalendarLabelDate(props.date)}</div>) }}
                                            eventPropGetter={eventPropGetter}
                                            defaultDate={date}
                                            localizer={localizer}
                                            formats={{ weekdayFormat: 'ddd' }}
                                            events={slots}
                                            startAccessor="start"
                                            endAccessor="end"
                                            style={{ height: window.innerWidth > 1000 ? '46vh' : '40vh' }}
                                            allDayAccessor={false}
                                            defaultView="day"
                                            views={['day']}
                                            min={new Date(2017, 10, 0, 8, 0, 0)}
                                            max={new Date(2017, 10, 0, 20, 0, 0)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='mr_multiselect_container'>
                                <div className='mr_multiselect pointer'>
                                    <div className='select_guest'>
                                        <MultiSelectComponent id={'select_guest'} label={"Select a guest"} selectedGuest={selectedGuest} setSelectedGuest={setSelectedGuest} options={guestList} setOptions={setGuestList} setModalLoading={setModalLoading} />
                                    </div>
                                </div>
                                <div className='mr_multiselect pointer'>
                                    <div className='select_optional_guest'>
                                        <MultiSelectComponent id={'select_optional_guest'} label={"Select an optional guest"} selectedGuest={selectedOptionalGuest} setSelectedGuest={setSelectedOptionalGuest} options={optionalGuestList} setOptions={setOptionalGuestList} setModalLoading={setModalLoading} />
                                    </div>
                                </div>
                                <div className="flex mr_timepicker_container">
                                    <div className='options_book_meeting_room'>
                                        <div>{getTranslationByKey('GENERIC.TOGGLE.ALLDAY')}</div>
                                        <div Style={"padding: 6px;"}>
                                            <input checked={allDay} type="checkbox" className="checkbox" id="chk_2" onChange={() => setAllDay(!allDay)} />
                                            <label className="meeting_room_switch_label" htmlFor="chk_2">
                                                <div className="meeting_room_ball"></div>
                                            </label>
                                        </div>
                                    </div>

                                    <div className={!allDay ? 'options_book_meeting_room pointer' : 'options_book_meeting_room not_pointer'}>
                                        <div>From:</div>
                                        <TimePicker onChange={(val) => onChangeTimeFrom(val)} value={timeFrom} disableClock={true} disabled={allDay ? true : false} />
                                    </div>
                                    <div className={!allDay ? 'options_book_meeting_room pointer' : 'options_book_meeting_room not_pointer'}>
                                        <div>Till:</div>
                                        <TimePicker onChange={(val) => onChangeTimeTo(val)} value={timeTo} disableClock={true} disabled={allDay ? true : false} />
                                    </div>
                                </div>
                                <div className='text-align: -webkit-center;'>
                                    <div id="book_meeting_room" className='pointer' onClick={() => book()}>{getTranslationByKey('MR.DETAIL.SCHEDULELABEL')}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                }

            </Modal>

        </React.Fragment>)

}