export function LoadingComponentWeb(props) {
    return (
        <div className='loader_ellipsis loader_ellipsis_web'>
            <div class="lds-ellipsis">
                <div Style={"background: #000000;"}></div>
                <div Style={"background: #000000;"}></div>
                <div Style={"background: #000000;"}></div>
                <div Style={"background: #000000;"}></div>
            </div>
        </div>)
}
export function LoadingComponent(props) {
    return (
        <div className='loader_ellipsis'>
            <div class="lds-ellipsis">
                <div Style={"background: #000000"}></div>
                <div Style={"background: #000000"}></div>
                <div Style={"background: #000000"}></div>
                <div Style={"background: #000000"}></div>
            </div>
        </div>)
}