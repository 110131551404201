import React, { useEffect, useState } from 'react';
import { CanteenService } from "../Services/Services";
import { Line } from 'react-chartjs-2';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import DatePicker from "react-datepicker";
import { Chart, registerables } from 'chart.js';
import { getTranslationByKey } from '../global';
import { LoadingComponentWeb } from './LoadingComponent';
Chart.register(...registerables);


export function Canteen(props) {

    const canteenService = new CanteenService();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingStats, setLoadingStats] = useState(true);
    const [fromDate, setFromDate] = useState(new Date());
    const [canteen, setCanteen] = useState([]);
    const [stats, setStats] = useState([]);

    var currentdate = new Date();
    var datetime = ("0" + currentdate.getDate()).slice(-2) + "/"
        + ('0' + (currentdate.getMonth() + 1)).slice(-2) + "/"
        + currentdate.getFullYear() + " "
        + currentdate.getHours() + ":"
        + ('0' + currentdate.getMinutes()).slice(-2);

    let labels = ['12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00'];
    var lista_persone = [];
    var lista_timestamps = [];

    useEffect(() => {
        canteenService.getCanteen(props.office.id).then((data) => {
            setCanteen(data);
            getStats('today', true, true, data.id);
            window.history.pushState(null, null, window.location.pathname)
            window.addEventListener('popstate', onBackButtonEvent)
        });
    }, [])

    useEffect(() => {
        if (!isLoading)
            getStats(fromDate, true, false, canteen.id)
    }, [fromDate])

    /*
    Prende in input il giorno da calcolare (vuoto se da calendar),
        flagSpinnerStats: flag che serve per settare lo spinner dedicato al grafico,
        flagGlobal: flag che serve per avviare lo spinner globale
    */
    const getStats = (day, flagSpinnerStats, flagGlobal, id) => {

        setLoading(flagGlobal)
        setLoadingStats(flagSpinnerStats)

        let formatData = '';
        if (day === 'today') {
            let newDate = new Date()
            formatData = newDate.getFullYear() + '-'
                + ('0' + (newDate.getMonth() + 1)).slice(-2)
                + '-' + ("0" + newDate.getDate()).slice(-2)
        } else if (day == 'yesterday') {
            let yesterday = (function () { this.setDate(this.getDate() - 1); return this })
                .call(new Date);
            formatData = yesterday.getFullYear() + '-'
                + ('0' + (yesterday.getMonth() + 1)).slice(-2)
                + '-' + ("0" + yesterday.getDate()).slice(-2)
        } else {
            formatData = fromDate.getFullYear() + '-'
                + ('0' + (fromDate.getMonth() + 1)).slice(-2)
                + '-' + ("0" + fromDate.getDate()).slice(-2)
        }
        canteenService.getOccupancyStats(formatData, id).then((data) => {
            setStats(data);
            setLoading(false)
            setLoadingStats(false)
        })
    }

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        window.location.reload()
    }

    if (!isLoading && stats.occupancy_timeline != undefined) {
        if (stats.occupancy_timeline.length != 0) {
            let lista = stats.occupancy_timeline;
            lista.map((elem) => {
                if (labels.includes(elem.timestamp)) {
                    lista_timestamps.push(elem.timestamp)
                    lista_persone.push(elem.occupancy)
                }
            })
        }
    }

    const state = {
        labels: lista_timestamps,
        datasets: [
            {
                fill: false,
                lineTension: 0.3,
                borderColor: '#40B250',
                borderWidth: 3,
                data: lista_persone,
                pointRadius: 0,
            }
        ]
    }

    const override2 = css`
                        display: block;
                        margin: 15% auto;
                        `;

    let color = 'background: #000000;'
    if (props.user && props.user.modules) {
        props.user.modules.offices.map((o) => {
            if (o.name === 'Gucci Hub')
                color = 'background: #000000'
        })
    }

    const handleCalendarClose = () => {
        window.scroll({ top: -1, left: 0, behavior: "smooth" });
    }

    return (
        <div>
            <div className="canteen_global_container">
                {isLoading ?
                    <LoadingComponentWeb />
                    :
                    <React.Fragment>
                        <div className="flex">
                            <div className="canteen_container">
                                <div Style={"text-align: -webkit-center; margin-top: 10px;"}>
                                    {window.innerWidth > 1000 ?
                                        <div className="menu_button_canteen_web pointer" onClick={() => window.open(canteen.menu_link, '_blank')}>
                                            <div className="logo_restaurant_canteen_web"></div>
                                            <div className='menu_text_web'>Menù</div>
                                        </div>
                                        :
                                        <div className="menu_button_canteen flex pointer" onClick={() => window.open(canteen.menu_link, '_blank')}>
                                            <div className="logo_restaurant_canteen"></div>
                                            <div className='menu_text'>Menù</div>
                                        </div>
                                    }
                                </div>
                                <div className="canteen_baloons_container mt-3">
                                    <div className="main_baloon_container">
                                        <div className="canteen_status">
                                            <div className="gucci-text">
                                                {getTranslationByKey('CANTEEN.CURRENTLY')}
                                            </div>
                                            <div className="gucci-text-bold" >
                                                {canteen.currently_open ? canteen.current_occupancy : getTranslationByKey('CANTEEN.CLOSED')}
                                            </div>
                                            <div>
                                                <div className="canteen_subtitle_1">{getTranslationByKey('CANTEEN.LASTUPDATE %@').replace('%@', '')}  {datetime}</div>
                                                <div className="canteen_subtitle_2">{getTranslationByKey('CANTEEN.OPENINGHOURS %@').replace('%@', '')} {canteen ? canteen.opening_hour : "00:00"} - {canteen ? canteen.closing_hour : "00:00"}</div>
                                            </div>
                                        </div>
                                        <div className="max_seats">
                                            <div className="gucci-text">
                                                {getTranslationByKey('CANTEEN.TOTALSEATS')}
                                            </div>
                                            <div className="gucci-text-bold" >
                                                {canteen ? canteen.seats : ''}
                                            </div> </div>
                                    </div>
                                </div>
                                <div className="canteen_graph">
                                    <div className="canteen_dates_container flex">
                                        <DatePicker onCalendarClose={handleCalendarClose} className={"canteen_datepicker"} value={fromDate} selected={fromDate} dateFormat='dd MMM yyyy' onChange={(date) => setFromDate(date)} />
                                        <div className="canteen_date_tag" onClick={() => this.getStats("today", true, false, canteen.id)}>{getTranslationByKey('CANTEEN.TODAY')}</div>
                                    </div>
                                    <br />
                                    {isLoadingStats ?
                                        <ClipLoader color={"#4d3047"} size={45} css={override2} />
                                        :
                                        stats.occupancy_timeline.length != 0 ?
                                            <div>
                                                <Line
                                                    data={state}
                                                    options={{
                                                        title: {
                                                            display: true,
                                                            text: ' ',
                                                            fontSize: 20
                                                        },
                                                        plugins: {
                                                            legend: {
                                                                display: false
                                                            },
                                                        },
                                                        scales: {
                                                            x: {
                                                                grid: {
                                                                    display: false
                                                                },
                                                                ticks: {
                                                                    font: {
                                                                        size: 14,
                                                                        family: "GucciSans-Book"
                                                                    }
                                                                }

                                                            },
                                                            y: {
                                                                grid: {
                                                                    display: false
                                                                },
                                                                ticks: {
                                                                    font: {
                                                                        size: 14,
                                                                        family: "GucciSans-Book"
                                                                    },
                                                                },
                                                                max: canteen.max_occupancy,
                                                                min: 0
                                                            }
                                                        }
                                                    }}
                                                /></div>
                                            :
                                            <div id="canteen_nodata_message">{getTranslationByKey('CANTEEN.NODATA')}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}
