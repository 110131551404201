import React, { Component } from "react";
import { loginRequest, msalInstance } from "../authConfig";
import { LoginService } from "../Services/Services";
import MD5 from "crypto-js/md5";
import Cookies from "universal-cookie";
import ReactDOM from 'react-dom';
import { Buffer } from "buffer"
import { UseAlertMessage } from "./Alert";
import NewHomepage from "./NewHomepage";

//Login Page
class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            username: '',
            password: '',
            email: '',
            cookies: new Cookies(),
            isTemporary: false,
            isForgot: false,
            temporaryPassword: '',
            newPassword1: '',
            newPassword2: ''
        }

        this.setLoading = this.setLoading.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
        this.setUsername = this.setUsername.bind(this)
        this.setPassword = this.setPassword.bind(this)
        this.checkTemporary = this.checkTemporary.bind(this)

        /* handle change new password */
        this.handleTempPassword = this.handleTempPassword.bind(this)
        this.handleNewPassword1 = this.handleNewPassword1.bind(this)
        this.handleNewPassword2 = this.handleNewPassword2.bind(this)
        this.changePassword = this.changePassword.bind(this)
        this.returnToLogin = this.returnToLogin.bind(this)

        /* handle forgot password */
        this.handleForgotPassword = this.handleForgotPassword.bind(this)
        this.setEmail = this.setEmail.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)
    }

    componentDidMount() {
        setTimeout(function () { //Start the timer
            this.setLoading(false)
        }.bind(this), 1000)
    }

    async setLoading(val) {
        this.setState({
            loading: val
        })
    }

    handleLogin() {
        let loginService = new LoginService();
        let password = MD5(this.state.password).toString();
        let auth = Buffer.from(this.state.username + ':' + password).toString('base64');
        loginService.login(auth, this.checkTemporary)
    }

    handleTempPassword(e) {
        this.setState({
            temporaryPassword: e.target.value
        })
    }

    handleNewPassword1(e) {
        this.setState({
            newPassword1: e.target.value
        })
    }

    handleNewPassword2(e) {
        this.setState({
            newPassword2: e.target.value
        })
    }

    changePassword() {

        let loginService = new LoginService();
        if (this.state.temporaryPassword.match(/^\s+$/) || this.state.temporaryPassword === '') {
            UseAlertMessage(400, "Invalid temporary password. Please enter your temporary password to proceed. We sent it by email!");
        } else if (this.state.newPassword1 === '' || this.state.newPassword2 === '' || this.state.newPassword1 !== this.state.newPassword2) {
            UseAlertMessage(400, "Please confirm your password writing the same password!");
        } else {
            let obj = {
                email: this.state.username,
                old_password: MD5(this.state.temporaryPassword).toString(),
                new_password: MD5(this.state.newPassword1).toString()
            }
            loginService.changePassword(obj, this.returnToLogin)
        }
    }

    returnToLogin() {
        this.setState({
            loading: false,
            isTemporary: false,
            isForgot: false,
            password: '',
            username: '',
            temporaryPassword: ''
        })
    }

    setUsername(e) {
        this.setState({
            username: e.target.value
        })
    }

    setPassword(e) {
        this.setState({
            password: e.target.value
        })
    }

    setEmail(e) {
        this.setState({
            email: e.target.value
        })
    }

    checkTemporary(isTemp) {
        if (isTemp) {
            this.setState({
                isTemporary: isTemp,
                password: '',
                newPassword1: '',
                newPassword2: ''
            })
        } else {
            ReactDOM.render(<NewHomepage sel="palazzo_mancini" />, document.getElementById('root'));
        }
    }

    handleForgotPassword() {
        this.setState({
            isForgot: true,
        })
    }

    handleGoBack() {
        this.setState({
            isForgot: false
        })
    }

    resetPassword() {

        this.setState({
            loading: true
        })

        let ls = new LoginService();
        let email = this.state.email;
        ls.resetPassword(email, this.returnToLogin)
    }

    login() {
        this.setLoading(true).then(() => {
            msalInstance.loginRedirect(loginRequest).catch(e => {
            })
        })
    }

    render() {
        if (this.state.loading)
            return (<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>)
        else
            return (
                <div className="login">
                    <div className="login-cont">
                        <div className="logo-container">
                            <div className="logo"></div>
                        </div>

                        {this.state.isTemporary ?
                            <React.Fragment>
                                <div className="container_reset_password">
                                    <div className="separator-container mb-3">
                                        <div className="login-separator-cont">
                                            <div className="symb">
                                                You must change your password before logging on the first time
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input-text" placeholder="Temporary password" id="tempPassword" value={this.state.temporaryPassword} type='password' onChange={(e) => this.handleTempPassword(e)}></input>
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input-text" placeholder="New Password" id="newPassword" value={this.state.newPassword1} type='password' onChange={(e) => this.handleNewPassword1(e)}>
                                        </input>
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input-text" placeholder="Confirm Password" id="confPassword" value={this.state.newPassword2} type='password' onChange={(e) => this.handleNewPassword2(e)}></input>
                                    </div>
                                </div>
                                <div className="input-container">
                                    <button className="login-button" onClick={() => this.changePassword()}>CHANGE PASSWORD</button>
                                </div>

                            </React.Fragment>
                            : (this.state.isForgot ?
                                <div className="container_reset_password">
                                    <div className="separator-container mb-3">
                                        <div className="login-separator-cont">
                                            <div className="symb">
                                                Please enter your email address below and we will send you a new temporary password.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input-text" placeholder="Email" id="email" value={this.state.email} type='input' onChange={(e) => this.setEmail(e)}></input>
                                    </div>
                                    <div className="input-container">
                                        <button className="login-button" onClick={() => this.resetPassword()}>RESET PASSWORD</button>
                                    </div>
                                    <div className="forgot-password-container">
                                        <div className="forgot-password-cell" style={{ textDecoration: "underline" }} onClick={() => this.handleGoBack()}>
                                            « Back
                                        </div>
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div className="input-container mb-4">
                                        <button className="login-button" onClick={() => this.login()}>LOGIN WITH GUCCI CREDENTIALS</button>
                                    </div>
                                    <div className="separator-container mb-3">
                                        <div className="login-separator-cont">
                                            <hr className="login-separator" />
                                            <div className="symb">
                                                OR
                                            </div>
                                            <hr className="login-separator" />
                                        </div>
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input-text" placeholder="Username" id="username" value={this.state.username} type='text' onChange={(e) => this.setUsername(e)}></input>
                                    </div>
                                    <div className="input-container">
                                        <input className="login-input-text" placeholder="Password" id="password" value={this.state.password} type='password' onChange={(e) => this.setPassword(e)}></input>
                                    </div>

                                    <div className="input-container">
                                        <button className="login-button" onClick={() => this.handleLogin()}>LOGIN</button>
                                    </div>
                                    <div className="forgot-password-container">
                                        <div className="forgot-password-cell" style={{ textDecoration: "underline" }} onClick={() => this.handleForgotPassword()}>
                                            Forgot password?
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                </div >)
    }
}
export default Login;