import React, { useEffect, useState } from 'react';
import { EventService } from "../Services/Services";
import ScrollCalendarAgenda from './ScrollCalendarAgenda';
import { UseAlertMessage } from './Alert';
import { LoadingComponentWeb } from './LoadingComponent';

export default function Agenda(props) {

    const [eS] = React.useState(new EventService())
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [agendaModuleInfo, setAgendaModuleInfo] = useState([])
    const [agendaEvents, setAgendaEvents] = useState([])
    const [filters, setFilters] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        if (props.event && props.event.id) {
            let s = selectedDate.getFullYear() + '-' + ('0' + (selectedDate.getMonth() + 1)).slice(-2) + '-' + selectedDate.getDate()
            setSelectedDate(s)
            eS.getAgenda(props.event.id).then((data) => {
                setAgendaEvents(data.events)
                setAgendaModuleInfo(data)
                // se la data corrente non è presente nell'array data.events seleziono il suo primo elemento
                let today = new Date()
                let d = ''
                let isDateInArray = false
                data.events.map((elem) => {
                    d = new Date(elem.date)
                    if (today.getFullYear() == d.getFullYear() && today.getMonth() == d.getMonth() && today.getDate() == d.getDate()) {
                        isDateInArray = true
                    }
                })
                if (isDateInArray) {
                    setSelectedDate(today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + today.getDate())
                } else if (data.events.length == 0) {
                    setLoading(false)
                    UseAlertMessage(200, 'Agenda not available')
                    if (props.user.modules.my_office)
                        props.select('home', props.user.modules.my_office)
                    else {
                        setTimeout(() => {
                            props.select('home', null)
                        }, 500);
                    }
                } else {
                    d = new Date(data.events[0].date)
                    setSelectedDate(d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + d.getDate())
                }
                /*
                if (props.eventDetail) {
                    props.eventDetail.content.map((el) => {
                        if (el.button_link != null && el.button_link.module_key === 'agenda') {

                            setSelectedDate(new Date(el.button_link.module_info.min_date))
                        }
                    })
                }
                */

                eS.getEventFilters(props.event.id).then((data) => {
                    setFilters(data)
                    setLoading(false)
                })
            })
        }

    }, [props.agenda])

    useEffect(() => {
        window.onclick = function (event) {
            if (!event.target.matches('.dropbtn-agenda') && document.getElementById("myDropdown-agenda"))
                document.getElementById("myDropdown-agenda").classList.remove("show")
        }
    }, [])

    const changeFilter = (f) => {
        let body = {
            "tag": f
        }
        setLoading(true)
        eS.filterEventAgenda(props.event.id, body).then((data) => {
            setAgendaEvents(data)
            setLoading(false)
        })
    }

    //format date
    const getDate = (string) => {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(string).toLocaleDateString([], options);
    }

    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] && translation[key].v ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    const dayHasEvents = (day) => {
        let ret = false
        agendaEvents.map((e) => {
            if (checkDate(e.date))
                ret = true
        })
        return ret
    }

    const myFunction = () => {
        if (document.getElementById("myDropdown-agenda") && document.getElementById("myDropdown-agenda").classList)
            document.getElementById("myDropdown-agenda").classList.toggle("show");
    }

    const handleSelectedDate = (sd) => {
        let s = sd.getFullYear() + '-' + (1 + sd.getMonth()) + '-' + sd.getDate()
        setSelectedDate(s)
    }

    const checkDate = (date) => {
        let d1 = new Date(date)
        let year = ''
        let month = ''
        let day = ''
        if (selectedDate) {
            year = selectedDate.split('-')[0]
            month = selectedDate.split('-')[1]
            day = selectedDate.split('-')[2]
        }
        let newDate = new Date()
        newDate.setFullYear(year)
        newDate.setMonth(month - 1)
        newDate.setDate(day)
        newDate.setHours(0, 0, 0, 0)
        if (d1.getFullYear() == year && d1.getMonth() == (month - 1) && d1.getDate() == day) return true
        return false
    }

    return (
        <div>
            {loading ?
                <LoadingComponentWeb />
                :
                <React.Fragment>

                    <div className='calendar_container_agenda'>
                        <div className='flex calendar_filter_container'>
                            {agendaModuleInfo.min_date && agendaModuleInfo.max_date ?
                                <ScrollCalendarAgenda setLoading={setLoading} setSelectedDate={handleSelectedDate} selectedDate={selectedDate} startDate={agendaModuleInfo.min_date.split('T')[0]} endDate={agendaModuleInfo.max_date.split('T')[0]} />
                                :
                                ''}

                            {agendaEvents && agendaEvents.length > 0 ?
                                <div className="header_user pointer dropbtn-agenda-container">
                                    <div className="dropbtn-agenda" onClick={() => myFunction()} >
                                        <div id="myDropdown-agenda" class="dropdown-content-header-agenda">
                                            {filters.map((f) =>
                                                <div className="flex drop-agenda" onClick={() => changeFilter(f)}>
                                                    <div className="left_menu_text" Style={'align-self: center;'} onClick={() => changeFilter(f)}>{f}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div Style={"text-align: -webkit-right; "}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class=" pointer ml-3 bi bi-filter logout_arrow" viewBox="0 0 16 16">
                                            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                                        </svg>
                                    </div>
                                </div>
                                : ''}
                        </div>

                        <div className='agenda_events_cards_container'>
                            {dayHasEvents(getDate(selectedDate)) ?
                                agendaEvents.map((e) =>
                                    <React.Fragment>
                                        {checkDate(e.date) ?
                                            <div className='agenda_events_card pointer'>
                                                <div className='bold' Style={"text-transform: uppercase;"}>{e.timestamp_label}</div>
                                                <div className='bold'>{e.title}</div>
                                                <div>{e.location}</div>
                                            </div>
                                            : ''}
                                    </React.Fragment>)
                                :
                                <React.Fragment>
                                    <div Style={"text-align: -webkit-center;"}>{getTranslationByKey('EVENTS.AGENDA.NODATA')}</div>
                                </React.Fragment>
                            }

                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}