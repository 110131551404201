import React from 'react';
import { IoIosCheckmark, IoIosClose } from "react-icons/io";
import "izitoast-react/dist/iziToast.css";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import '../App.css';
import { DeskService } from '../Services/Services';

class BookingDetailMonth extends React.Component {
    constructor(props) {
        super(props);

        var leftBarStyle = {
            width: '2px',
            background: '#000000',
            'border-radius': '3px',
            'margin-left': '10px',
            border: '2px solid #000000',
            'margin-right': '10px'
        }

        if (props.value.length > 0) {
            leftBarStyle = {
                width: '2px',
                background: '#000000',
                'border-radius': '3px',
                'margin-left': '10px',
                border: '2px solid  #000000',
                'margin-right': '10px'
            }
        }

        this.state = {
            bookings: props.value,
            key: props.key,
            leftBar: leftBarStyle,
            infoBoxText: "Please, try again later",
            loading: false
        }
        this.loading = this.loading.bind(this)
        this.setBookings = this.setBookings.bind(this)
    };

    updateleftBarStyle = () => {
        var leftBarStyle = {
            width: '2px',
            background: '#000000',
            'border-radius': '3px',
            'margin-left': '10px',
            border: '2px solid #000000',
        }

        if (this.state.bookings.length > 0) {
            leftBarStyle = {
                width: '2px',
                background: ' #000000',
                'border-radius': '3px',
                'margin-left': '10px',
                border: '2px solid  #000000',
            }
        }
        this.setState({ leftBar: leftBarStyle })
    }
    loading(val) {
        this.setState({
            loading: val
        })
    }

    setBookings(bookingsList) {
        this.setState({
            bookings: bookingsList
        })
    }

    leadingActions = () => (
        <LeadingActions>
            <SwipeAction onClick={() => console.info('swipe action triggered')}>
                Action name
            </SwipeAction>
        </LeadingActions>
    );


    trailingActions = (booking_id) => (
        <TrailingActions>
            <SwipeAction
                destructive={false} onClick={() => this.deleteBooking(booking_id)}
            ><div className="deleteContainer">
                    <div className="iconCentered">
                        {/* <span className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
              </svg>
            </span> */}
                        Delete
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    );


    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.value !== this.state.bookings) {

            var leftBarStyle = {
                width: '2px',
                background: '#000000',
                'border-radius': '3px',
                'margin-left': '10px',
                border: '2px solid #000000',
                'margin-right': '9px'
            }

            if (nextProps.value.length > 0) {
                leftBarStyle = {
                    width: '2px',
                    background: ' #000000',
                    'border-radius': '3px',
                    'margin-left': '10px',
                    border: '2px solid  #000000',
                    'margin-right': '9px'
                }
            }

            this.setState({ bookings: nextProps.value, leftBar: leftBarStyle });
        }
    }


    deleteBooking = (bookingID) => {
        this.props.loading(true)

        let ds = new DeskService()
        ds.deleteBooking(bookingID).then(() => {
            this.props.loading(false)
            //  this.props.reload()
            this.props.update()
        })
    }


    writeBookings = () => {
        var wrottenBookings = [];

        var bookings = this.state.bookings;

        if (bookings.length > 0) {
            for (let i = 0; i < bookings.length; i++) {
                /*var date = new Date(bookings[i].date);
                var dateW = date.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' });
                dateW = dateW.substring(0, dateW.length - 6) + dateW.substring(dateW.length - 5, dateW.length);
          
                wrottenBookings.push(<p key={bookings[i].id + "date"}> {dateW} | Room {bookings[i].room}</p>);
                wrottenBookings.push(<p className="BookingDetailData" key={bookings[i].id + "info"}> {bookings[i].location?.name} | Area {bookings[i].area} | {bookings[i].floor?.name} | Desk {bookings[i].desk} </p>);
                wrottenBookings.push(<p className="BookingDetailData" key={bookings[i].id + "time"}> {bookings[i].time} </p>);*/
                var date = new Date(bookings[i].date);
                var dateW = date.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' });
                dateW = dateW.substring(0, dateW.length - 6) + dateW.substring(dateW.length - 5, dateW.length);
                if (!bookings[i].away && !bookings[i].holiday) {
                    wrottenBookings.push(<div key={bookings[i].id} className='flex'><div style={this.state.leftBar}></div>  <div ><p key={bookings[i].id + "date"}> {dateW} | Room {bookings[i].room}</p>
                        <p className="BookingDetailData" key={bookings[i].id + "info"}> {bookings[i].location?.name} | Area {bookings[i].area} | {bookings[i].floor?.name} | Desk {bookings[i].desk} </p>
                        <p className="BookingDetailData" key={bookings[i].id + "time"}> {bookings[i].time} | Canteen {bookings[i].canteen ? <IoIosCheckmark style={{ fontSize: '2em', transform: 'translate(-20%, -5%)' }} /> : <IoIosClose style={{ fontSize: '2em', transform: 'translate(-20%, -5%)' }} />}</p>
                    </div>
                    </div>)
                } else if (bookings[i].away && bookings[i].floor?.id) {
                    wrottenBookings.push(<div key={bookings[i].id} className='flex'><div style={this.state.leftBar}></div><div >
                        <p key={bookings[i].id + "date"}> {dateW} | Room {bookings[i].room}</p>
                        <p className="BookingDetailData" key={bookings[i].id + "info"}> {bookings[i].location?.name} | Area {bookings[i].area} | {bookings[i].floor?.name} | Desk {bookings[i].desk} </p>
                        <p className="BookingDetailData" key={bookings[i].id + "time"}> {bookings[i].time} | Away | Canteen {bookings[i].canteen ? <IoIosCheckmark style={{ fontSize: '2em', transform: 'translate(-20%, -5%)' }} /> : <IoIosClose style={{ fontSize: '2em', transform: 'translate(-20%, -5%)' }} />}</p>
                    </div> </div>)
                } else if (bookings[i].away) {
                    wrottenBookings.push(<div key={bookings[i].id} className='flex'><div style={this.state.leftBar}></div><div >
                        <p key={bookings[i].id + "date"}> {dateW} | {bookings[i].location?.name}</p>
                        <p className="BookingDetailData" key={bookings[i].id + "time"}> {bookings[i].time} | Away | Canteen {bookings[i].canteen ? <IoIosCheckmark style={{ fontSize: '2em', transform: 'translate(-20%, -5%)' }} /> : <IoIosClose style={{ fontSize: '2em', transform: 'translate(-20%, -5%)' }} />}</p>
                    </div> </div>)
                } else {
                    wrottenBookings.push(<div key={bookings[i].id} className='flex'><div style={this.state.leftBar}></div><div>
                        <p key={bookings[i].id + "date"}> {dateW}</p>
                        <p className="BookingDetailData" key={bookings[i].id + "time"}> {bookings[i].time} | Day off </p>
                    </div></div>)
                }
            }
            return (
                <SwipeableList
                    fullSwipe={true}
                    // style={{ backgroundColor: '#999878' }}
                    type={ListType.IOS}
                    threshold={0.85}
                >

                    {wrottenBookings.map((booking, index) => (

                        < SwipeableListItem
                            key={this.state.bookings[index].id}
                            //leadingActions={this.leadingActions()}
                            trailingActions={this.trailingActions(this.state.bookings[index].id)}
                        >
                            {booking}
                        </SwipeableListItem>

                    ))
                    }
                </SwipeableList >
            )

        } else {
            wrottenBookings.push(<p key="noBookings">No bookings for the selected day</p>);
            wrottenBookings.push(<p className="BookingDetailData" key="tapForAvailability">Tap to see availability</p>);
            return wrottenBookings;
        }


    }

    render() {
        if (this.state.loading)
            return (<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>)
        else
            return (
                <div>
                    <div className="BookingContainer">

                        <div className="BookingDetail">
                            {this.state.bookings.length > 0 ? this.writeBookings() : ''}
                        </div>
                    </div>


                </div>
            );
    }
}

export default BookingDetailMonth;
