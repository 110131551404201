import { useEffect } from "react"
import { VrService } from "../Services/Services"

export function HeadsetDetail(props) {

    useEffect(() => {
        let a = document.getElementsByClassName('vr_global_container')
        if (a && a[0])
            a[0].scrollTo(0, 0, 'smooth')
    }, [])

    //translation applanga function
    const getTranslationByKey = (key) => {
        let translation = ''
        let translation_en = ''
        if (sessionStorage.getItem('translation')) {
            translation = JSON.parse(sessionStorage.getItem('translation'))
            translation_en = JSON.parse(sessionStorage.getItem('translation_en'))
            return translation[key] ? translation[key].v : (translation_en[key] && translation_en[key].v ? translation_en[key].v : key)
        } else {
            return key
        }
    }

    //borrow headset vr 
    const borrowVr = () => {
        let vs = new VrService()
        vs.borrowVr(props.headset.id).then((data) => {
            props.back()
        })
    }

    //return headset vr
    const returnVr = () => {
        let vs = new VrService()
        vs.returnVr(props.headset.id).then((data) => {
            props.back()
        })
    }

    return (
        <div>
            <div className="vr_infos_image_container">
                <div id="vr_headset_image" Style={"background-image:url(" + (props.headset.image_url ? props.headset.image_url : '') + ");"}></div>

                <div Style="text-align: -webkit-left;">
                    <div>
                        <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('VR.DETAILS.TITLE.KEY')}</div>
                        <div>{props.headset.title}</div>
                    </div>
                    <div className="vr_headset_line">
                        <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('VR.DETAILS.DURATION.KEY')}</div>
                        <div>{props.headset.duration}</div>
                    </div>
                    <div className="vr_headset_line">
                        <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('VR.DETAILS.AVAILABLECOPY.KEY')}</div>
                        <div>{props.headset.available_copies}</div>
                    </div>
                    <div className="vr_headset_line">
                        <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('VR.DETAILS.TOTALCOPY.KEY')}</div>
                        <div>{props.headset.total_copies}</div>
                    </div>
                </div>
            </div>

            <div Style={"text-align: center; text-align: -webkit-center; "}>
                {props.headset.borrowed ?
                    <div className="bookshelf_detail_button pointer" onClick={returnVr}>{getTranslationByKey('VR.DETAILS.RETURN')}</div>
                    :
                    (!props.headset.available ?
                        <div className="bookshelf_detail_button disabled_vr_button pointer"  >{getTranslationByKey('VR.DETAILS.BUTTON.NOTAVAILABLE')}</div>
                        :
                        <div className="bookshelf_detail_button pointer" onClick={borrowVr} >{getTranslationByKey('VR.DETAILS.BORROW')}</div>
                    )
                }
            </div>
            <div>
                <div>
                    <div className="mt-2" Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('VR.DETAILS.DESCRIPTION.KEY')}</div>
                    <div>{props.headset.description}</div>
                </div>
                <div className="vr_headset_line">
                    <div Style={"font-family: 'GucciSans-Bold';"}>{getTranslationByKey('VR.DETAILS.BORROWING.PERIOD.KEY')}</div>
                    <div>{props.headset.borrowing_period}</div>
                </div>
                <div className="vr_headset_line" Style={"text-align: -webkit-center; margin-top: 30px;"}>
                    <a href={"mailto:ccms-bit@gucci.com"}>
                        <div>{getTranslationByKey('VR.DETAIL.DONATEVR')}</div>
                    </a>
                </div>
            </div>
        </div>
    )


}